import React from 'react';

import { DepartmentErrorType } from 'model/DepartmentErrorType';
import { DepartmentErrorsWrapper } from 'view/DepartmentsPage/AddDepartments/AddDepartments.s';

export namespace GroupDepartmentsErrors {
  export type Props = {
    errors: any[];
  };
}

export const departmentsErrors = [
  {
    type: DepartmentErrorType.NotUniqueName,
    message: 'Departments must be unique - the following have the same names:',
  },
  {
    type: DepartmentErrorType.MissingParent,
    message:
      'Departments in level 2 and below must have a parent department - the following do not:',
  },
  {
    type: DepartmentErrorType.OneDepartmentPerRow,
    message: 'There can only be one Department per row:',
  },
  {
    type: DepartmentErrorType.TooLongName,
    message: 'A department name can be a maximum of 128 characters:',
  },
  {
    type: DepartmentErrorType.InvalidDepartmentStructure,
    message: 'Department structure must start from cell A1:',
  },
];

export const GroupDepartmentsErrors: React.VFC<GroupDepartmentsErrors.Props> = ({ errors }) => (
  <DepartmentErrorsWrapper>
    <h5>Oops - something went wrong:</h5>
    {departmentsErrors.map(
      (departmentError, index) =>
        errors.find((error: any) => error.message === departmentError.type) && (
          <div key={index}>
            <p>{departmentError.message}</p>
            <ul>
              {errors
                .filter((error: any) => error.message === departmentError.type)
                .map((error: any, i: number) => (
                  <li key={i}>{error.path}</li>
                ))}
            </ul>
          </div>
        )
    )}
    <h6>Please fix this and then try uploading again. Thank you</h6>
  </DepartmentErrorsWrapper>
);
