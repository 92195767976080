import { createAsyncThunk } from '@reduxjs/toolkit';
import { EmployeeApi } from 'api/EmployeeApi';
import { demographicRepository } from 'container/demographicRepository';
import { employeeRepository } from 'container/employeeRepository';
import { serializeError } from 'shared/utils/redux';

import { STORE_NAME } from './initialState';

export const fetchLifecycleDemographics = createAsyncThunk(
  `${STORE_NAME}/fetchLifecycleDemographics`,
  ({ pulseLifecycleProjectId }: { pulseLifecycleProjectId: number }) => {
    return demographicRepository.list(pulseLifecycleProjectId);
  },
  { serializeError }
);

export const fetchLifecycleEmployeeList = createAsyncThunk(
  `${STORE_NAME}/fetchLifecycleEmployeeList`,
  ({
    pulseLifecycleProjectId,
    page,
    limit,
    createdAtSince,
    sort,
  }: {
    pulseLifecycleProjectId: number;
    page: number;
    limit: number;
    createdAtSince: string;
    sort: EmployeeApi.SortParam;
  }) => {
    return employeeRepository.list({
      projectId: pulseLifecycleProjectId,
      page,
      limit,
      sort,
      createdAtSince,
    });
  },
  { serializeError }
);
