import { PermissionFlag } from 'model/PermissionFlag';
import { PermissionGroup } from 'model/PermissionGroup';
import { PermissionTypes } from 'model/PermissionTypes';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'permissions';

export namespace PermissionsState {
  export type PermissionGroupResource = Resource<PermissionGroup[]>;

  export type Domain = {
    permissionTypes: PermissionTypes;
    permissionGroups: PermissionGroupResource;
    permissionFlags: PermissionFlag[];
  };

  export const INITIAL_DOMAIN: Domain = {
    permissionTypes: new PermissionTypes({
      primary_feature: [],
      feature: [],
      comment_demographic: [],
      comment_department: [],
      summary_demographic: [],
      summary_department: [],
      access: [],
    }),
    permissionGroups: Resource.void(),
    permissionFlags: [],
  };
}
