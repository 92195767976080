import { SimpleModel } from 'model/SimpleModel';

import { PermissionGroup } from './PermissionGroup';

export namespace User {
  export interface Shape {
    id?: string;
    firstName?: string;
    lastName?: string;
    email: string;
    permissions?: string[];
    groups?: PermissionGroup[];
    department?: number;
    password?: string;
    role?: string;
    isClientAdmin?: boolean;
  }
}

export class User extends SimpleModel<User.Shape> {
  static readonly MIN_PERSON_AGE_TO_WORK = 16;

  getId(): string {
    return this.get('id');
  }

  getFirstName(): string {
    return this.get('firstName');
  }

  getLastName(): string {
    return this.get('lastName');
  }

  getEmail(): string {
    return this.get('email');
  }

  getRole(): string {
    return this.get('role');
  }

  isClientAdmin(): boolean {
    return this.get('isClientAdmin');
  }

  getPassword(): string {
    return this.get('password');
  }

  getDepartment(): number {
    return Number(this.get('department'));
  }

  getPermissions(): string[] {
    return this.get('permissions', []);
  }

  getPermission(objectType: string): string | undefined {
    return this.get('permissions', []).find((permission) => permission.includes(objectType));
  }

  getDepartmentId(objectType: string): string {
    return this.getPermission(objectType).split('-').slice(-1)[0];
  }

  removeDepartment(): this {
    return this.remove('department');
  }

  getPermissionGroups(): PermissionGroup[] {
    return this.get('groups', []);
  }

  getPermissionGroup(projectId: number): PermissionGroup {
    return this.get('groups').find(
      (group) => group.getObjectIdentifier() === `project-${projectId}`
    );
  }

  hasClientAdminRole(): boolean {
    return !!this.get('groups').find((group) => PermissionGroup.isClientAdmin(group.getName()));
  }

  addPermissionGroup(newPermissionGroup: PermissionGroup): this {
    return this.update('groups', (groups) => [
      ...groups.filter(
        (group) => group.getObjectIdentifier() !== newPermissionGroup.getObjectIdentifier()
      ),
      newPermissionGroup,
    ]);
  }
}
