import { DeliveryStatisticApi } from 'api/DeliveryStatisticApi';
import { List } from 'immutable';
import { DeliveryStatisticMapper } from 'mapper/DeliveryStatisticMapper';
import { DeliveryStatistic } from 'model/DeliveryStatistic';

export class DeliveryStatisticRepository {
  constructor(
    private deliveryStatisticApi: DeliveryStatisticApi,
    private deliveryStatisticMapper: DeliveryStatisticMapper
  ) {}

  fetch(id: string, daysLimit: number = 0): Promise<List<DeliveryStatistic>> {
    return this.deliveryStatisticApi
      .fetch(id, daysLimit)
      .then((entries) =>
        List(entries.map((entry) => this.deliveryStatisticMapper.deserialize(entry)))
      );
  }

  refresh(distributionId: string, emailId: number): Promise<number> {
    return this.deliveryStatisticApi.refresh(distributionId, emailId).then((entry) => entry);
  }
}
