import { ScheduleApi } from 'api/ScheduleApi';
import { ScheduleMapper } from 'mapper/ScheduleMapper';
import { Schedule } from 'model/Schedule';

export class ScheduleRepository {
  constructor(private scheduleApi: ScheduleApi, private scheduleMapper: ScheduleMapper) {}

  schedule(emailTemplateId: number, model: Schedule): Promise<Schedule> {
    return this.scheduleApi
      .schedule(emailTemplateId, this.scheduleMapper.serialize(model))
      .then((entry) => this.scheduleMapper.deserialize(entry));
  }

  unschedule(emailTemplateId: number, scheduleId: number): Promise<void> {
    return this.scheduleApi.unschedule(emailTemplateId, scheduleId);
  }
}
