import { selectApiData, selectApiNoContent } from 'util/selector/apiSelector';

import { ApiMessage } from 'api/const';
import { WithData } from 'api/interfaces';
import { PageItemApi } from 'api/PageItemApi';
import { AxiosInstance } from 'axios';

export namespace PageApi {
  export type Entry = {
    id: number;
    position?: number;
    items?: PageItemApi.Entry[];
  };
}

export class PageApi {
  constructor(private client: AxiosInstance) {}

  list(projectId: number): Promise<PageApi.Entry[]> {
    return this.client
      .get<WithData<PageApi.Entry[]>>(`/api/v1/project/${projectId}/page`)
      .then(selectApiData);
  }

  create(projectId: number, page: PageApi.Entry): Promise<PageApi.Entry> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...data } = page;

    return this.client
      .post(`/api/v1/project/${projectId}/page`, {
        ...data,
      })
      .then(selectApiData);
  }

  update(page: PageApi.Entry): Promise<PageApi.Entry> {
    const { id, ...data } = page;

    return this.client
      .post<WithData<PageApi.Entry>>(`/api/v1/page/${id}`, { ...data })
      .then(selectApiData);
  }

  delete(pageId: number): Promise<void> {
    return this.client
      .delete<ApiMessage.Deleted>(`/api/v1/page/${pageId}`)
      .then(selectApiNoContent);
  }

  reorderItems(pageId: number, order: number[]): Promise<any> {
    return this.client.post(`/api/v1/page/${pageId}/reorder-items`, { order });
  }
}
