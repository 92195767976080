import { createAsyncThunk } from '@reduxjs/toolkit';
import { kioskDistributionRepository } from 'container/kioskRepository';
import { STORE_NAME } from 'reduxStore/kioskDistribution/initialState';
import { selectCurrentProjectId } from 'reduxStore/project/projectSelector';
import { serializeError } from 'shared/utils/redux';

export const fetchKioskDistribution = createAsyncThunk(
  `${STORE_NAME}/fetchKioskDistribution`,
  (_, { getState }) => {
    const projectId = selectCurrentProjectId(getState());
    return kioskDistributionRepository.fetch(projectId);
  },
  { serializeError }
);
