import { List } from 'immutable';
import { ProjectTemplate } from 'model/ProjectTemplate';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'projectTemplate';

export namespace ProjectTemplateState {
  export type Domain = {
    listResource: Resource<List<ProjectTemplate>>;
    currentResource: Resource<ProjectTemplate>;
  };

  export const INITIAL_DOMAIN: Domain = {
    listResource: Resource.void(),
    currentResource: Resource.void(),
  };
}
