import { PulseProjectApi } from 'api/PulseProjectApi';
import { Project } from 'model/Project';
import moment from 'moment';

export class PulseProjectMapper {
  deserialize(entry: PulseProjectApi.SubProject): Project {
    const formattedLiveStart = entry.liveStart && entry.liveStart.split('T')[0];
    const formattedLiveStop = entry.liveStop && entry.liveStop.split('T')[0];

    return new Project({
      name: entry.name,
      updatedAt: moment.utc(entry.updatedAt),
      status: entry.status,
      kind: entry.kind,
      liveStart: moment(formattedLiveStart),
      liveStop: moment(formattedLiveStop),
      responseRate: entry.responseRate,
      id: entry.id,
      // TODO separate SubProject and Project class. Sub project should not use full Project model
    } as Project.Shape);
  }
}
