import { selectApiData } from 'util/selector/apiSelector';

import { DistributionApi } from 'api/DistributionApi';
import { AxiosInstance } from 'axios';

export namespace PaperDistributionApi {
  export type Entry = DistributionApi.Entry & {
    surveyLink: string;
  };
}

export class PaperDistributionApi {
  constructor(private client: AxiosInstance) {}

  get(projectId: number): Promise<PaperDistributionApi.Entry> {
    return this.client.get(`/api/v1/project/${projectId}/print-distribution`).then(selectApiData);
  }
}
