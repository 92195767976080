import { resortList } from 'util/resortList';

import { Iterable, List } from 'immutable';
import { AsyncModel } from 'model/AsyncModel';
import { DiscreteQuestion } from 'model/DiscreteQuestion';
import { ImageWidget } from 'model/ImageWidget';
import { PageItem } from 'model/PageItem';
import { PageItemKind } from 'model/PageItemKind';
import { Question } from 'model/Question';
import { QuestionOpinion } from 'model/QuestionOpinion';
import { TextWidget } from 'model/TextWidget';

export namespace Page {
  export interface Shape {
    id?: number;
    position?: number;
    items?: List<PageItem>;
  }
}

export class Page extends AsyncModel<Page.Shape> {
  getId(): number {
    return this.get('id');
  }

  getPosition(): number {
    return this.get('position');
  }

  setPosition(position: number): this {
    return this.set('position', position);
  }

  moveUp(): this {
    return this.update('position', (position) => position - 1);
  }

  moveDown(): this {
    return this.update('position', (position) => position + 1);
  }

  getItems(): List<PageItem> {
    return this.get('items', List<PageItem>())
      .sortBy((item) => item.getPosition())
      .toList();
  }

  isEmpty(): boolean {
    return this.getItems().isEmpty();
  }

  hasRecommendQuestion(): boolean {
    const items = this.get('items', List<PageItem>());

    return !!items.find(
      (item) =>
        item.getKind() === PageItemKind.QuestionOpinion &&
        (item as QuestionOpinion).getIsRecommendedQuestion()
    );
  }

  hasConsentQuestion(): boolean {
    return !!this.getItems().find((item) => item.getKind() === PageItemKind.QuestionConsent);
  }

  getQuestions(): List<Question> {
    return this.getItems()
      .filter((item) => item instanceof Question)
      .toList() as List<Question>;
  }

  getMediaQuestions(): List<ImageWidget | TextWidget> {
    return (this.getItems().filter(
      (item) => item instanceof ImageWidget || item instanceof TextWidget
    ) as Iterable<number, ImageWidget | TextWidget>).toList();
  }

  getDiscreteQuestions(): List<Question & DiscreteQuestion> {
    return this.getQuestions()
      .filter(
        (question) =>
          question.getKind() !== PageItemKind.QuestionCommentsBox &&
          question.getKind() !== PageItemKind.QuestionForm
      )
      .toList() as List<Question & DiscreteQuestion>;
  }

  setItems(items: List<PageItem>): this {
    return this.set('items', items);
  }

  removeItem(itemId: number): this {
    return this.update('items', (items) =>
      items.filter((item) => item.getId() !== itemId).toList()
    );
  }

  findItem(itemId: number): PageItem {
    return this.getItems().find((item) => item.getId() === itemId);
  }

  moveItem(fromPosition: number, toPosition: number): this {
    return this.update('items', (items) =>
      resortList(items, fromPosition, toPosition)
        .map((item, position) => item.setPosition(position + 1))
        .toList()
    );
  }

  insertItem(toPosition: number, pageItem: PageItem): this {
    return this.update('items', (items) =>
      items
        .insert(toPosition, pageItem)
        .map((item, position) => item.setPosition(position + 1))
        .toList()
    );
  }
}
