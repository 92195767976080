import { AuthApi } from 'api/AuthApi';
import { JWTUserMapper } from 'mapper/JWTUserMapper';
import { JWTUser } from 'model/JWTUser';

export class AuthRepository {
  constructor(private authApi: AuthApi, private jwtUserMapper: JWTUserMapper) {}

  logged(): Promise<Readonly<JWTUser>> {
    return this.authApi.logged().then((entry) => this.jwtUserMapper.deserialize(entry));
  }

  resetPasswordRequest({
    email,
    currentCircle,
  }: AuthApi.ResetPasswordRequestParams): Promise<unknown> {
    return this.authApi.resetPasswordRequest({ email, currentCircle });
  }

  resetMfaRequest({ email }: { email: string }): Promise<unknown> {
    return this.authApi.resetMfaRequest({ email });
  }

  logout(): Promise<void> {
    return this.authApi.logout();
  }
}
