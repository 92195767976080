import { createSlice } from '@reduxjs/toolkit';
import { Resource } from 'model/Resource';
import { fetchProjectSubmits } from 'reduxStore/projectSubmit/asyncActions';

import { ProjectSubmitState, STORE_NAME } from './initialState';

export const projectSubmitSlice = createSlice({
  name: STORE_NAME,
  initialState: ProjectSubmitState.INITIAL_DOMAIN,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectSubmits.pending, (state, action) => {
        if (action.meta.arg.projectId !== state.projectId) {
          state.listResource = Resource.pending();
        }
        state.projectId = action.meta.arg.projectId;
      })
      .addCase(fetchProjectSubmits.fulfilled, (state, action) => {
        state.listResource = Resource.resolve(action.payload);
      })
      .addCase(fetchProjectSubmits.rejected, (state, action) => {
        state.listResource = Resource.reject(action.error);
      });
  },
});
