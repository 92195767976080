import { createSlice } from '@reduxjs/toolkit';
import { Exception } from 'exception/Exception';
import { List } from 'immutable';
import { deleteTrend, fetchTrendDataList, uploadTrendData } from 'reduxStore/trend/asyncActions';

import { STORE_NAME, TrendState } from './initialState';

export const trendSlice = createSlice({
  name: STORE_NAME,
  initialState: TrendState.INITIAL_DOMAIN,
  reducers: {
    clearErrors: (state) => {
      state.errors = TrendState.INITIAL_DOMAIN.errors;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrendDataList.fulfilled, (state, action) => {
        state.list = action.payload;
      })

      .addCase(deleteTrend.fulfilled, (state, action) => {
        // removing trend locally on deleteTrend.fulfilled
        state.list = List(state.list.filter((item) => item.getId() !== action.payload));
      })

      .addCase(uploadTrendData.fulfilled, (state, action) => {
        state.list = List([action.payload, ...state.list.toArray()]);
        state.errors = null;
      })

      .addCase(uploadTrendData.rejected, (state, action) => {
        state.errors = List((action.payload as Exception).getExceptionList());
      });
  },
});

export const { clearErrors } = trendSlice.actions;
