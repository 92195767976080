import { selectApiData, selectApiNoContent } from 'util/selector/apiSelector';

import { AxiosInstance } from 'axios';

export namespace ScheduleApi {
  export type Entry = {
    id?: number;
    date?: string;
    relativeDate?: string;
    lastSent?: string;
  };
}

export class ScheduleApi {
  constructor(private client: AxiosInstance) {}

  schedule(emailTemplateId: number, entry: ScheduleApi.Entry): Promise<ScheduleApi.Entry> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { lastSent, ...data } = entry;

    return this.client
      .post(`/api/v1/email-template/${emailTemplateId}/schedule`, data)
      .then(selectApiData);
  }

  unschedule(emailTemplateId: number, scheduleId: number): Promise<void> {
    return this.client
      .delete(`/api/v1/email-template/${emailTemplateId}/schedule/${scheduleId}`)
      .then(selectApiNoContent);
  }
}
