import { createAsyncThunk } from '@reduxjs/toolkit';
import { categoryRepository } from 'container/categoryRepository';
import { serializeError } from 'shared/utils/redux';

import { STORE_NAME } from './initialState';

export const fetchProjectCategories = createAsyncThunk(
  `${STORE_NAME}/fetchProjectCategories`,
  ({ projectId }: { projectId: number }) => {
    return categoryRepository.list(projectId);
  },
  { serializeError }
);
