import { PaperDistribution } from 'model/PaperDistribution';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'paperDistribution';

export namespace PaperDistributionState {
  export type Domain = {
    distributionResource: Resource<PaperDistribution>;
  };

  export const INITIAL_DOMAIN: Domain = {
    distributionResource: Resource.void<PaperDistribution>(),
  };
}
