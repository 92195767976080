import React from 'react';

import { grey } from '@mui/material/colors';
import CardContent from '@mui/material/CardContent';
import { ProjectRateWrapper } from 'component/ProjectRate/ProjectRate.s';

export namespace ProjectRate {
  export type Props = {
    rate: number;
    className?: string;
  };
}

export const ProjectRate: React.VFC<ProjectRate.Props> = (props) => (
  <ProjectRateWrapper className={props.className}>
    <CardContent>
      <h5 color={grey[500]}>Response Rate</h5>
      <h2 data-testid="responseRate">{Math.round(props.rate * 100)} %</h2>
    </CardContent>
  </ProjectRateWrapper>
);
