import { createAsyncThunk } from '@reduxjs/toolkit';
import { permissionsRepository } from 'container/permissionsRepository';
import { serializeError } from 'shared/utils/redux';

import { STORE_NAME } from './initialState';

export const fetchPermissions = createAsyncThunk(
  `${STORE_NAME}/fetchPermissions`,
  ({ projectId }: { projectId: number }) => {
    return permissionsRepository.get(projectId);
  },
  { serializeError }
);

export const fetchPermissionGroups = createAsyncThunk(
  `${STORE_NAME}/fetchPermissionGroups`,
  ({ projectId }: { projectId: number }) => {
    return permissionsRepository.getPermissionGroups(projectId);
  },
  { serializeError }
);

export const fetchPermissionFlags = createAsyncThunk(
  `${STORE_NAME}/fetchPermissionFlags`,
  () => {
    return permissionsRepository.getFlags();
  },
  { serializeError }
);
