import { isArrayEmpty } from 'util/isArrayEmpty';

import { DemographicApi } from 'api/DemographicApi';
import { List } from 'immutable';
import { ClassificationMapper } from 'mapper/ClassificationMapper';
import { Demographic } from 'model/Demographic';

export class DemographicMapper {
  constructor(private classificationMapper: ClassificationMapper) {}

  serialize(model: Demographic): DemographicApi.Entry {
    return {
      title: model.getTitle(),
      position: model.getPosition(),
      reportedAs: model.getReportedAs(),
      classifications: !isArrayEmpty(model.getClassifications())
        ? model
            .getClassifications()
            .map((classification) => this.classificationMapper.serialize(classification))
            .sortBy((item) => item.position)
            .toArray()
        : undefined,
    };
  }

  deserialize(entry: DemographicApi.Entry): Demographic {
    return new Demographic({
      id: entry.id,
      title: entry.title,
      reportedAs: entry.reportedAs,
      position: entry.position,
      kind: entry.kind,
      classifications: List(
        (entry.classifications || [])
          .filter((classificationEntry) => !!classificationEntry)
          .map((classificationEntry) => this.classificationMapper.deserialize(classificationEntry))
      ),
    });
  }
}
