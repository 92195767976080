import { PulseProjectApi } from 'api/PulseProjectApi';
import { List } from 'immutable';
import { ProjectMapper } from 'mapper/ProjectMapper';
import { PulseTemplate } from 'model/PulseTemplate';

export class PulseTemplateMapper {
  constructor(private projectMapper: ProjectMapper) {}

  deserialize(entry: PulseProjectApi.Entry): PulseTemplate {
    const {
      project,
      pulseProjects = [],
      pulseOnDemandProjects = [],
      pulseLifecycleProjects = [],
    } = entry;

    return new PulseTemplate({
      hasChildProjectsAnyPublishableSubmits: entry.hasChildProjectsAnyPublishableSubmits,
      project: this.projectMapper.deserialize(project),
      pulseProjects: List(pulseProjects.map((pulse) => this.projectMapper.deserialize(pulse))),
      pulseOnDemandPolls: List(
        pulseOnDemandProjects.map((OnDemand) => this.projectMapper.deserialize(OnDemand))
      ),
      pulseLifecycleProjects: List(
        pulseLifecycleProjects.map((lifecycle) => this.projectMapper.deserialize(lifecycle))
      ),
    });
  }
}
