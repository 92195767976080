import React from 'react';
import { Link } from 'react-router-dom';

import { Button, TableCell } from '@mui/material';
import { ClientRoute } from 'app/route/client';
import TableRow from '@mui/material/TableRow';
import { Client } from 'model/Client';
import { getClientPlanName } from 'model/ClientPlanType';
import { compilePath } from 'router/compilePath';

namespace ClientsTableBody {
  export type Props = {
    client: Client;
    onViewClick: (id: string) => void;
  };
}

export const ClientsTableBody: React.VFC<ClientsTableBody.Props> = (props) => {
  return (
    <TableRow data-testid="clientItem">
      <TableCell data-testid="clientName">{props.client.getName()}</TableCell>
      <TableCell data-testid="clientPlan">{getClientPlanName(props.client.getPlan())}</TableCell>
      <TableCell align="center">
        <Button
          data-testid="viewClient"
          href={props.client.getUrl()}
          color="primary"
          target="_blank"
        >
          View
        </Button>
        <Button
          to={compilePath(ClientRoute.Project.ACCOUNT_SETTINGS, {
            id: props.client.getFirstPulseTemplateId(),
          })}
          component={Link}
          color="secondary"
          data-testid="editClient"
        >
          Edit
        </Button>
      </TableCell>
    </TableRow>
  );
};
