import { selectApiNoContent } from 'util/selector/apiSelector';

import { ApiMessage } from 'api/const';
import { AxiosInstance } from 'axios';

export namespace TrendDataApi {
  export type Entry = {
    id: number;
    title: string;
  };
}

export class TrendDataApi {
  constructor(private client: AxiosInstance) {}

  list(id: number): Promise<TrendDataApi.Entry[]> {
    return this.client.get(`/api/v1/project/${id}/trend`).then((response) => response.data.data);
  }

  delete(id: number): Promise<void> {
    return this.client.delete<ApiMessage.Deleted>(`/api/v1/trend/${id}`).then(selectApiNoContent);
  }

  upload(file: File, id: number): Promise<TrendDataApi.Entry> {
    const data = new FormData();
    data.append('document', file);

    return this.client
      .post(`/api/v1/project/${id}/trend-excel`, data)
      .then((response) => response.data.data[0]);
  }
}
