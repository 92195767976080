import { List } from 'immutable';
import { EmployeeStats } from 'model/EmploeeStats';
import { Employee } from 'model/Employee';
import { Pagination } from 'model/Pagination';

export const STORE_NAME = 'employee';

export namespace EmployeeState {
  export type Domain = {
    list: List<Employee>;
    chosenEmployees: List<Employee>;
    listPagination: Pagination | null;
    employeeUploadStats: EmployeeStats | null;
  };

  export const INITIAL_DOMAIN: Domain = {
    list: List.of(),
    chosenEmployees: List.of(),
    listPagination: null,
    employeeUploadStats: null,
  };
}
