import { PageItemTranslationDto } from 'api/dto/PageItemTranslationsDto';
import { PageItemApi } from 'api/PageItemApi';
import { List } from 'immutable';
import { PageItemMapper } from 'mapper/PageItemMapper';
import { PageItem } from 'model/PageItem';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';

export class PageItemRepository {
  constructor(private pageItemApi: PageItemApi, private pageItemMapper: PageItemMapper) {}

  list(pageId: number): Promise<List<PageItem>> {
    return this.pageItemApi
      .list(pageId)
      .then((entries) => List(entries.map((entry) => this.pageItemMapper.deserialize(entry))));
  }

  create(pageId: number, model: PageItem, shouldSendFormData?: boolean): Promise<PageItem> {
    return this.pageItemApi
      .create(pageId, this.pageItemMapper.serialize(model), shouldSendFormData)
      .then((entry) => this.pageItemMapper.deserialize(entry));
  }

  createAndList(pageId: number, model: PageItem): Promise<List<PageItem>> {
    return this.create(pageId, model).then(() => this.list(pageId));
  }

  savePageItemTranslations({
    itemId,
    translations,
  }: {
    itemId: number;
    translations: PageItemTranslationDto[];
  }): Promise<any> {
    // TODO: check what's returned and if we want to use it
    return this.pageItemApi.savePageItemTranslations({ itemId, translations });
  }

  getTranslatedPageItem({
    itemId,
    language,
  }: {
    itemId: number;
    language: LangCodeEnum;
  }): Promise<PageItem> {
    return this.pageItemApi
      .getTranslatedPageItem({ itemId, language })
      .then((pageItemEntry) => this.pageItemMapper.deserialize(pageItemEntry));
  }

  updateAndList(
    model: PageItem,
    pageId: number,
    shouldSendFormData?: boolean
  ): Promise<List<PageItem>> {
    return this.pageItemApi
      .update(this.pageItemMapper.serialize(model), shouldSendFormData)
      .then(() => this.list(pageId));
  }

  update(model: PageItem, shouldSendFormData?: boolean): Promise<PageItem> {
    return this.pageItemApi
      .update(this.pageItemMapper.serialize(model), shouldSendFormData)
      .then((entry) => this.pageItemMapper.deserialize(entry));
  }

  reorder(pageId: number, position: number, model: PageItem): Promise<List<PageItem>> {
    return this.pageItemApi
      .reorder(pageId, position, this.pageItemMapper.serialize(model))
      .then(() => this.list(pageId));
  }

  move(pageId: number, itemId: number, position: number): Promise<List<PageItem>> {
    return this.pageItemApi.move(pageId, itemId, position).then(() => this.list(pageId));
  }

  delete(itemId: number, pageId: number): Promise<List<PageItem>> {
    return this.pageItemApi.delete(itemId).then(() => this.list(pageId));
  }

  moveToPulseProject(itemId: number, projectId: number): Promise<void> {
    return this.pageItemApi.moveToPulseProject(itemId, projectId);
  }
}
