import { createSlice } from '@reduxjs/toolkit';
import { List } from 'immutable';
import { Resource } from 'model/Resource';
import { addAsyncCases } from 'shared/utils/redux';

import {
  fetchEmailDistribution,
  scheduleEmailTemplate,
  unscheduleEmailTemplate,
} from './asyncActions';
import { EmailDistributionState, STORE_NAME } from './initialState';

export const emailDistributionSlice = createSlice({
  name: STORE_NAME,
  initialState: EmailDistributionState.INITIAL_DOMAIN,
  reducers: {
    clearEmailDistribution: (state) => {
      state.distributionResource = EmailDistributionState.INITIAL_DOMAIN.distributionResource;
    },
  },
  extraReducers: (builder) => {
    addAsyncCases(builder, fetchEmailDistribution, (state, action) => {
      state.distributionResource = action.payload;
    });

    builder.addCase(scheduleEmailTemplate.fulfilled, (state, action) => {
      const newEmailTemplates = List(
        state.distributionResource
          .getContent()
          .getEmailTemplates()
          .toArray()
          .map((emailTemplate) =>
            emailTemplate.getId() === action.meta.arg.emailTemplateId
              ? emailTemplate.mergeSchedule(action.payload)
              : emailTemplate.clone()
          )
      );

      state.distributionResource = Resource.resolve(
        state.distributionResource.getContent().setEmailTemplates(newEmailTemplates)
      );
    });

    builder.addCase(unscheduleEmailTemplate.fulfilled, (state, action) => {
      const newEmailTemplates = List(
        state.distributionResource
          .getContent()
          .getEmailTemplates()
          .toArray()
          .map((emailTemplate) =>
            emailTemplate.getId() === action.meta.arg.emailTemplateId
              ? emailTemplate.removeScheduleById(action.meta.arg.scheduleId)
              : emailTemplate.clone()
          )
      );

      state.distributionResource = Resource.resolve(
        state.distributionResource.getContent().setEmailTemplates(newEmailTemplates)
      );
    });
  },
});

export const { clearEmailDistribution } = emailDistributionSlice.actions;
