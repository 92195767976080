import { createSlice } from '@reduxjs/toolkit';
import { List } from 'immutable';
import { Resource } from 'model/Resource';
import { addAsyncCases } from 'shared/utils/redux';

import { fetchLifecycleDemographics, fetchLifecycleEmployeeList } from './asyncActions';
import { PulseLifecycleState, STORE_NAME } from './initialState';

export const pulseLifecycleSlice = createSlice({
  name: STORE_NAME,
  initialState: PulseLifecycleState.INITIAL_DOMAIN,
  reducers: {
    clearLifecycleEmployeeList: (state) => {
      state.list = List.of();
      state.listPagination = null;
      state.demographicsResource = Resource.void();
    },
  },
  extraReducers: (builder) => {
    addAsyncCases(builder, fetchLifecycleDemographics, (state, action) => {
      state.demographicsResource = action.payload;
    });

    builder
      .addCase(fetchLifecycleEmployeeList.fulfilled, (state, action) => {
        state.list = action.payload.list;
        state.listPagination = action.payload.pagination;
      })
      .addCase(fetchLifecycleEmployeeList.rejected, (state) => {
        state.list = PulseLifecycleState.INITIAL_DOMAIN['list'];
        state.listPagination = PulseLifecycleState.INITIAL_DOMAIN['listPagination'];
      });
  },
});

export const { clearLifecycleEmployeeList } = pulseLifecycleSlice.actions;
