import { PlaceholderApi } from 'api/PlaceholderApi';
import { List } from 'immutable';
import { PlaceholderMapper } from 'mapper/PlaceholderMapper';
import { Placeholder } from 'model/Placeholder';

export class PlaceholderRepository {
  constructor(
    private placeholderApi: PlaceholderApi,
    private placeholderMapper: PlaceholderMapper
  ) {}

  fetch(projectId: number): Promise<List<Placeholder>> {
    return this.placeholderApi
      .fetch(projectId)
      .then((entries) =>
        List<Placeholder>(entries.map((entry) => this.placeholderMapper.deserialize(entry)))
      );
  }
}
