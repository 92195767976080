import { createTheme, ThemeOptions } from '@mui/material/styles';
import { colors } from 'app/theme/colors';

export const muiDialogPaperWidth = '770px';

export const themeOptions: ThemeOptions = {
  typography: {
    h1: {
      fontSize: '2.5rem',
      fontWeight: '300',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: '300',
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: '300',
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: '300',
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: '300',
    },
    h6: {
      fontSize: '1rem',
      fontWeight: '300',
    },
  },
  palette: {
    primary: {
      main: colors.cinnabar,
      dark: colors.tiaMaria,
    },
    secondary: {
      main: colors.shipGray,
    },
    background: {
      default: colors.porcelain,
    },
    error: {
      main: colors.pomegranate,
    },
  },
  shape: {
    borderRadius: 2,
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        standardWarning: {
          '& > .MuiAlert-icon': {
            color: `${colors.clementine} !important`,
            opacity: 1,
          },
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        onClick: (e) => {
          if ((e.target as HTMLElement).classList.contains('MuiDialog-container')) {
            e.preventDefault();
          }
        },
        BackdropProps: { sx: { pointerEvents: 'none' } },
      },
      styleOverrides: {
        paperFullWidth: {
          maxWidth: muiDialogPaperWidth,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 12,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h4',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.white,
          color: colors.shipGray,
          padding: '8px',
          borderRadius: '5px',
          boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
          fontWeight: 100,
          fontFamily: 'Roboto',
          fontSize: 13,
        },
      },
    },
  },
};

export const workbuzzTheme = createTheme(themeOptions);
