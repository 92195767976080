import { DemographicApi } from 'api/DemographicApi';
import { List } from 'immutable';
import { DemographicMapper } from 'mapper/DemographicMapper';
import { Demographic } from 'model/Demographic';

export class DemographicRepository {
  constructor(
    private demographicApi: DemographicApi,
    private demographicMapper: DemographicMapper
  ) {}

  list(projectId: number): Promise<List<Demographic>> {
    return this.demographicApi
      .list(projectId)
      .then((entries) => List(entries.map((entry) => this.demographicMapper.deserialize(entry))));
  }

  create(projectId: number, data: Demographic): Promise<Demographic> {
    return this.demographicApi
      .create(projectId, this.demographicMapper.serialize(data))
      .then((entry) => this.demographicMapper.deserialize(entry));
  }

  createDefault(projectId: number, model: Demographic): Promise<any> {
    return this.demographicApi
      .createDefault(projectId, this.demographicMapper.serialize(model))
      .then((entry) => this.demographicMapper.deserialize(entry));
  }

  update(projectId: number, data: Demographic): Promise<Demographic> {
    return this.demographicApi
      .update(projectId, data.getId(), this.demographicMapper.serialize(data))
      .then((entry) => this.demographicMapper.deserialize(entry));
  }

  delete(projectId: number, demographicId: number): Promise<any> {
    return this.demographicApi.delete(projectId, demographicId);
  }

  reorder(projectId: number, list: List<number>): Promise<any> {
    return this.demographicApi.reorder(projectId, list.toArray());
  }

  listReport(projectId: number): Promise<List<Demographic>> {
    return this.demographicApi
      .listReport(projectId)
      .then((entries) => List(entries.map((entry) => this.demographicMapper.deserialize(entry))));
  }
}
