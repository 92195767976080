import { List } from 'immutable';
import { Constant } from 'model/Constant';
import { Resource } from 'model/Resource';
import { Timezone } from 'model/Timezone';

export const STORE_NAME = 'configs';

export namespace ConfigsState {
  export type Domain = {
    constants?: Resource<Constant>;
    timezones?: Resource<List<Timezone>>;
  };

  export const INITIAL_CONSTANT: Domain['constants'] = Resource.void();
  export const INITIAL_TIMEZONE: Domain['timezones'] = Resource.void();

  export const INITIAL_DOMAIN: Domain = {
    constants: INITIAL_CONSTANT,
    timezones: INITIAL_TIMEZONE,
  };
}

export type ConfigsState = {
  configs?: ConfigsState.Domain;
};
