import React, { Component } from 'react';

import { ColorPicker } from 'component/ColorPicker/ColorPicker';
import { cover, popover, swatch, ColorPreview } from 'component/ColorPicker/ColorPicker.s';
import { WrappedFieldProps } from 'redux-form';

export namespace ColorPickerPopover {
  export type Props = WrappedFieldProps & {
    style?: any;
    popoverStyle?: any;
    'data-testid': string;
  };
  export type State = {
    isOpen: boolean;
  };
}

export class ColorPickerPopover extends Component<
  ColorPickerPopover.Props,
  ColorPickerPopover.State
> {
  state: ColorPickerPopover.State = {
    isOpen: false,
  };

  handlePreviewClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    this.setState({ isOpen: !this.state.isOpen });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  render(): JSX.Element {
    const style = {
      ...swatch,
      ...this.props.style,
      border: 'none',
      marginBottom: '8px',
    };

    const popoverStyle = {
      ...popover,
      ...this.props.popoverStyle,
    };

    return (
      <div>
        <button style={style} onClick={this.handlePreviewClick} data-testid="colorPicker">
          <ColorPreview color={this.props.input.value} />
        </button>
        {this.state.isOpen ? (
          <div style={popoverStyle}>
            {/* eslint-disable-next-line */}
            <div style={cover} onClick={this.handleClose} />
            <ColorPicker {...this.props} />
          </div>
        ) : null}
      </div>
    );
  }
}
