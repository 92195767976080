import { ProjectSettingsApi } from 'api/ProjectSettingsApi';
import { ProjectMapper } from 'mapper/ProjectMapper';
import { ProjectSettingsMapper } from 'mapper/ProjectSettingsMapper';
import { SurveySettingsMapper } from 'mapper/SurveySettingsMapper';
import { Project } from 'model/Project';
import { ProjectSettings } from 'model/ProjectSettings';
import { SurveySettings } from 'model/SurveySettings';

export class ProjectSettingsRepository {
  constructor(
    private projectSettingsApi: ProjectSettingsApi,
    private projectSettingsMapper: ProjectSettingsMapper,
    private projectMapper: ProjectMapper,
    private surveySettingsMapper: SurveySettingsMapper
  ) {}

  fetch(projectId: number): Promise<ProjectSettings> {
    return this.projectSettingsApi
      .fetch(projectId)
      .then((entry: ProjectSettingsApi.Entry) => this.projectSettingsMapper.deserialize(entry));
  }

  update(projectId: number, data: SurveySettings): Promise<Project> {
    return this.projectSettingsApi
      .update(projectId, this.surveySettingsMapper.serialize(data))
      .then((response) => this.projectMapper.deserialize(response));
  }

  changeDates(projectId: number, data: SurveySettings): Promise<null> {
    return this.projectSettingsApi.updateDates(
      projectId,
      this.surveySettingsMapper.serializeDates(data)
    );
  }
}
