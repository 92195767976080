import React from 'react';
import { connect } from 'react-redux';

import Done from '@mui/icons-material/Done';
import { Button, SecondaryButton } from 'component/Button/Button';
import { ConnectedDialog } from 'component/ConnectedDialog/ConnectedDialog';
import {
  selectIsModalPending,
  selectModalName,
  selectModalParams,
} from 'reduxStore/modal/selectors';
import { closeModal, updateModal } from 'reduxStore/modal/slice';
import { AdminModal } from 'register/AdminModal';
import { AdminModalParamsMapping } from 'register/AdminModalParams';

export namespace ConfirmationModal {
  export type StateProps = {
    params: AdminModalParamsMapping[AdminModal];
    isModalPending: boolean;
  };
  export type DispatchProps = {
    onConfirmClick: (params: AdminModalParamsMapping[AdminModal]) => void;
    onCancelClick: (params: AdminModalParamsMapping[AdminModal]) => void;
  };
  export type OwnProps<N extends AdminModal> = ConnectedDialog.OwnProps<N> & {
    onConfirm: (params: AdminModalParamsMapping[N]) => Promise<any> | void;
    onCancel?: (params: AdminModalParamsMapping[N]) => Promise<any>;
    visibleCancelButton?: boolean;
    visibleConfirmButton?: boolean;
    confirmLabel?: string;
    cancelLabel?: string;
    'data-testid'?: string;
  };
  export type Props = StateProps & DispatchProps & OwnProps<AdminModal>;
}

export const ConfirmationModalPure: React.VFC<ConfirmationModal.Props> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onConfirm,
  onCancelClick,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onCancel,
  onConfirmClick,
  visibleCancelButton,
  visibleConfirmButton,
  confirmLabel,
  cancelLabel,
  'data-testid': dataTestId,
  params,
  isModalPending,
  actions,
  ...dialogProps
}) => {
  const dialogPropsActions = Array.isArray(actions) ? actions : actions ? [actions] : [];
  return (
    <ConnectedDialog
      {...({
        ...dialogProps,
        actions: [
          ...dialogPropsActions,
          <SecondaryButton
            keyboardFocused
            onClick={() => onCancelClick(params)}
            label={cancelLabel}
            disabled={isModalPending}
            style={{ display: visibleCancelButton ? 'auto' : 'none' }}
            key="cancel"
          />,
          <Button
            data-testid={dataTestId}
            color="primary"
            onClick={() => onConfirmClick(params)}
            endIcon={<Done />}
            label={confirmLabel}
            disabled={isModalPending}
            style={{ display: visibleConfirmButton ? 'auto' : 'none' }}
            key="confirm"
          />,
        ],
      } as any)}
    />
  );
};

ConfirmationModalPure.defaultProps = {
  visibleCancelButton: true,
  visibleConfirmButton: true,
  confirmLabel: 'Confirm',
  cancelLabel: 'Cancel',
};

export const ConfirmationModal = connect(
  (state): ConfirmationModal.StateProps => {
    const modalName = selectModalName(state);

    return {
      params: selectModalParams(modalName)(state),
      isModalPending: selectIsModalPending(state),
    };
  },
  (
    dispatch,
    ownProps: ConfirmationModal.OwnProps<AdminModal>
  ): ConfirmationModal.DispatchProps => ({
    onConfirmClick: (params) => {
      const promise = ownProps.onConfirm(params);

      if (promise) {
        dispatch(updateModal({ name: ownProps.name, params, pending: true }));
      }
      try {
        (promise || Promise.resolve())
          .then(() => dispatch(closeModal()))
          .catch(() => dispatch(updateModal({ name: ownProps.name, params, pending: false })));
      } catch (error) {
        Promise.resolve().then(() => dispatch(closeModal()));
      }
    },
    onCancelClick: (params = {}) => {
      let promise;

      if (ownProps.onCancel) {
        promise = ownProps.onCancel(params);

        if (promise) {
          dispatch(updateModal({ name: ownProps.name, params, pending: true }));
        }
      }

      (promise || Promise.resolve())
        .then(() => dispatch(closeModal()))
        .catch(() => dispatch(updateModal({ name: ownProps.name, params, pending: false })));
    },
  })
)(ConfirmationModalPure) as <N extends AdminModal>(
  props: ConfirmationModal.OwnProps<N>
) => JSX.Element;
