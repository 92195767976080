import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { STORE_NAME, SnackbarState } from './initialState';

export const snackbarsSlice = createSlice({
  name: STORE_NAME,
  initialState: SnackbarState.INITIAL_DOMAIN,
  reducers: {
    registerSnackbar: (state, action: PayloadAction<SnackbarState.Snackbar>) => {
      state.list = [...state.list, action.payload];
    },
    unregisterSnackbar: (state, action: PayloadAction<{ id: number }>) => {
      state.list = state.list.filter((snackbar) => snackbar.id !== action.payload.id);
    },
    markSnackbarAsOpened: (state, action: PayloadAction<{ id: number }>) => {
      state.list = state.list.map((snackbar) =>
        snackbar.id === action.payload.id ? { ...snackbar, isOpen: true } : snackbar
      );
    },
    markSnackbarAsClosed: (state, action: PayloadAction<{ id: number }>) => {
      state.list = state.list.map((snackbar) =>
        snackbar.id === action.payload.id ? { ...snackbar, isOpen: false } : snackbar
      );
    },
  },
});

export const {
  registerSnackbar,
  unregisterSnackbar,
  markSnackbarAsOpened,
  markSnackbarAsClosed,
} = snackbarsSlice.actions;
