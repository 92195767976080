import { List } from 'immutable';
import { Department } from 'model/Department';
import { TrendQuestion } from 'repository/TrendItemsRepository';

export const STORE_NAME = 'trendItems';

export namespace TrendItemsState {
  export type Domain = {
    list: List<TrendQuestion>;
    departments: List<Department>;
  };

  export const INITIAL_DOMAIN: Domain = {
    list: List<TrendQuestion>(),
    departments: List<Department>(),
  };
}
