import { findSiblingsInDepartmentsTree } from 'util/findSiblingsInDepartmentTree';

import { Department } from 'model/Department';
import {
  getRelativeOrderAbove,
  getRelativeOrderBelow,
  DepartmentPositionType,
} from 'model/DepartmentPositionType';

type GetNewOrder = (
  selectedDepartment: Department,
  positionDepartmentId: number,
  positionType: DepartmentPositionType,
  departmentList: Immutable.List<Department>
) => number[];

export const getNewDepartmentsOrder: GetNewOrder = (
  selectedDepartment,
  relativeDepartmentId,
  positionType,
  departmentList
) => {
  const selectedDepartmentId = selectedDepartment.getId();

  if (selectedDepartmentId === relativeDepartmentId) {
    console.warn('Cannot move department relative to itself!');
    return [];
  }

  const sameLevelDepartments = findSiblingsInDepartmentsTree(
    departmentList.toArray(),
    selectedDepartmentId
  )
    .map((department) => department.getId())
    .filter((id) => id !== selectedDepartmentId);

  const relativeIndex = sameLevelDepartments.indexOf(relativeDepartmentId);
  const beforeRelative = sameLevelDepartments.slice(0, relativeIndex);
  const afterRelative = sameLevelDepartments.slice(relativeIndex + 1);

  switch (positionType) {
    case 'Above':
      return getRelativeOrderAbove(
        beforeRelative,
        selectedDepartmentId,
        relativeDepartmentId,
        afterRelative
      );
    case 'Below':
      return getRelativeOrderBelow(
        beforeRelative,
        selectedDepartmentId,
        relativeDepartmentId,
        afterRelative
      );
  }
};
