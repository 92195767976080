import { createAsyncThunk } from '@reduxjs/toolkit';
import { projectRepository } from 'container/projectRepository';
import { Company } from 'model/Company';
import { ProjectSortBy } from 'model/ProjectSortBy';
import { ProjectStatus } from 'model/ProjectStatus';
import { STORE_NAME } from 'reduxStore/project/initialState';
import { setProjectListPagination } from 'reduxStore/project/slice';
import { updateCurrentProject } from 'reduxStore/projectTemplate/slice';
import { serializeError } from 'shared/utils/redux';

type fetchProjectListProps = {
  page: number;
  limit: number;
  searchText: string;
  sortBy: ProjectSortBy;
  status?: ProjectStatus;
  circleId?: string;
};

export const fetchProjectList = createAsyncThunk(
  `${STORE_NAME}/fetchProjectList`,
  async (
    { page, limit, searchText, sortBy, status, circleId }: fetchProjectListProps,
    { dispatch }
  ) => {
    const result = await projectRepository.list({
      page,
      limit,
      searchText,
      sortBy,
      status,
      circleId,
    });

    dispatch(setProjectListPagination(result.pagination));

    return result.list;
  },
  { serializeError }
);

export const fetchCurrentProject = createAsyncThunk(
  `${STORE_NAME}/fetchCurrentProject`,
  ({ projectId }: { projectId: number }) => {
    return projectRepository.get(projectId);
  },
  { serializeError }
);

export const updateProject = createAsyncThunk(
  `${STORE_NAME}/updateProject`,
  async (
    {
      projectId,
      company,
      logo,
      ssoEnabled,
      multiLanguageEnabled,
      customKioskCodesEnabled,
    }: {
      projectId: number;
      company: Company;
      logo?: File;
      ssoEnabled?: boolean;
      multiLanguageEnabled?: boolean;
      customKioskCodesEnabled?: boolean;
    },
    { dispatch }
  ) => {
    const project = await projectRepository.update({
      company,
      projectId,
      logo,
      ssoEnabled,
      multiLanguageEnabled,
      customKioskCodesEnabled,
    });

    dispatch(updateCurrentProject({ project }));

    return project;
  },
  { serializeError }
);
