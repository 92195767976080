import { PageItemApi } from 'api/PageItemApi';
import { PageItemAbstractMapper } from 'mapper/PageItemAbstractMapper';
import { Question } from 'model/Question';

export abstract class QuestionAbstractMapper {
  static serialize(model: Question): PageItemApi.QuestionEntry {
    return {
      ...PageItemAbstractMapper.serialize(model),
      content: model.getContent(),
    };
  }

  static deserializeShape(entry: PageItemApi.QuestionEntry): Question.Shape {
    // we can't deserialize question model, because it's an abstract model
    return {
      ...PageItemAbstractMapper.deserializeShape(entry),
      content: entry.content,
    };
  }
}
