import { DepartmentApi } from 'api/DepartmentApi';
import { List } from 'immutable';
import { DepartmentMapper } from 'mapper/DepartmentMapper';
import { Department } from 'model/Department';

export class DepartmentRepository {
  constructor(private departmentApi: DepartmentApi, private departmentMapper: DepartmentMapper) {}

  list(projectId: number): Promise<List<Department>> {
    return this.departmentApi
      .list(projectId)
      .then((entries) => List(entries.map((entry) => this.departmentMapper.deserialize(entry))));
  }

  upload(projectId: number, file: File, dryRun?: boolean): Promise<List<Department>> {
    return this.departmentApi
      .upload(projectId, file, dryRun)
      .then((entries) => List(entries.map((entry) => this.departmentMapper.deserialize(entry))));
  }

  add(projectId: number, name: string): Promise<Department> {
    return this.departmentApi
      .add(projectId, name)
      .then((entry) => this.departmentMapper.deserialize(entry));
  }

  edit(departmentId: number, name: string): Promise<Department> {
    return this.departmentApi
      .edit(departmentId, name)
      .then((entry) => this.departmentMapper.deserialize(entry));
  }

  delete(departmentId: number): Promise<void> {
    return this.departmentApi.delete(departmentId);
  }

  reorder(order: number[]): Promise<any> {
    return this.departmentApi.reorder(order);
  }

  changeParent(childId: number, parentId: number): Promise<any> {
    return this.departmentApi.changeParent(childId, parentId);
  }
}
