import { PageItemApi } from 'api/PageItemApi';
import { PageItemKindMapper } from 'mapper/PageItemMapper';
import { QuestionAbstractMapper } from 'mapper/QuestionAbstractMapper';
import { PageItem } from 'model/PageItem';
import { PageItemKind } from 'model/PageItemKind';
import { QuestionConsent } from 'model/QuestionConsent';

export class QuestionConsentMapper
  implements PageItemKindMapper<QuestionConsent, PageItemApi.QuestionConsentEntry> {
  constructor() {}

  serialize(model: QuestionConsent): PageItemApi.QuestionConsentEntry {
    return {
      ...QuestionAbstractMapper.serialize(model),
      optionNo: model.getOptionNo(),
      optionYes: model.getOptionYes(),
      logic: null,
      kind: PageItemKind.QuestionConsent,
    };
  }

  deserialize({
    optionYes,
    optionNo,
    ...entry
  }: PageItemApi.QuestionConsentEntry): QuestionConsent {
    return new QuestionConsent({
      ...QuestionAbstractMapper.deserializeShape(entry),
      optionYes,
      optionNo,
      locale: entry.locale,
    });
  }

  supportsSerialize(model: PageItem): model is QuestionConsent {
    return model instanceof QuestionConsent;
  }

  supportsDeserialize(
    entry: PageItemApi.ImageWidgetEntry
  ): entry is PageItemApi.QuestionConsentEntry {
    return entry.kind === PageItemKind.QuestionConsent;
  }
}
