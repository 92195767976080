import { List } from 'immutable';
import { Demographic } from 'model/Demographic';
import { Employee } from 'model/Employee';
import { Pagination } from 'model/Pagination';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'pulseLifecycle';

export namespace PulseLifecycleState {
  export type Domain = {
    list: List<Employee>;
    listPagination: Pagination | null;
    demographicsResource: Resource<List<Demographic>>;
  };

  export const INITIAL_DOMAIN: Domain = {
    list: List.of(),
    listPagination: null,
    demographicsResource: Resource.void(),
  };
}
