import { createAsyncThunk } from '@reduxjs/toolkit';
import { projectSettingsRepository } from 'container/projectSettingsRepository';
import { pulseProjectRepository } from 'container/pulseProjectRepository';
import { SurveySettings } from 'model/SurveySettings';
import { selectIsPulseTemplate } from 'reduxStore/project/projectSelector';
import { serializeError } from 'shared/utils/redux';

import { STORE_NAME } from '../initialState';

export const fetchProjectSettings = createAsyncThunk(
  `${STORE_NAME}/fetchProjectSettings`,
  ({ projectId }: { projectId: number }) => {
    return projectSettingsRepository.fetch(projectId);
  },
  { serializeError }
);

export const updateProjectSettings = createAsyncThunk(
  `${STORE_NAME}/updateProjectSettings`,
  async (
    { projectId, settings }: { projectId: number; settings: SurveySettings },
    { getState }
  ) => {
    const project = await projectSettingsRepository.update(projectId, settings);

    const isPulseTemplate = selectIsPulseTemplate(getState());
    if (isPulseTemplate) {
      return pulseProjectRepository.get(projectId);
    } else {
      return project;
    }
  },
  { serializeError }
);

export const updatePulseLifecycleProjectSettings = createAsyncThunk(
  `${STORE_NAME}/updatePulseLifecycleProjectSettings`,
  async ({
    lifecycleProjectId,
    settings,
  }: {
    lifecycleProjectId: number;
    settings: SurveySettings;
  }) => {
    const pulseLifecycleProject = await projectSettingsRepository.update(
      lifecycleProjectId,
      settings
    );
    return { pulseLifecycleProject };
  },
  { serializeError }
);
