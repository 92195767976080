import { initReactI18next } from 'react-i18next';

import { getAppConfig } from 'app/getConfig';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';
import { LangNamespaceEnum } from 'shared/enums/LangNamespaceEnum';
import { languageConfig } from 'translations/languageConfig';

import AFRIKAANS_ADMIN_COMMON from './af_ZA/adminUICommon.json';
import AFRIKAANS_SHARED from './af_ZA/shared.json';
import ARABIC_UAE_ADMIN_COMMON from './ar_AE/adminUICommon.json';
import ARABIC_UAE_SHARED from './ar_AE/shared.json';
import BULGARIAN_ADMIN_COMMON from './bg_BG/adminUICommon.json';
import BULGARIAN_SHARED from './bg_BG/shared.json';
import BURMESE_ADMIN_COMMON from './my_MY/adminUICommon.json';
import BURMESE_SHARED from './my_MY/shared.json';
import BENGALI_ADMIN_COMMON from './bn_IN/adminUICommon.json';
import BENGALI_SHARED from './bn_IN/shared.json';
import BOSNIAN_ADMIN_COMMON from './bs_BA/adminUICommon.json';
import BOSNIAN_SHARED from './bs_BA/shared.json';
import CZECH_ADMIN_COMMON from './cs_CZ/adminUICommon.json';
import CZECH_SHARED from './cs_CZ/shared.json';
import WELSH_ADMIN_COMMON from './cy_GB/adminUICommon.json';
import WELSH_SHARED from './cy_GB/shared.json';
import DANISH_ADMIN_COMMON from './da_DK/adminUICommon.json';
import DANISH_SHARED from './da_DK/shared.json';
import GERMAN_ADMIN_COMMON from './de_DE/adminUICommon.json';
import GERMAN_SHARED from './de_DE/shared.json';
import ENGLISH_BRITISH_ADMIN_COMMON from './en_GB/adminUICommon.json';
import ENGLISH_BRITISH_SHARED from './en_GB/shared.json';
import ENGLISH_AMERICAN_SHARED from './en_US/shared.json';
import SPANISH_ADMIN_COMMON from './es_ES/adminUICommon.json';
import SPANISH_SHARED from './es_ES/shared.json';
import SPANISH_MEXICAN_ADMIN_COMMON from './es_MX/adminUICommon.json';
import SPANISH_MEXICAN_SHARED from './es_MX/shared.json';
import FRENCH_ADMIN_COMMON from './fr_FR/adminUICommon.json';
import FRENCH_SHARED from './fr_FR/shared.json';
import FILIPINO_ADMIN_COMMON from './fil_PH/adminUICommon.json';
import FILIPINO_SHARED from './fil_PH/shared.json';
import JAPANESE_ADMIN_COMMON from './ja_JP/adminUICommon.json';
import JAPANESE_SHARED from './ja_JP/shared.json';
import IRISH_ADMIN_COMMON from './ga_IE/adminUICommon.json';
import IRISH_SHARED from './ga_IE/shared.json';
import GUJARATI_ADMIN_COMMON from './gu_IN/adminUICommon.json';
import GUJARATI_SHARED from './gu_IN/shared.json';
import HINDI_ADMIN_COMMON from './hi_IN/adminUICommon.json';
import HINDI_SHARED from './hi_IN/shared.json';
import HUNGARIAN_ADMIN_COMMON from './hu_HU/adminUICommon.json';
import HUNGARIAN_SHARED from './hu_HU/shared.json';
import INDONESIAN_ADMIN_COMMON from './id_ID/adminUICommon.json';
import INDONESIAN_SHARED from './id_ID/shared.json';
import ITALIAN_ADMIN_COMMON from './it_IT/adminUICommon.json';
import ITALIAN_SHARED from './it_IT/shared.json';
import KOREAN_ADMIN_COMMON from './ko_KR/adminUICommon.json';
import KOREAN_SHARED from './ko_KR/shared.json';
import LITHUANIAN_ADMIN_COMMON from './lt_LT/adminUICommon.json';
import LITHUANIAN_SHARED from './lt_LT/shared.json';
import LATVIAN_ADMIN_COMMON from './lv_LV/adminUICommon.json';
import LATVIAN_SHARED from './lv_LV/shared.json';
import MALAGASY_ADMIN_COMMON from './mg_MG/adminUICommon.json';
import MALAGASY_SHARED from './mg_MG/shared.json';
import BELGIAN_ADMIN_COMMON from './nl_BE/adminUICommon.json';
import BELGIAN_SHARED from './nl_BE/shared.json';
import DUTCH_ADMIN_COMMON from './nl_NL/adminUICommon.json';
import DUTCH_SHARED from './nl_NL/shared.json';
import NORWEGIAN_ADMIN_COMMON from './no_NO/adminUICommon.json';
import NORWEGIAN_SHARED from './no_NO/shared.json';
import PERSIAN_ADMIN_COMMON from './fa_FA/adminUICommon.json';
import PERSIAN_SHARED from './fa_FA/shared.json';
import PUNJABI_INDIAN_ADMIN_COMMON from './pa_IN/adminUICommon.json';
import PUNJABI_INDIAN_SHARED from './pa_IN/shared.json';
import POLISH_ADMIN_COMMON from './pl_PL/adminUICommon.json';
import POLISH_SHARED from './pl_PL/shared.json';
import PORTUGUESE_BRAZILIAN_ADMIN_COMMON from './pt_BR/adminUICommon.json';
import PORTUGUESE_BRAZILIAN_SHARED from './pt_BR/shared.json';
import PORTUGUESE_ADMIN_COMMON from './pt_PT/adminUICommon.json';
import PORTUGUESE_SHARED from './pt_PT/shared.json';
import ROMANIAN_ADMIN_COMMON from './ro_RO/adminUICommon.json';
import ROMANIAN_SHARED from './ro_RO/shared.json';
import RUSSIAN_ADMIN_COMMON from './ru_RU/adminUICommon.json';
import RUSSIAN_SHARED from './ru_RU/shared.json';
import SLOVAKIAN_ADMIN_COMMON from './sk_SK/adminUICommon.json';
import SLOVAKIAN_SHARED from './sk_SK/shared.json';
import SLOVENIAN_ADMIN_COMMON from './sl_SI/adminUICommon.json';
import SLOVENIAN_SHARED from './sl_SI/shared.json';
import SWAHILI_ADMIN_COMMON from './sw_KE/adminUICommon.json';
import SWAHILI_SHARED from './sw_KE/shared.json';
import SWEDISH_ADMIN_COMMON from './sv_SE/adminUICommon.json';
import SWEDISH_SHARED from './sv_SE/shared.json';
import TAMIL_ADMIN_COMMON from './ta_IN/adminUICommon.json';
import TAMIL_SHARED from './ta_IN/shared.json';
import TSONGA_ADMIN_COMMON from './ts_TS/adminUICommon.json';
import TSONGA_SHARED from './ts_TS/shared.json';
import TURKISH_ADMIN_COMMON from './tr_TR/adminUICommon.json';
import TURKISH_SHARED from './tr_TR/shared.json';
import UKRAINIAN_ADMIN_COMMON from './uk_UA/adminUICommon.json';
import UKRAINIAN_SHARED from './uk_UA/shared.json';
import URDU_PAKISTAN_ADMIN_COMMON from './ur_PK/adminUICommon.json';
import URDU_PAKISTAN_SHARED from './ur_PK/shared.json';
import VIETNAMESE_ADMIN_COMMON from './vi_VN/adminUICommon.json';
import VIETNAMESE_SHARED from './vi_VN/shared.json';
import CHINESE_SIMPLIFIED_ADMIN_COMMON from './zh_CN/adminUICommon.json';
import CHINESE_SIMPLIFIED_SHARED from './zh_CN/shared.json';
import CHINESE_TRADITIONAL_ADMIN_COMMON from './zh_TW/adminUICommon.json';
import CHINESE_TRADITIONAL_SHARED from './zh_TW/shared.json';
import XHOSA_ADMIN_COMMON from './xh_XH/adminUICommon.json';
import XHOSA_SHARED from './xh_XH/shared.json';
import TETUN_SHARED from './tet_TET/shared.json';
import TETUN_ADMIN_COMMON from './tet_TET/adminUICommon.json';
import CROATIAN_SHARED from './hr_HR/shared.json';
import CROATIAN_ADMIN_COMMON from './hr_HR/adminUICommon.json';
import SERBIAN_SHARED from './sr_RS/shared.json';
import SERBIAN_ADMIN_COMMON from './sr_RS/adminUICommon.json';
import FINNISH_SHARED from './fi_FI/shared.json';
import FINNISH_ADMIN_COMMON from './fi_FI/adminUICommon.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: getAppConfig().NODE_ENV === 'development',
    returnEmptyString: false,
    ns: [LangNamespaceEnum.SHARED],
    lng: languageConfig.baseLanguage,
    resources: {
      // All fallback languages have to be initially loaded
      [LangCodeEnum.AFRIKAANS]: {
        [LangNamespaceEnum.SHARED]: AFRIKAANS_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: AFRIKAANS_ADMIN_COMMON,
      },
      [LangCodeEnum.ARABIC_UAE]: {
        [LangNamespaceEnum.SHARED]: ARABIC_UAE_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: ARABIC_UAE_ADMIN_COMMON,
      },
      [LangCodeEnum.BELGIAN]: {
        [LangNamespaceEnum.SHARED]: BELGIAN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: BELGIAN_ADMIN_COMMON,
      },
      [LangCodeEnum.BENGALI]: {
        [LangNamespaceEnum.SHARED]: BENGALI_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: BENGALI_ADMIN_COMMON,
      },
      [LangCodeEnum.BOSNIAN]: {
        [LangNamespaceEnum.SHARED]: BOSNIAN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: BOSNIAN_ADMIN_COMMON,
      },
      [LangCodeEnum.BULGARIAN]: {
        [LangNamespaceEnum.SHARED]: BULGARIAN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: BULGARIAN_ADMIN_COMMON,
      },
      [LangCodeEnum.BURMESE]: {
        [LangNamespaceEnum.SHARED]: BURMESE_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: BURMESE_ADMIN_COMMON,
      },
      [LangCodeEnum.CHINESE_SIMPLIFIED]: {
        [LangNamespaceEnum.SHARED]: CHINESE_SIMPLIFIED_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: CHINESE_SIMPLIFIED_ADMIN_COMMON,
      },
      [LangCodeEnum.CHINESE_TRADITIONAL]: {
        [LangNamespaceEnum.SHARED]: CHINESE_TRADITIONAL_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: CHINESE_TRADITIONAL_ADMIN_COMMON,
      },
      [LangCodeEnum.CROATIAN]: {
        [LangNamespaceEnum.SHARED]: CROATIAN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: CROATIAN_ADMIN_COMMON,
      },
      [LangCodeEnum.CZECH]: {
        [LangNamespaceEnum.SHARED]: CZECH_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: CZECH_ADMIN_COMMON,
      },
      [LangCodeEnum.DANISH]: {
        [LangNamespaceEnum.SHARED]: DANISH_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: DANISH_ADMIN_COMMON,
      },
      [LangCodeEnum.DUTCH]: {
        [LangNamespaceEnum.SHARED]: DUTCH_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: DUTCH_ADMIN_COMMON,
      },
      [LangCodeEnum.ENGLISH_AMERICAN]: {
        [LangNamespaceEnum.SHARED]: ENGLISH_AMERICAN_SHARED,
      },
      [LangCodeEnum.ENGLISH_BRITISH]: {
        [LangNamespaceEnum.SHARED]: ENGLISH_BRITISH_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: ENGLISH_BRITISH_ADMIN_COMMON,
      },
      [LangCodeEnum.FRENCH]: {
        [LangNamespaceEnum.SHARED]: FRENCH_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: FRENCH_ADMIN_COMMON,
      },
      [LangCodeEnum.FILIPINO]: {
        [LangNamespaceEnum.SHARED]: FILIPINO_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: FILIPINO_ADMIN_COMMON,
      },
      [LangCodeEnum.FINNISH]: {
        [LangNamespaceEnum.SHARED]: FINNISH_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: FINNISH_ADMIN_COMMON,
      },
      [LangCodeEnum.JAPANESE]: {
        [LangNamespaceEnum.SHARED]: JAPANESE_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: JAPANESE_ADMIN_COMMON,
      },
      [LangCodeEnum.GERMAN]: {
        [LangNamespaceEnum.SHARED]: GERMAN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: GERMAN_ADMIN_COMMON,
      },
      [LangCodeEnum.GUJARATI]: {
        [LangNamespaceEnum.SHARED]: GUJARATI_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: GUJARATI_ADMIN_COMMON,
      },
      [LangCodeEnum.HINDI]: {
        [LangNamespaceEnum.SHARED]: HINDI_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: HINDI_ADMIN_COMMON,
      },
      [LangCodeEnum.HUNGARIAN]: {
        [LangNamespaceEnum.SHARED]: HUNGARIAN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: HUNGARIAN_ADMIN_COMMON,
      },
      [LangCodeEnum.INDONESIAN]: {
        [LangNamespaceEnum.SHARED]: INDONESIAN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: INDONESIAN_ADMIN_COMMON,
      },
      [LangCodeEnum.IRISH]: {
        [LangNamespaceEnum.SHARED]: IRISH_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: IRISH_ADMIN_COMMON,
      },
      [LangCodeEnum.ITALIAN]: {
        [LangNamespaceEnum.SHARED]: ITALIAN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: ITALIAN_ADMIN_COMMON,
      },
      [LangCodeEnum.KOREAN]: {
        [LangNamespaceEnum.SHARED]: KOREAN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: KOREAN_ADMIN_COMMON,
      },
      [LangCodeEnum.LATVIAN]: {
        [LangNamespaceEnum.SHARED]: LATVIAN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: LATVIAN_ADMIN_COMMON,
      },
      [LangCodeEnum.LITHUANIAN]: {
        [LangNamespaceEnum.SHARED]: LITHUANIAN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: LITHUANIAN_ADMIN_COMMON,
      },
      [LangCodeEnum.MALAGASY]: {
        [LangNamespaceEnum.SHARED]: MALAGASY_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: MALAGASY_ADMIN_COMMON,
      },
      [LangCodeEnum.NORWEGIAN]: {
        [LangNamespaceEnum.SHARED]: NORWEGIAN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: NORWEGIAN_ADMIN_COMMON,
      },
      [LangCodeEnum.PERSIAN]: {
        [LangNamespaceEnum.SHARED]: PERSIAN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: PERSIAN_ADMIN_COMMON,
      },
      [LangCodeEnum.POLISH]: {
        [LangNamespaceEnum.SHARED]: POLISH_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: POLISH_ADMIN_COMMON,
      },
      [LangCodeEnum.PORTUGUESE]: {
        [LangNamespaceEnum.SHARED]: PORTUGUESE_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: PORTUGUESE_ADMIN_COMMON,
      },
      [LangCodeEnum.PORTUGUESE_BRAZILIAN]: {
        [LangNamespaceEnum.SHARED]: PORTUGUESE_BRAZILIAN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: PORTUGUESE_BRAZILIAN_ADMIN_COMMON,
      },
      [LangCodeEnum.PUNJABI_INDIAN]: {
        [LangNamespaceEnum.SHARED]: PUNJABI_INDIAN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: PUNJABI_INDIAN_ADMIN_COMMON,
      },
      [LangCodeEnum.ROMANIAN]: {
        [LangNamespaceEnum.SHARED]: ROMANIAN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: ROMANIAN_ADMIN_COMMON,
      },
      [LangCodeEnum.RUSSIAN]: {
        [LangNamespaceEnum.SHARED]: RUSSIAN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: RUSSIAN_ADMIN_COMMON,
      },
      [LangCodeEnum.SERBIAN]: {
        [LangNamespaceEnum.SHARED]: SERBIAN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: SERBIAN_ADMIN_COMMON,
      },
      [LangCodeEnum.SLOVAKIAN]: {
        [LangNamespaceEnum.SHARED]: SLOVAKIAN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: SLOVAKIAN_ADMIN_COMMON,
      },
      [LangCodeEnum.SLOVENIAN]: {
        [LangNamespaceEnum.SHARED]: SLOVENIAN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: SLOVENIAN_ADMIN_COMMON,
      },
      [LangCodeEnum.SPANISH]: {
        [LangNamespaceEnum.SHARED]: SPANISH_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: SPANISH_ADMIN_COMMON,
      },
      [LangCodeEnum.SPANISH_MEXICAN]: {
        [LangNamespaceEnum.SHARED]: SPANISH_MEXICAN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: SPANISH_MEXICAN_ADMIN_COMMON,
      },
      [LangCodeEnum.SWAHILI]: {
        [LangNamespaceEnum.SHARED]: SWAHILI_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: SWAHILI_ADMIN_COMMON,
      },
      [LangCodeEnum.SWEDISH]: {
        [LangNamespaceEnum.SHARED]: SWEDISH_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: SWEDISH_ADMIN_COMMON,
      },
      [LangCodeEnum.TAMIL]: {
        [LangNamespaceEnum.SHARED]: TAMIL_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: TAMIL_ADMIN_COMMON,
      },
      [LangCodeEnum.TETUN]: {
        [LangNamespaceEnum.SHARED]: TETUN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: TETUN_ADMIN_COMMON,
      },
      [LangCodeEnum.TSONGA]: {
        [LangNamespaceEnum.SHARED]: TSONGA_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: TSONGA_ADMIN_COMMON,
      },
      [LangCodeEnum.TURKISH]: {
        [LangNamespaceEnum.SHARED]: TURKISH_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: TURKISH_ADMIN_COMMON,
      },
      [LangCodeEnum.UKRAINIAN]: {
        [LangNamespaceEnum.SHARED]: UKRAINIAN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: UKRAINIAN_ADMIN_COMMON,
      },
      [LangCodeEnum.URDU_PAKISTAN]: {
        [LangNamespaceEnum.SHARED]: URDU_PAKISTAN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: URDU_PAKISTAN_ADMIN_COMMON,
      },
      [LangCodeEnum.VIETNAMESE]: {
        [LangNamespaceEnum.SHARED]: VIETNAMESE_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: VIETNAMESE_ADMIN_COMMON,
      },
      [LangCodeEnum.WELSH]: {
        [LangNamespaceEnum.SHARED]: WELSH_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: WELSH_ADMIN_COMMON,
      },
      [LangCodeEnum.ENGLISH_DEFAULT]: {
        [LangNamespaceEnum.SHARED]: ENGLISH_BRITISH_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: ENGLISH_BRITISH_ADMIN_COMMON,
      },
      [LangCodeEnum.PORTUGUESE_DEFAULT]: {
        [LangNamespaceEnum.SHARED]: PORTUGUESE_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: PORTUGUESE_ADMIN_COMMON,
      },
      [LangCodeEnum.CHINESE_DEFAULT]: {
        [LangNamespaceEnum.SHARED]: CHINESE_SIMPLIFIED_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: CHINESE_SIMPLIFIED_ADMIN_COMMON,
      },
      [LangCodeEnum.SPANISH_DEFAULT]: {
        [LangNamespaceEnum.SHARED]: SPANISH_MEXICAN_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: SPANISH_MEXICAN_ADMIN_COMMON,
      },
      [LangCodeEnum.XHOSA]: {
        [LangNamespaceEnum.SHARED]: XHOSA_SHARED,
        [LangNamespaceEnum.ADMIN_UI_COMMON]: XHOSA_ADMIN_COMMON,
      },
    },
    fallbackLng: {
      [LangCodeEnum.PORTUGUESE]: [LangCodeEnum.PORTUGUESE_DEFAULT, LangCodeEnum.ENGLISH_DEFAULT],
      [LangCodeEnum.PORTUGUESE_BRAZILIAN]: [
        LangCodeEnum.PORTUGUESE_DEFAULT,
        LangCodeEnum.ENGLISH_DEFAULT,
      ],
      [LangCodeEnum.CHINESE_SIMPLIFIED]: [
        LangCodeEnum.CHINESE_DEFAULT,
        LangCodeEnum.ENGLISH_DEFAULT,
      ],
      [LangCodeEnum.CHINESE_TRADITIONAL]: [
        LangCodeEnum.CHINESE_DEFAULT,
        LangCodeEnum.ENGLISH_DEFAULT,
      ],
      [LangCodeEnum.SPANISH]: [LangCodeEnum.SPANISH_DEFAULT, LangCodeEnum.ENGLISH_DEFAULT],
      [LangCodeEnum.SPANISH_MEXICAN]: [LangCodeEnum.SPANISH_DEFAULT, LangCodeEnum.ENGLISH_DEFAULT],
      default: [LangCodeEnum.ENGLISH_DEFAULT],
    },
  });

export { i18n };
