import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { ProjectState } from 'reduxStore/project/initialState';
import {
  fetchProjectLayout,
  saveProjectLayout,
} from 'reduxStore/project/projectLayout/asyncActions';
import { addAsyncCases } from 'shared/utils/redux';

export const projectLayoutReducer = (builder: ActionReducerMapBuilder<ProjectState.Domain>) => {
  addAsyncCases(builder, fetchProjectLayout, (state, action) => {
    state.layoutResource = action.payload;
  });

  addAsyncCases(builder, saveProjectLayout, (state, action) => {
    state.layoutResource = action.payload;
  });
};
