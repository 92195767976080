import React from 'react';
import { Link } from 'react-router-dom';

import { AdminRoute } from 'app/route/admin';
import workbuzzLogo from 'app/asset/workbuzz_logo.svg';
import { compilePath } from 'router/compilePath';
import { HeaderItem, Logo, LogosWrapper } from 'view/TopBars/Header/Header.s';

export namespace Logos {
  export type Props = {
    isClientAdmin: boolean;
    companyLogo: string | null;
    companyName: string | null;
  };
}

type WBLogoProps = {
  isClientAdmin: boolean;
};
const WBLogo = ({ isClientAdmin }: WBLogoProps) => {
  if (isClientAdmin) {
    return (
      <HeaderItem>
        <Logo data-testid="headerLogo" src={workbuzzLogo} />
      </HeaderItem>
    );
  } else {
    return (
      <HeaderItem>
        <Link data-testid="headerLogoLink" to={compilePath(AdminRoute.CLIENTS_PATTERN)}>
          <Logo data-testid="headerLogo" src={workbuzzLogo} />
        </Link>
      </HeaderItem>
    );
  }
};

export const Logos = ({ isClientAdmin, companyLogo, companyName }: Logos.Props) => {
  return (
    <LogosWrapper>
      <WBLogo isClientAdmin={isClientAdmin} />
      {companyLogo && (
        <HeaderItem>
          <Logo data-testid="headerCompanyLogo" src={companyLogo} alt={`${companyName} logo`} />
        </HeaderItem>
      )}
      {companyName && (
        <HeaderItem>
          <h2 data-testid="headerCompanyName">{companyName}</h2>
        </HeaderItem>
      )}
    </LogosWrapper>
  );
};
