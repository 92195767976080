import { List } from 'immutable';
import { CustomQuestionOption } from 'model/CustomQuestionOption';
import { DiscreteQuestion } from 'model/DiscreteQuestion';
import { NumberingSkippable } from 'model/NumberingSkippable';
import { PageItemKind } from 'model/PageItemKind';
import { Question } from 'model/Question';
import { SimpleModel } from 'model/SimpleModel';

export namespace QuestionDemographic {
  export interface Shape extends Question.Shape {
    options: List<CustomQuestionOption>;
    isGhost: boolean;
    numberingSkipped: boolean;
    isRequired?: boolean;
  }
}

export class QuestionDemographic
  extends Question<QuestionDemographic.Shape>
  implements NumberingSkippable, DiscreteQuestion {
  constructor(initialData: SimpleModel.Data<QuestionDemographic.Shape>) {
    super(initialData, PageItemKind.QuestionDemographic);
  }

  getOptions(): List<CustomQuestionOption> {
    return this.get('options', List<CustomQuestionOption>());
  }

  isGhost(): boolean {
    return this.get('isGhost', false);
  }

  isRequired(): boolean {
    return this.get('isRequired');
  }

  isNumberingSkipped(): boolean {
    return this.get('numberingSkipped');
  }

  getAvailableOptions(): List<CustomQuestionOption> {
    return this.getOptions();
  }
}
