import { TimezoneApi } from 'api/TimezoneApi';
import { Timezone } from 'model/Timezone';

export class TimezoneMapper {
  deserialize(entry: TimezoneApi.Entry): Timezone {
    return new Timezone({
      id: entry.id,
      name: entry.name,
      offset: entry.offset,
    });
  }
}
