import { companyApi } from 'container/companyApi';
import { companyMapper } from 'container/companyMapper';
import { projectMapper } from 'container/projectMapper';
import { CompanyRepository } from 'repository/CompanyRepository';

export const companyRepository: CompanyRepository = new CompanyRepository(
  companyApi,
  companyMapper,
  projectMapper
);
