import { PaperDistributionApi } from 'api/PaperDistributionApi';
import { DistributionMapperByChannel } from 'mapper/DistributionMapper';
import { DistributionChannel } from 'model/DistributionChannel';
import { PaperDistribution } from 'model/PaperDistribution';

export class PaperDistributionMapper
  implements DistributionMapperByChannel<PaperDistribution, PaperDistributionApi.Entry> {
  serialize(model: PaperDistribution): PaperDistributionApi.Entry {
    return {
      id: model.getId(),
      slug: model.getSlug(),
      surveyLink: model.getLink(),
    };
  }

  deserialize(entry: PaperDistributionApi.Entry): PaperDistribution {
    return new PaperDistribution({
      id: entry.id,
      slug: entry.slug,
      link: entry.surveyLink,
    });
  }

  supportsSerialize(model: PaperDistribution): boolean {
    return model.getChannel() === DistributionChannel.Paper;
  }

  supportsDeserialize(entry: PaperDistributionApi.Entry): boolean {
    return entry.channel === DistributionChannel.Paper;
  }
}
