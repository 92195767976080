import { createSlice } from '@reduxjs/toolkit';
import { Resource } from 'model/Resource';
import { fetchClients } from 'reduxStore/client/asyncActions';

import { ClientState, STORE_NAME } from './initialState';

export const clientSlice = createSlice({
  name: STORE_NAME,
  initialState: ClientState.INITIAL_DOMAIN,
  reducers: {
    clearClientState: () => ClientState.INITIAL_DOMAIN,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.listResource = Resource.resolve(action.payload.list);
        state.listPagination = action.payload.pagination;
      })
      .addCase(fetchClients.rejected, (state, action) => {
        state.listResource = Resource.reject(action.error);
        state.listPagination = null;
      });
  },
});

export const { clearClientState } = clientSlice.actions;
