import { Exception } from 'exception/Exception';
import { List } from 'immutable';
import { Trend } from 'model/Trend';

export const STORE_NAME = 'trend';

export namespace TrendState {
  export type Domain = {
    list: List<Trend>;
    errors: List<Exception> | null;
  };

  export const INITIAL_DOMAIN: Domain = {
    list: List<Trend>(),
    errors: null,
  };
}
