import { Moment } from 'moment';

export enum LifecycleType {
  ONBOARD = 'onboard',
  EXIT = 'exit',
}

export enum LifecycleLabel {
  ONBOARD = 'Onboard',
  EXIT = 'Exit',
}

export type PulseLifecycleProjectReceived = {
  type: LifecycleType;
  createdAt: Moment;
};

export type PulseLifecycleProjectReceivedEntry = {
  type: LifecycleType;
  createdAt: string;
};
