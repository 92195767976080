import { List } from 'immutable';
import { Classification } from 'model/Classification';
import { DemographicKind } from 'model/DemographicKind';
import { SimpleModel } from 'model/SimpleModel';

export namespace Demographic {
  export interface Shape {
    id?: number;
    title?: string;
    reportedAs?: string;
    position?: number;
    kind?: number;
    classifications?: List<Classification>;
  }
}

export class Demographic extends SimpleModel<Demographic.Shape> {
  getId(): number {
    return this.get('id');
  }

  getTitle(): string {
    return this.get('title');
  }

  getReportedAs(): string {
    return this.get('reportedAs');
  }

  getPosition(): number {
    return this.get('position');
  }

  getKind(): DemographicKind {
    return this.get('kind');
  }

  getClassifications(): List<Classification> {
    return this.get('classifications', List<Classification>());
  }

  getSortClassifications(): List<Classification> {
    return this.getClassifications().sortBy((item) => item.getPosition()) as List<Classification>;
  }

  hasClassifications(): boolean {
    return !this.getClassifications().isEmpty();
  }
}
