import { UserApi } from 'api/UserApi';
import { List } from 'immutable';
import { UserMapper } from 'mapper/UserMapper';
import { User } from 'model/User';

export class UserRepository {
  constructor(private userApi: UserApi, private userMapper: UserMapper) {}

  list(projectId: number, query?: UserApi.GetUserListQuery): Promise<List<User>> {
    return this.userApi
      .list(projectId, query)
      .then((list = []) => List(list.map((entry) => this.userMapper.deserialize(entry))));
  }

  findUsers(email: string): Promise<List<User>> {
    return this.userApi
      .findUsers(email)
      .then((list = []) => List(list.map((entry) => this.userMapper.deserialize(entry))));
  }

  create(id: number, model: User): Promise<User> {
    return this.userApi
      .create(id, this.userMapper.serialize(model))
      .then((entry) => this.userMapper.deserialize(entry));
  }

  update(projectId: number, user: User): Promise<User> {
    return this.userApi
      .update(projectId, this.userMapper.serialize(user))
      .then((entry) => this.userMapper.deserialize(entry));
  }

  delete(projectId: number, id: string): Promise<void> {
    return this.userApi.delete(projectId, id);
  }

  uploadExcel(file: File, id: number): Promise<List<User>> {
    return this.userApi.uploadExcel(file, id);
  }
}
