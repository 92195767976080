const AdminRole = 'ROLE_ADMIN';
const ClientAdminRole = 'ROLE_CLIENT_ADMIN';
const CircleAdminRole = 'ROLE_CIRCLE_ADMIN';

type UserRolesMap = {
  Admin: typeof AdminRole;
  ClientAdmin: typeof ClientAdminRole;
  CircleAdmin: typeof CircleAdminRole;
};

export type UserRoles = UserRolesMap[keyof UserRolesMap];

export const UserRoleType: UserRolesMap = {
  Admin: AdminRole,
  ClientAdmin: ClientAdminRole,
  CircleAdmin: CircleAdminRole,
};
