import { SimpleModel } from 'model/SimpleModel';
import { UserRoles, UserRoleType } from 'model/UserRoleType';
import { getIsAdminOrCircleAdmin } from 'shared/utils/getIsAdminOrCircleAdmin';

export namespace JWTUser {
  export interface Shape {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    roles: string[];
  }
}

export class JWTUser extends SimpleModel<JWTUser.Shape> {
  getId(): string {
    return this.get('id');
  }

  getUsername(): string {
    return this.get('username');
  }

  getFirstName(): string {
    return this.get('firstName');
  }

  getLastName(): string {
    return this.get('lastName');
  }

  getEmail(): string {
    return this.get('username');
  }

  getRoles(): UserRoles[] {
    return this.get('roles') as UserRoles[];
  }

  isClientAdmin(): boolean {
    return this.get('roles').includes(UserRoleType.ClientAdmin);
  }

  isWbAdmin(): boolean {
    return this.get('roles').includes(UserRoleType.Admin);
  }

  isCircleAdmin(): boolean {
    return this.get('roles').includes(UserRoleType.CircleAdmin);
  }

  canCreatePastProjects(): boolean {
    // Admin and CircleAdmin can create ODP in the past
    return getIsAdminOrCircleAdmin(this.getRoles());
  }
}
