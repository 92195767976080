import { List } from 'immutable';
import { Department } from 'model/Department';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'department';

export namespace DepartmentState {
  export type ListResource = Resource<List<Department>>;

  export type Domain = {
    listResource: ListResource;
    dryListResource: ListResource;
  };

  export const INITIAL_DOMAIN: Domain = {
    listResource: Resource.void<List<Department>>(),
    dryListResource: Resource.void<List<Department>>(),
  };
}
