import { selectApiData, selectApiNoContent } from 'util/selector/apiSelector';

import { ApiMessage } from 'api/const';
import { AxiosInstance } from 'axios';

export namespace DepartmentApi {
  export type Entry = {
    id?: number;
    name?: string;
    children?: Entry[];
    connected?: Entry;
    position: number;
  };
}

export class DepartmentApi {
  constructor(private client: AxiosInstance) {}

  list(projectId: number): Promise<DepartmentApi.Entry[]> {
    return this.client.get(`/api/v1/project/${projectId}/department`).then(selectApiData);
  }

  upload(projectId: number, file: File, dryRun: boolean = false): Promise<DepartmentApi.Entry[]> {
    const data = new FormData();
    data.append('document', file);

    return this.client
      .post(`/api/v1/project/${projectId}/departments-excel${dryRun ? '?dry-run' : ''}`, data)
      .then(selectApiData);
  }

  add(projectId: number, name: string): Promise<DepartmentApi.Entry> {
    return this.client
      .post(`/api/v1/project/${projectId}/department`, { name: name })
      .then(selectApiData);
  }

  edit(departmentId: number, name: string): Promise<DepartmentApi.Entry> {
    return this.client
      .post(`/api/v1/department/${departmentId}`, { name: name })
      .then(selectApiData);
  }

  delete(departmentId: number): Promise<void> {
    return this.client
      .delete<ApiMessage.Deleted>(`/api/v1/department/${departmentId}`)
      .then(selectApiNoContent);
  }

  reorder(order: number[]): Promise<any> {
    return this.client.post(`/api/v1/department/reorder`, { order });
  }

  changeParent(childId: number, parentId: number): Promise<any> {
    return this.client.post(`/api/v1/department/${childId}/change-parent`, { parent: parentId });
  }
}
