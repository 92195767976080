import { connectRouter, RouterState } from 'connected-react-router';

import { CombinedState, combineReducers } from '@reduxjs/toolkit';
import { History } from 'history';
import { Reducer, StateFromReducersMapObject } from 'redux';
import { reducer as form } from 'redux-form';
import { accessCodeSlice } from 'reduxStore/accessCode/slice';
import { authSlice } from 'reduxStore/auth/slice';
import { categoriesSlice } from 'reduxStore/categories/slice';
import { clientSlice } from 'reduxStore/client/slice';
import { companySlice } from 'reduxStore/company/slice';
import { configsSlice } from 'reduxStore/configs/slice';
import { contactListSlice } from 'reduxStore/contactList/slice';
import { deliveryStatisticSlice } from 'reduxStore/deliveryStatistic/slice';
import { demographicSlice } from 'reduxStore/demographic/slice';
import { departmentSlice } from 'reduxStore/department/slice';
import { emailDistributionSlice } from 'reduxStore/emailDistribution/slice';
import { employeeSlice } from 'reduxStore/employee/slice';
import { jobSlice } from 'reduxStore/job/slice';
import { kioskDistributionSlice } from 'reduxStore/kioskDistribution/slice';
import { modalSlice } from 'reduxStore/modal/slice';
import { pageSlice } from 'reduxStore/page/slice';
import { paperDistributionSlice } from 'reduxStore/paperDistribution/slice';
import { permissionsSlice } from 'reduxStore/permissions/slice';
import { placeholderSlice } from 'reduxStore/placeholders/slice';
import { projectSlice } from 'reduxStore/project/slice';
import { projectSubmitSlice } from 'reduxStore/projectSubmit/slice';
import { projectTemplateSlice } from 'reduxStore/projectTemplate/slice';
import { pulseLifecycleSlice } from 'reduxStore/pulseLifecycle/slice';
import { queueSlice } from 'reduxStore/queue/slice';
import { snackbarsSlice } from 'reduxStore/snackbars/slice';
import { staticResourceSlice } from 'reduxStore/staticResource/slice';
import { trendSlice } from 'reduxStore/trend/slice';
import { trendItemsSlice } from 'reduxStore/trendItems/slice';
import { userSlice } from 'reduxStore/users/slice';
import { webLinkDistributionSlice } from 'reduxStore/webLink/slice';

import { circlesSlice } from './circles/slice';

const appReducer = {
  form,
  [accessCodeSlice.name]: accessCodeSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [categoriesSlice.name]: categoriesSlice.reducer,
  [circlesSlice.name]: circlesSlice.reducer,
  [clientSlice.name]: clientSlice.reducer,
  [companySlice.name]: companySlice.reducer,
  [configsSlice.name]: configsSlice.reducer,
  [contactListSlice.name]: contactListSlice.reducer,
  [deliveryStatisticSlice.name]: deliveryStatisticSlice.reducer,
  [demographicSlice.name]: demographicSlice.reducer,
  [departmentSlice.name]: departmentSlice.reducer,
  [emailDistributionSlice.name]: emailDistributionSlice.reducer,
  [employeeSlice.name]: employeeSlice.reducer,
  [jobSlice.name]: jobSlice.reducer,
  [kioskDistributionSlice.name]: kioskDistributionSlice.reducer,
  [modalSlice.name]: modalSlice.reducer,
  [pageSlice.name]: pageSlice.reducer,
  [paperDistributionSlice.name]: paperDistributionSlice.reducer,
  [permissionsSlice.name]: permissionsSlice.reducer,
  [placeholderSlice.name]: placeholderSlice.reducer,
  [projectSlice.name]: projectSlice.reducer,
  [projectSubmitSlice.name]: projectSubmitSlice.reducer,
  [projectTemplateSlice.name]: projectTemplateSlice.reducer,
  [pulseLifecycleSlice.name]: pulseLifecycleSlice.reducer,
  [queueSlice.name]: queueSlice.reducer,
  [snackbarsSlice.name]: snackbarsSlice.reducer,
  [staticResourceSlice.name]: staticResourceSlice.reducer,
  [trendItemsSlice.name]: trendItemsSlice.reducer,
  [trendSlice.name]: trendSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [webLinkDistributionSlice.name]: webLinkDistributionSlice.reducer,
};

export type AdminState = StateFromReducersMapObject<typeof appReducer> & {
  router: RouterState<unknown>;
};

export const adminReducer = ({
  history,
}: {
  history: History;
}): Reducer<CombinedState<AdminState>> => {
  return combineReducers({
    router: connectRouter(history),
    ...appReducer,
  });
};
