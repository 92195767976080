import { CategoryApi } from 'api/CategoryApi';
import { CategoryMapper } from 'mapper/CategoryMapper';
import { Category } from 'model/Category';

export class CategoryRepository {
  constructor(private categoryApi: CategoryApi, private categoryMapper: CategoryMapper) {}

  list(projectId: number): Promise<Readonly<Category[]>> {
    return this.categoryApi
      .list(projectId)
      .then((entries) => entries.map((entry) => this.categoryMapper.deserialize(entry)));
  }

  create(projectId: number, category: Category): Promise<Category> {
    return this.categoryApi
      .create(projectId, this.categoryMapper.serialize(category))
      .then((entry) => this.categoryMapper.deserialize(entry));
  }

  update(category: Category): Promise<Category> {
    return this.categoryApi
      .update(this.categoryMapper.serialize(category))
      .then((entry) => this.categoryMapper.deserialize(entry));
  }

  delete(id: number): Promise<void> {
    return this.categoryApi.delete(id);
  }
}
