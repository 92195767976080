import { selectApiData, selectApiNoContent } from 'util/selector/apiSelector';

import { SortDirection } from 'shared/constants';
import { ApiMessage } from 'api/const';
import { WithData } from 'api/interfaces';
import { PermissionsApi } from 'api/PermissionsApi';
import { AxiosInstance } from 'axios';
import qs from 'qs';

export namespace UserApi {
  export type Entry = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    permissions?: string[];
    groups?: PermissionsApi.PermissionGroupEntry[];
    role: string;
    isClientAdmin: boolean;
  };
  export type Post = {
    id?: string;
    firstName?: string;
    lastName?: string;
    email: string;
    password: string;
    department?: number;
    permissions?: string[];
    groups?: string[];
    isClientAdmin?: boolean;
  };

  export type GetUserListQuery = {
    sort?: {
      [sortKey in 'name' | 'email' | 'groups']?: SortDirection;
    };
  };
}

export class UserApi {
  constructor(private authClient: AxiosInstance, private adminClient: AxiosInstance) {}

  list(projectId: number, query?: UserApi.GetUserListQuery): Promise<UserApi.Entry[]> {
    return this.adminClient
      .get(`/api/v1/projects/${projectId}/users`, {
        params: query,
        paramsSerializer: (params) => qs.stringify(params, { encode: false }),
      })
      .then(selectApiData);
  }

  findUsers(email: string): Promise<UserApi.Entry[]> {
    return this.authClient
      .get(`/api/user`, {
        params: { f: { email_like: email } },
        paramsSerializer: (params) => qs.stringify(params, { encode: false }),
      })
      .then(selectApiData);
  }

  create(id: number, entry: UserApi.Post): Promise<UserApi.Entry> {
    return this.adminClient
      .post<WithData<UserApi.Entry>>(`/api/v1/project/${id}/user`, entry)
      .then(selectApiData);
  }

  update(projectId: number, entry: UserApi.Post): Promise<UserApi.Entry> {
    return this.adminClient
      .post<WithData<UserApi.Entry>>(`/api/v1/project/${projectId}/user/${entry.id}`, entry)
      .then(selectApiData);
  }

  delete(projectId: number, id: string): Promise<void> {
    return this.adminClient
      .delete<ApiMessage.Deleted>(`/api/v1/project/${projectId}/user/${id}`)
      .then(selectApiNoContent);
  }

  uploadExcel(file: File, id: number): Promise<any> {
    const data = new FormData();
    data.append('document', file);

    return this.adminClient.post(`/api/v1/project/${id}/user/excel`, data);
  }
}
