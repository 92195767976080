import { List } from 'immutable';
import { Resource } from 'model/Resource';
import { User } from 'model/User';

export const STORE_NAME = 'users';

export namespace UsersState {
  export type Domain = {
    list: Resource<List<User>>;
    found: Resource<List<User>>;
  };

  export const INITIAL_DOMAIN: Domain = {
    list: Resource.void(),
    found: Resource.void(),
  };
}
