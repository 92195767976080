import { JobApi } from 'api/JobApi';
import { JobExtended } from 'model/JobExtended';
import moment from 'moment';

export class JobExtendedMapper {
  serialize(model: JobExtended): JobApi.JobEntry {
    return {
      id: model.getId(),
      name: model.getName(),
      status: model.getStatus(),
      visited: model.getVisited(),
    };
  }

  deserialize(entry: JobApi.JobEntry): JobExtended {
    const executionTime = moment(entry.executionTime);

    return new JobExtended({
      id: entry.id,
      name: entry.name,
      status: entry.status,
      reason: entry.reason,
      progress: entry.progress || 0,
      warnings: entry.warnings,
      executionTime: executionTime.isValid() ? executionTime.unix() : 0,
      visited: entry.visited,
      result: entry.result,
    });
  }
}
