import { Enum } from 'model/Enum';

export class EnumMapper {
  deserialize(key: number | string, name: string): Enum {
    return new Enum({
      key: key,
      name: name,
    });
  }
}
