import React from 'react';
import { connect } from 'react-redux';

import { snackbarStyle } from 'component/SnackbarPortal/SnackbarPortal.s';
import { AdminDispatch } from 'reduxStore/appStore';
import { closeSnackbar } from 'reduxStore/snackbars/asyncActions';
import { selectSnackbars } from 'reduxStore/snackbars/selectors';
import { Snackbar } from '@mui/material';
import { SnackbarState } from 'reduxStore/snackbars/initialState';

export namespace SnackbarPortal {
  export type StateProps = {
    snackbars: SnackbarState.Snackbar[];
  };
  export type DispatchProps = {
    onClose: (id: number) => void;
  };
  export type OwnProps = {};
  export type Props = StateProps & DispatchProps & OwnProps;
}

export const SnackbarPortalPure: React.VFC<SnackbarPortal.Props> = (props) => (
  <div>
    {props.snackbars.map((snackbar) => (
      <Snackbar
        key={snackbar.id}
        message={snackbar.message}
        open={!!snackbar.isOpen}
        style={snackbarStyle}
        onClose={() => props.onClose(snackbar.id)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        id={`snackbar-${snackbar.id}`}
      />
    ))}
  </div>
);

export const SnackbarPortal: React.ComponentClass<SnackbarPortal.OwnProps> = connect(
  (state): SnackbarPortal.StateProps => ({
    snackbars: selectSnackbars(state),
  }),
  (dispatch: AdminDispatch): SnackbarPortal.DispatchProps => ({
    onClose: (id: number) => dispatch(closeSnackbar({ id })),
  })
)(SnackbarPortalPure) as React.ComponentClass<SnackbarPortal.OwnProps>;
