import { createSlice } from '@reduxjs/toolkit';
import { addTakeLatestResourceFactory } from 'shared/utils/redux';

import {
  fetchEmailDistributionJobs,
  fetchKioskDistributionJobs,
  fetchProjectJobs,
} from './asyncActions';
import { JobState, STORE_NAME } from './initialState';

const addTakeLatestResource = addTakeLatestResourceFactory<JobState.Domain>();

export const jobSlice = createSlice({
  name: STORE_NAME,
  initialState: JobState.INITIAL_DOMAIN,
  reducers: {
    clearProjectJobs: (state) => {
      state.projectJobs = JobState.INITIAL_DOMAIN.projectJobs;
    },
    clearEmailDistributionJobs: (state) => {
      state.emailDistributionJobs = JobState.INITIAL_DOMAIN.emailDistributionJobs;
    },
    clearKioskDistributionJobs: (state) => {
      state.kioskDistributionJobs = JobState.INITIAL_DOMAIN.kioskDistributionJobs;
    },
  },
  extraReducers: (builder) => {
    addTakeLatestResource(builder, fetchProjectJobs, 'projectJobs');
    addTakeLatestResource(builder, fetchEmailDistributionJobs, 'emailDistributionJobs');
    addTakeLatestResource(builder, fetchKioskDistributionJobs, 'kioskDistributionJobs');
  },
});

export const {
  clearProjectJobs,
  clearEmailDistributionJobs,
  clearKioskDistributionJobs,
} = jobSlice.actions;
