import { createAsyncThunk } from '@reduxjs/toolkit';
import { placeholderRepository } from 'container/placeholderRepository';
import { STORE_NAME } from 'reduxStore/staticResource/initialState';
import { serializeError } from 'shared/utils/redux';

export const fetchPlaceholders = createAsyncThunk(
  `${STORE_NAME}/fetchPlaceholders`,
  async ({ projectId }: { projectId: number }) => {
    return placeholderRepository.fetch(projectId);
  },
  { serializeError }
);
