import React from 'react';
import { connect } from 'react-redux';

import { SxProps } from '@mui/system';
import DialogActions from '@mui/material/DialogActions';
import { Dialog, DialogContent, DialogProps, DialogTitle, Theme } from '@mui/material';
import { AdminDispatch, AdminState } from 'reduxStore/appStore';
import { selectIsModalOpen, selectModalParams } from 'reduxStore/modal/selectors';
import { closeModal } from 'reduxStore/modal/slice';
import { AdminModal } from 'register/AdminModal';
import { AdminModalParamsMapping } from 'register/AdminModalParams';

export namespace ConnectedDialog {
  export type StateProps = {
    open: boolean;
    params: any;
  };
  export type DispatchProps = {
    onRequestClose: () => void;
  };
  export type OwnProps<N extends AdminModal> = Omit<Partial<DialogProps>, 'title'> & {
    name: N;
    title?: React.ReactNode;
    actions?: React.ReactElement | React.ReactElement[];
    actionsTopContent?: JSX.Element | string;
    content?: (open: boolean, params: AdminModalParamsMapping[N]) => JSX.Element;
    dialogContentSx?: SxProps<Theme>;
  };
  export type Props = StateProps & DispatchProps & OwnProps<AdminModal>;
}

export const ConnectedDialogPure = ({
  name,
  content,
  params,
  actionsTopContent,
  actions,
  title,
  onRequestClose,
  onClose,
  dialogContentSx,
  ...dialogProps
}: ConnectedDialog.Props): JSX.Element => {
  return (
    <Dialog
      data-testid={name}
      {...dialogProps}
      fullWidth
      onClose={(...args) => {
        onRequestClose?.();
        onClose?.(...args);
      }}
    >
      {!!title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent sx={dialogContentSx} className="app-modal" dividers>
        {content ? content(dialogProps.open, params) : dialogProps.children}
      </DialogContent>
      {(actions || actionsTopContent) && (
        <>
          {actionsTopContent && <DialogContent>{actionsTopContent}</DialogContent>}
          <DialogActions>
            {Array.isArray(actions) && (
              <div>
                {actions.map((Component, index) => React.cloneElement(Component, { key: index }))}
              </div>
            )}
            {actions && !Array.isArray(actions) && { ...actions }}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export const ConnectedDialog = connect(
  (
    state: AdminState,
    ownProps: ConnectedDialog.OwnProps<AdminModal>
  ): ConnectedDialog.StateProps => ({
    open: selectIsModalOpen(ownProps.name)(state),
    params: selectIsModalOpen(ownProps.name)(state) ? selectModalParams(ownProps.name)(state) : {},
  }),
  (dispatch: AdminDispatch): ConnectedDialog.DispatchProps => ({
    onRequestClose: () => dispatch(closeModal()),
  })
)(ConnectedDialogPure) as <N extends AdminModal>(props: ConnectedDialog.OwnProps<N>) => JSX.Element;
