import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserApi } from 'api/UserApi';
import { userRepository } from 'container/userRepository';
import { serializeError } from 'shared/utils/redux';

import { STORE_NAME } from './initialState';

export const fetchUserList = createAsyncThunk(
  `${STORE_NAME}/fetchUserList`,
  ({ projectId, query }: { projectId: number; query?: UserApi.GetUserListQuery }) => {
    return userRepository.list(projectId, query);
  },
  { serializeError }
);

export const findUsersByEmail = createAsyncThunk(
  `${STORE_NAME}/findUsersByEmail`,
  ({ email }: { email: string }) => {
    return userRepository.findUsers(email);
  },
  { serializeError }
);
