import React from 'react';

import { DropzoneEmptyMessage } from 'component/Dropzone/DropzoneEmptyMessage';
import { DropzoneMultiFilesMessage } from 'component/Dropzone/DropzoneMultiFilesMessage';
import { DropzoneSingleFileMessage } from 'component/Dropzone/DropzoneSingleFileMessage';

export namespace DropzoneDefaultMessage {
  export type Props = {
    files: File[];
    isHidden?: boolean;
  };
}

export const DropzoneDefaultMessage: React.VFC<DropzoneDefaultMessage.Props> = ({
  files,
  isHidden = false,
}) => {
  switch (files.length) {
    case 0:
      return <DropzoneEmptyMessage />;
    case 1:
      return isHidden ? null : <DropzoneSingleFileMessage file={files[0]} />;
    default:
      return <DropzoneMultiFilesMessage files={files} />;
  }
};
