import { DepartmentApi } from 'api/DepartmentApi';
import { EmployeeApi } from 'api/EmployeeApi';
import { List } from 'immutable';
import { ClassificationDateMapper } from 'mapper/ClassificationDateMapper';
import { ClassificationMapper } from 'mapper/ClassificationMapper';
import { Employee } from 'model/Employee';
import moment from 'moment';

export class EmployeeMapper {
  constructor(
    private classificationMapper: ClassificationMapper,
    private classificationDateMapper: ClassificationDateMapper
  ) {}

  serialize(model: Employee): EmployeeApi.Entry {
    return {
      id: model.getId(),
      firstName: model.getFirstName(),
      lastName: model.getLastName(),
      email: model.getEmail(),
      preferredLanguage: model.getPreferredLanguage(),
      lineManagerEmail: model.getLineManagerEmail(),
      department: model.getDepartment() as number,
      demographyClassifications: model.getClassifications().toArray() as number[],
      classificationValues: model
        .getClassificationDate()
        .map((item) => this.classificationDateMapper.serialize(item))
        .toArray(),
      active: model.isActive() ? 1 : 0,
    };
  }

  deserialize(entry: EmployeeApi.Entry): Employee {
    return new Employee({
      id: entry.id,
      firstName: entry.firstName,
      lastName: entry.lastName,
      email: entry.email,
      preferredLanguage: entry.preferredLanguage,
      lineManagerEmail: entry.lineManagerEmail,
      department: entry.department ? (entry.department as DepartmentApi.Entry).id : undefined,
      departmentNestedName: entry.departmentNestedName,
      surveyMethod: entry.email ? 'email' : 'kiosk',
      classifications: entry.demographyClassifications
        ? (List(
            (entry.demographyClassifications as any).map((classification: any) =>
              this.classificationMapper.deserialize(classification, entry.classificationValues)
            )
          ) as any)
        : [],
      active: Boolean(entry.active),
      createdAt: entry.createdAt ? moment(entry.createdAt) : undefined,
      pulseLifecycleProjects: List(
        entry.lifecycleSurveys?.map((elem) => ({
          type: elem.type,
          createdAt: moment.utc(elem.createdAt),
        })) ?? []
      ),
    });
  }
}
