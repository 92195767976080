import { SimpleModel } from 'model/SimpleModel';

export namespace Trend {
  export interface Shape {
    id: number;
    title: string;
  }
}

export class Trend extends SimpleModel<Trend.Shape> {
  getId(): number {
    return this.get('id');
  }

  getTitle(): string {
    return this.get('title');
  }
}
