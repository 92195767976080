import { List } from 'immutable';
import { Pagination } from 'model/Pagination';
import { Project } from 'model/Project';
import { ProjectSettings } from 'model/ProjectSettings';
import { PulseTemplate } from 'model/PulseTemplate';
import { Resource } from 'model/Resource';
import { ProjectLayoutState } from 'reduxStore/project/projectLayout/initialState';

export const STORE_NAME = 'project';

export namespace ProjectState {
  export type ProjectList = List<Project>;
  export type ProjectListResource = Resource<ProjectList>;
  /**
   * This could be normal project (e.g.: Engagement or CAS)
   * or PulseTemplate with additional information about pulses, ODP, and lifecycles
   */
  export type CurrentProject = Project | PulseTemplate;
  export type ProjectCurrentResource = Resource<CurrentProject>;
  export type ProjectSettingsResource = Resource<ProjectSettings>;
  export type WebLinkResource = string;
  export type ProjectListPagination = Pagination | null;
  export type SubProjects = {
    current: Resource<Project>;
    list: Resource<List<Project>>;
    pagination: Pagination | null;
  };

  // TODO refactor, split this to states depending on what type of project is selected Normal / Pulse / ODP etc.
  export type Domain = {
    listResource: ProjectListResource;
    currentResource: ProjectCurrentResource;
    layoutResource: ProjectLayoutState.Domain;
    settingsResource: ProjectSettingsResource;
    webLinkResource: WebLinkResource;
    listPagination: ProjectListPagination;
    subProjects: SubProjects;
  };

  // TODO REMOVE
  export const INITIAL_LIST_RESOURCE: ProjectListResource = Resource.void<ProjectList>();
  export const INITIAL_CURRENT_RESOURCE: ProjectCurrentResource = Resource.void<CurrentProject>();
  export const INITIAL_WEB_LINK_RESOURCE: WebLinkResource = '';
  export const INITIAL_PROJECT_SETTINGS_RESOURCE: ProjectSettingsResource = Resource.void<ProjectSettings>();
  export const INITIAL_PROJECT_LIST_PAGINATION: Pagination | any = null;
  export const INITIAL_SUB_PROJECTS: SubProjects = {
    list: Resource.void<List<Project>>(),
    current: Resource.void<Project>(),
    pagination: null,
  };

  export const INITIAL_DOMAIN: Domain = {
    listResource: INITIAL_LIST_RESOURCE,
    currentResource: INITIAL_CURRENT_RESOURCE,
    layoutResource: ProjectLayoutState.INITIAL_DOMAIN,
    settingsResource: INITIAL_PROJECT_SETTINGS_RESOURCE,
    webLinkResource: INITIAL_WEB_LINK_RESOURCE,
    listPagination: INITIAL_PROJECT_LIST_PAGINATION,
    subProjects: INITIAL_SUB_PROJECTS,
  };
}
