import { useEffect } from 'react';

import { useAppDispatch } from 'reduxStore/appStore';
import { fetchCircles } from 'reduxStore/circles/asyncActions';

export const useFetchCircles = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCircles());
  }, [dispatch]);
};
