import { ProjectApi } from 'api/ProjectApi';
import { List } from 'immutable';
import { CategoryMapper } from 'mapper/CategoryMapper';
import { CompanyMapper } from 'mapper/CompanyMapper';
import { DistributionMapper } from 'mapper/DistributionMapper';
import { JobMapper } from 'mapper/JobMapper';
import { PageMapper } from 'mapper/PageMapper';
import { ProjectLayoutMapper } from 'mapper/ProjectLayoutMapper';
import { ProjectSettingsMapper } from 'mapper/ProjectSettingsMapper';
import { Company } from 'model/Company';
import { Project } from 'model/Project';
import { Timezone } from 'model/Timezone';
import moment from 'moment';

export class ProjectMapper {
  constructor(
    private pageMapper: PageMapper,
    private projectLayoutMapper: ProjectLayoutMapper,
    private projectSettingsMapper: ProjectSettingsMapper,
    private distributionMapper: DistributionMapper,
    private categoryMapper: CategoryMapper,
    private jobMapper: JobMapper,
    private companyMapper: CompanyMapper
  ) {}

  serialize(model: Project): ProjectApi.Entry {
    return {
      id: model.getId(),
      name: model.getName(),
      company: model.getCompany() && this.companyMapper.serialize(model.getCompany()),
      status: model.getStatus(),
      liveStop: model.getLiveStop() && model.getLiveStop().toISOString(),
      liveStart: model.getLiveStart() && model.getLiveStart().toISOString(),
      createdAt: model.getCreatedAt() && model.getCreatedAt().toISOString(),
      updatedAt: model.getUpdatedAt() && model.getUpdatedAt().toISOString(),
      createdBy: model.getCreatedBy(),
      updatedBy: model.getUpdatedBy(),
      reportRecipients: model.getReportRecipients() && model.getReportRecipients().toArray(),
      jobs:
        model.getJobs() &&
        model
          .getJobs()
          .map((job) => this.jobMapper.serialize(job))
          .toArray(),
      responseRate: model.getResponseRate(),
      responseStarted: model.getResponseStarted(),
      responsePartial: model.getResponsePartial(),
      responseCompleted: model.getResponseCompleted(),
      categories: model
        .getCategories()
        .map((category) => this.categoryMapper.serialize(category))
        .toArray(),
      dirty: model.isDirty(),
      isPublished: model.isPublished(),
      kind: model.getKind(),
      isKioskDistributionEmpty: model.isKioskDistributionEmpty(),
      createdInPast: model.isCreatedInPast(),
      lifecycleType: model.getLifecycleType(),
    };
  }

  deserialize(entry: ProjectApi.Entry): Project {
    const formattedLiveStart = entry.liveStart && entry.liveStart.split('T')[0];
    const formattedLiveStop = entry.liveStop && entry.liveStop.split('T')[0];

    return new Project({
      id: entry.id,
      name: entry.name,
      company: entry.company
        ? this.companyMapper.deserialize(entry.company)
        : entry.companyName &&
          new Company({
            name: entry.companyName,
            circleId: entry.circleId,
            id: entry.companyId,
            timezone: Timezone.DEFAULT_TIMEZONE,
            settings: {
              ssoEnabled: false,
              multiLanguageEnabled: false,
              enabledLocales: [],
              enabledDashboardLocales: [],
              customKioskCodesEnabled: false,
            },
          }),
      status: entry.status,
      liveStart: entry.liveStart && moment(formattedLiveStart),
      liveStop: entry.liveStop && moment(formattedLiveStop),
      pulseFrequencyId: entry.pulseFrequencyId || null,
      createdAt: entry.createdAt && moment.utc(entry.createdAt),
      updatedAt: entry.updatedAt && moment.utc(entry.updatedAt),
      createdBy: entry.createdBy,
      updatedBy: entry.updatedBy,
      reportRecipients: List(entry.reportRecipients),
      responseRate: entry.responseRate,
      responsePartial: entry.responsePartial || 0,
      responseStarted: entry.responseStarted || 0,
      responseCompleted: entry.responseCompleted || 0,
      pages: List((entry.pages || []).map((pageEntry) => this.pageMapper.deserialize(pageEntry))),
      layout: entry.layout && this.projectLayoutMapper.deserialize(entry.layout),
      settings: entry.settings && this.projectSettingsMapper.deserialize(entry.settings),
      jobs: entry.jobs && List(entry.jobs.map((job) => this.jobMapper.deserialize(job))),
      distributions: List(
        (entry.distributions || [])
          // we filter because we don't have all distributions implemented
          .filter((distributionEntry) =>
            this.distributionMapper.supportsDeserialize(distributionEntry)
          )
          .map((distributionEntry) => this.distributionMapper.deserialize(distributionEntry))
      ),
      categories: List(
        (entry.categories || []).map((categoryEntry) =>
          this.categoryMapper.deserialize(categoryEntry)
        )
      ),
      dirty: entry.dirty,
      isPublished: entry.isPublished,
      kind: entry.kind,
      isKioskDistributionEmpty: entry.isKioskDistributionEmpty,
      createdInPast: entry.createdInPast,
      lifecycleType: entry.lifecycleType,
    });
  }
}
