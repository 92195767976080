import { DepartmentApi } from 'api/DepartmentApi';
import { List } from 'immutable';
import { Department } from 'model/Department';

export class DepartmentMapper {
  serialize(model: Department): DepartmentApi.Entry {
    return {
      id: model.getId(),
      name: model.getName(),
      children: model
        .getChildren()
        .map((childModel) => this.serialize(childModel))
        .toArray(),
      position: model.getPosition(),
    };
  }

  deserialize(entry: DepartmentApi.Entry, parentId?: number): Department {
    return new Department({
      id: entry.id,
      name: entry.name,
      children: List<Department>(
        (entry.children || [])
          .filter((childEntry) => !!childEntry)
          .map((childEntry) => this.deserialize(childEntry, entry.id))
      ),
      connected: entry.connected && this.deserialize(entry.connected),
      position: entry.position,
      parentId: parentId,
    });
  }
}
