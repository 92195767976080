import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import isObject from 'lodash/isObject';

// TODO: Discuss with the backend guys should we pass null values.
// TODO: Revert the commit (except changes in QuestionCheckboxFormMapper.ts) before upcoming release full regression.
export function jsonToFormData(
  json: any,
  form = new FormData(),
  nested: (string | number)[] = []
): FormData {
  if (isObject(json) && !(json instanceof File)) {
    Object.keys(json).forEach((key) => {
      jsonToFormData((json as any)[key], form, [...nested, key]);
    });
  } else if (isArray(json)) {
    json.forEach((value, index) => {
      jsonToFormData(json[index], form, [...nested, index]);
    });
  } else if (json !== undefined && json !== null) {
    if (nested.length === 0) {
      throw new Error('Cannot map scalar value to FormData.');
    }
    if (isBoolean(json)) {
      form.append(buildFormKey(nested), json ? '1' : '0');
    } else {
      form.append(buildFormKey(nested), json);
    }
  } else {
    if (nested.length === 0) {
      throw new Error('Cannot map undefined value to FormData.');
    }
  }

  return form;
}

export function buildFormKey(nested: (string | number)[]): string {
  const [mainKey, ...nestedKeys] = nested;

  return `${mainKey}${nestedKeys.length ? `[${nestedKeys.join('][')}]` : ''}`;
}
