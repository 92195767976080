import { createSlice } from '@reduxjs/toolkit';
import { addAsyncCases } from 'shared/utils/redux';

import { fetchPaperDistribution } from './asyncActions';
import { PaperDistributionState, STORE_NAME } from './initialState';

export const paperDistributionSlice = createSlice({
  name: STORE_NAME,
  initialState: PaperDistributionState.INITIAL_DOMAIN,
  reducers: {
    clearPaperDistribution: (state) => {
      state.distributionResource = PaperDistributionState.INITIAL_DOMAIN.distributionResource;
    },
  },
  extraReducers: (builder) => {
    addAsyncCases(builder, fetchPaperDistribution, (state, action) => {
      state.distributionResource = action.payload;
    });
  },
});

export const { clearPaperDistribution } = paperDistributionSlice.actions;
