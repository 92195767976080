import { is, Iterable, Map } from 'immutable';

export namespace SimpleModel {
  export type Data<T> = T | Map<keyof T, any>;
  export type Mutator<T> = (data: Map<keyof T, any>) => any;
  export type Updater<T> = (data: T) => T;
}

export class SimpleModel<T extends { [key: string]: any }> {
  protected static readonly JSON_MEMOIZED = '@@__JSON_MEMOIZED__@@';

  private memoized: { [key: string]: any } = {};
  private data: Map<keyof T, any>;

  // @ts-ignore
  constructor(private initialData: SimpleModel.Data<T>) {
    this.data = Map(initialData);
  }

  clone(): this {
    return new (Object.getPrototypeOf(this).constructor)(this.data);
  }

  update<K extends keyof T>(key: K, updater: (v: T[K]) => any): this {
    const cloned = this.clone();
    cloned.data = cloned.data.update(key, updater);
    return cloned;
  }

  get<K extends keyof T>(key: K, notSetValue?: T[K]): T[K] {
    return this.data.get(key, notSetValue);
  }

  set<K extends keyof T>(key: K, value: T[K]): this {
    const cloned = this.clone();
    cloned.data = cloned.data.set(key, value);
    return cloned;
  }

  has<K extends keyof T>(key: K): boolean {
    return this.data.has(key);
  }

  remove<K extends keyof T>(key: K): this {
    const cloned = this.clone();
    cloned.data = cloned.data.remove(key);
    return cloned;
  }

  withMutations(mutator: SimpleModel.Mutator<T>): this {
    const cloned = this.clone();
    cloned.data = cloned.data.withMutations(mutator);
    return cloned;
  }

  equals(model: any): boolean {
    return model instanceof SimpleModel && is(this.data, model.data);
  }

  toJSON(): any {
    return this.memoize(SimpleModel.JSON_MEMOIZED, (data) =>
      Iterable.isKeyed(data) ? data.toObject() : data.toArray()
    );
  }

  protected memoize<M>(key: string, getter: (data: Map<keyof T, any>) => M): M {
    if (!this.memoized.hasOwnProperty(key)) {
      this.memoized[key] = getter(this.data);
    }

    return this.memoized[key];
  }
}
