// https://chir.ag/projects/name-that-color/
export const colors = {
  alabaster: '#f7f7f7',
  alto: '#d4d4d4',
  blackDE: '#000000de',
  cinderella: '#fbE4dd',
  cinnabar: '#e64a19',
  clementine: '#ED6C02',
  doveGray: '#676666',
  dustyGray: '#949494',
  flamingo: '#f36d2b',
  iron: '#d8d9dd',
  mineShaft: '#2c2c2c',
  pomegranate: '#f44336',
  porcelain: '#eceff1',
  seashell: '#f1f1f1',
  shark: '#212222',
  shipGray: '#404042',
  silverChalice77: '#adadad77',
  silverChalice: '#9e9e9e',
  tiaMaria: '#c9380c',
  treePoppy: '#f89a1f',
  white: '#ffffff',
};
