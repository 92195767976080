import React, { useState } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { List, Theme } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MUIMenuItem from '@mui/material/MenuItem';
import { SxProps } from '@mui/system';

export namespace MenuItem {
  export type Props = {
    children?: string | JSX.Element;
    leftIcon?: JSX.Element;
    rightIcon?: JSX.Element;
    onClick?: (event?: React.MouseEvent<HTMLLIElement>) => void;
    disabled?: boolean;
    menuItems?: JSX.Element[];
    value?: any;
    sx?: SxProps<Theme>;
    'data-testid'?: string;
  };
}

export const MenuItem = ({
  leftIcon,
  children,
  rightIcon,
  onClick,
  disabled,
  menuItems,
  value,
  sx,
  'data-testid': dataTestId,
}: MenuItem.Props): JSX.Element => {
  const [open, setOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLLIElement>) => {
    event.preventDefault();

    if (menuItems && menuItems.length > 0) {
      setOpen(!open);
    }

    if (onClick) {
      onClick(event);
    }
  };

  const renderRightSideIcon = () => {
    if (menuItems && menuItems.length > 0) {
      return open ? <ExpandLessIcon /> : <ExpandMoreIcon />;
    } else if (rightIcon) {
      return <ListItemIcon>{rightIcon}</ListItemIcon>;
    } else {
      return null;
    }
  };

  return (
    <>
      <MUIMenuItem
        sx={sx}
        onClick={handleClick}
        disabled={disabled}
        value={value}
        data-testid={dataTestId}
      >
        {leftIcon && <ListItemIcon>{leftIcon}</ListItemIcon>}
        <ListItemText>{children}</ListItemText>
        {renderRightSideIcon()}
      </MUIMenuItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ pl: 4 }}>
          {menuItems}
        </List>
      </Collapse>
    </>
  );
};
