import { KioskDistribution } from 'model/KioskDistribution';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'kioskDistribution';

export namespace KioskDistributionState {
  export type Domain = {
    distributionResource?: Resource<KioskDistribution>;
  };

  export const INITIAL_DOMAIN: Domain = {
    distributionResource: Resource.void<KioskDistribution>(),
  };
}
