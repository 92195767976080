import { createAsyncThunk } from '@reduxjs/toolkit';
import { projectLayoutRepository } from 'container/projectLayoutRepository';
import { ProjectLayout } from 'model/ProjectLayout';
import { STORE_NAME } from 'reduxStore/project/initialState';
import { selectCurrentProjectId } from 'reduxStore/project/projectSelector';
import { serializeError } from 'shared/utils/redux';

export const fetchProjectLayout = createAsyncThunk(
  `${STORE_NAME}/fetchProjectLayout`,
  ({ projectId }: { projectId: number }) => {
    return projectLayoutRepository.fetch(projectId);
  },
  { serializeError }
);

export const saveProjectLayout = createAsyncThunk(
  `${STORE_NAME}/saveProjectLayout`,
  ({ layout }: { layout: ProjectLayout }, { getState }) => {
    const projectId = selectCurrentProjectId(getState());
    return projectLayoutRepository.save(projectId, layout);
  },
  { serializeError }
);
