import { CategoryApi } from 'api/CategoryApi';
import { Category } from 'model/Category';

export class CategoryMapper {
  serialize(model: Category): CategoryApi.Entry {
    return {
      id: model.getId(),
      isGlobal: model.isGlobal(),
      isActive: model.isActive(),
      name: model.getName(),
    };
  }

  deserialize(entry: CategoryApi.Entry): Category {
    return new Category({
      id: entry.id,
      isGlobal: entry.isGlobal,
      isActive: entry.isActive,
      name: entry.name,
    });
  }
}
