import React from 'react';

import { TextField, TextFieldProps } from '@mui/material';

const HELPER_TEXT_MIN_HEIGHT = 20;
const SPACE_HEIGHT = 5;

export type BaseTextFieldProps = TextFieldProps & {
  removeBottomMargin?: boolean;
  'data-testid'?: string;
};

export const BaseTextField: React.VFC<BaseTextFieldProps> = ({
  error,
  removeBottomMargin,
  ...rest
}) => {
  const dataTestId = rest['data-testid'];
  const labelDataTestId = dataTestId ? `${dataTestId}Label` : undefined;
  const inputDataTestId = dataTestId ? `${dataTestId}Input` : undefined;

  return (
    <TextField
      error={error}
      {...rest}
      sx={{
        marginBottom:
          error || removeBottomMargin ? undefined : `${HELPER_TEXT_MIN_HEIGHT + SPACE_HEIGHT}px`,
        ...rest.sx,
      }}
      FormHelperTextProps={{
        ...rest.FormHelperTextProps,
        sx: {
          marginTop: 0,
          minHeight: removeBottomMargin ? undefined : `${HELPER_TEXT_MIN_HEIGHT}px`,
          marginBottom: removeBottomMargin ? undefined : `${SPACE_HEIGHT}px`,
          ...rest.FormHelperTextProps?.sx,
        },
      }}
      InputLabelProps={{
        // @ts-expect-error
        'data-testid': labelDataTestId,
        ...rest.InputLabelProps,
      }}
      inputProps={{
        'data-testid': inputDataTestId,
        ...rest.inputProps,
      }}
    />
  );
};
