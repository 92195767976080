import { WebLinkDistributionApi } from 'api/WebLinkDistributionApi';
import { WebLinkDistributionMapper } from 'mapper/WebLinkDistributionMapper';
import { WebLinkDistribution } from 'model/WebLinkDistribution';

export class WebLinkDistributionRepository {
  constructor(
    private webLinkDistributionApi: WebLinkDistributionApi,
    private webLinkDistributionMapper: WebLinkDistributionMapper
  ) {}

  get(projectId: number): Promise<WebLinkDistribution> {
    return this.webLinkDistributionApi
      .get(projectId)
      .then((entry) => this.webLinkDistributionMapper.deserialize(entry));
  }
}
