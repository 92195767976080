import { createSelector } from '@reduxjs/toolkit';
import { AdminState } from 'reduxStore/appStore';

import { STORE_NAME } from './initialState';

export function selectWebLinkDomain(state: AdminState) {
  return state[STORE_NAME];
}

export const selectWebLinkDistributionResource = createSelector(
  selectWebLinkDomain,
  (domain) => domain.distributionResource
);
