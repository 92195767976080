import { selectErrorCode, selectErrorData } from 'util/selector/axiosSelector';

import { AxiosError } from 'axios';
import { Exception } from 'exception/Exception';

export class HttpException extends Exception {
  static createFromAxiosError(response: AxiosError, field?: string): HttpException {
    return new this(selectErrorData(response, field), selectErrorCode(response));
  }

  constructor(message: string, protected httpCode?: number) {
    super({
      type: 'HTTP_ERROR',
      message: message,
      code: 'HTTP_ERROR',
    });
    Object.setPrototypeOf(this, HttpException.prototype);
  }

  getHttpCode(): number {
    return this.httpCode;
  }
}
