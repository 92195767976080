import { JWTUser } from 'model/JWTUser';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'auth';

export namespace AuthState {
  export type Domain = {
    loggedUserResource: Resource<Readonly<JWTUser>>;
  };

  export const INITIAL_DOMAIN: Domain = {
    loggedUserResource: Resource.void(),
  };
}
