import { createSlice } from '@reduxjs/toolkit';
import {
  fetchUnsubscribedAccessCodes,
  searchAccessCodes,
} from 'reduxStore/accessCode/asyncActions';
import { AccessCodeState, STORE_NAME } from 'reduxStore/accessCode/initialState';
import { addAsyncCases } from 'shared/utils/redux';

export const accessCodeSlice = createSlice({
  name: STORE_NAME,
  initialState: AccessCodeState.INITIAL_DOMAIN,
  reducers: {
    clearAccessCodes: (state) => {
      state.unsubscribedResource = AccessCodeState.INITIAL_LIST_RESOURCE;
    },
  },
  extraReducers: (builder) => {
    addAsyncCases(builder, fetchUnsubscribedAccessCodes, (state, action) => {
      state.unsubscribedResource = action.payload;
      state.searchResource = action.payload;
    });

    addAsyncCases(builder, searchAccessCodes, (state, action) => {
      state.searchResource = action.payload;
    });
  },
});

export const { clearAccessCodes } = accessCodeSlice.actions;
