import { Permission } from 'model/Permission';
import { SimpleModel } from 'model/SimpleModel';

// Permission Types are all available permissions for selected project

export namespace PermissionTypes {
  export interface Shape {
    primary_feature: Permission[];
    feature: Permission[];
    comment_demographic: Permission[];
    comment_department: Permission[];
    summary_demographic: Permission[];
    summary_department: Permission[];
    access: Permission[];
  }

  export type View = {
    id: string;
    permissions: Permission[];
    label?: string;
  };
}

export class PermissionTypes extends SimpleModel<PermissionTypes.Shape> {
  getPrimaryPermissions(): Permission[] {
    return this.get('primary_feature');
  }

  getFeaturePermissions(): Permission[] {
    return this.get('feature');
  }

  getDemographicCommentPermissions(): Permission[] {
    return this.get('comment_demographic');
  }

  getDepartmentCommentPermissions(): Permission[] {
    return this.get('comment_department');
  }

  getDemographicSummaryPermissions(): Permission[] {
    return this.get('summary_demographic');
  }

  getDepartmentSummaryPermissions(): Permission[] {
    return this.get('summary_department');
  }

  getProjectAccessPermissions(): Permission[] {
    return this.get('access');
  }

  getPrimaryPermissionsView(): PermissionTypes.View {
    return {
      id: 'feature',
      permissions: this.getPrimaryPermissions(),
    };
  }

  getFeaturePermissionsView(): PermissionTypes.View {
    return {
      id: 'feature',
      label: 'Feature',
      permissions: this.getFeaturePermissions(),
    };
  }

  getDepartmentSummaryPermissionsView(): PermissionTypes.View {
    return {
      id: 'summary-department',
      label: 'View results for:',
      permissions: this.getDepartmentSummaryPermissions(),
    };
  }

  getDemographicSummaryPermissionsView(): PermissionTypes.View {
    return {
      id: 'summary-demography',
      label: 'View following demographics:',
      permissions: this.getDemographicSummaryPermissions(),
    };
  }

  getDepartmentCommentPermissionsView(): PermissionTypes.View {
    return {
      id: 'comment-department',
      label: 'View comments for:',
      permissions: this.getDepartmentCommentPermissions(),
    };
  }

  getDemographicCommentPermissionsView(): PermissionTypes.View {
    return {
      id: 'comment-demography',
      label: 'View comments for:',
      permissions: this.getDemographicCommentPermissions(),
    };
  }

  getProjectAccessView(): PermissionTypes.View {
    return {
      id: 'access',
      label: 'Survey access:',
      permissions: this.getProjectAccessPermissions(),
    };
  }

  getBasePermissionsTypesView(isPulseTemplate: boolean): PermissionTypes.View[] {
    return [
      ...(isPulseTemplate ? [this.getProjectAccessView()] : []),
      this.getFeaturePermissionsView(),
      this.getDepartmentSummaryPermissionsView(),
      this.getDemographicSummaryPermissionsView(),
      this.getDepartmentCommentPermissionsView(),
    ];
  }

  getCustomPermissionsTypesView(): PermissionTypes.View[] {
    return [this.getDemographicCommentPermissionsView()];
  }

  // TODO refactor - move to backend so that front receive proper structure for given user role
  getPermissionTypesView(isClientAdmin: boolean, isPulseTemplate: boolean): PermissionTypes.View[] {
    return isClientAdmin
      ? this.getBasePermissionsTypesView(isPulseTemplate)
      : [
          ...this.getBasePermissionsTypesView(isPulseTemplate),
          ...this.getCustomPermissionsTypesView(),
        ];
  }
}
