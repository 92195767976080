export const STORE_NAME = 'snackbars';

export namespace SnackbarState {
  export type Snackbar = {
    id: number;
    message: string;
    isOpen?: boolean;
    timeoutId?: ReturnType<typeof setTimeout>;
  };

  export type Domain = { list: Snackbar[] };

  export const INITIAL_DOMAIN: Domain = { list: [] };
}
