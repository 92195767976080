import { flatten } from 'util/flatten';

import { List } from 'immutable';
import { Department } from 'model/Department';

const buildName = (name: string, prefixes: string[] = []) => flatten([prefixes, name]).join(' -> ');

const handleItem = (
  item: Department,
  prefixes: string[] = [],
  result: { name: string; id: number }[] = []
) => {
  const name = buildName(item.getName(), prefixes);
  result.push({
    name: name,
    id: item.getId(),
  });

  item.getChildren().forEach((child) => {
    handleItem(child, prefixes.concat([item.getName()]), result);
  });

  return result;
};

export const createDepartmentTreeList = (tree: List<Department>) =>
  flatten(tree.toArray().map((item) => handleItem(item)));

export const createDepartmentTreeListUnique = (
  tree: List<Department>
): { name: string; id: number }[] =>
  createDepartmentTreeList(tree).reduce((accumulator, current) => {
    if (!accumulator.find((item: { name: string; id: number }) => item.id === current.id)) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);

export const createExcludingDepartmentTreeList = (
  departments: List<Department>,
  department: Department
): { name: string; id: number }[] => {
  const childrenIds = department?.hasChildren() ? getAllChildrenIds(department) : [];

  return createDepartmentTreeList(departments)
    .filter((item) => !childrenIds.includes(item.id))
    .filter((item) => item.id !== department?.getId() && item.id !== department?.getParentId());
};

export const getAllChildrenIds = (department: Department): number[] | any => {
  if (department.hasChildren()) {
    const result = department
      .getChildren()
      .map((item) => getAllChildrenIds(item))
      .toArray();

    return flatten([department.getId(), flatten(result)]);
  } else {
    return department.getId();
  }
};
