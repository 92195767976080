import React from 'react';

import { ColorPickerPopover } from 'component/ColorPicker/ColorPickerPopover';
import {
  BenchmarkingDescription,
  BenchmarkingTitle,
  BenchmarkingWrapper,
  CompanyBrandColorWrapper,
} from 'component/CreateProject/CreateProjectDialog.s';
import { Dropzone } from 'component/Dropzone/Dropzone';
import { DropzoneDefaultMessage } from 'component/Dropzone/DropzoneDefaultMessage';
import { DropzoneClientLogoEmptyMessage } from 'component/DropzoneClientLogoEmptyMessage/DropzoneClientLogoEmptyMessage';
import { AutocompleteData } from 'component/Form/Autocomplete';
import { SelectField } from 'component/Form/SelectField';
import { TextField } from 'component/Form/TextField';
import { MenuItem } from 'component/MenuItem/MenuItem';
import { SurveyTypeLabel } from 'component/SurveyTypeLabel/SurveyTypeLabel';
import { List } from 'immutable';
import { Enum } from 'model/Enum';
import { Project } from 'model/Project';
import { Field } from 'redux-form';
import { required } from 'validator/required';
import { Circle } from 'model/Circle';

export namespace CreateProjectNewCompany {
  export type Props = {
    benchmarkSectors: List<Enum>;
    circles: List<Circle>;
    employeeRangeGroup: List<Enum>;
    timezones: AutocompleteData<string>[];
    isEngagementScoreProjectType: boolean;
  };
}

export const CreateProjectNewCompany = (props: CreateProjectNewCompany.Props): JSX.Element => (
  <div>
    <Field
      name="companyName"
      component={TextField}
      label="Company name"
      validate={required('Company name is required')}
      fullWidth
    />
    {props.circles.count() > 1 && (
      <Field
        data-testid="circleField"
        name="circle"
        component={SelectField}
        label="Circle"
        validate={required('Circle is required')}
        fullWidth
      >
        {props.circles
          .sort((a, b) => a.getName().localeCompare(b.getName()))
          .map((circle, index) => (
            <MenuItem key={index} value={circle.getId()}>
              {circle.getName()}
            </MenuItem>
          ))}
      </Field>
    )}
    <Field
      name="companyLogo"
      label="Logo"
      component={Dropzone}
      accept={Project.getAcceptedImagesTypes()}
    >
      {(files: File[]) => {
        switch (files.length) {
          case 0:
            return <DropzoneClientLogoEmptyMessage />;
          default:
            return <DropzoneDefaultMessage files={files} />;
        }
      }}
    </Field>
    <CompanyBrandColorWrapper>
      <Field
        name="brandColor"
        component={ColorPickerPopover}
        style={{ margin: '0px 10px 0px 0px' }}
        popoverStyle={{ bottom: '200px' }}
      />
      <span>Company Brand Colour</span>
    </CompanyBrandColorWrapper>
    <Field
      name="timezone"
      component={SelectField}
      label="Timezone"
      validate={required('Timezone is required')}
    >
      {props.timezones.map((item, index) => (
        <MenuItem key={index} value={item.value}>
          {item.text}
        </MenuItem>
      ))}
    </Field>
    <Field
      name="kind"
      component={SelectField}
      label="Survey type"
      validate={required('Survey type is required')}
    >
      {Project.SURVEY_TYPE.map((type, index) => (
        <MenuItem key={index} value={type}>
          <SurveyTypeLabel kind={type} />
        </MenuItem>
      ))}
    </Field>
    {props.isEngagementScoreProjectType && (
      <BenchmarkingWrapper>
        <BenchmarkingTitle>Benchmarking</BenchmarkingTitle>
        <BenchmarkingDescription>
          To benchmark your results against similar companies please tell us a bit more about your
          organisation:
        </BenchmarkingDescription>
        <Field
          name="benchmarkSector"
          component={SelectField}
          label="Sector"
          validate={required('Benchmark sector is required')}
        >
          {props.benchmarkSectors.map((item, index) => (
            <MenuItem key={index} value={item.getKey()}>
              {item.getName()}
            </MenuItem>
          ))}
        </Field>
        <Field
          name="approxNumberEmployee"
          component={SelectField}
          label="Approx number of employees"
          validate={required('Approx number of employees is required')}
        >
          {props.employeeRangeGroup.map((item, index) => (
            <MenuItem key={index} value={item.getKey()}>
              {item.getName()}
            </MenuItem>
          ))}
        </Field>
      </BenchmarkingWrapper>
    )}
  </div>
);
