import { createSlice } from '@reduxjs/toolkit';
import { addTakeLatestResourceFactory } from 'shared/utils/redux';

import { QueueState, STORE_NAME } from './initialState';
import { fetchProjectQueues } from './asyncActions';

const addTakeLatestResource = addTakeLatestResourceFactory<QueueState.Domain>();

export const queueSlice = createSlice({
  name: STORE_NAME,
  initialState: QueueState.INITIAL_DOMAIN,
  reducers: {
    clearProjectQueuesState: (state) => {
      state.projectQueues = QueueState.INITIAL_DOMAIN.projectQueues;
    },
  },
  extraReducers: (builder) => {
    addTakeLatestResource(builder, fetchProjectQueues, 'projectQueues');
  },
});

export const { clearProjectQueuesState } = queueSlice.actions;
