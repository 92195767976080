import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { selectUserId } from 'reduxStore/auth/selectors';

export const useUserGuiding = () => {
  const isFeatureEnabled = useFeatureIsOn('core.user-guiding');
  const userId = useSelector(selectUserId);

  useEffect(() => {
    if (isFeatureEnabled && !window?.userGuiding) {
      const script = document.createElement('script');
      script.setAttribute('data-testid', 'userGuidingScript');
      script.async = true;
      script.innerHTML = `
        (function(g,u,i,d,e,s){
          g[e]=g[e]||[];
          var f=u.getElementsByTagName(i)[0];
          var k=u.createElement(i);
          k.async=true;
          k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';
          f.parentNode.insertBefore(k,f);
          if(g[d])return;
          var ug=g[d]={q:[]};
          ug.c=function(n){return function(){ug.q.push([n,arguments])};};
          var m=['previewGuide','finishPreview','track','identify','hideChecklist','launchChecklist'];
          for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}
        })(window,document,'script','userGuiding','userGuidingLayer','IOM88144ZCTID');
      `;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isFeatureEnabled]);

  useEffect(() => {
    if (isFeatureEnabled && userId) {
      window?.userGuiding?.identify(userId);
    }
  }, [isFeatureEnabled, userId]);
};
