import { ContactListApi } from 'api/ContactListApi';
import { ContactListMapper } from 'mapper/ContactListMapper';
import { ContactList, ContactListSend } from 'model/ContactList';

export class ContactListRepository {
  constructor(
    private contactListApi: ContactListApi,
    private contactListMapper: ContactListMapper
  ) {}

  fetch(id: string): Promise<ContactList> {
    return this.contactListApi.fetch(id).then((entry) => this.contactListMapper.deserialize(entry));
  }

  create(id: string, contactList: ContactListSend): Promise<void> {
    return this.contactListApi.create(id, this.contactListMapper.serialize(contactList));
  }
}
