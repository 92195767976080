export namespace AdminFormNames {
  export const CreateProject = 'CreateProject';
  export const CreateProjectType = 'CreateProjectType';
  export const TextOrMediaEditor = 'TextOrMediaEditor';
  export const TextOrMediaEditorTranslation = 'TextOrMediaEditorTranslation';
  export const PageItemLogic = 'PageItemLogic';
  export const UploadEmployees = 'UploadEmployees';
  export const UploadDepartments = 'UploadDepartments';
  export const SetupCompanyDetails = 'SetupCompanyDetails';
  export const QuestionKind = 'QuestionKind';
  export const CreateQuestion = 'CreateQuestion';
  export const CreateQuestionTranslation = 'CreateQuestionTranslation';
  export const CreateQuestionSettings = 'CreateQuestionSettings';
  export const EmailTemplate = 'EmailTemplate';
  export const EmailTest = 'EmailTest';
  export const ExportData = 'ExportData';
  export const ScheduleForm = 'ScheduleForm';
  export const PreviousTemplate = 'PreviousTemplate';
  export const PreviousSurvey = 'PreviousSurvey';
  export const CustomCategory = 'CustomCategory';
  export const ContactListType = 'ContactListType';
  export const ContactList = 'ContactList';
  export const AutomatedEmail = 'AutomatedEmail';
  export const SearchContact = 'SearchContact';
  export const LiveStopDate = 'LiveStopDate';
  export const ProjectSettings = 'ProjectSettings';
  export const ImportSurvey = 'ImportSurvey';
  export const AddUser = 'AddUser';
  export const UploadUsers = 'UploadUsers';
  export const BenchmarkDataType = 'BenchmarkDataType';
  export const TrendDataType = 'TrendDataType';
  export const DashboardSettings = 'DashboardSettings';
  export const UploadTrendData = 'UploadTrendData';
  export const UploadBenchmarkData = 'UploadBenchmarkData';
  export const CreateProjectTemplate = 'CreateProjectTemplate';
  export const PreviewSurvey = 'PreviewSurvey';
  export const SurveySettings = 'SurveySettings';
  export const AddDemographic = 'AddDemographic';
  export const AddDepartment = 'AddDepartment';
  export const ChangeDepartmentPosition = 'ChangeDepartmentPosition';
  export const ChangeDepartmentParent = 'ChangeDepartmentParent';
  export const Employee = 'Employee';
  export const EmployeeUploadType = 'EmployeeUploadType';
  export const CreateClient = 'CreateClient';
  export const QuestionLibrary = 'QuestionLibrary';
  export const PulseSurveyStartDate = 'PulseSurveyStartDate';
  export const PulseSurveySettings = 'PulseSurveySettings';
  export const PulseSurveyFrequency = 'PulseSurveyFrequency';
  export const PulseProjectDate = 'PulseProjectDate';
  export const AccountSettings = 'AccountSettings';
  export const AddClientAdmin = 'AddClientAdmin';
  export const MoveQuestion = 'MoveQuestion';
  export const QuestionTrends = 'QuestionTrends';
  export const ConnectedDepartmentsForm = 'ConnectedDepartmentsForm';
  export const SelectConnectedPulses = 'SelectConnectedPulses';
  export const Notifications = 'Notifications';
  export const OnDemandPoll = 'OnDemandPoll';
  export const CopyOnDemandPoll = 'CopyOnDemandPoll';
  export const DemandEmailRemainder = 'OnDemandPoll';
  export const ManageSurvey = 'ManageSurvey';
  export const DistributeLifecycleToChosenEmployees = 'DistributeLifecycleToChosenEmployees';
  export const UploadLifecycleAudience = 'UploadLifecycleAudience';
  export const MultiFactorAuthenticationSettings = 'MultiFactorAuthentication';
  export const AddSSOProviderDomain = 'AddSSOProviderDomain';
  export const MultiLanguageSettings = 'MultiLanguage';
  export const MultiLanguageEnabledLanguage = 'MultiLanguageEnabledLanguage';
  export const MultiLanguageDashboardLanguages = 'MultiLanguageDashboardLanguages';
  export const CustomKioskCodesSettings = 'CustomKioskCodesSettings';
}

export type AdminFormName = keyof typeof AdminFormNames;
