import React, { useEffect, useRef } from 'react';

import MuiButton, { ButtonProps } from '@mui/material/Button';

/**
 * Props compatible with MUI 0.19
 */
export type BackwardCompatible = ButtonProps & {
  label?: string;
  keyboardFocused?: boolean;
  target?: string;
};

export type Props = BackwardCompatible;

export const Button = ({ label, children, keyboardFocused, ...props }: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (keyboardFocused && buttonRef.current) {
      buttonRef.current.focus();
    }
  }, [keyboardFocused, buttonRef]);

  if (children) {
    console.warn('Children should not be used in app button');
  }

  return (
    <MuiButton ref={buttonRef} {...props}>
      {label}
      {children}
    </MuiButton>
  );
};

export const FlatButton = Button;

/**
 * Flat grey button
 */
export const SecondaryButton = (props: Props) => {
  return <Button {...props} color="secondary" />;
};

export const ActionButton = (props: Props) => {
  return <Button {...props} color="primary" />;
};

export const RaisedButton = ({ ...props }: Props) => {
  return <Button variant="contained" {...props} />;
};
