interface DepartmentPosition {
  Above: 'Above';
  Below: 'Below';
}

export type DepartmentPositionType = DepartmentPosition['Above'] | DepartmentPosition['Below'];

export const DepartmentPositionType: DepartmentPosition = {
  Above: 'Above',
  Below: 'Below',
};

type GetRelativeOrder = (
  beforeRelative: number[],
  selectedDepartmentId: number,
  relativeDepartmentId: number,
  afterRelative: number[]
) => number[];

export const getRelativeOrderAbove: GetRelativeOrder = (
  beforeRelative,
  selectedDepartmentId,
  relativeDepartmentId,
  afterRelative
) => {
  return [...beforeRelative, selectedDepartmentId, relativeDepartmentId, ...afterRelative];
};

export const getRelativeOrderBelow: GetRelativeOrder = (
  beforeRelative,
  selectedDepartmentId,
  relativeDepartmentId,
  afterRelative
) => {
  return [...beforeRelative, relativeDepartmentId, selectedDepartmentId, ...afterRelative];
};
