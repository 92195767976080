import { PageItemApi } from 'api/PageItemApi';
import { CustomQuestionOption } from 'model/CustomQuestionOption';

export class CustomQuestionOptionMapper {
  serialize(model: CustomQuestionOption): PageItemApi.CustomQuestionOptionEntry {
    return {
      id: model.getId(),
      option: model.getOption(),
      position: model.getPosition(),
    };
  }

  deserialize(entry: PageItemApi.CustomQuestionOptionEntry): CustomQuestionOption {
    return new CustomQuestionOption({
      id: entry.id,
      option: entry.option,
      position: entry.position,
    });
  }
}
