import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { ActionButton, SecondaryButton } from 'component/Button/Button';
import { ConnectedDialog } from 'component/ConnectedDialog/ConnectedDialog';
import { ContentWrapper } from 'component/CreateProject/CreateProjectDialog.s';
import { CreateProjectForm } from 'component/CreateProject/CreateProjectForm';
import { CreateProjectTypeForm } from 'component/CreateProject/CreateProjectTypeForm';
import { isSubmitting, submit } from 'redux-form';
import { useAppDispatch } from 'reduxStore/appStore';
import { closeModal } from 'reduxStore/modal/slice';
import { selectIsProjectTypeSelected } from 'reduxStore/project/projectSelector';
import { AdminFormNames } from 'register/AdminFormNames';
import { AdminModal } from 'register/AdminModal';

import { useFetchCompanyList } from './hooks/useFetchCompanyList';

type CreateProjectDialogPureProps = {
  isProjectTypeSelected: boolean;
  isSaving: boolean;
  onSave: () => void;
  onSaveSuccess: () => void;
  onCancel: () => void;
};

export const CreateProjectDialogPure: React.VFC<CreateProjectDialogPureProps> = (props) => (
  <ConnectedDialog
    name={AdminModal.CreateProject}
    title="Create Project"
    actions={[
      <SecondaryButton key="cancel" onClick={() => props.onCancel()}>
        Cancel
      </SecondaryButton>,
      <ActionButton
        id="createProject"
        /* eslint-disable-next-line jsx-a11y/no-autofocus */
        autoFocus
        disabled={!props.isProjectTypeSelected || props.isSaving}
        onClick={() => props.onSave()}
        key="create"
      >
        Create
      </ActionButton>,
    ]}
  >
    <ContentWrapper id="createProjectType">
      <CreateProjectTypeForm />
      {props.isProjectTypeSelected && <CreateProjectForm onSubmitSuccess={props.onSaveSuccess} />}
    </ContentWrapper>
  </ConnectedDialog>
);

export const CreateProjectDialog: React.VFC = () => {
  useFetchCompanyList();

  const dispatch = useAppDispatch();
  const isProjectTypeSelected = useSelector(selectIsProjectTypeSelected);
  const isSaving = useSelector(isSubmitting(AdminFormNames.CreateProject));

  const onSave = useCallback(() => dispatch(submit(AdminFormNames.CreateProject)), [dispatch]);

  const onCancel = useCallback(() => dispatch(closeModal()), [dispatch]);

  return (
    <CreateProjectDialogPure
      isProjectTypeSelected={isProjectTypeSelected}
      isSaving={isSaving}
      onSave={onSave}
      onCancel={onCancel}
      onSaveSuccess={onCancel}
    />
  );
};
