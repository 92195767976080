export enum JobName {
  ProcessEmployeeDatabase = 'process_respondent_database',
  CommitEmployeeDatabase = 'commit_respondent_database',
  CreateContactList = 'create_contact_list',
  UploadUsers = 'upload_user_database',
  UpdateEmailStatuses = 'update_email_status',
  ReleasePulseProject = 'release_pulse_project',
  PublishProjectSubmits = 'publish_project_submits',
  ProcessLifecycleRespondents = 'process_lifecycle_respondents',
}
