import { ContactListApi } from 'api/ContactListApi';
import { DemographicApi } from 'api/DemographicApi';
import { DepartmentApi } from 'api/DepartmentApi';
import { List } from 'immutable';
import { DemographicMapper } from 'mapper/DemographicMapper';
import { DepartmentMapper } from 'mapper/DepartmentMapper';
import { ContactList, ContactListSend } from 'model/ContactList';
import { ContactListType } from 'model/ContactListType';
import { Demographic } from 'model/Demographic';
import { Department } from 'model/Department';

export class ContactListMapper {
  constructor(
    private demographicMapper: DemographicMapper,
    private departmentMapper: DepartmentMapper
  ) {}

  serialize(contactList: ContactListSend): ContactListApi.EntrySend {
    return {
      type: contactList.type,
      list: contactList.list && contactList.list.map((elem) => elem.getId()).toArray(),
    };
  }

  deserialize(entry: ContactListApi.EntryRecieve): ContactList {
    return new ContactList({
      id: entry.id,
      type: entry.type,
      list: entry.list && this.deserializeList(entry.type, entry.list),
    });
  }

  deserializeList(
    type: ContactListType,
    list: ContactListApi.EntryRecieve['list']
  ): List<Demographic> | List<Department> {
    switch (type) {
      case ContactListType.Demographics:
        return List(
          list.map((item) => this.demographicMapper.deserialize(item as DemographicApi.Entry))
        );
      case ContactListType.Departments:
        return List(
          list.map((item) => this.departmentMapper.deserialize(item as DepartmentApi.Entry))
        );
      default:
        throw new Error('ContactListMapper deserializeList got unknown type.');
    }
  }
}
