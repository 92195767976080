import { SimpleModel } from 'model/SimpleModel';

export namespace Pagination {
  export interface Shape {
    limit: number;
    page: number;
    pages: number;
    total: number;
  }
}

export class Pagination extends SimpleModel<Pagination.Shape> {
  getLimit(): number {
    return this.get('limit');
  }

  getPage(): number {
    return this.get('page');
  }

  getPages(): number {
    return this.get('pages');
  }

  getTotal(): number {
    return this.get('total');
  }
}
