import React from 'react';

import { mapError } from 'component/Form/mapError';
import { WrappedFieldProps } from 'redux-form';
import { DistributiveOmit } from 'shared/utils/type';

import { BaseTextField, BaseTextFieldProps } from './BaseTextField';

export type Props = WrappedFieldProps & DistributiveOmit<BaseTextFieldProps, 'select'>;

export const SelectField = ({
  input: { onChange, value, onBlur, ...inputProps },
  meta,
  onChange: onChangeFromField,
  fullWidth = true,
  ...props
}: Props): JSX.Element => {
  const { errorText } = mapError(meta, 'errorText');
  const hasError = !!errorText;

  return (
    <BaseTextField
      value={value}
      error={hasError}
      helperText={errorText}
      onBlur={() => onBlur(value)}
      onChange={(event) => {
        onChange(event.target.value);
        if (onChangeFromField) {
          onChangeFromField(event);
        }
      }}
      {...inputProps}
      {...props}
      fullWidth={fullWidth}
      select
    />
  );
};
