import { DistributionApi } from 'api/DistributionApi';
import { Distribution } from 'model/Distribution';

export interface DistributionMapperByChannel<
  M extends Distribution = Distribution,
  E extends DistributionApi.Entry = DistributionApi.Entry
  // tslint:disable-next-line
> {
  serialize(model: M): any;
  deserialize(entry: E): M;
  supportsSerialize(model: M): boolean;
  supportsDeserialize(entry: E): boolean;
}

export class DistributionMapper
  implements DistributionMapperByChannel<Distribution, DistributionApi.EntryAny> {
  constructor(private mappers: DistributionMapperByChannel[]) {}

  serialize<T extends DistributionApi.EntryAny>(model: Distribution): T {
    return this.findSerializer(model).serialize(model) as T;
  }

  deserialize<T extends Distribution>(entry: DistributionApi.EntryAny): T {
    return this.findDeserializer(entry).deserialize(entry) as T;
  }

  supportsSerialize(model: Distribution): boolean {
    return this.mappers.some((mapper) => mapper.supportsSerialize(model));
  }

  supportsDeserialize(entry: DistributionApi.EntryAny): boolean {
    return this.mappers.some((mapper) => mapper.supportsDeserialize(entry));
  }

  private findSerializer(model: Distribution): DistributionMapperByChannel {
    const serializer = this.mappers.find((mapper) => mapper.supportsSerialize(model));

    if (!serializer) {
      throw new Error('Cannot find serializer for distribution model: ' + JSON.stringify(model));
    }

    return serializer;
  }

  private findDeserializer(entry: DistributionApi.EntryAny): DistributionMapperByChannel {
    const deserializer = this.mappers.find((mapper) => mapper.supportsDeserialize(entry));

    if (!deserializer) {
      throw new Error('Cannot find deserializer for distribution entry: ' + JSON.stringify(entry));
    }

    return deserializer;
  }
}
