export namespace AdminRoute {
  export const PATTERN = '' as const;
  export const PROJECTS_PATTERN = `${PATTERN}/projects` as const;
  export const PROJECT_PATTERN = `${PATTERN}/project/:id` as const;
  export const CLIENTS_PATTERN = `${PATTERN}/clients` as const;
  export const PROJECT_TEMPLATES_PATTERN = `${PATTERN}/project-templates` as const;
  export const PROJECT_TEMPLATE_PATTERN = `${PATTERN}/project-template/:id` as const;
  export const MASTER_BENCHMARK_PATTERN = `${PATTERN}/master-benchmark` as const;
  export const HELP_FEEDBACK_PATTERN = `${PATTERN}/help-feedback` as const;

  export namespace Project {
    export const COMPANY_SETTINGS_PATTERN = `${PROJECT_PATTERN}/company-settings` as const;
    export const PEOPLE_PATTERN = `${PROJECT_PATTERN}/people` as const;
    export const SURVEY_PATTERN = `${PROJECT_PATTERN}/survey` as const;
    export const DISTRIBUTION_PATTERN = `${PROJECT_PATTERN}/distribution` as const;
    export const TRENDING_PATTERN = `${PROJECT_PATTERN}/trending` as const;
    export const DATA_PATTERN = `${PROJECT_PATTERN}/data` as const;

    export namespace People {
      export const DASHBOARD_PERMISSIONS_PATTERN = `${PEOPLE_PATTERN}/permissions` as const;
      export const USERS_PATTERN = `${DASHBOARD_PERMISSIONS_PATTERN}/users` as const;
      export const ACCESS_CONTROL_PATTERN = `${DASHBOARD_PERMISSIONS_PATTERN}/access-control` as const;
      export const NOTIFICATIONS_PATTERN = `${PEOPLE_PATTERN}/notifications` as const;
      export const LOGON_ACTIVITY_PATTERN = `${PEOPLE_PATTERN}/logon-activity` as const;
      export const ATTRIBUTES_PATTERN = `${PEOPLE_PATTERN}/attributes` as const;
      export const ATTRIBUTES_DEMOGRAPHICS = `${ATTRIBUTES_PATTERN}/demographics` as const;
      export const ATTRIBUTES_DEPARTMENTS = `${ATTRIBUTES_PATTERN}/departments` as const;
      export const EMPLOYEES = `${PEOPLE_PATTERN}/employees` as const;
    }

    export namespace Survey {
      export const DEVELOP_PATTERN = `${SURVEY_PATTERN}/develop` as const;
      export const PAGE_SETTINGS_PATTERN = `${SURVEY_PATTERN}/page-settings` as const;
      export const ADVANCED_PATTERN = `${SURVEY_PATTERN}/advanced` as const;
      export const SETTINGS = `${SURVEY_PATTERN}/settings` as const;

      export namespace Develop {
        export const QUESTIONS_PATTERN = `${DEVELOP_PATTERN}/questions` as const;
      }
    }

    export namespace Distribution {
      export const WEB_LINK_PATTERN = `${DISTRIBUTION_PATTERN}/web-link` as const;
      export const EMAIL_PATTERN = `${DISTRIBUTION_PATTERN}/email` as const;
      export const KIOSK_PATTERN = `${DISTRIBUTION_PATTERN}/kiosk` as const;
      export const PAPER_PATTERN = `${DISTRIBUTION_PATTERN}/paper` as const;
      export const WHITELISTING_PATTERN = `${DISTRIBUTION_PATTERN}/whitelisting` as const;
    }

    export namespace Trending {
      export const BENCHMARK_DATA_PATTERN = `${TRENDING_PATTERN}/benchmark-data` as const;
      export const TREND_DATA_PATTERN = `${TRENDING_PATTERN}/trend-data` as const;
    }

    export namespace Data {
      export const EXPORT_PATTERN = `${DATA_PATTERN}/export` as const;
      export const DOWNLOADS_PATTERN = `${DATA_PATTERN}/downloads` as const;
      export const IMPORT_SURVEY_PATTERN = `${DATA_PATTERN}/import` as const;
      export const MANAGE_SURVEY_PATTERN = `${DATA_PATTERN}/manage` as const;
    }
  }

  export namespace Library {
    export const SURVEY_PATTERN = `${PROJECT_TEMPLATE_PATTERN}/develop` as const;

    export namespace Survey {
      export const QUESTIONS_PATTERN = `${SURVEY_PATTERN}/questions` as const;
      export const PAGE_SETTINGS_PATTERN = `${SURVEY_PATTERN}/page-settings` as const;
      export const ADVANCED_PATTERN = `${SURVEY_PATTERN}/advanced` as const;
    }
  }
}
