import { AdminModalParamsMapping } from 'register/AdminModalParams';

export const STORE_NAME = 'modal';

export type AdminModalParams = {
  [key in keyof AdminModalParamsMapping]: {
    name: key;
    params: AdminModalParamsMapping[key];
  };
}[keyof AdminModalParamsMapping];

export namespace ModalState {
  export type Domain = (
    | {
        name: null;
        params: {};
      }
    | AdminModalParams
  ) & {
    pending: boolean;
  };

  export const INITIAL_DOMAIN: Domain = {
    name: null,
    params: {},
    pending: false,
  };
}
