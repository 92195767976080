import { DemographicApi } from 'api/DemographicApi';
import { ClassificationDate } from 'model/ClassificationDate';

export class ClassificationDateMapper {
  serialize(model: ClassificationDate): DemographicApi.ClassificationDateEntry {
    return {
      id: model.getId(),
      value: model.getValue(),
      demography: model.getDemographyId(),
    };
  }

  deserialize(entry: DemographicApi.ClassificationDateEntry): ClassificationDate {
    return new ClassificationDate({
      id: entry.id,
      value: entry.value,
      demographyId: entry.demography,
      demographyKind: entry.demographyKind,
    });
  }
}
