import { createSlice } from '@reduxjs/toolkit';
import { addAsyncCases } from 'shared/utils/redux';
import {
  fetchDepartmentList,
  uploadDepartmentList,
  uploadDepartmentDry,
} from 'reduxStore/department/asyncActions';

import { DepartmentState, STORE_NAME } from './initialState';

export const departmentSlice = createSlice({
  name: STORE_NAME,
  initialState: DepartmentState.INITIAL_DOMAIN,
  reducers: {
    clearDepartmentsList: (state) => {
      state.listResource = DepartmentState.INITIAL_DOMAIN.listResource;
    },
    clearDepartmentsDryList: (state) => {
      state.dryListResource = DepartmentState.INITIAL_DOMAIN.dryListResource;
    },
  },
  extraReducers: (builder) => {
    addAsyncCases(builder, fetchDepartmentList, (state, action) => {
      state.listResource = action.payload;
    });
    addAsyncCases(builder, uploadDepartmentList, (state, action) => {
      state.listResource = action.payload;
    });
    addAsyncCases(builder, uploadDepartmentDry, (state, action) => {
      state.dryListResource = action.payload;
    });
  },
});

export const { clearDepartmentsList, clearDepartmentsDryList } = departmentSlice.actions;
