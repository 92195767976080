import { styled } from '@mui/material';

export const ContentWrapper = styled('div')({
  paddingTop: '16px',
});

export const CompanyBrandColorWrapper = styled('div')({
  display: 'flex',
  marginTop: '15px',
});

export const BenchmarkingWrapper = styled('div')({
  marginTop: '25px',
  display: 'flex',
  flexDirection: 'column',
});

export const BenchmarkingTitle = styled('h6')({
  fontWeight: 'bold',
});

export const BenchmarkingDescription = styled('span')({
  fontSize: 14,
});
