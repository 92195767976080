import { SimpleModel } from 'model/SimpleModel';

export namespace Placeholder {
  export interface Shape {
    label: string;
    value: string | Placeholder[];
    type?: string;
  }
}

export class Placeholder extends SimpleModel<Placeholder.Shape> {
  getType(): string {
    return this.get('type');
  }

  getLabel(): string {
    return this.get('label');
  }

  getValue(): string | Placeholder[] {
    return this.get('value');
  }
}
