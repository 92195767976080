import { JobApi } from 'api/JobApi';
import { JobExtendedMapper } from 'mapper/JobExtendedMapper';
import { Job } from 'model/Job';

export class JobMapper {
  constructor(private jobExtendedMapper: JobExtendedMapper) {}

  serialize(model: Job): JobApi.Entry {
    return model.getExtendedJob()
      ? this.jobExtendedMapper.serialize(model.getExtendedJob())
      : undefined;
  }

  deserialize(entry: JobApi.Entry, projectId?: number): Job {
    return new Job({
      job: entry ? this.jobExtendedMapper.deserialize(entry) : undefined,
      projectId: projectId,
    });
  }
}
