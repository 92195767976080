import { createSlice } from '@reduxjs/toolkit';
import { Resource } from 'model/Resource';
import { addAsyncCases } from 'shared/utils/redux';

import { fetchUserList, findUsersByEmail } from './asyncActions';
import { STORE_NAME, UsersState } from './initialState';

export const userSlice = createSlice({
  name: STORE_NAME,
  initialState: UsersState.INITIAL_DOMAIN,
  reducers: {
    clearUserList: (state) => {
      state.list = UsersState.INITIAL_DOMAIN.list;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserList.fulfilled, (state, action) => {
        state.list = Resource.resolve(action.payload);
      })
      .addCase(fetchUserList.rejected, (state, action) => {
        state.list = Resource.reject(action.error);
      });

    addAsyncCases(builder, findUsersByEmail, (state, action) => {
      state.found = action.payload;
    });
  },
});

export const { clearUserList } = userSlice.actions;
