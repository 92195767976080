import { flatten } from 'util/flatten';

import { List } from 'immutable';
import { Department } from 'model/Department';

const handleItem = (item: Department, result: any[] = []) => {
  result.push(item);

  item.getChildren().forEach((child) => {
    handleItem(child, result);
  });

  return result;
};

export const treeToFlatList = (tree: List<Department>) =>
  List(flatten(tree.toArray().map((item) => handleItem(item))));
