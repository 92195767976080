import { selectApiData } from 'util/selector/apiSelector';

import { AxiosInstance } from 'axios';

export namespace PlaceholderApi {
  export type Entry = {
    label: string;
    value: string | Entry[];
    type?: string;
  };
}

export class PlaceholderApi {
  constructor(private client: AxiosInstance) {}

  fetch(projectId: number): Promise<PlaceholderApi.Entry[]> {
    return this.client
      .get(`/api/v1/project/${projectId}/email-template-placeholders`)
      .then(selectApiData);
  }
}
