import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminModal } from 'register/AdminModal';
import { AdminModalKey, AdminModalParamsMapping } from 'register/AdminModalParams';

import { ModalState, STORE_NAME } from './initialState';

// Modal has bad architecture, to support TS check generic action have to be passed in this way otherwise slice will not see it.

type OpenModalPayload<N extends AdminModal> = {
  name: AdminModalKey<N>;
  params: AdminModalParamsMapping[N];
};
const OPEN_MODAL_TYPE = `${STORE_NAME}/openModal`;
export const openModal = <N extends AdminModal>(payload: OpenModalPayload<N>) => ({
  type: OPEN_MODAL_TYPE,
  payload,
});

type UpdateModalPayload<N extends AdminModal> = OpenModalPayload<N> & {
  pending?: boolean;
};
const UPDATE_MODAL_TYPE = `${STORE_NAME}/updateModal`;
export const updateModal = <N extends AdminModal>(payload: UpdateModalPayload<N>) => ({
  type: UPDATE_MODAL_TYPE,
  payload,
});

export const modalSlice = createSlice({
  name: STORE_NAME,
  initialState: ModalState.INITIAL_DOMAIN,
  reducers: {
    closeModal: () => ModalState.INITIAL_DOMAIN,
  },
  extraReducers: (builder) => {
    builder
      .addCase(OPEN_MODAL_TYPE, (state, action: PayloadAction<OpenModalPayload<AdminModal>>) => {
        state.name = action.payload.name;
        state.params = action.payload.params;
        state.pending = false;
      })
      .addCase(
        UPDATE_MODAL_TYPE,
        (state, action: PayloadAction<UpdateModalPayload<AdminModal>>) => {
          if (state.name === action.payload.name) {
            state.params = action.payload.params;
            state.pending = !!action.payload.pending;
          }
        }
      );
  },
});

export const { closeModal } = modalSlice.actions;
