import { AxiosInstance } from 'axios';
import { ConstantMapper } from 'mapper/ConstantMapper';
import { Constant } from 'model/Constant';

export namespace ConstantApi {
  export type Entry = {
    benchmark_sectors: { [key: number]: string };
    pulse_frequencies: { [key: string]: string };
    employee_range_group: { [key: number]: string };
    demographics_constant: { [key: string]: string };
  };
}

export class ConstantApi {
  constructor(private api: AxiosInstance, private constantMapper: ConstantMapper) {}

  list(): Promise<Constant> {
    return this.api
      .get('/api/v1/enums')
      .then((response) => this.constantMapper.deserialize(response.data));
  }
}
