import React from 'react';

import { RadioButton } from 'component/Button/RadioButton';
import { RadioButtonGroupField } from 'component/Form/RadioButtonGroupField';
import { ProjectType } from 'model/ProjectType';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { AdminFormNames } from 'register/AdminFormNames';

export namespace CreateProjectTypeForm {
  export type OwnProps = {};
  export type FormData = {
    type: string;
  };
  export type Props = OwnProps & InjectedFormProps<FormData, OwnProps>;
}

export const CreateProjectTypeFormPure: React.VFC<CreateProjectTypeForm.Props> = () => {
  return (
    <Field name="type" component={RadioButtonGroupField}>
      <RadioButton value={ProjectType.NewCompany.toString()} label="New Company" />
      <RadioButton value={ProjectType.ExistingCompany.toString()} label="Existing Company" />
    </Field>
  );
};

export const CreateProjectTypeForm = reduxForm({
  form: AdminFormNames.CreateProjectType,
})(CreateProjectTypeFormPure);
