import { selectApiData, selectApiNoContent } from 'util/selector/apiSelector';

import { ApiMessage } from 'api/const';
import { WithData } from 'api/interfaces';
import { AxiosInstance } from 'axios';

export namespace DemographicApi {
  export type Entry = {
    id?: number;
    title?: string;
    reportedAs?: string;
    position?: number;
    kind?: number;
    classifications?: ClassificationEntry[];
  };
  export type ClassificationEntry = {
    id?: number;
    title?: string;
    position?: number;
    demographyId?: number;
    demographyKind?: number;
  };
  export type ClassificationDateEntry = {
    id?: number;
    value?: string;
    demography: number;
    demographyKind?: number;
  };
}

export class DemographicApi {
  constructor(private client: AxiosInstance, private reportClient: AxiosInstance) {}

  list(projectId: number): Promise<DemographicApi.Entry[]> {
    return this.client.get(`/api/v1/project/${projectId}/demographic`).then(selectApiData);
  }

  create(projectId: number, data: DemographicApi.Entry): Promise<DemographicApi.Entry> {
    return this.client
      .post<WithData<DemographicApi.Entry>>(`/api/v1/project/${projectId}/demographic`, data)
      .then(selectApiData);
  }

  createDefault(projectId: number, data: DemographicApi.Entry): Promise<DemographicApi.Entry> {
    return this.client
      .post<WithData<DemographicApi.Entry>>(
        `/api/v1/project/${projectId}/demographic-constant`,
        data
      )
      .then(selectApiData);
  }

  update(
    projectId: number,
    demographicId: number,
    demographic: DemographicApi.Entry
  ): Promise<DemographicApi.Entry> {
    return this.client
      .post<WithData<DemographicApi.Entry>>(`/api/v1/demographic/${demographicId}`, demographic)
      .then(selectApiData);
  }

  delete(projectId: number, demographicId: number): Promise<void> {
    return this.client
      .delete<ApiMessage.Deleted>(`/api/v1/demographic/${demographicId}`)
      .then(selectApiNoContent);
  }

  reorder(projectId: number, list: number[]): Promise<void> {
    return this.client
      .post<void>(`/api/v1/demographic-reorder`, { order: list })
      .then(selectApiNoContent);
  }

  listReport(projectId: number): Promise<DemographicApi.Entry[]> {
    return this.reportClient
      .get(`/api/v1/project/${projectId}/demography`)
      .then((response) => response.data.data);
  }
}
