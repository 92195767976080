import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectNextSnackbarId, selectSnackbarById } from 'reduxStore/snackbars/selectors';
import {
  registerSnackbar,
  unregisterSnackbar,
  markSnackbarAsOpened,
  markSnackbarAsClosed,
} from 'reduxStore/snackbars/slice';
import { serializeError } from 'shared/utils/redux';

import { STORE_NAME } from './initialState';

const DEFAULT_SNACKBAR_TIMEOUT = 5000;

export const openSnackbar = createAsyncThunk(
  `${STORE_NAME}/openSnackbar`,
  async (
    { message, timeout = DEFAULT_SNACKBAR_TIMEOUT }: { message: string; timeout?: number },
    { getState, dispatch }
  ) => {
    const id = selectNextSnackbarId(getState());
    const timeoutId = setTimeout(() => dispatch(markSnackbarAsClosed({ id })), timeout);

    dispatch(registerSnackbar({ id, message, timeoutId }));

    // to animate snackbar enter
    setTimeout(() => dispatch(markSnackbarAsOpened({ id })), 0);

    return id;
  },
  { serializeError }
);

export const closeSnackbar = createAsyncThunk(
  `${STORE_NAME}/openSnackbar`,
  async ({ id }: { id: number }, { getState, dispatch }) => {
    const snackbar = selectSnackbarById(id)(getState());

    if (snackbar?.timeoutId) {
      clearTimeout(snackbar.timeoutId);
    }

    dispatch(markSnackbarAsClosed({ id }));

    // to animate snackbar leave
    setTimeout(() => dispatch(unregisterSnackbar({ id })), 1000);
  },
  { serializeError }
);
