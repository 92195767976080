import { Category } from 'model/Category';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'categories';

export namespace CategoriesState {
  type Domain = {
    categoriesResource: Resource<Readonly<Category[]>>;
  };

  export const INITIAL_DOMAIN: Domain = {
    categoriesResource: Resource.void(),
  };
}
