import { useSelector } from 'react-redux';

import { AdminRoute } from 'app/route/admin';
import { ClientRoute } from 'app/route/client';
import { selectIsAdmin, selectIsClientAdmin } from 'reduxStore/auth/selectors';
import { wbLazy } from 'shared/utils/wbLazy';
import { ClientsPage } from 'view/ClientsPage/ClientsPage';
import { ProjectsListPage } from 'view/ProjectsListPage/ProjectsListPage';

import { NavigationItem } from './models';

const LibraryRoutes = wbLazy(() =>
  import(/* webpackChunkName: "LibraryRoutes" */ 'router/LibraryRoutes/LibraryRoutes').then(
    (module) => ({
      default: module['LibraryRoutes'],
    })
  )
);
const ProtectedPulseProjectRoutes = wbLazy(() =>
  import(
    /* webpackChunkName: "ProtectedPulseProjectRoutes" */ 'router/PulseProjectRoutes/ProtectedPulseProjectRoutes'
  ).then((module) => ({
    default: module['ProtectedPulseProjectRoutes'],
  }))
);
const ProtectedRegularProjectRoutes = wbLazy(() =>
  import(
    /* webpackChunkName: "ProtectedRegularProjectRoutes" */ 'router/RegularProjectRoutes/ProtectedRegularProjectRoutes'
  ).then((module) => ({
    default: module['ProtectedRegularProjectRoutes'],
  }))
);
const LibraryPage = wbLazy(() =>
  import(/* webpackChunkName: "LibraryPage" */ 'view/LibraryPage/LibraryPage').then((module) => ({
    default: module['LibraryPage'],
  }))
);

export const useAppRoutes = (): NavigationItem[] => {
  const isClientAdmin = useSelector(selectIsClientAdmin);
  const isAdmin = useSelector(selectIsAdmin);

  return [
    {
      path: ClientRoute.PROJECT_PATTERN,
      component: ProtectedPulseProjectRoutes,
      canActivate: isClientAdmin || isAdmin,
    },
    {
      path: AdminRoute.PROJECT_TEMPLATE_PATTERN,
      component: LibraryRoutes,
      canActivate: isClientAdmin || isAdmin,
    },
    {
      path: AdminRoute.PROJECT_TEMPLATES_PATTERN,
      component: LibraryPage,
      canActivate: isClientAdmin || isAdmin,
    },
    {
      path: AdminRoute.PROJECT_PATTERN,
      component: ProtectedRegularProjectRoutes,
      canActivate: isAdmin,
    },
    {
      path: AdminRoute.PROJECTS_PATTERN,
      component: ProjectsListPage,
      canActivate: isAdmin,
    },
    {
      path: AdminRoute.CLIENTS_PATTERN,
      component: ClientsPage,
      canActivate: isAdmin,
    },
  ];
};
