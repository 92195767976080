import { PageItemApi } from 'api/PageItemApi';
import { StaticResourceApi } from 'api/StaticResourceApi';
import { PageItemAbstractMapper } from 'mapper/PageItemAbstractMapper';
import { PageItemKindMapper } from 'mapper/PageItemMapper';
import { StaticResourceMapper } from 'mapper/StaticResourceMapper';
import { ImageWidget } from 'model/ImageWidget';
import { PageItemKind } from 'model/PageItemKind';

export class ImageWidgetMapper
  implements PageItemKindMapper<ImageWidget, PageItemApi.ImageWidgetEntry> {
  constructor(private staticResourceMapper: StaticResourceMapper) {}

  serialize(model: ImageWidget): PageItemApi.ImageWidgetEntry {
    return {
      ...PageItemAbstractMapper.serialize(model),
      id: model.getId(),
      position: model.getPosition(),
      kind: model.getKind(),
      image: model.getImage() ? model.getImage().getId() : undefined,
      // TODO: missing class - FormType :(
      imageNew: model.getImageNew() ? model.getImageNew().toObject() : undefined,
      width: model.getWidth(),
      height: model.getHeight(),
      align: model.getAlign(),
    };
  }

  deserialize(entry: PageItemApi.ImageWidgetEntry): ImageWidget {
    return new ImageWidget({
      ...PageItemAbstractMapper.deserializeShape(entry),
      id: entry.id,
      position: entry.position,
      image: entry.image
        ? this.staticResourceMapper.deserialize(entry.image as StaticResourceApi.Entry)
        : undefined,
      width: entry.width,
      height: entry.height,
      align: entry.align,
    });
  }

  supportsSerialize(model: ImageWidget): boolean {
    return model instanceof ImageWidget;
  }

  supportsDeserialize(entry: PageItemApi.ItemEntry): boolean {
    return entry.kind === PageItemKind.ImageWidget;
  }
}
