import React from 'react';
import { connect } from 'react-redux';

import { ConfirmationModal } from 'component/ConfirmationModal/ConfirmationModal';
import Delete from '@mui/icons-material/Delete';
import { SecondaryButton } from 'component/Button/Button';
import { ConnectedDialog } from 'component/ConnectedDialog/ConnectedDialog';
import { DangerFlatButton } from 'component/DangerFlatButton/DangerFlatButton';
import { AdminState } from 'reduxStore/appStore';
import {
  selectIsModalPending,
  selectModalName,
  selectModalParams,
} from 'reduxStore/modal/selectors';
import { closeModal, updateModal } from 'reduxStore/modal/slice';
import { AdminModal } from 'register/AdminModal';
import { AdminModalParamsMapping } from 'register/AdminModalParams';

export namespace DeleteConfirmationDialog {
  export type StateProps = {
    params: AdminModalParamsMapping[AdminModal];
    isModalPending: boolean;
  };
  export type DispatchProps = {
    onConfirmClick: (params: AdminModalParamsMapping[AdminModal]) => void;
    onCancelClick: (params: AdminModalParamsMapping[AdminModal]) => void;
  };
  export type OwnProps<N extends AdminModal> = ConnectedDialog.OwnProps<N> & {
    onConfirm: (params: AdminModalParamsMapping[N]) => Promise<any>;
    onCancel?: (params: AdminModalParamsMapping[N]) => Promise<any>;
  };
  export type Props = StateProps & DispatchProps & OwnProps<AdminModal>;
}

export const DeleteConfirmationDialogPure: React.VFC<DeleteConfirmationDialog.Props> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onConfirm,
  onConfirmClick,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onCancel,
  onCancelClick,
  params,
  isModalPending,
  actions,
  ...dialogProps
}) => {
  const dialogPropsActions = Array.isArray(actions) ? actions : actions ? [actions] : [];
  return (
    <ConnectedDialog
      {...({
        ...dialogProps,
        actions: [
          ...dialogPropsActions,
          <SecondaryButton
            keyboardFocused
            onClick={() => onCancelClick(params)}
            label="Cancel"
            disabled={isModalPending}
            key="cancel"
          />,
          <DangerFlatButton
            data-testid="deleteProjectConfirmationButton"
            onClick={() => onConfirmClick(params)}
            endIcon={<Delete />}
            label="Delete"
            disabled={isModalPending}
            key="delete"
          />,
        ],
      } as any)}
    />
  );
};

/**
 * Component for delete confirmation - there are two hardcoded actions - custom actions will be merged and placed before hardcoded
 */
export const DeleteConfirmationDialog = connect<
  DeleteConfirmationDialog.StateProps,
  DeleteConfirmationDialog.DispatchProps,
  DeleteConfirmationDialog.OwnProps<AdminModal>
>(
  (state: AdminState): DeleteConfirmationDialog.StateProps => {
    const modalName = selectModalName(state);

    return {
      params: selectModalParams(modalName)(state),
      isModalPending: selectIsModalPending(state),
    };
  },
  (
    dispatch,
    ownProps: DeleteConfirmationDialog.OwnProps<AdminModal>
  ): DeleteConfirmationDialog.DispatchProps => ({
    onConfirmClick: (params) => {
      const promise = ownProps.onConfirm(params);

      if (promise) {
        dispatch(updateModal({ name: ownProps.name, params, pending: true }));
      }

      (promise || Promise.resolve())
        .then(() => dispatch(closeModal()))
        .catch(() => dispatch(updateModal({ name: ownProps.name, params, pending: true })));
    },
    onCancelClick: (params = {}) => {
      let promise;

      if (ownProps.onCancel) {
        promise = ownProps.onCancel(params);

        if (promise) {
          dispatch(updateModal({ name: ownProps.name, params: { ...params, pending: true } }));
        }
      }

      (promise || Promise.resolve())
        .then(() => dispatch(closeModal()))
        .catch(() =>
          dispatch(updateModal({ name: ownProps.name, params: { ...params, pending: false } }))
        );
    },
  })
)(DeleteConfirmationDialogPure) as <N extends AdminModal>(
  props: ConfirmationModal.OwnProps<N>
) => JSX.Element;
