import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { demandPollReducer } from 'reduxStore/project/demandPoll/reducer';
import { projectReducer } from 'reduxStore/project/project/reducer';
import { projectLayoutReducer } from 'reduxStore/project/projectLayout/reducer';
import { projectSettingsReducer } from 'reduxStore/project/projectSettings/reducer';
import { pulseProjectReducer } from 'reduxStore/project/pulseProject/reducer';

import { ProjectState, STORE_NAME } from './initialState';

export const projectSlice = createSlice({
  name: STORE_NAME,
  initialState: ProjectState.INITIAL_DOMAIN,
  reducers: {
    // For each reducers please use createAction
    resetCurrentProject: (state) => {
      state.subProjects = ProjectState.INITIAL_DOMAIN['subProjects'];
      state.currentResource = ProjectState.INITIAL_DOMAIN['currentResource'];
    },
    setProjectListPagination: (
      state,
      action: PayloadAction<ProjectState.ProjectListPagination>
    ) => {
      state.listPagination = action.payload;
    },
  },
  extraReducers: (builder) => {
    demandPollReducer(builder);
    projectReducer(builder);
    projectLayoutReducer(builder);
    projectSettingsReducer(builder);
    pulseProjectReducer(builder);
  },
});

export const { resetCurrentProject, setProjectListPagination } = projectSlice.actions;
