import { createSlice } from '@reduxjs/toolkit';
import { fetchPlaceholders } from 'reduxStore/placeholders/asyncActions';
import { addAsyncCases } from 'shared/utils/redux';

import { PlaceholderState, STORE_NAME } from './initialState';

export const placeholderSlice = createSlice({
  name: STORE_NAME,
  initialState: PlaceholderState.INITIAL_DOMAIN,
  reducers: {
    clearPlaceholders: (state) => {
      state.listResource = PlaceholderState.INITIAL_DOMAIN.listResource;
    },
  },
  extraReducers: (builder) => {
    addAsyncCases(builder, fetchPlaceholders, (state, action) => {
      state.listResource = action.payload;
    });
  },
});

export const { clearPlaceholders } = placeholderSlice.actions;
