import { List } from 'immutable';
import { CustomQuestionOption } from 'model/CustomQuestionOption';
import { DiscreteQuestion } from 'model/DiscreteQuestion';
import { PageItemKind } from 'model/PageItemKind';
import { Question } from 'model/Question';
import { SimpleModel } from 'model/SimpleModel';

export namespace QuestionConsent {
  export interface Shape extends Question.Shape {
    optionNo: string;
    optionYes: string;
  }
}

export class QuestionConsent extends Question<QuestionConsent.Shape> implements DiscreteQuestion {
  private readonly consentOptions = List<CustomQuestionOption>();

  constructor(initialData: SimpleModel.Data<QuestionConsent.Shape>) {
    super(initialData, PageItemKind.QuestionConsent);

    this.consentOptions = List<CustomQuestionOption>([
      new CustomQuestionOption({
        option: this.get('optionYes'),
        position: 1,
      }),
      new CustomQuestionOption({
        option: this.get('optionNo'),
        position: 2,
      }),
    ]);
  }

  getOptions(): List<CustomQuestionOption> {
    return this.consentOptions;
  }

  getOptionYes(): string {
    return this.get('optionYes');
  }

  getOptionNo(): string {
    return this.get('optionNo');
  }

  getAvailableOptions(): List<CustomQuestionOption> {
    // By domain design both options are always available
    return this.getOptions();
  }
}
