import { createAsyncThunk } from '@reduxjs/toolkit';
import { webLinkDistributionRepository } from 'container/webLinkDistributionRepository';
import { serializeError } from 'shared/utils/redux';

import { STORE_NAME } from './initialState';

export const fetchWebLinkDistribution = createAsyncThunk(
  `${STORE_NAME}/fetchWebLinkDistribution`,
  ({ projectId }: { projectId: number }) => {
    return webLinkDistributionRepository.get(projectId);
  },
  { serializeError }
);
