import { SimpleModel } from 'model/SimpleModel';

export namespace Timezone {
  export interface Shape {
    id: string;
    name: string;
    offset: string;
  }
}

export class Timezone extends SimpleModel<Timezone.Shape> {
  static readonly DEFAULT_TIMEZONE: string = 'Europe/London';

  getId(): string {
    return this.get('id');
  }

  getName(): string {
    return this.get('name');
  }

  getOffset(): string {
    return this.get('offset');
  }
}
