import { isArrayEmpty } from 'util/isArrayEmpty';
import { createResourceContentSelector } from 'util/selector/resourceSelector';

import { List } from 'immutable';
import { EmailTemplate } from 'model/EmailTemplate';
import { EmailDistribution } from 'model/EmailDistribution';
import { Resource } from 'model/Resource';
import { AdminState } from 'reduxStore/appStore';
import { EmailDistributionState } from 'reduxStore/emailDistribution/initialState';
import { selectCurrentProject } from 'reduxStore/project/projectSelector';
import { createSelector } from '@reduxjs/toolkit';

export function selectEmailDomain(state: AdminState): EmailDistributionState.Domain {
  return state && state.emailDistribution;
}

export const selectEmailDistributionResource = createSelector(
  selectEmailDomain,
  (domain) => (domain && domain.distributionResource) || Resource.void<EmailDistribution>()
);

export const selectEmailDistribution = createResourceContentSelector(
  selectEmailDistributionResource
);

export const selectEmailDistributionId = createSelector(
  selectEmailDistribution,
  (emailDistribution) => emailDistribution && emailDistribution.getId()
);

export const selectInvitation = createSelector(
  selectEmailDistribution,
  (emailDistribution) => emailDistribution && emailDistribution.getInvitation()
);

export const selectInvitationSchedules = createSelector(selectInvitation, (invitation) =>
  invitation?.getSchedules()
);

export const selectInvitationScheduleId = createSelector(
  selectInvitation,
  (invitation) => (invitation && invitation.getId()) || null
);

export const selectHasInvitationAtLeastOneScheduleSent = createSelector(
  selectInvitationSchedules,
  (schedules) => !!schedules?.find((schedule) => schedule.wasSent())
);

export const selectIsScheduleEditable = createSelector(
  selectCurrentProject,
  (project) =>
    (project && project.isEditable() && project.hasStartAndStopDate()) || project.isLive()
);

export const selectRemainders = createSelector(
  selectEmailDistribution,
  (distribution) => distribution?.getReminders() ?? List.of<EmailTemplate>()
);

export const selectFirstRemainder = createSelector(selectRemainders, (remainders) =>
  !isArrayEmpty(remainders) ? remainders.first() : null
);
