import { List } from 'immutable';
import { Logic } from 'model/Logic';
import { LogicDependencyMode } from 'model/LogicDependencyMode';
import { LogicType } from 'model/LogicType';
import { SimpleModel } from 'model/SimpleModel';

export namespace LogicDependency {
  export interface Shape extends Logic.Shape {
    questionId: number;
    options?: List<number>;
    mode?: LogicDependencyMode;
  }
}

export class LogicDependency extends Logic<LogicDependency.Shape> {
  constructor(initialData: SimpleModel.Data<LogicDependency.Shape>) {
    super(initialData, LogicType.Dependency);
  }

  getQuestionId(): number {
    return this.get('questionId');
  }

  setOptions(options: List<number>): this {
    return this.set('options', options);
  }

  getOptions(): List<number> {
    return this.get('options', List<number>());
  }

  getMode(): LogicDependencyMode {
    return this.get('mode', LogicDependencyMode.AllEqual);
  }

  setMode(mode: LogicDependencyMode): this {
    return this.set('mode', mode);
  }
}
