import { PageItemApi } from 'api/PageItemApi';
import { PageItemKindMapper } from 'mapper/PageItemMapper';
import { QuestionAbstractMapper } from 'mapper/QuestionAbstractMapper';
import { PageItem } from 'model/PageItem';
import { PageItemKind } from 'model/PageItemKind';
import { QuestionCommentBox } from 'model/QuestionCommentBox';
import { Sentiment, SentimentMap } from 'model/Sentiment';

export class QuestionCommentBoxMapper
  implements PageItemKindMapper<QuestionCommentBox, PageItemApi.QuestionCommentBox> {
  serialize(model: QuestionCommentBox): PageItemApi.QuestionCommentBox {
    return {
      ...QuestionAbstractMapper.serialize(model),
      required: model.isRequired() ? 1 : 0,
      height: model.getTextBoxHeight(),
      reportedAs: model.getReportedAs(),
      numberingSkipped: model.isNumberingSkipped() ? 1 : 0,
      sentiment: this.serializeSentiment(model.getSentiment()),
    };
  }

  deserialize(entry: PageItemApi.QuestionCommentBox): QuestionCommentBox {
    return new QuestionCommentBox({
      ...QuestionAbstractMapper.deserializeShape(entry),
      isRequired: Boolean(entry.required),
      textBoxHeight: entry.height,
      reportedAs: entry.reportedAs,
      numberingSkipped: Boolean(entry.numberingSkipped),
      sentiment: this.deserializeSentiment(entry.sentiment),
      locale: entry.locale,
    });
  }

  deserializeSentiment(
    apiSentiment: PageItemApi.QuestionCommentBox['sentiment']
  ): SentimentMap[Sentiment] {
    switch (apiSentiment) {
      case -1:
        return Sentiment.Negative;
      case 0:
        return Sentiment.Neutral;
      case 1:
        return Sentiment.Positive;
      default:
        return null;
    }
  }

  serializeSentiment(
    sentiment: SentimentMap[Sentiment]
  ): PageItemApi.QuestionCommentBox['sentiment'] {
    switch (sentiment) {
      case Sentiment.Positive:
        return 1;
      case Sentiment.Neutral:
        return 0;
      case Sentiment.Negative:
        return -1;
      default:
        return null;
    }
  }

  supportsSerialize(model: PageItem): boolean {
    return model instanceof QuestionCommentBox;
  }

  supportsDeserialize(entry: PageItemApi.ImageWidgetEntry): boolean {
    return entry.kind === PageItemKind.QuestionCommentsBox;
  }
}
