import { mediaQueries } from 'component/mediaQueries/mediaQueries.s';
import { styled } from '@mui/material';

export const Wrapper = styled('div')({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  height: '100vh',
  alignItems: 'center',
});

export const MainWrapper = styled('main')({
  flex: '1',
  padding: '30px 15px',
  fontFamily: 'Roboto',
  fontWeight: 300,
  width: '100%',
  [mediaQueries.xLarge]: {
    maxWidth: 1300,
  },
});
