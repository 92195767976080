import { JobExtended } from 'model/JobExtended';
import { JobName } from 'model/JobName';
import { SimpleModel } from 'model/SimpleModel';

export namespace Job {
  export interface Shape<Name> {
    job: JobExtended<Name>;
    projectId: number;
  }
}

export class Job<Name = string> extends SimpleModel<Job.Shape<Name>> {
  static isProcessEmployeeDatabaseJob(job: Job): boolean {
    return job && job.getExtendedJob().getName() === JobName.ProcessEmployeeDatabase;
  }

  static isCommitEmployeeDatabaseJob(job: Job): boolean {
    return job && job.getExtendedJob().getName() === JobName.CommitEmployeeDatabase;
  }

  static isCreateContactListJob(job: JobExtended): boolean {
    return job && job.getName() === JobName.CreateContactList;
  }

  static isUploadUsersJob(job: Job): boolean {
    return job && job.getExtendedJob().getName() === JobName.UploadUsers;
  }

  static isReleasePulseProjectJob(job: Job): boolean {
    return job && job.getExtendedJob().getName() === JobName.ReleasePulseProject;
  }

  static isPublishProjectSubmits(job: Job): boolean {
    return job && job.getExtendedJob().getName() === JobName.PublishProjectSubmits;
  }

  static isUpdateEmailStatusesJob(job: JobExtended): boolean {
    return job && job.getName() === JobName.UpdateEmailStatuses;
  }

  static isProcessLifecycleRespondents(
    job: Job
  ): job is Job<typeof JobName.ProcessLifecycleRespondents> {
    return job?.getExtendedJob().getName() === JobName.ProcessLifecycleRespondents;
  }

  static isFailedJob(job: Job): boolean {
    return job && !!job.getExtendedJob().getReason();
  }

  static sortByExecutionTime(a: Job, b: Job): number {
    return b.getExtendedJob().getExecutionTime() - a.getExtendedJob().getExecutionTime();
  }

  getExtendedJob(): JobExtended<Name> {
    return this.get('job');
  }

  getProjectId(): number {
    return this.get('projectId');
  }

  isVisited(): boolean {
    return this.getExtendedJob().getVisited();
  }
}
