import { Set } from 'immutable';
import { Logic } from 'model/Logic';
import { LogicType } from 'model/LogicType';
import { SimpleModel } from 'model/SimpleModel';

export namespace LogicClassification {
  export interface Shape extends Logic.Shape {
    departmentsIds?: Set<number>;
    classificationsIds?: Set<number>;
  }
}

export class LogicClassification extends Logic<LogicClassification.Shape> {
  constructor(initialData: SimpleModel.Data<LogicClassification.Shape>) {
    super(initialData, LogicType.Classification);
  }

  getDepartmentsIds(): Set<number> {
    return this.get('departmentsIds');
  }

  getClassificationsIds(): Set<number> {
    return this.get('classificationsIds');
  }
}
