import { EmployeeApi } from 'api/EmployeeApi';
import { EmployeeStats } from 'model/EmploeeStats';

export class EmployeeStatsMapper {
  deserialize(entry: EmployeeApi.StatsEntry): EmployeeStats {
    return new EmployeeStats({
      existingRespondents: entry.existingRespondents,
      newRespondents: entry.newRespondents,
      orphanRespondents: entry.orphanRespondents,
    });
  }
}
