import { ProjectSortBy } from 'model/ProjectSortBy';
import { ProjectStatus } from 'model/ProjectStatus';

export const projectListFilterEmptyValue = -1;

export type ProjectListFilter = {
  searchText: string;
  circleId: string | typeof projectListFilterEmptyValue;
  status: ProjectStatus | typeof projectListFilterEmptyValue;
  sortBy: ProjectSortBy;
};
