import { PageItemApi } from 'api/PageItemApi';
import { List } from 'immutable';
import { CustomQuestionOptionMapper } from 'mapper/CustomQuestionOptionMapper';
import { PageItemKindMapper } from 'mapper/PageItemMapper';
import { QuestionAbstractMapper } from 'mapper/QuestionAbstractMapper';
import { PageItem } from 'model/PageItem';
import { PageItemKind } from 'model/PageItemKind';
import { QuestionCheckbox } from 'model/QuestionCheckbox';

export class QuestionCheckboxMapper
  implements PageItemKindMapper<QuestionCheckbox, PageItemApi.QuestionCheckboxEntry> {
  constructor(private customQuestionOptionMapper: CustomQuestionOptionMapper) {}

  serialize(model: QuestionCheckbox): PageItemApi.QuestionCheckboxEntry {
    return {
      ...QuestionAbstractMapper.serialize(model),
      options: model
        .getOptions()
        .map((option) => this.customQuestionOptionMapper.serialize(option))
        .toArray(),
      limitAnswerCount: model.getLimitAnswerCount(),
      required: model.isRequired() ? 1 : 0,
      hasOther: model.hasOther() ? 1 : 0,
      numberingSkipped: model.isNumberingSkipped() ? 1 : 0,
    };
  }

  deserialize(entry: PageItemApi.QuestionCheckboxEntry): QuestionCheckbox {
    return new QuestionCheckbox({
      ...QuestionAbstractMapper.deserializeShape(entry),
      options: List(
        (entry.options || [])
          .map((option) => this.customQuestionOptionMapper.deserialize(option))
          .sort((a, b) => a.getPosition() - b.getPosition())
      ),
      limitAnswerCount: entry.limitAnswerCount,
      isRequired: Boolean(entry.required),
      hasOther: Boolean(entry.hasOther),
      numberingSkipped: Boolean(entry.numberingSkipped),
      locale: entry.locale,
    });
  }

  supportsSerialize(model: PageItem): boolean {
    return model instanceof QuestionCheckbox;
  }

  supportsDeserialize(entry: PageItemApi.ImageWidgetEntry): boolean {
    return entry.kind === PageItemKind.QuestionCheckbox;
  }
}
