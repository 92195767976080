import { AdminState } from 'reduxStore/appStore';
import { createSelector } from '@reduxjs/toolkit';

export const selectSnackbarsDomain = (state: AdminState) => state.snackbars;

export const selectSnackbars = createSelector(selectSnackbarsDomain, (domain) => domain.list);

export const selectNextSnackbarId = createSelector(
  selectSnackbars,
  (snackbars) => snackbars.reduce((max, snackbar) => Math.max(max, snackbar.id), 0) + 1
);

export const selectSnackbarById = (id: number) =>
  createSelector(selectSnackbars, (snackbars) => snackbars.find((snackbar) => snackbar.id === id));
