import { List } from 'immutable';
import { AccessCode } from 'model/AccessCode';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'accessCode';

export namespace AccessCodeState {
  export type ListResource = Resource<List<AccessCode>>;

  export type Domain = {
    unsubscribedResource?: ListResource;
    searchResource?: ListResource;
  };

  export const INITIAL_LIST_RESOURCE: ListResource = Resource.void<List<AccessCode>>();
  export const INITIAL_DOMAIN: Domain = {
    unsubscribedResource: INITIAL_LIST_RESOURCE,
    searchResource: INITIAL_LIST_RESOURCE,
  };
}
