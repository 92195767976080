import React from 'react';
import { useSelector } from 'react-redux';

import { Dropdown } from 'component/Dropdown/Dropdown';
import { DropdownItem } from 'component/Dropdown/Dropdown.s';
import { selectReportUILinks } from 'reduxStore/project/projectSelector';
import { ActionsButtonsWrapper, HeaderItem, HeaderLink } from 'view/TopBars/Header/Header.s';
import { NavItem } from 'view/TopBars/NavigationBar/NavItem/NavItem';
import { NavigationNode } from 'model/NavigationNode';
import { AdminRoute } from 'app/route/admin';

type UserActionsProps = {
  isWBAdmin: boolean;
  isCircleAdmin: boolean;
  isClientAdmin: boolean;
  isInWorkBuzzCircle: boolean;
  hasRegularProjects: boolean;
};

const DashboardDropdown: React.VFC = () => {
  const reportUILinks = useSelector(selectReportUILinks);

  if (reportUILinks.length === 0) {
    return null;
  }

  return (
    <Dropdown data-testid="dashboard" label="Dashboard" variant="clear">
      {reportUILinks.map(({ label, href }, index) => (
        <DropdownItem
          key={index}
          onClick={() => {
            window.open(href, '__blank', 'noopener noreferrer');
          }}
        >
          {label}
        </DropdownItem>
      ))}
    </Dropdown>
  );
};

const NavigationLinks = ({ links }: { links: NavigationNode[] }) => {
  return (
    <ActionsButtonsWrapper>
      {links.map(({ label, href }, index) => (
        <NavItem href={href} label={label} key={index} />
      ))}
    </ActionsButtonsWrapper>
  );
};

export const UserActions: React.VFC<UserActionsProps> = ({
  isClientAdmin,
  isCircleAdmin,
  isWBAdmin,
  isInWorkBuzzCircle,
  hasRegularProjects,
}) => {
  if (isWBAdmin || (isCircleAdmin && hasRegularProjects)) {
    return (
      <NavigationLinks
        links={[
          { href: AdminRoute.PROJECTS_PATTERN, label: 'Projects' },
          { href: AdminRoute.CLIENTS_PATTERN, label: 'Clients' },
          ...(isInWorkBuzzCircle
            ? [{ href: AdminRoute.PROJECT_TEMPLATES_PATTERN, label: 'Library' }]
            : []),
        ]}
      />
    );
  }

  if (isCircleAdmin && !hasRegularProjects) {
    return <NavigationLinks links={[{ href: AdminRoute.CLIENTS_PATTERN, label: 'Clients' }]} />;
  }

  if (isClientAdmin) {
    return (
      <>
        <HeaderItem>
          <HeaderLink
            data-testid="knowledgebaseLink"
            href="https://workbuzzsupport.zendesk.com/hc/en-gb"
            target="_blank"
          >
            Knowledgebase
          </HeaderLink>
        </HeaderItem>
        <DashboardDropdown />
      </>
    );
  }

  return null;
};
