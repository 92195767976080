import { ConstantApi } from 'api/ConstantApi';
import { List } from 'immutable';
import { EnumMapper } from 'mapper/EnumMapper';
import { Constant } from 'model/Constant';

export class ConstantMapper {
  constructor(private enumMapper: EnumMapper) {}

  deserialize(entry: ConstantApi.Entry): Constant {
    return new Constant({
      benchmarkSectors: List(
        Object.keys(entry.benchmark_sectors).map((id) =>
          this.enumMapper.deserialize(Number(id), entry.benchmark_sectors[Number(id)])
        )
      ),
      pulseFrequencies: List(
        Object.keys(entry.pulse_frequencies).map((key) =>
          this.enumMapper.deserialize(key, entry.pulse_frequencies[key])
        )
      ),
      employeeRangeGroup: List(
        Object.keys(entry.employee_range_group).map((id) =>
          this.enumMapper.deserialize(Number(id), entry.employee_range_group[Number(id)])
        )
      ),
      demographics: List(
        Object.keys(entry.demographics_constant).map((key) =>
          this.enumMapper.deserialize(key, entry.demographics_constant[key])
        )
      ),
    });
  }
}
