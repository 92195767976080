import React from 'react';
import { Switch } from 'react-router-dom';

import { AdminRoute } from 'app/route/admin';
import { createRelativeRoute } from 'component/RelativeRoute/RelativeRoute';
import { SnackbarPortal } from 'component/SnackbarPortal/SnackbarPortal';
import { useFetchConstantsAndTimezones } from 'router/hooks/useFetchConstantsAndTimezones';
import { MainWrapper, Wrapper } from 'router/AppRoutes.s';
import { renderRoutes } from 'router/components/renderRoutes';
import { AppHeader } from 'view/TopBars/Header/Header';
import { useInitializeRUM } from 'shared/hooks/useInitializeRUM';
import { useInitializeDataDogLogs } from 'shared/hooks/useInitializeDataDogLogs';
import { useFetchCircles } from 'shared/hooks/useFetchCircles';
import { useInitializePostHog } from 'shared/hooks/useInitializePostHog';
import { useUserGuiding } from 'shared/hooks/useUserGuiding';

import { useAppRoutes } from './useAppRoutes';

const RelativeRoute = createRelativeRoute(AdminRoute.PATTERN);

export const AppRoutes: React.VFC = () => {
  const routes = useAppRoutes();

  useInitializeRUM();
  useInitializePostHog();
  useUserGuiding();
  useInitializeDataDogLogs();
  useFetchConstantsAndTimezones();
  useFetchCircles();

  return (
    <Wrapper>
      <AppHeader />
      <MainWrapper>
        <Switch>{renderRoutes({ RelativeRoute, routes })}</Switch>
      </MainWrapper>
      <SnackbarPortal />
    </Wrapper>
  );
};
