import React from 'react';

import { Typography, TypographyProps, styled } from '@mui/material';
import { green, grey, red } from '@mui/material/colors';
import { css } from 'glamor';

export const dropzone = () =>
  css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px dashed ${grey[400]}`,
    margin: '10px 0',
    minHeight: '200px',
    width: '100%',
    fontSize: '1em',
    transition: 'all .2s',
    cursor: 'pointer',
    flexDirection: 'column',
  });

export const dropzoneActiveStyle = {
  borderColor: green[500],
  color: green[700],
  background: green[50],
};

export const dropzoneRejectedStyle = {
  borderColor: grey[500],
  color: grey[700],
  background: grey[50],
};

export const dropzoneFilledStyle = {
  background: grey[200],
};

export const dropzoneEmptyStyle = {
  color: grey[600],
};

export const dropzoneErrorStyle = {
  borderColor: red[500],
  color: red[700],
  background: red[50],
};

export const dropzoneDisabledStyle = {
  cursor: 'no-drop',
  borderColor: grey[300],
  color: grey[500],
  background: grey[200],
};

export const DropzoneMessage = (props: TypographyProps) => (
  <Typography
    variant="body2"
    component="span"
    {...props}
    sx={{
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      msFlexDirection: 'column',
      msFlexAlign: 'center',
      justifyContent: 'center',
      height: 250,
      ...props.sx,
    }}
  />
);

export const ErrorWrapper = styled('div')({
  textAlign: 'center',
  color: red[700],
  fontWeight: 'bold',
  width: '100%',
});

export const Label = styled('label')({
  fontSize: 16,
  transform: 'scale(0.75) translateY(22px)',
  lineHeight: '22px',
  transformOrigin: 'left top 0px',
  pointerEvents: 'none',
  userSelect: 'none',
  color: 'rgba(0, 0, 0, 0.3)',
});

export const ImagePreview = styled('img')({
  maxWidth: '300px',
  maxHeight: '100px',
  margin: '.5em',
});

export const DropzoneSingleFileMessageWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '250px',
  justifyContent: 'center',
});
