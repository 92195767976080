import { SimpleModel } from 'model/SimpleModel';

export namespace Category {
  export interface Shape {
    name: string;
    id?: number;
    isGlobal?: boolean;
    isActive?: boolean;
  }
}

export class Category extends SimpleModel<Category.Shape> {
  getId(): number {
    return this.get('id');
  }

  isGlobal(): boolean {
    return this.get('isGlobal');
  }

  isActive(): boolean {
    return this.get('isActive');
  }

  getName(): string {
    return this.get('name');
  }
}
