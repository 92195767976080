import { AxiosInstance } from 'axios';
import { QueueMapper } from 'mapper/queueMapper';
import { Queue } from 'model/Queue';

export type QueueApiError = {
  message: string;
  code: string;
  path: string;
};

export namespace QueueApi {
  export type Entry = {
    id: number;
    name: string;
    status: number;
    projectId: number;
    progress?: number;
    reason?: (string | QueueApiError)[];
  };
}

export class QueueApi {
  constructor(private api: AxiosInstance, private queueMapper: QueueMapper) {}

  list(id: number): Promise<Queue[]> {
    return this.api
      .get(`/api/v1/project/${id}/queue`)
      .then((response) => response.data.data)
      .then((entries: QueueApi.Entry[]) =>
        entries.map((entry) => this.queueMapper.deserialize(entry))
      );
  }

  get(id: number): Promise<QueueApi.Entry> {
    return this.api.get(`/api/v1/queue/${id}`).then((response) => response.data);
  }
}
