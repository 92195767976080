import { PageItemApi } from 'api/PageItemApi';
import { List } from 'immutable';
import { CustomQuestionOptionMapper } from 'mapper/CustomQuestionOptionMapper';
import { PageItemKindMapper } from 'mapper/PageItemMapper';
import { QuestionAbstractMapper } from 'mapper/QuestionAbstractMapper';
import { PageItem } from 'model/PageItem';
import { PageItemKind } from 'model/PageItemKind';
import { QuestionForm } from 'model/QuestionForm';

export class QuestionFormMapper
  implements PageItemKindMapper<QuestionForm, PageItemApi.QuestionFormEntry> {
  constructor(private customQuestionOptionMapper: CustomQuestionOptionMapper) {}

  serialize(model: QuestionForm): PageItemApi.QuestionFormEntry {
    return {
      ...QuestionAbstractMapper.serialize(model),
      options: model
        .getOptions()
        .map((option) => this.customQuestionOptionMapper.serialize(option))
        .toArray(),
      optionHeight: model.getOptionHeight(),
      numberingSkipped: model.isNumberingSkipped() ? 1 : 0,
    };
  }

  deserialize(entry: PageItemApi.QuestionFormEntry): QuestionForm {
    return new QuestionForm({
      ...QuestionAbstractMapper.deserializeShape(entry),
      options: List(
        (entry.options || [])
          .map((option) => this.customQuestionOptionMapper.deserialize(option))
          .sort((a, b) => a.getPosition() - b.getPosition())
      ),
      optionHeight: entry.optionHeight,
      numberingSkipped: Boolean(entry.numberingSkipped),
    });
  }

  supportsSerialize(model: PageItem): boolean {
    return model instanceof QuestionForm;
  }

  supportsDeserialize(entry: PageItemApi.ImageWidgetEntry): boolean {
    return entry.kind === PageItemKind.QuestionForm;
  }
}
