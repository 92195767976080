import { ActionReducerMapBuilder, AsyncThunk, Draft } from '@reduxjs/toolkit';
import { Resource } from 'model/Resource';
import { ResourceType, TakeLatest } from 'shared/utils/type';

const isTakeLatestData = (state: any): state is TakeLatest<any> => {
  return typeof state?.requestId === 'string' && !!state?.data;
};

type PickTakeLatestKeys<State> = {
  [key in keyof State]: State[key] extends TakeLatest<unknown> ? key : never;
}[keyof State];

export const addTakeLatestResourceFactory = <State = unknown>() => {
  type OnlyTakeLatestKeys = PickTakeLatestKeys<Draft<State>>;

  return <Key extends OnlyTakeLatestKeys>(
    builder: ActionReducerMapBuilder<State>,
    // @ts-expect-error
    asyncActionCreator: AsyncThunk<ResourceType<State[Key]['data']>, unknown, {}>,
    key: Key
  ) => {
    builder
      .addCase(asyncActionCreator.pending, (state, action) => {
        const stateToUpdate = state[key];
        if (isTakeLatestData(stateToUpdate)) {
          stateToUpdate.requestId = action.meta.requestId;
        }
      })
      .addCase(asyncActionCreator.fulfilled, (state, action) => {
        const stateToUpdate = state[key];
        if (isTakeLatestData(stateToUpdate)) {
          if (action.meta.requestId === stateToUpdate.requestId) {
            stateToUpdate.data = Resource.resolve<any>(action.payload);
          }
        }
      })
      .addCase(asyncActionCreator.rejected, (state, action) => {
        const stateToUpdate = state[key];
        if (isTakeLatestData(stateToUpdate)) {
          if (action.meta.requestId === stateToUpdate.requestId) {
            stateToUpdate.data = Resource.reject<any>(action.error);
          }
        }
      });
  };
};
