import { createResourceContentSelector } from 'util/selector/resourceSelector';

import { getIsAdminOrCircleAdmin } from 'shared/utils/getIsAdminOrCircleAdmin';
import { createSelector } from '@reduxjs/toolkit';
import { UserRoleType } from 'model/UserRoleType';
import { AdminState } from 'reduxStore/appStore';

export const selectAuthDomain = (state: AdminState) => state.auth;

export const selectLoggedUserResource = createSelector(
  selectAuthDomain,
  (domain) => domain.loggedUserResource
);

export const selectLoggedUser = createResourceContentSelector(selectLoggedUserResource);

export const selectCanCreatePastOnDemandProjects = createSelector(selectLoggedUser, (user) =>
  user?.canCreatePastProjects()
);

export const selectUserRoles = createSelector(selectLoggedUser, (user) => user?.getRoles() || []);

export const selectUserId = createSelector(selectLoggedUser, (user) => user?.getId() || '');

export const selectIsClientAdmin = createSelector(selectUserRoles, (roles) =>
  roles?.includes(UserRoleType.ClientAdmin)
);

export const selectIsWBAdmin = createSelector(selectUserRoles, (roles) =>
  roles?.includes(UserRoleType.Admin)
);
export const selectIsCircleAdmin = createSelector(selectUserRoles, (roles) =>
  roles?.includes(UserRoleType.CircleAdmin)
);

export const selectIsAdmin = createSelector(selectUserRoles, getIsAdminOrCircleAdmin);

export const selectUserFirstName = createSelector(selectLoggedUser, (user) => user?.getFirstName());

export const selectUserLastName = createSelector(selectLoggedUser, (user) => user?.getLastName());

export const selectUserEmail = createSelector(selectLoggedUser, (user) => user?.getEmail());

export const selectShortenUserFullName = createSelector(
  selectUserFirstName,
  selectUserLastName,
  (firstName, lastName) => firstName && `${firstName} ${lastName ? lastName[0] + '.' : ''}`
);

export const selectUserName = createSelector(
  selectShortenUserFullName,
  selectUserEmail,
  (userFullName, userEmail) => (userFullName ? userFullName : userEmail)
);
