import { List } from 'immutable';
import { Category } from 'model/Category';
import { DiscreteQuestion } from 'model/DiscreteQuestion';
import { PageItem } from 'model/PageItem';
import { PageItemKind } from 'model/PageItemKind';
import { Question } from 'model/Question';
import { SimpleModel } from 'model/SimpleModel';
import { StaticQuestionOption } from 'model/StaticQuestionOption';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';

export namespace QuestionOpinion {
  export interface Shape extends Question.Shape {
    options: List<string>;
    isRequired?: boolean;
    reportedAs?: string;
    notApplicable?: string;
    category?: Category;
    isRecommendedQuestion: boolean;
    locale: LangCodeEnum;
    hasNotApplicableOption: boolean;
  }
}

export class QuestionOpinion extends Question<QuestionOpinion.Shape> implements DiscreteQuestion {
  static indexToValue(index: number): number {
    return index + 1;
  }

  static isQuestionOpinion(
    pageItem: PageItem | Question | QuestionOpinion
  ): pageItem is QuestionOpinion {
    return pageItem instanceof QuestionOpinion;
  }

  constructor(initialData: SimpleModel.Data<QuestionOpinion.Shape>) {
    super(initialData, PageItemKind.QuestionOpinion);
  }

  getCategory(): Category {
    return this.get('category');
  }

  hasCategory(): boolean {
    return Boolean(this.get('category'));
  }

  getCategoryName(): string {
    const category = this.get('category');
    return category ? category.getName() : '';
  }

  getReportedAs(): string {
    return this.get('reportedAs');
  }

  getNotApplicable(): string {
    return this.get('notApplicable');
  }

  getHasNotApplicableOption(): boolean {
    return this.get('hasNotApplicableOption');
  }

  getOptions(): List<string> {
    return this.get('options');
  }

  getIsRecommendedQuestion(): boolean {
    return this.get('isRecommendedQuestion');
  }

  isRequired(): boolean {
    return this.get('isRequired');
  }

  getAvailableOptions(): List<StaticQuestionOption> {
    return this.getOptions()
      .map(
        (option, index) =>
          new StaticQuestionOption({ option, value: QuestionOpinion.indexToValue(index) })
      )
      .toList();
  }

  getLocale() {
    return this.get('locale');
  }
}
