import { KioskDistributionApi } from 'api/KioskDistributionApi';
import { KioskDistributionMapper } from 'mapper/KioskDistributionMapper';
import { KioskDistribution } from 'model/KioskDistribution';

export class KioskDistributionRepository {
  constructor(
    private kioskModeDistributionApi: KioskDistributionApi,
    private kioskModeDistributionMapper: KioskDistributionMapper
  ) {}

  fetch(projectId: number): Promise<KioskDistribution> {
    return this.kioskModeDistributionApi
      .fetch(projectId)
      .then((entry) => this.kioskModeDistributionMapper.deserialize(entry));
  }
}
