import { List } from 'immutable';
import { Category } from 'model/Category';
import { Page } from 'model/Page';
import { PageItem } from 'model/PageItem';
import { ProjectKind } from 'model/ProjectKind';
import { SimpleModel } from 'model/SimpleModel';
import { Moment } from 'moment';

export namespace ProjectTemplate {
  export interface Shape {
    id: number;
    name: string;
    createdAt: Moment;
    createdBy: string;
    updatedAt: Moment;
    updatedBy: string;
    categories: List<Category>;
    pages: List<Page>;
    kind: ProjectKind;
  }
}

export class ProjectTemplate<
  S extends ProjectTemplate.Shape = ProjectTemplate.Shape
> extends SimpleModel<S> {
  getId(): number {
    return this.get('id');
  }

  getName(): string {
    return this.get('name');
  }

  getCreatedAt(): Moment {
    return this.get('createdAt');
  }

  getCreatedBy(): string {
    return this.get('createdBy');
  }

  getUpdatedAt(): Moment {
    return this.get('updatedAt');
  }

  getUpdatedBy(): string {
    return this.get('updatedBy');
  }

  getCategories(): List<Category> {
    return this.get('categories', List<Category>());
  }

  getKind(): ProjectKind {
    return this.get('kind');
  }

  isCAS(): boolean {
    return this.getKind() === ProjectKind.PROJECT_CLIENT_ADVOCACY_SCORE;
  }

  isEngagementScore(): boolean {
    return this.getKind() === ProjectKind.PROJECT_ENGAGEMENT_SCORE;
  }

  getPages(): List<Page> {
    return this.get('pages') || List<Page>();
  }

  getPageByPageItem(item: PageItem): Page {
    return this.getPages().find((page) => page.getItems().contains(item));
  }

  getPrevPagesByPageItem(item: PageItem): List<Page> {
    const currentPage = this.getPageByPageItem(item);

    return this.getPages()
      .filter((page) => page.getPosition() < currentPage.getPosition())
      .toList();
  }

  getNextPagesByPageItem(item: PageItem): List<Page> {
    const currentPage = this.getPageByPageItem(item);

    return this.getPages()
      .filter((page) => page.getPosition() > currentPage.getPosition())
      .toList();
  }
}
