import { createSlice } from '@reduxjs/toolkit';
import { addAsyncCases } from 'shared/utils/redux';

import { fetchContactList } from './asyncActions';
import { ContactListState, STORE_NAME } from './initialState';

export const contactListSlice = createSlice({
  name: STORE_NAME,
  initialState: ContactListState.INITIAL_DOMAIN,
  reducers: {
    clearContactList: (state) => {
      state.contactList = ContactListState.INITIAL_DOMAIN.contactList;
    },
  },
  extraReducers: (builder) => {
    addAsyncCases(builder, fetchContactList, (state, action) => {
      state.contactList = action.payload;
    });
  },
});

export const { clearContactList } = contactListSlice.actions;
