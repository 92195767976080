import { compile, PathFunction } from 'path-to-regexp';
import { stringify as stringifyQuery } from 'query-string';

export type CompilePathParams = {
  [key: string]: string | number | string[] | number[];
};
export type CompilePathQuery = {
  [key: string]: any;
};
type CompilePathCache = {
  [key: string]: PathFunction;
};

const compilePathCache: CompilePathCache = {};

export function compilePath(
  pattern: string,
  params?: CompilePathParams,
  query?: CompilePathQuery
): string {
  if (!compilePathCache[pattern]) {
    compilePathCache[pattern] = compile(pattern);
  }

  try {
    const compiledPath = compilePathCache[pattern](params);
    if (query === undefined) {
      return compiledPath;
    }

    const stringifiedQuery = stringifyQuery(query);
    return stringifiedQuery ? `${compiledPath}?${stringifiedQuery}` : compiledPath;
  } catch (error) {
    return '/';
  }
}
