import { LogicApi } from 'api/LogicApi';
import { List } from 'immutable';
import { LogicTypeMapper } from 'mapper/LogicMapper';
import { Logic } from 'model/Logic';
import { LogicDependency } from 'model/LogicDependency';
import { LogicType } from 'model/LogicType';

export class LogicDependencyMapper
  implements LogicTypeMapper<LogicDependency, LogicApi.DependencyEntry> {
  serialize(model: LogicDependency): LogicApi.DependencyEntry {
    return {
      type: model.getType(),
      question: model.getQuestionId(),
      options: model.getOptions().toArray(),
      mode: model.getMode(),
    };
  }

  deserialize(entry: LogicApi.DependencyEntry): LogicDependency {
    return new LogicDependency({
      id: entry.id,
      type: entry.type,
      questionId: entry.question,
      options: List<number>(entry.options || []),
      mode: entry.mode,
    });
  }

  supportsSerialize(model: Logic): boolean {
    return model instanceof LogicDependency;
  }

  supportsDeserialize(entry: LogicApi.Entry): boolean {
    return entry.type === LogicType.Dependency;
  }
}
