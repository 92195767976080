import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { PulseTemplate } from 'model/PulseTemplate';
import { Resource } from 'model/Resource';
import { ProjectState } from 'reduxStore/project/initialState';
import {
  fetchCurrentProject,
  fetchProjectList,
  updateProject,
} from 'reduxStore/project/project/asyncActions';
import { addAsyncCases } from 'shared/utils/redux';

export const projectReducer = (builder: ActionReducerMapBuilder<ProjectState.Domain>) => {
  addAsyncCases(builder, fetchProjectList, (state, action) => {
    state.listResource = action.payload;
  });

  builder
    .addCase(fetchCurrentProject.fulfilled, (state, action) => {
      state.currentResource = Resource.resolve(action.payload);
    })
    .addCase(fetchCurrentProject.rejected, (state, action) => {
      state.currentResource = Resource.reject(action.error);
    })
    .addCase(updateProject.pending, (state) => {
      state.currentResource = Resource.pending();
    })
    .addCase(updateProject.fulfilled, (state, action) => {
      const currentProject = state.currentResource.getContent();
      if (currentProject instanceof PulseTemplate) {
        state.currentResource.setContent(currentProject.setProject(action.payload));
      } else {
        state.currentResource = Resource.resolve(action.payload);
      }
    })
    .addCase(updateProject.rejected, (state, action) => {
      state.currentResource = Resource.reject(action.error);
    });
};
