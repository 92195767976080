import React, { createContext, useContext, useRef, useState } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';

import { LangCodeEnum } from 'shared/enums/LangCodeEnum';
import { languageConfig } from 'translations/languageConfig';
import { lazyLoadTranslations } from 'translations/lazyLoadTranslations';
import { getLanguageDirection } from 'shared/utils/getLanguageDirection';
import { getLanguageFromLocale } from 'shared/utils/getLanguageFromLocale';

const LanguageContext = createContext<{
  language: LangCodeEnum;
  changeLanguage: (language: LangCodeEnum) => Promise<void>;
  setChangeLanguageCallback: (
    changeLanguageCallback: (locale: LangCodeEnum) => Promise<void>
  ) => void;
}>({
  language: languageConfig.baseLanguage,
  changeLanguage: () => Promise.resolve(),
  setChangeLanguageCallback: () => {},
});

export const useLanguage = () => {
  return useContext(LanguageContext);
};

type LanguageProviderProps = {
  children: React.ReactNode;
};

export const LanguageProvider: React.VFC<LanguageProviderProps> = ({ children }) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(languageConfig.baseLanguage);
  document.documentElement.dir = getLanguageDirection(language);
  document.documentElement.lang = getLanguageFromLocale(language);

  type ChangeLanguageCallbackRefType = (locale: LangCodeEnum) => Promise<void>;
  const changeLanguageCallbackRef = useRef<ChangeLanguageCallbackRefType>(() => Promise.resolve());

  const setChangeLanguageCallback = (changeLanguageCallback: ChangeLanguageCallbackRefType) => {
    changeLanguageCallbackRef.current = changeLanguageCallback;
  };

  const changeLanguage = async (language: LangCodeEnum) => {
    await lazyLoadTranslations(language);
    await changeLanguageCallbackRef.current(language);
    document.documentElement.dir = getLanguageDirection(language);
    document.documentElement.lang = getLanguageFromLocale(language);
    await i18n.changeLanguage(language);
    setLanguage(language);
  };

  return (
    <I18nextProvider i18n={i18n}>
      <LanguageContext.Provider value={{ language, changeLanguage, setChangeLanguageCallback }}>
        {children}
      </LanguageContext.Provider>
    </I18nextProvider>
  );
};
