import { emailDistributionMapper } from 'container/emailDistributionMapper';
import { paperDistributionMapper } from 'container/paperDistributionMapper';
import { webLinkDistributionMapper } from 'container/webLinkDistributionMapper';
import { DistributionMapper } from 'mapper/DistributionMapper';

export const distributionMapper = new DistributionMapper([
  emailDistributionMapper,
  webLinkDistributionMapper,
  paperDistributionMapper,
]);
