import { KioskDistributionApi } from 'api/KioskDistributionApi';
import { DistributionMapperByChannel } from 'mapper/DistributionMapper';
import { DistributionChannel } from 'model/DistributionChannel';
import { KioskDistribution } from 'model/KioskDistribution';

export class KioskDistributionMapper
  implements DistributionMapperByChannel<KioskDistribution, KioskDistributionApi.Entry> {
  serialize(model: KioskDistribution): KioskDistributionApi.Entry {
    return {
      id: model.getId(),
      slug: model.getSlug(),
      surveyLink: model.getLink(),
    };
  }

  deserialize(entry: KioskDistributionApi.Entry): KioskDistribution {
    return new KioskDistribution({
      id: entry.id,
      slug: entry.slug,
      link: entry.surveyLink,
    });
  }

  supportsSerialize(model: KioskDistribution): boolean {
    return model.getChannel() === DistributionChannel.Kiosk;
  }

  supportsDeserialize(entry: KioskDistributionApi.Entry): boolean {
    return entry.channel === DistributionChannel.Kiosk;
  }
}
