import { Map } from 'immutable';
import { AsyncModel } from 'model/AsyncModel';
import { LogicType } from 'model/LogicType';
import { SimpleModel } from 'model/SimpleModel';

export namespace Logic {
  export interface Shape {
    id?: number;
    type?: LogicType;
    [key: string]: any; // TODO replace with unknown
  }
}

export abstract class Logic<S extends Logic.Shape = Logic.Shape> extends AsyncModel<S> {
  protected constructor(initialData: SimpleModel.Data<Logic.Shape>, type?: LogicType) {
    const map = (Map.isMap(initialData)
      ? initialData
      : Map<keyof Logic.Shape, any>(initialData)) as Map<keyof Logic.Shape, any>;

    super(type !== undefined ? (map.set('type', type) as any) : map);
  }

  getId(): number {
    return this.get('id');
  }

  getType(): LogicType {
    return this.get('type');
  }

  isClassification(): boolean {
    return this.get('type') === LogicType.Classification;
  }

  isDistribution(): boolean {
    return this.get('type') === LogicType.Distribution;
  }

  isDependency(): boolean {
    return this.get('type') === LogicType.Dependency;
  }
}
