import React from 'react';
import { connect } from 'react-redux';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { FlatButton } from 'component/Button/Button';
import { Pagination } from 'component/Pagination/Pagination';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { AdminDispatch } from 'reduxStore/appStore';
import { ProjectState } from 'reduxStore/project/initialState';
import { Box, Grid } from '@mui/material';
import { fetchSubProjectsList } from 'reduxStore/project/pulseProject/actions';
import {
  selectSubProjectsList,
  selectSubProjectsPagination,
} from 'reduxStore/project/pulseProject/selectors';

type ProjectListPaginationViewPureProps = {
  listResource: ProjectState.ProjectListResource;
  onPageChange: (page: number) => void;
  currentPage: number;
  totalPages: number;
};

export const ProjectListPaginationViewPure: React.VFC<ProjectListPaginationViewPureProps> = (
  props
) => (
  <ResourceLoader
    optimistic
    resource={props.listResource}
    content={() => (
      <div>
        {props.totalPages > 1 && (
          <Grid container spacing={4} alignItems="center">
            <Box component={Grid} item sm display={{ xs: 'none', sm: 'block' }}>
              <FlatButton
                label="Back"
                onClick={() => props.onPageChange(props.currentPage - 1)}
                startIcon={<ArrowBackIcon />}
                disabled={props.currentPage === 1}
              />
            </Box>
            <Grid item xs>
              <Pagination
                pageCount={props.totalPages}
                page={props.currentPage - 1}
                onPageChange={(page) => props.onPageChange(page + 1)}
              />
            </Grid>
            <Box component={Grid} item sm display={{ xs: 'none', sm: 'block' }} textAlign="right">
              <FlatButton
                label="Next"
                onClick={() => props.onPageChange(props.currentPage + 1)}
                endIcon={<ArrowForwardIcon />}
                disabled={props.currentPage === props.totalPages}
              />
            </Box>
          </Grid>
        )}
      </div>
    )}
  />
);

type ProjectListPaginationViewProps = ProjectListPaginationViewPureProps;
export const ProjectListPaginationView: React.VFC<ProjectListPaginationViewProps> = ({
  listResource,
  onPageChange,
  currentPage,
  totalPages,
}) => {
  return (
    <ProjectListPaginationViewPure
      currentPage={currentPage}
      onPageChange={onPageChange}
      totalPages={totalPages}
      listResource={listResource}
    />
  );
};

export namespace SubProjectListPaginationView {
  export type State = {};
  export type StateProps = {
    currentPage: number;
    totalPages: number;
    listResource: ProjectState.ProjectListResource;
  };
  export type DispatchProps = {
    onPageChange: (page: number) => void;
  };
  export type OwnProps = {};
  export type Props = StateProps & DispatchProps & OwnProps;
}
export const SubProjectListPaginationView: React.ComponentClass<SubProjectListPaginationView.OwnProps> = connect(
  (state): SubProjectListPaginationView.StateProps => {
    const pagination = selectSubProjectsPagination(state);

    return {
      totalPages: pagination && pagination.getPages(),
      currentPage: (pagination && pagination.getPage()) || 1,
      listResource: selectSubProjectsList(state),
    };
  },
  (dispatch: AdminDispatch): SubProjectListPaginationView.DispatchProps => ({
    onPageChange: (page) =>
      dispatch(
        fetchSubProjectsList({
          config: {
            page,
          },
        })
      ),
  })
)(ProjectListPaginationViewPure) as React.ComponentClass<SubProjectListPaginationView.OwnProps>;
