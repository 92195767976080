import { QueueApiError } from 'api/QueueApi';
import { JobStatus } from 'model/JobStatus';
import { QueueName } from 'model/QueueName';
import { SimpleModel } from 'model/SimpleModel';

export namespace Queue {
  export interface Shape {
    id: number;
    name: string;
    progress: number;
    status: JobStatus;
    projectId: number;
    reason?: (string | QueueApiError)[];
  }
}

export class Queue extends SimpleModel<Queue.Shape> {
  static isImportSurveyQueue(queue: Queue): boolean {
    return queue && queue.getName() === QueueName.ImportSurveyAnswer;
  }

  getId(): number {
    return this.get('id');
  }

  getStatus(): JobStatus {
    return this.get('status');
  }

  getName(): string {
    return this.get('name');
  }

  getReason(): any {
    return this.get('reason');
  }

  getProgress(): number {
    return this.get('progress', 0);
  }

  getProjectId(): number {
    return this.get('projectId');
  }

  isSuccessful(): boolean {
    return this.getStatus() === JobStatus.Success;
  }

  isFailed(): boolean {
    return this.getStatus() === JobStatus.Failure;
  }

  isPending(): boolean {
    return this.getStatus() === JobStatus.Execution;
  }
}
