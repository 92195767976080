import React from 'react';

import {
  DropzoneMessage,
  DropzoneSingleFileMessageWrapper,
  ImagePreview,
} from 'component/Dropzone/Dropzone.s';
import { DropzoneImagePreview } from 'component/Dropzone/DropzoneImagePreview';
import { Project } from 'model/Project';
import { ProjectImage } from 'model/ProjectImage';

export const DropzoneSingleFileMessage: React.VFC<{ file: File }> = ({ file }) => {
  const extension = file.name.match(/\.[^.]+$/)?.[0];
  const isImage = Project.IMAGE_ACCEPT.includes(extension as ProjectImage);
  return (
    <DropzoneSingleFileMessageWrapper>
      {isImage && (
        <>
          <DropzoneMessage sx={{ height: 'auto' }}>Selected image:</DropzoneMessage>
          <ImagePreview src={URL.createObjectURL(file)} data-testid="imagePreview" />
        </>
      )}
      <DropzoneMessage sx={{ height: 'auto' }}>
        Selected file: <DropzoneImagePreview file={file} />
      </DropzoneMessage>
    </DropzoneSingleFileMessageWrapper>
  );
};
