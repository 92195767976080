import { SimpleModel } from 'model/SimpleModel';

export namespace Enum {
  export interface Shape {
    key: number | string;
    name: string;
  }
}

export class Enum extends SimpleModel<Enum.Shape> {
  getKey(): number | string {
    return this.get('key');
  }

  getName(): string {
    return this.get('name');
  }
}
