import React from 'react';

import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import Radio, { RadioProps } from '@mui/material/Radio';
import { Box } from '@mui/material';

type Props = RadioProps & {
  value: unknown;
  label?: FormControlLabelProps['label'];
};

export const RadioButton = ({ value, label, ...props }: Props) => {
  return (
    <Box>
      <FormControlLabel value={value} control={<Radio {...props} />} label={label} />
    </Box>
  );
};
