import { PageItemApi } from 'api/PageItemApi';
import { List } from 'immutable';
import { CustomQuestionOptionMapper } from 'mapper/CustomQuestionOptionMapper';
import { PageItemKindMapper } from 'mapper/PageItemMapper';
import { QuestionAbstractMapper } from 'mapper/QuestionAbstractMapper';
import { PageItem } from 'model/PageItem';
import { PageItemKind } from 'model/PageItemKind';
import { QuestionRadio } from 'model/QuestionRadio';

export class QuestionRadioMapper
  implements PageItemKindMapper<QuestionRadio, PageItemApi.QuestionRadioEntry> {
  constructor(private customQuestionOptionMapper: CustomQuestionOptionMapper) {}

  serialize(model: QuestionRadio): PageItemApi.QuestionRadioEntry {
    return {
      ...QuestionAbstractMapper.serialize(model),
      options: model
        .getOptions()
        .map((option) => this.customQuestionOptionMapper.serialize(option))
        .toArray(),
      required: model.isRequired() ? 1 : 0,
      hasOther: model.hasOther() ? 1 : 0,
    };
  }

  deserialize(entry: PageItemApi.QuestionRadioEntry): QuestionRadio {
    return new QuestionRadio({
      ...QuestionAbstractMapper.deserializeShape(entry),
      options: List(
        (entry.options || [])
          .map((option) => this.customQuestionOptionMapper.deserialize(option))
          .sort((a, b) => a.getPosition() - b.getPosition())
      ),
      isRequired: Boolean(entry.required),
      hasOther: Boolean(entry.hasOther),
    });
  }

  supportsSerialize(model: PageItem): boolean {
    return model instanceof QuestionRadio;
  }

  supportsDeserialize(entry: PageItemApi.ImageWidgetEntry): boolean {
    return entry.kind === PageItemKind.QuestionRadioButton;
  }
}
