import EventNoteIcon from '@mui/icons-material/EventNote';
import { styled } from '@mui/material';

export const ProjectCompanyDataWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
});

export const ProjectIcon = styled(EventNoteIcon)({
  width: 70,
  height: 70,
  margin: '0 15px',
  alignSelf: 'baseline',
});

export const SubtitleWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});
