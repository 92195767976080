import { LangCodeEnum } from 'shared/enums/LangCodeEnum';
import { i18n } from 'translations/i18n';
import { LangNamespaceEnum } from 'shared/enums/LangNamespaceEnum';
import { LanguageCodeToLocaleDirectoryMapper } from 'translations/LanguageCodeToLocaleDirectoryMapper';

export const lazyLoadTranslations = async (language: LangCodeEnum): Promise<void> => {
  const localeDirectoryName = LanguageCodeToLocaleDirectoryMapper[language];

  if (!localeDirectoryName) {
    throw new Error(`There is no locale mapper for language: ${language}`);
  }

  for (const langNamespace of Object.values(LangNamespaceEnum)) {
    if (!i18n.hasResourceBundle(language, langNamespace)) {
      await import(`./${localeDirectoryName}/${langNamespace}.json`)
        .then((mod) => mod.default)
        .then((resourceBundle) => {
          i18n.addResourceBundle(language, langNamespace, resourceBundle);
        });
    }
  }
};
