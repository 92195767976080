import { PageApi } from 'api/PageApi';
import { List } from 'immutable';
import { PageMapper } from 'mapper/PageMapper';
import { Page } from 'model/Page';

export class PageRepository {
  constructor(private pageApi: PageApi, private pageMapper: PageMapper) {}

  list(projectId: number): Promise<List<Page>> {
    return this.pageApi
      .list(projectId)
      .then((entries = []) => this.pageMapper.deserializeList(entries));
  }

  create(projectId: number, page: Page): Promise<List<Page>> {
    return this.pageApi
      .create(projectId, this.pageMapper.serialize(page))
      .then(() => this.list(projectId));
  }

  update(projectId: number, page: Page): Promise<List<Page>> {
    return this.pageApi.update(this.pageMapper.serialize(page)).then(() => this.list(projectId));
  }

  delete(pageId: number): Promise<void> {
    return this.pageApi.delete(pageId);
  }

  moveUp(projectId: number, page: Page): Promise<List<Page>> {
    return this.pageApi
      .update(this.pageMapper.serialize(page.moveUp()))
      .then(() => this.list(projectId));
  }

  moveDown(projectId: number, page: Page): Promise<List<Page>> {
    return this.pageApi
      .update(this.pageMapper.serialize(page.moveDown()))
      .then(() => this.list(projectId));
  }
}
