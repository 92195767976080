import { selectApiData } from 'util/selector/apiSelector';

import { WithData } from 'api/interfaces';
import { PaginationApi } from 'api/PaginationApi';
import { ProjectApi } from 'api/ProjectApi';
import { AxiosInstance } from 'axios';
import { LifecycleType } from 'model/Lifecycle';
import { Project } from 'model/Project';
import { PulseFrequencyData } from 'repository/PulseProjectRepository';

export namespace PulseProjectApi {
  export type Entry = {
    hasChildProjectsAnyPublishableSubmits: boolean;
    project: ProjectApi.Entry;
    pulseProjects: ProjectApi.Entry[];
    pulseOnDemandProjects: ProjectApi.Entry[];
    pulseLifecycleProjects: ProjectApi.Entry[];
  };

  export type SubProjectsListEntry = {
    limit: number;
    page: number;
    pages: number;
    total: number;
    _embedded: {
      items: SubProject[];
    };
  };

  export type SubProject = {
    name: ProjectApi.Entry['name'];
    updatedAt: ProjectApi.Entry['updatedAt'];
    createdBy: ProjectApi.Entry['createdBy'];
    createdAt: ProjectApi.Entry['createdAt'];
    status: ProjectApi.Entry['status'];
    kind: ProjectApi.Entry['kind'];
    liveStart: ProjectApi.Entry['liveStart'];
    liveStop: ProjectApi.Entry['liveStop'];
    responseRate: ProjectApi.Entry['responseRate'];
    id: ProjectApi.Entry['id'];
    templateId: ProjectApi.Entry['id'];
  };
}

export class PulseProjectApi {
  constructor(private apiClient: AxiosInstance) {}

  fetch(): Promise<PulseProjectApi.Entry> {
    return this.apiClient
      .get<WithData<PulseProjectApi.Entry>>('/api/v1/pulse-template/for-current-user')
      .then(selectApiData);
  }

  get(pulseTemplateId: Project.Shape['id']): Promise<PulseProjectApi.Entry> {
    return this.apiClient
      .get<WithData<PulseProjectApi.Entry>>(`/api/v1/pulse-template/${pulseTemplateId}`)
      .then(selectApiData);
  }

  create(
    pulseTemplateId: Project.Shape['id'],
    { startDate, pulseFrequencyId }: PulseFrequencyData
  ): Promise<PulseProjectApi.Entry> {
    return this.apiClient
      .post<WithData<PulseProjectApi.Entry>>(`api/v1/pulse-template/${pulseTemplateId}/create`, {
        startDate: startDate.toDate().toDateString(),
        pulseFrequencyId,
      })
      .then(selectApiData);
  }

  getChildProjects(pulseTemplateId: Project.Shape['id']): Promise<ProjectApi.Entry[]> {
    return this.apiClient
      .get(`/api/v1/pulse-template/${pulseTemplateId}/all-child-projects`)
      .then(selectApiData);
  }

  getSubProjects(
    pulseTemplateId: Project.Shape['id'],
    query: string
  ): Promise<{
    list: PulseProjectApi.SubProject[];
    pagination: PaginationApi.Entry;
  }> {
    return this.apiClient
      .get<WithData<PulseProjectApi.SubProjectsListEntry>>(
        `api/v1/pulse-template/${pulseTemplateId}/projects${query}`
      )
      .then(selectApiData)
      .then(({ page, limit, pages, total, _embedded }) => {
        return {
          list: _embedded.items || [],
          pagination: {
            page,
            limit,
            pages,
            total,
          },
        };
      });
  }

  updateFrequency(
    pulseTemplateId: Project.Shape['id'],
    { startDate, pulseFrequencyId }: PulseFrequencyData
  ): Promise<PulseProjectApi.Entry> {
    return this.apiClient
      .post<WithData<PulseProjectApi.Entry>>(
        `api/v1/pulse-template/${pulseTemplateId}/change-frequency`,
        {
          startDate: startDate.toDate().toDateString(),
          pulseFrequencyId,
        }
      )
      .then(selectApiData);
  }

  distributeLifecycle(
    pulseTemplateId: Project.Shape['id'],
    lifecycleType: LifecycleType,
    employeesIds: number[]
  ): Promise<void> {
    return this.apiClient.post(
      `api/v1/pulse-template/${pulseTemplateId}/pulse-lifecycle-project/${lifecycleType}/distribute`,
      { parentRespondentIds: employeesIds }
    );
  }

  uploadRespondents(
    pulseTemplateId: Project.Shape['id'],
    lifecycleType: LifecycleType,
    file: File
  ): Promise<void> {
    const data = new FormData();
    data.append('document', file);

    return this.apiClient.post(
      `api/v1/pulse-template/${pulseTemplateId}/pulse-lifecycle-project/${lifecycleType}/populate-respondents`,
      data
    );
  }
}
