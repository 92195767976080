import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { Resource } from 'model/Resource';
import { ProjectState } from 'reduxStore/project/initialState';

import {
  fetchCurrentPulseProject,
  getPulseProject,
  createPulseProject,
  changePulseFrequency,
  fetchSubProjectsList,
} from './actions';

export const pulseProjectReducer = (builder: ActionReducerMapBuilder<ProjectState.Domain>) => {
  builder
    .addCase(fetchCurrentPulseProject.fulfilled, (state, action) => {
      state.currentResource = Resource.resolve(action.payload);
    })
    .addCase(fetchCurrentPulseProject.rejected, (state, action) => {
      state.currentResource = Resource.reject(action.error);
    })

    .addCase(getPulseProject.fulfilled, (state, action) => {
      state.currentResource = Resource.resolve(action.payload);
    })
    .addCase(getPulseProject.rejected, (state, action) => {
      state.currentResource = Resource.reject(action.error);
    })

    .addCase(fetchSubProjectsList.fulfilled, (state, action) => {
      state.subProjects.list = Resource.resolve(action.payload.list);
      state.subProjects.pagination = action.payload.pagination;
    })
    .addCase(fetchSubProjectsList.rejected, (state, action) => {
      state.subProjects.list = Resource.reject(action.error);
      state.subProjects.pagination = ProjectState.INITIAL_DOMAIN['subProjects'].pagination;
    })

    .addCase(createPulseProject.fulfilled, (state, action) => {
      state.currentResource = Resource.resolve(action.payload);
    })

    .addCase(changePulseFrequency.fulfilled, (state, action) => {
      state.currentResource = Resource.resolve(action.payload);
    });
};
