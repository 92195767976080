import { SimpleModel } from 'model/SimpleModel';

export namespace EmployeeStats {
  export interface Shape {
    existingRespondents: number;
    newRespondents: number;
    orphanRespondents: number;
  }
}

export class EmployeeStats extends SimpleModel<EmployeeStats.Shape> {
  getExistingRespondents(): number {
    return this.get('existingRespondents');
  }

  getNewRespondents(): number {
    return this.get('newRespondents');
  }

  getOrphanRespondents(): number {
    return this.get('orphanRespondents');
  }
}
