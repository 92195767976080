import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import React from 'react';
import ReactDOM from 'react-dom';

import { store } from 'reduxStore/appStore';
import { fetchAndProcessLoggedUser } from 'reduxStore/auth/asyncActions';

import { App } from './App';

// recompute dialog positions on every dispatch (hacky way)
store.subscribe(() => {
  window.dispatchEvent(new Event('resize'));
  setTimeout(() => {
    window.dispatchEvent(new Event('resize'));
  }, 0);
});

document.addEventListener('DOMContentLoaded', () => {
  store.dispatch(fetchAndProcessLoggedUser()).then(() => {
    const root = document.getElementById('root');
    ReactDOM.render(<App />, root);
  });
});
