import get from 'lodash/get';
import memoize from 'lodash/memoize';
import { AdminState } from 'reduxStore/appStore';
import { createSelector, Selector } from '@reduxjs/toolkit';
import { AdminModal } from 'register/AdminModal';
import { AdminModalKey, AdminModalParamsMapping } from 'register/AdminModalParams';

import { STORE_NAME } from './initialState';

export const selectModal = (state: AdminState) => state[STORE_NAME];

export const selectModalName = createSelector(selectModal, (modal) => modal.name);

export const _selectModalParams = createSelector(selectModal, (modal) => modal.params);

/**
 * @deprecated
 * Use hook useModalParams
 */
export const selectModalParams = <N extends AdminModal>(modalName: AdminModalKey<N>) =>
  createSelector(selectModalName, _selectModalParams, (name, params) => {
    if (modalName !== name) {
      return {};
    }
    return params;
  });

/**
 * @deprecated
 * Use hook useModalParams
 */
export const selectModalParam = <
  N extends AdminModal,
  K extends keyof AdminModalParamsMapping[AdminModalKey<N>]
>(
  modalName: AdminModalKey<N>,
  paramName: K
) =>
  createSelector(selectModalName, _selectModalParams, (name, params) => {
    if (modalName !== name) {
      return undefined;
    }
    const result = get(params, paramName);
    return result as AdminModalParamsMapping[AdminModalKey<N>][K];
  });

export const selectIsModalPending = createSelector(selectModal, (modal) => modal.pending);

export const selectIsModalOpen: <N extends AdminModal>(
  modalName: AdminModalKey<N>
) => Selector<AdminState, boolean> = memoize(
  (modalName: string) =>
    createSelector(
      selectModalName,
      (currentModalName) => currentModalName === modalName
    ) as Selector<AdminState, boolean>
) as any;
