import { jsonToFormData } from 'util/jsonToFormData';
import { selectApiData, selectApiNoContent } from 'util/selector/apiSelector';
import { selectResponseData } from 'util/selector/axiosSelector';

import { CategoryApi } from 'api/CategoryApi';
import { ApiMessage } from 'api/const';
import { PageItemTranslationDto } from 'api/dto/PageItemTranslationsDto';
import { WithData } from 'api/interfaces';
import { LogicApi } from 'api/LogicApi';
import { StaticResourceApi } from 'api/StaticResourceApi';
import { AxiosInstance } from 'axios';
import { PageItemKind } from 'model/PageItemKind';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';

export namespace PageItemApi {
  export type ItemEntry = {
    id: number;
    position?: number;
    kind?: PageItemKind;
    logic?: LogicApi.Entry;
    page?: number;
    benchmarkItem?: number;
    benchmarkItemId?: number;
    trendItem?: number;
    trendItemId?: number;
    locale?: LangCodeEnum;
  };

  export type ItemEntryAny = ItemEntry & {
    [key: string]: any;
  };
  export type QuestionEntry = ItemEntry & {
    content?: string;
  };
  export type CustomQuestionOptionEntry = {
    id?: number;
    option: string;
    position: number;
  };
  export type QuestionDemographicEntry = QuestionEntry & {
    options: CustomQuestionOptionEntry[];
    numberingSkipped: number;
    isGhost?: number;
    required?: number;
  };
  export type QuestionCASEntry = QuestionEntry & {
    required?: number;
  };
  export type QuestionOpinionEntry = QuestionEntry & {
    category?: CategoryApi.Entry | number;
    option1: string;
    option2: string;
    option3: string;
    option4: string;
    option5: string;
    reportedAs?: string;
    notApplicable?: string;
    required?: number;
    isRecommendedQuestion: boolean;
    locale: LangCodeEnum;
    hasNotApplicableOption: boolean;
  };
  export type QuestionCommentBox = QuestionEntry & {
    height: number;
    reportedAs: string;
    required?: number;
    numberingSkipped: number;
    sentiment: -1 | 0 | 1 | null;
    locale?: LangCodeEnum;
  };
  export type QuestionFormEntry = QuestionEntry & {
    options: CustomQuestionOptionEntry[];
    optionHeight?: number;
    numberingSkipped: number;
  };

  export type QuestionCheckboxEntry = QuestionEntry & {
    options: CustomQuestionOptionEntry[];
    limitAnswerCount?: number;
    required?: number;
    hasOther?: number;
    numberingSkipped: number;
    locale?: LangCodeEnum;
  };
  export type QuestionConsentEntry = QuestionEntry & {
    logic: undefined; // Consent question do not contain logic
    kind: PageItemKind.QuestionConsent;
    optionYes: string;
    optionNo: string;
  };
  export type QuestionRadioEntry = QuestionEntry & {
    options: CustomQuestionOptionEntry[];
    required?: number;
    hasOther?: number;
  };
  export type TextWidgetEntry = ItemEntry & {
    content?: string;
    htmlContent?: string;
  };
  export type ImageWidgetEntry = ItemEntry & {
    image?: number | StaticResourceApi.Entry;
    imageNew?: {
      file?: File;
    };
    width?: number;
    height?: number;
    align?: string;
  };

  export type Entry =
    | QuestionEntry
    | TextWidgetEntry
    | ImageWidgetEntry
    | QuestionOpinionEntry
    | QuestionDemographicEntry;
}

export class PageItemApi {
  constructor(private client: AxiosInstance) {}

  list(pageId: number): Promise<PageItemApi.Entry[]> {
    return this.client
      .get(`/api/v1/page/${pageId}/item`)
      .then((response) => selectResponseData(response, '_embedded.items', []));
  }

  create(
    pageId: number,
    entry: PageItemApi.Entry,
    shouldSendFormData?: boolean
  ): Promise<PageItemApi.Entry> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, position, ...data } = entry;

    return this.client
      .post<WithData<PageItemApi.Entry>>(
        `/api/v1/page/${pageId}/item`,
        shouldSendFormData ? jsonToFormData(data) : data
      )
      .then(selectApiData);
  }

  getTranslatedPageItem({ itemId, language }: { itemId: number; language: LangCodeEnum }) {
    return this.client
      .get<WithData<PageItemApi.Entry>>(`/api/v1/item/${itemId}?locale=${language}`)
      .then(selectApiData);
  }

  savePageItemTranslations({
    itemId,
    translations,
  }: {
    itemId: number;
    translations: PageItemTranslationDto[];
  }): Promise<PageItemApi.Entry> {
    return this.client
      .post<WithData<PageItemApi.Entry>>(`/api/v1/item/${itemId}/translations`, { translations })
      .then(selectApiData);
  }

  update(entry: PageItemApi.Entry, shouldSendFormData?: boolean): Promise<PageItemApi.Entry> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id: itemId, position, kind, ...data } = entry;

    return this.client
      .post<WithData<PageItemApi.Entry>>(
        `/api/v1/item/${itemId}`,
        shouldSendFormData ? jsonToFormData(data) : data
      )
      .then(selectApiData);
  }

  reorder(
    pageId: number,
    newPosition: number,
    entry: PageItemApi.Entry
  ): Promise<PageItemApi.Entry> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id: itemId, position, kind, ...data } = entry;
    const reorderedItem = { page: pageId, position: newPosition, ...data };

    return this.client
      .post<WithData<PageItemApi.Entry>>(`/api/v1/item/${itemId}`, reorderedItem)
      .then(selectApiData);
  }

  move(pageId: number, itemId: number, position: number): Promise<PageItemApi.Entry> {
    return this.client
      .post(`/api/v1/item/${itemId}/move`, { page: pageId, position })
      .then(selectApiData);
  }

  delete(itemId: number): Promise<void> {
    return this.client
      .delete<ApiMessage.Deleted>(`/api/v1/item/${itemId}`)
      .then(selectApiNoContent);
  }

  moveToPulseProject(itemId: number, projectId: number): Promise<any> {
    return this.client.post(`/api/v1/copy-item/${itemId}/${projectId}`);
  }
}
