import { useState } from 'react';

import { useDebounceValue } from 'shared/hooks/useDebounceValue';

type SearchFiltersParams = {
  resetPaginationPage?: () => void;
  debounceMs?: number;
};

export const useSearchFilters = <T extends object>(
  initialFilters: T,
  { resetPaginationPage, debounceMs }: SearchFiltersParams = {}
) => {
  const [filters, setFilters] = useState<T>(initialFilters);

  const debouncedFilters = useDebounceValue(filters, debounceMs);

  const resetPaginationPageCb = () => {
    resetPaginationPage?.();
  };

  const mergeFilters = (value: Partial<T> | ((prev: T) => T)) => {
    resetPaginationPageCb();
    if (typeof value === 'function') {
      setFilters(value);
    } else {
      setFilters((prev) => ({
        ...prev,
        ...value,
      }));
    }
  };

  return {
    filters,
    debouncedFilters,
    mergeFilters,
  };
};
