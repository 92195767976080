import { ProjectKind } from 'model/ProjectKind';
import qs from 'qs';

export const createProjectSelectQuery = (
  companyId?: number,
  kind?: ProjectKind,
  circleId?: string
) => {
  return (
    '?' +
    qs.stringify(
      {
        filter: { company: companyId, kind: kind, 'company.circleId': circleId },
        limit: 100,
      },
      { encode: false, skipNulls: true }
    )
  );
};
