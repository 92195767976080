import { List } from 'immutable';
import { Resource } from 'model/Resource';
import { StaticResource } from 'model/StaticResource';

export const STORE_NAME = 'staticResource';

export namespace StaticResourceState {
  export type Domain = {
    listResource: Resource<List<StaticResource>>;
  };

  export const INITIAL_DOMAIN: Domain = {
    listResource: Resource.void<List<StaticResource>>(),
  };
}
