import { createAsyncThunk } from '@reduxjs/toolkit';
import { deliveryStatisticRepository } from 'container/deliveryStatisticRepository';
import { selectEmailDistributionId } from 'reduxStore/emailDistribution/selectors';
import { serializeError } from 'shared/utils/redux';

import { STORE_NAME } from './initialState';

export type FetchDeliveryStatisticProps = { emailDistributionId: string; daysLimit?: number };
export const fetchDeliveryStatistic = createAsyncThunk(
  `${STORE_NAME}/fetchDeliveryStatistic`,
  async ({ emailDistributionId, daysLimit }: FetchDeliveryStatisticProps) => {
    return deliveryStatisticRepository.fetch(emailDistributionId, daysLimit);
  },
  { serializeError }
);

export type RefreshDeliveryStatisticProps = { emailId: number; daysLimit: number };
export const refreshDeliveryStatistic = createAsyncThunk(
  `${STORE_NAME}/refreshDeliveryStatistic`,
  async ({ emailId, daysLimit }: RefreshDeliveryStatisticProps, { dispatch, getState }) => {
    const emailDistributionId = selectEmailDistributionId(getState());

    const jobId = await deliveryStatisticRepository.refresh(emailDistributionId, emailId);
    dispatch(fetchDeliveryStatistic({ emailDistributionId, daysLimit }));
    return jobId;
  },
  { serializeError }
);
