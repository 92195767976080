import { List } from 'immutable';
import { ProjectSubmit } from 'model/ProjectSubmit';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'projectSubmit';

export namespace ProjectSubmitState {
  export type Domain = {
    listResource?: Resource<List<ProjectSubmit>>;
    projectId: number | null;
  };

  export const INITIAL_DOMAIN: Domain = {
    listResource: Resource.void(),
    projectId: null,
  };
}
