import { companyApi } from 'container/companyApi';
import { companyMapper } from 'container/companyMapper';
import { paginationMapper } from 'container/paginationMapper';
import { projectApi } from 'container/projectApi';
import { projectMapper } from 'container/projectMapper';
import { ProjectRepository } from 'repository/ProjectRepository';

export const projectRepository: ProjectRepository = new ProjectRepository(
  projectApi,
  projectMapper,
  paginationMapper,
  companyMapper,
  companyApi
);
