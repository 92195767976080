import React from 'react';
import ReactDropzone, { DropzoneProps } from 'react-dropzone';

import {
  dropzone,
  dropzoneActiveStyle,
  dropzoneDisabledStyle,
  dropzoneEmptyStyle,
  dropzoneErrorStyle,
  dropzoneFilledStyle,
  dropzoneRejectedStyle,
  ErrorWrapper,
  Label,
} from 'component/Dropzone/Dropzone.s';
import { DropzoneDefaultMessage } from 'component/Dropzone/DropzoneDefaultMessage';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import { WrappedFieldProps } from 'redux-form';
import { GroupDepartmentsErrors } from 'view/DepartmentsPage/AddDepartments/GroupDepartmentsErrors';

export { DropzoneMessage } from 'component/Dropzone/Dropzone.s';

type ChildrenFunction = (files: File[], isHidden?: boolean) => JSX.Element;

export namespace Dropzone {
  export type Props = Partial<DropzoneProps> & {
    label?: string;
    children?: React.ReactNode | ChildrenFunction;
    'data-testid'?: string;
  };
}

const isChildrenFunction = (children: Dropzone.Props['children']): children is ChildrenFunction => {
  return isFunction(children);
};

export const Dropzone: React.VFC<Dropzone.Props & WrappedFieldProps> = ({
  label,
  input,
  meta,
  children = null,
  'data-testid': dataTestId,
  ...props
}) => (
  <div data-testid="dropzoneWrapper">
    {label && <Label>{label}</Label>}
    <ReactDropzone
      {...(props as DropzoneProps)}
      style={{
        ...(input.value ? dropzoneFilledStyle : dropzoneEmptyStyle),
        ...(meta.error ? dropzoneErrorStyle : {}),
        ...(props.style || {}),
      }}
      inputProps={{
        // @ts-expect-error
        'data-testid': dataTestId,
        ...props.inputProps,
      }}
      activeStyle={{ ...dropzoneActiveStyle, ...(props.activeStyle || {}) }}
      rejectStyle={{ ...dropzoneRejectedStyle, ...(props.rejectStyle || {}) }}
      disabledStyle={{ ...dropzoneDisabledStyle, ...(props.disabledStyle || {}) }}
      onDropAccepted={(files: File[]) => input.onChange(props.multiple ? files : files[0])}
      className={dropzone() + ' ' + (props.className || '')}
    >
      {isChildrenFunction(children)
        ? children(
            input.value ? (!isArray(input.value) ? [input.value] : input.value) : [],
            Array.isArray(meta.error)
          )
        : children}

      {Array.isArray(meta.error) ? (
        <GroupDepartmentsErrors errors={meta.error} />
      ) : (
        meta.error && <ErrorWrapper>{meta.error}</ErrorWrapper>
      )}
    </ReactDropzone>
  </div>
);

Dropzone.defaultProps = {
  children: (files: File[], isHidden: boolean) => (
    <DropzoneDefaultMessage files={files} isHidden={isHidden} />
  ),
};
