import { selectApiData, selectApiNoContent } from 'util/selector/apiSelector';

import { ApiMessage } from 'api/const';
import { WithData } from 'api/interfaces';
import { AxiosInstance } from 'axios';

export namespace PermissionsApi {
  export type Entry = {
    primary_feature: PermissionEntry[];
    feature: PermissionEntry[];
    comment_demographic: PermissionEntry[];
    comment_department: PermissionEntry[];
    summary_demographic: PermissionEntry[];
    summary_department: PermissionEntry[];
    access: PermissionEntry[];
  };
  export type PermissionGroupEntry = {
    id: string;
    name: string;
    objectIdentifier: string;
    flags: PermissionFlagEntry[];
    permissions: PermissionEntry[];
  };
  export type PermissionGroupPostEntry = {
    id?: string;
    name?: string;
    permissions?: string[];
  };

  export type PermissionFlagEntry = {
    label: string;
    permissionId?: string;
  };

  export type PermissionEntry = {
    id?: string;
    name: string;
    hint?: string;
    clientAdminBlocked?: boolean;
    wbAdminBlocked?: boolean;
    children?: PermissionEntry[];
  };
}

export class PermissionsApi {
  constructor(private client: AxiosInstance) {}

  get(projectId: number): Promise<PermissionsApi.Entry> {
    return this.client
      .get<WithData<PermissionsApi.Entry>>(`/api/v1/project/${projectId}/permissions`)
      .then(selectApiData);
  }

  getPermissionGroups(projectId: number): Promise<PermissionsApi.PermissionGroupEntry[]> {
    return this.client.get(`/api/v1/project/${projectId}/permission-groups`).then(selectApiData);
  }

  addPermissionGroup(
    projectId: number,
    data: PermissionsApi.PermissionGroupPostEntry
  ): Promise<PermissionsApi.PermissionGroupEntry> {
    return this.client
      .post<WithData<PermissionsApi.PermissionGroupEntry>>(
        `/api/v1/project/${projectId}/permission-groups`,
        data
      )
      .then(selectApiData);
  }

  editPermissionGroup(
    projectId: number,
    permissionGroup: PermissionsApi.PermissionGroupPostEntry
  ): Promise<PermissionsApi.PermissionGroupEntry> {
    return this.client
      .post<WithData<PermissionsApi.PermissionGroupEntry>>(
        `/api/v1/project/${projectId}/permission-groups/${permissionGroup.id}`,
        permissionGroup
      )
      .then(selectApiData);
  }

  deletePermissionGroup(projectId: number, permissionGroupId: string): Promise<void> {
    return this.client
      .delete<ApiMessage.Deleted>(
        `/api/v1/project/${projectId}/permission-groups/${permissionGroupId}`
      )
      .then(selectApiNoContent);
  }

  getFlags(): Promise<PermissionsApi.PermissionFlagEntry[]> {
    return this.client
      .get<WithData<PermissionsApi.PermissionFlagEntry[]>>(`/api/v1/permission-flags`)
      .then(selectApiData);
  }
}
