import { Resource } from 'model/Resource';
import { Circle } from 'model/Circle';
import { List } from 'immutable';

export const STORE_NAME = 'circles';

export namespace CirclesState {
  export type Domain = {
    list: Resource<List<Circle>>;
  };

  export const INITIAL_DOMAIN: Domain = {
    list: Resource.void(),
  };
}

export type CirclesState = {
  circles?: CirclesState.Domain;
};
