import { createUnknownException } from 'exception/createException';
import { UNKNOWN_ERROR, VALIDATION_ERROR } from 'exception/EmployeeCodeExpections';
import { Exception } from 'exception/Exception';
import { ValidationErrorException } from 'exception/ValidationErrorException';

type SelectAppException = <T extends Exception>(
  error:
    | {
        code: string;
        message: string;
        [key: string]: any;
      }
    | string
) => T | Exception;
export const selectAppException: SelectAppException = (reason) => {
  if (typeof reason === 'string') {
    return new Exception({
      type: 'API_ERROR',
      message: reason,
      code: 'UNKNOWN_ERROR',
    });
  }

  const { code, message, ...args } = reason;

  switch (code) {
    case UNKNOWN_ERROR:
      return createUnknownException();
    case VALIDATION_ERROR:
      return new ValidationErrorException(message, args);
    default:
      return new Exception({
        type: 'API_ERROR',
        message,
        code,
      });
  }
};
