import { createAsyncThunk } from '@reduxjs/toolkit';
import { trendDataRepository } from 'container/trendDataRepository';
import { serializeError } from 'shared/utils/redux';

import { STORE_NAME } from './initialState';

export const fetchTrendDataList = createAsyncThunk(
  `${STORE_NAME}/fetchTrendDataList`,
  async ({ projectId }: { projectId: number }) => {
    return trendDataRepository.list(projectId);
  },
  { serializeError }
);

export const deleteTrend = createAsyncThunk(
  `${STORE_NAME}/deleteTrend`,
  async ({ id }: { id: number }) => {
    return trendDataRepository.delete(id).then(() => id);
  },
  { serializeError }
);

export const uploadTrendData = createAsyncThunk(
  `${STORE_NAME}/uploadTrendData`,
  async ({ projectId, file }: { projectId: number; file: File }, { rejectWithValue }) => {
    return trendDataRepository.upload(file, projectId).catch(rejectWithValue);
  },
  { serializeError }
);
