import { List } from 'immutable';
import { Placeholder } from 'model/Placeholder';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'placeholders';

export namespace PlaceholderState {
  export type Domain = {
    listResource: Resource<List<Placeholder>>;
  };

  export const INITIAL_DOMAIN: Domain = {
    listResource: Resource.void<List<Placeholder>>(),
  };
}
