import { ClientRoute } from 'app/route/client';
import { Breadcrumbs } from 'component/Breadcrumbs/Breadcrumbs';
import { List } from 'immutable';
import { Company } from 'model/Company';
import { Distribution } from 'model/Distribution';
import { Job } from 'model/Job';
import { LifecycleType } from 'model/Lifecycle';
import { ProjectImage } from 'model/ProjectImage';
import { ProjectKind } from 'model/ProjectKind';
import { ProjectLayout } from 'model/ProjectLayout';
import { ProjectSettings } from 'model/ProjectSettings';
import { ProjectSortBy } from 'model/ProjectSortBy';
import { ProjectStatus } from 'model/ProjectStatus';
import { ProjectTemplate } from 'model/ProjectTemplate';
import { QuestionOpinion } from 'model/QuestionOpinion';
import { Moment } from 'moment';
import { compilePath } from 'router/compilePath';

export namespace Project {
  export interface Shape extends ProjectTemplate.Shape {
    status: ProjectStatus;
    company?: Company;
    liveStart: Moment;
    liveStop: Moment;
    pulseFrequencyId?: string | null;
    responseRate: number;
    responsePartial?: number;
    responseStarted?: number;
    responseCompleted?: number;
    reportRecipients?: List<string>;
    jobs?: List<Job>;
    layout?: ProjectLayout;
    settings?: ProjectSettings;
    distributions?: List<Distribution>;
    dirty?: boolean;
    isPublished?: boolean;
    isKioskDistributionEmpty?: boolean;
    createdInPast?: boolean;
    lifecycleType?: LifecycleType;
  }
}

export class Project extends ProjectTemplate<Project.Shape> {
  static readonly STATUSES: ProjectStatus[] = [
    ProjectStatus.InDevelopment,
    ProjectStatus.Live,
    ProjectStatus.Closed,
    ProjectStatus.Deleted,
  ];

  static readonly SURVEY_TYPE: ProjectKind[] = [
    ProjectKind.PROJECT_CLIENT_ADVOCACY_SCORE,
    ProjectKind.PROJECT_ENGAGEMENT_SCORE,
    ProjectKind.PROJECT_OVERALL_SCORE,
  ];

  static readonly SORT_BYS: ProjectSortBy[] = [ProjectSortBy.LastModified, ProjectSortBy.LiveDates];

  static readonly IMAGE_ACCEPT: ProjectImage[] = [
    ProjectImage.PNG,
    ProjectImage.JPG,
    ProjectImage.JPEG,
  ];

  static getAcceptedImagesTypes(): string {
    return Project.IMAGE_ACCEPT.toString();
  }

  static getFixableOnly(projects: List<Project>): List<Project> {
    return projects.filter((project) => project.isFixableOnly()).toList();
  }

  static sortByLiveStop(a: Project, b: Project): number {
    return b.getLiveStop().unix() - a.getLiveStop().unix();
  }

  static isEngagementScoreRelated(kind: ProjectKind): boolean {
    switch (kind) {
      case ProjectKind.PROJECT_ENGAGEMENT_SCORE:
      case ProjectKind.PULSE_PROJECT:
      case ProjectKind.PULSE_TEMPLATE:
        return true;
      default:
        return false;
    }
  }

  static isPulseTypeProject(kind: ProjectKind): boolean {
    switch (kind) {
      case ProjectKind.PULSE_PROJECT:
      case ProjectKind.PULSE_TEMPLATE:
      case ProjectKind.PULSE_ON_DEMAND_PROJECT:
      case ProjectKind.PULSE_LIFECYCLE_PROJECT:
        return true;
      default:
        return false;
    }
  }

  static lastPublishedProjectId(projects: List<Project>): number | undefined {
    if (!projects || !(projects.size > 0)) {
      return undefined;
    }

    const last = projects
      .sort((a, b) => b.getId() - a.getId())
      .find((project) => project && project.isPublished());
    return last && last.getId();
  }

  getId(): number {
    return this.get('id');
  }

  getStatus(): ProjectStatus {
    return this.get('status', ProjectStatus.InDevelopment);
  }

  getCompany(): Company {
    return this.get('company');
  }

  hasCompany(): boolean {
    return this.has('company');
  }

  setStatus(status: ProjectStatus): this {
    return this.set('status', status);
  }

  isInDevelopment(): boolean {
    return this.getStatus() === ProjectStatus.InDevelopment;
  }

  isKioskDistributionEmpty(): boolean {
    return this.get('isKioskDistributionEmpty');
  }

  isLive(): boolean {
    return this.getStatus() === ProjectStatus.Live;
  }

  isClosed(): boolean {
    return this.getStatus() === ProjectStatus.Closed;
  }

  isDeleted(): boolean {
    return this.getStatus() === ProjectStatus.Deleted;
  }

  isEditable(): boolean {
    return this.isInDevelopment() || this.isLifecycleProject();
  }

  /**
   * We can only fix some properties, not fully edit.
   * @returns {boolean}
   */
  isFixableOnly(): boolean {
    return this.isLive() || this.isClosed();
  }

  isCloseable(): boolean {
    return this.isInDevelopment() || this.isLive();
  }

  isDeletable(): boolean {
    return this.isInDevelopment() || this.isClosed();
  }

  isDemandPoll(): boolean {
    return this.getKind() === ProjectKind.PULSE_ON_DEMAND_PROJECT;
  }

  isLifecycleProject(): boolean {
    return this.getKind() === ProjectKind.PULSE_LIFECYCLE_PROJECT;
  }

  getLiveStart(): Moment {
    return this.get('liveStart');
  }

  getLiveStop(): Moment {
    return this.get('liveStop');
  }

  getPulseFrequencyId(): string | null {
    return this.get('pulseFrequencyId');
  }

  getLifecycleType(): LifecycleType | null {
    return this.get('lifecycleType');
  }

  getReportRecipients(): List<string> {
    return this.get('reportRecipients');
  }

  addReportRecipient(recipient: string): this {
    return this.update('reportRecipients', (recipients) => recipients.push(recipient));
  }

  updateReportRecipient(recipient: string, index: number): this {
    return this.update('reportRecipients', (recipients) => recipients.set(index, recipient));
  }

  deleteReportRecipient(index: number): this {
    return this.update('reportRecipients', (recipients) => recipients.delete(index));
  }

  getResponseRate(): number {
    return this.get('responseRate');
  }

  getResponsePartial(): number {
    return this.get('responsePartial', 0);
  }

  getResponseStarted(): number {
    return this.get('responseStarted', 0);
  }

  getResponseCompleted(): number {
    return this.get('responseCompleted', 0);
  }

  getLayout(): ProjectLayout {
    return this.get('layout');
  }

  getSettings(): ProjectSettings {
    return this.get('settings');
  }

  setSettings(settings: ProjectSettings): this {
    return this.set('settings', settings);
  }

  getDistributions(): List<Distribution> {
    return this.get('distributions');
  }

  getJobs(): List<Job> {
    return this.get('jobs');
  }

  isDirty(): boolean {
    return this.get('dirty');
  }

  setIsDirty(isDirty: boolean): this {
    return this.set('dirty', isDirty);
  }

  isPublished(): boolean {
    return this.get('isPublished');
  }

  hasStartAndStopDate(): boolean {
    return Boolean(this.getLiveStop() && this.getLiveStart());
  }

  isCreatedInPast(): boolean {
    return this.get('createdInPast');
  }

  getQuestionLibrary(): List<QuestionOpinion> | null {
    if (this.getKind() !== ProjectKind.PULSE_TEMPLATE) {
      return null;
    }

    return (
      this.getPages().first() &&
      (this.getPages()
        .first()
        .getItems()
        .filter(QuestionOpinion.isQuestionOpinion)
        .toList() as List<QuestionOpinion>)
    );
  }

  getBreadcrumbsPath(pulseTemplateId: Project.Shape['id']): Breadcrumbs.PathItem[] {
    // TODO: This code is up for refactor when adding new lifecycle types.
    const lifecycleType = this.getLifecycleType();

    if (lifecycleType) {
      const lifecycleUrl = compilePath(ClientRoute.Project.Survey.Develop.QUESTION_PATTERN, {
        id: pulseTemplateId,
        survey_id: this.getId(),
      });
      return [
        {
          label: 'Employee Lifecycle Surveys',
        },
        {
          label: lifecycleType,
          href: lifecycleUrl,
        },
      ];
    } else {
      const liveAndScheduled = [ProjectStatus.InDevelopment, ProjectStatus.Live];
      const labelPrefix = liveAndScheduled.includes(this.getStatus())
        ? 'Live and Scheduled'
        : 'Archived';

      const labelSuffix = this.isDemandPoll() ? 'Polls' : 'Surveys';

      let url;

      if (this.isDemandPoll()) {
        url = liveAndScheduled.includes(this.getStatus())
          ? ClientRoute.Project.DemandPoll.LIVE_AND_SCHEDULED
          : ClientRoute.Project.DemandPoll.ARCHIVED;
      } else {
        url = liveAndScheduled.includes(this.getStatus())
          ? ClientRoute.Project.Survey.LIVE_AND_SCHEDULED
          : ClientRoute.Project.Survey.ARCHIVED;
      }

      return [
        {
          label: `${labelPrefix} ${labelSuffix}`,
          href: compilePath(url, { id: pulseTemplateId }),
        },
        {
          label: this.getName(),
          href: compilePath(ClientRoute.Project.Survey.Develop.QUESTION_PATTERN, {
            id: pulseTemplateId,
            survey_id: this.getId(),
          }),
        },
      ];
    }
  }
}
