import { AvailableQuestionOption } from 'model/AvailableQuestionOption';
import { SimpleModel } from 'model/SimpleModel';

export namespace CustomQuestionOption {
  export interface Shape {
    id?: number;
    option: string;
    position: number;
  }
}

export class CustomQuestionOption
  extends SimpleModel<CustomQuestionOption.Shape>
  implements AvailableQuestionOption {
  getId(): number {
    return this.get('id');
  }

  getOption(): string {
    return this.get('option');
  }

  getValue(): number {
    return this.get('id');
  }

  getPosition(): number {
    return this.get('position');
  }
}
