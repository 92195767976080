import React from 'react';
import { RGBColor } from 'react-color';
import { connect } from 'react-redux';

import { projectRepository } from 'container/projectRepository';
import { orange } from '@mui/material/colors';
import { AdminRoute } from 'app/route/admin';
import { CreateProjectExistingCompany } from 'component/CreateProject/CreateProjectExistingCompany';
import { CreateProjectNewCompany } from 'component/CreateProject/CreateProjectNewCompany';
import { AutocompleteData } from 'component/Form/Autocomplete';
import { List } from 'immutable';
import { Company } from 'model/Company';
import { Enum } from 'model/Enum';
import { ProjectKind } from 'model/ProjectKind';
import { ProjectType } from 'model/ProjectType';
import { Resource } from 'model/Resource';
import { Timezone } from 'model/Timezone';
import { compose } from 'redux';
import { formValueSelector, reduxForm, InjectedFormProps } from 'redux-form';
import { AdminDispatch } from 'reduxStore/appStore';
import { selectCompanyListResource } from 'reduxStore/company/selectors';
import {
  selectBenchmarkSectors,
  selectEmployeeRangeGroup,
  selectTimezoneAutocompleteOptions,
} from 'reduxStore/configs/selectors';
import { openSnackbar } from 'reduxStore/snackbars/asyncActions';
import { AdminFormNames } from 'register/AdminFormNames';
import { pushRoute } from 'router/routerAction';
import tinycolor from 'tinycolor2';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { Circle } from 'model/Circle';
import { selectCircles, selectCirclesResource } from 'reduxStore/circles/selectors';

export namespace CreateProjectForm {
  export type StateProps = {
    projectType: number;
    listResource: Resource<List<Company>>;
    benchmarkSectors: List<Enum>;
    circlesResource: Resource<List<Circle>>;
    employeeRangeGroup: List<Enum>;
    timezones: AutocompleteData<string>[];
    isEngagementScoreProjectType: boolean;
    onSubmit: (data: FormData, dispatch: AdminDispatch, props: Props) => void;
    initialValues: FormData;
  };
  export type DispatchProps = {};
  export type OwnProps = {
    onSubmitSuccess?: () => void;
  };
  export type FormData = {
    circle?: string;
    companyName?: string;
    companyLogo?: File;
    company?: Company;
    brandColor?: RGBColor;
    timezone: string;
    benchmarkSector?: number;
    approxNumberEmployee?: number;
    kind?: ProjectKind;
  };
  export type Props = OwnProps & InjectedFormProps<FormData, OwnProps> & StateProps;
}

export const CreateProjectFormPure: React.VFC<CreateProjectForm.Props> = (props) => (
  <ResourceLoader
    resource={[props.circlesResource, props.listResource]}
    content={([circles, companies]: [List<Circle>, List<Company>]) => {
      return (
        <form onSubmit={props.handleSubmit}>
          {props.projectType === ProjectType.NewCompany ? (
            <CreateProjectNewCompany
              benchmarkSectors={props.benchmarkSectors}
              circles={circles}
              employeeRangeGroup={props.employeeRangeGroup}
              timezones={props.timezones}
              isEngagementScoreProjectType={props.isEngagementScoreProjectType}
            />
          ) : (
            <CreateProjectExistingCompany circles={circles} companies={companies} />
          )}
        </form>
      );
    }}
  ></ResourceLoader>
);

export const CreateProjectForm = compose(
  connect(
    (state): CreateProjectForm.StateProps => {
      const type = Number(formValueSelector(AdminFormNames.CreateProjectType)(state, 'type'));
      const kind = formValueSelector(AdminFormNames.CreateProject)(state, 'kind');
      const timezones = selectTimezoneAutocompleteOptions(state);
      const initialTimezone = timezones.find(({ value }) => value === Timezone.DEFAULT_TIMEZONE);
      const listResource = selectCompanyListResource(state);

      return {
        listResource,
        benchmarkSectors: selectBenchmarkSectors(state),
        circlesResource: selectCirclesResource(state),
        employeeRangeGroup: selectEmployeeRangeGroup(state),
        timezones: timezones,
        isEngagementScoreProjectType: kind === ProjectKind.PROJECT_ENGAGEMENT_SCORE,
        projectType: type,
        initialValues: {
          brandColor: tinycolor(orange[600]).toRgb(),
          timezone: initialTimezone && initialTimezone.value,
        },
        onSubmit: (data: CreateProjectForm.FormData, dispatch: AdminDispatch) => {
          const model = {
            company:
              type === ProjectType.ExistingCompany
                ? data.company
                : new Company({
                    name: data.companyName,
                    brandColor: data.brandColor,
                    circleId: data.circle || selectCircles(state).first().getId(),
                    timezone: data.timezone,
                    approxEmployeeNumber: data.approxNumberEmployee,
                    sectors: List.of(data.benchmarkSector),
                    settings: {
                      ssoEnabled: false,
                      multiLanguageEnabled: false,
                      enabledLocales: [],
                      enabledDashboardLocales: [],
                      customKioskCodesEnabled: false,
                    },
                  }),
            kind: data.kind,
          };
          return projectRepository.create(model, { logo: data.companyLogo }).then((project) => {
            dispatch(openSnackbar({ message: 'Project created successfully.' }));
            dispatch(pushRoute(AdminRoute.Project.Survey.SETTINGS, { id: project.getId() }));
          });
        },
      };
    }
  ),
  reduxForm({
    form: AdminFormNames.CreateProject,
  })
)(CreateProjectFormPure);
