import { createAsyncThunk } from '@reduxjs/toolkit';
import { authRepository } from 'container/authRepository';
import { AdminThunk } from 'reduxStore/appStore';
import {
  redirectToClientsList,
  redirectToLogin,
  redirectToProjectsList,
} from 'reduxStore/auth/utils';
import { fetchCurrentPulseProject } from 'reduxStore/project/pulseProject/actions';
import { serializeError } from 'shared/utils/redux';
import { AuthApi } from 'api/AuthApi';

import { STORE_NAME } from './initialState';

export const fetchLoggedUser = createAsyncThunk(
  `${STORE_NAME}/fetchLoggedUser`,
  () => {
    return authRepository.logged();
  },
  { serializeError }
);

export const fetchAndProcessLoggedUser = createAsyncThunk(
  `${STORE_NAME}/fetchAndProcessLoggedUser`,
  (_, { dispatch }) => {
    dispatch(fetchLoggedUser())
      .unwrap()
      .then((loggedUser) => {
        if (loggedUser.isClientAdmin()) {
          dispatch(fetchCurrentPulseProject());
        } else if (loggedUser.isWbAdmin() || loggedUser.isCircleAdmin()) {
          redirectToClientsList();
        } else {
          redirectToProjectsList();
        }
      })
      .catch(() => redirectToLogin());
  },
  { serializeError }
);

export const resetPasswordRequest = ({
  email,
  currentCircle,
}: AuthApi.ResetPasswordRequestParams): AdminThunk<Promise<unknown>> => () =>
  authRepository.resetPasswordRequest({ email, currentCircle });

export const resetMfaRequest = ({ email }: { email: string }): AdminThunk<Promise<unknown>> => () =>
  authRepository.resetMfaRequest({ email });

export const logout = (): AdminThunk<Promise<void>> => () =>
  authRepository.logout().then(() => redirectToLogin());
