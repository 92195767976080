import { List } from 'immutable';
import { FailedEmails } from 'model/FailedEmails';
import { SimpleModel } from 'model/SimpleModel';

export namespace DeliveryStatistic {
  export interface Shape {
    emailTemplateId: number;
    emailTemplateJobId: number;
    delivered: List<string>;
    failed: List<FailedEmails>;
    enqueued: List<string>;
  }
}

export class DeliveryStatistic extends SimpleModel<DeliveryStatistic.Shape> {
  getEmailTemplateId(): number {
    return this.get('emailTemplateId');
  }

  getEmailTemplateJobId(): number {
    return this.get('emailTemplateJobId');
  }

  getDelivered(): List<string> {
    return this.get('delivered');
  }

  getFailed(): List<FailedEmails> {
    return this.get('failed');
  }

  getEnqueued(): List<string> {
    return this.get('enqueued');
  }
}
