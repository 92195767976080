import { isArrayEmpty } from 'util/isArrayEmpty';
import { createResourceContentSelector } from 'util/selector/resourceSelector';

import { createSelector } from '@reduxjs/toolkit';
import { Job } from 'model/Job';
import { JobExtended } from 'model/JobExtended';
import { JobName } from 'model/JobName';
import { Resource } from 'model/Resource';
import { AdminState } from 'reduxStore/appStore';

import { JobState, STORE_NAME } from './initialState';

export const selectJobDomain = (state: AdminState) =>
  state?.[STORE_NAME] ?? JobState.INITIAL_DOMAIN;

export const selectProjectJobsResource = createSelector(
  selectJobDomain,
  (domain) => domain.projectJobs.data
);

const selectProjectJobs = createResourceContentSelector(selectProjectJobsResource);

export const selectHasJobs = createSelector(
  selectProjectJobs,
  (jobs) => jobs && !isArrayEmpty(jobs)
);

export const selectProcessEmployeeDatabaseJobResource = createSelector(
  selectProjectJobsResource,
  (jobsResource) =>
    Resource.combine(jobsResource).setContent(
      jobsResource
        .getContent([])
        .sort(Job.sortByExecutionTime)
        .find(Job.isProcessEmployeeDatabaseJob)
    ) as Resource<Job>
);

export const selectProcessEmployeeDatabaseJob = createResourceContentSelector(
  selectProcessEmployeeDatabaseJobResource
);

export const selectProcessEmployeeDatabaseIsSuccessful = createSelector(
  selectProcessEmployeeDatabaseJob,
  (job) => job && job.getExtendedJob().isSuccessful()
);

export const selectProcessEmployeeDatabaseJobIsVisited = createSelector(
  selectProcessEmployeeDatabaseJob,
  (job) => job && job.isVisited()
);

export const selectProcessEmployeeDatabaseJobIsNotVisited = createSelector(
  selectProcessEmployeeDatabaseJob,
  (job) => job && !job.isVisited()
);

export const selectCommitEmployeeDatabaseJobResource = createSelector(
  selectProjectJobsResource,
  (jobsResource) =>
    Resource.combine(jobsResource).setContent(
      jobsResource
        .getContent([])
        .sort(Job.sortByExecutionTime)
        .find(Job.isCommitEmployeeDatabaseJob)
    ) as Resource<Job>
);

export const selectCommitEmployeeDatabaseJob = createResourceContentSelector(
  selectCommitEmployeeDatabaseJobResource
);

export const selectCommitEmployeeDatabaseJobId = createSelector(
  selectCommitEmployeeDatabaseJob,
  (job) => job && job.getExtendedJob().getId()
);

export const selectCommitEmployeeDatabaseIsSuccessful = createSelector(
  selectCommitEmployeeDatabaseJob,
  (job) => job && job.getExtendedJob().isSuccessful()
);

export const selectUploadUsersJobResource = createSelector(
  selectProjectJobsResource,
  (jobsResource) =>
    Resource.combine(jobsResource).setContent(
      jobsResource.getContent([]).sort(Job.sortByExecutionTime).find(Job.isUploadUsersJob)
    ) as Resource<Job>
);

export const selectUploadUsersJob = createResourceContentSelector(selectUploadUsersJobResource);

export const selectIsUploadUsersJobPending = createSelector(
  selectUploadUsersJob,
  (job) => job && job.getExtendedJob().isPending()
);

export const selectIsUploadUsersJobSuccess = createSelector(
  selectUploadUsersJob,
  (job) => job && job.getExtendedJob().isSuccessful()
);

export const selectUploadUserJobId = createSelector(
  selectUploadUsersJob,
  (job) => job && job.getExtendedJob().getId()
);

export const selectIsJobErrorVisited = createSelector(
  selectUploadUsersJob,
  (job) => job && job.isVisited()
);

export const selectProcessUploadEmployeeJobId = createSelector(
  selectProcessEmployeeDatabaseJob,
  (job) => {
    const extendedJob = job && job.getExtendedJob();

    return extendedJob && extendedJob.getId();
  }
);

const selectPublishProjectSubmitsJobResource = createSelector(
  selectProjectJobsResource,
  (jobsResource) =>
    Resource.combine(jobsResource).setContent(
      jobsResource
        .getContent([])
        .filter((job) => job.getExtendedJob().getName() === JobName.PublishProjectSubmits)
    ) as Resource<Job[]>
);

export const selectPublishProjectSubmitsIsPending = createSelector(
  createResourceContentSelector(selectPublishProjectSubmitsJobResource),
  (jobs: Job[]) => {
    const pendingJob = jobs && jobs.find((job) => job.getExtendedJob().isPending());

    return pendingJob && pendingJob.getExtendedJob().isPending();
  }
);

export const selectLastFailPublishJob = createSelector(
  selectPublishProjectSubmitsJobResource,
  (jobResource) => {
    return jobResource.getContent([]).sort(Job.sortByExecutionTime).find(Job.isFailedJob);
  }
);

export const selectEmailDistributionJobsResource = createSelector(
  selectJobDomain,
  (domain) => domain.emailDistributionJobs.data
);
export const selectEmailDistributionCreateContactListJobResource = createSelector(
  selectEmailDistributionJobsResource,
  (jobsResource) =>
    Resource.combine(jobsResource).setContent(
      jobsResource.getContent([]).find(Job.isCreateContactListJob)
    ) as Resource<JobExtended>
);
export const selectEmailDistributionCreateContactListJobIsSuccessful = createSelector(
  createResourceContentSelector(selectEmailDistributionCreateContactListJobResource),
  (job: JobExtended<string>) => job && job.isSuccessful()
);

export const selectKioskDistributionJobsResource = createSelector(
  selectJobDomain,
  (domain) => domain.kioskDistributionJobs.data
);
export const selectKioskDistributionCreateContactListJobResource = createSelector(
  selectKioskDistributionJobsResource,
  (jobsResource) =>
    Resource.combine(jobsResource).setContent(
      jobsResource.getContent([]).find(Job.isCreateContactListJob)
    ) as Resource<JobExtended>
);
export const selectKioskDistributionCreateContactListJobIsSuccessful = createSelector(
  createResourceContentSelector(selectKioskDistributionCreateContactListJobResource),
  (job: JobExtended<string>) => job && job.isSuccessful()
);
