import { List } from 'immutable';
import { Company } from 'model/Company';
import { Project } from 'model/Project';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'company';

export namespace CompanyState {
  type CompanyList = List<Company>;
  type CompanyProjectList = List<Project>;
  type CompanyListResource = Resource<CompanyList>;
  type CompanyProjectListResource = Resource<CompanyProjectList>;

  export type Domain = {
    listResource: CompanyListResource;
    projectListResource: CompanyProjectListResource;
  };

  export const INITIAL_DOMAIN: Domain = {
    listResource: Resource.void<CompanyList>(),
    projectListResource: Resource.void<CompanyProjectList>(),
  };
}
