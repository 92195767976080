import { PageApi } from 'api/PageApi';
import { List } from 'immutable';
import { PageItemMapper } from 'mapper/PageItemMapper';
import { Page } from 'model/Page';

export class PageMapper {
  constructor(private pageItemMapper: PageItemMapper) {}

  serialize(model: Page): PageApi.Entry {
    return {
      id: model.getId(),
      position: model.getPosition(),
    };
  }

  deserialize(entry: PageApi.Entry): Page {
    return new Page({
      id: entry.id,
      position: entry.position,
      items: List((entry.items || []).map((item) => this.pageItemMapper.deserialize(item)))
        .sortBy((item) => item.getPosition())
        .toList(),
    });
  }

  deserializeList(entries: PageApi.Entry[]): List<Page> {
    return List<Page>(
      (entries || []).filter((entry) => !!entry).map((entry) => this.deserialize(entry))
    )
      .sortBy((page) => page.getPosition())
      .toList();
  }
}
