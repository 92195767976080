import { selectApiData } from 'util/selector/apiSelector';

import { AxiosInstance } from 'axios';

export namespace ProjectLayoutApi {
  export type Entry = {
    questionTextColor: string;
    backgroundColor: string;
    navigationAndProgressColor: string;
    saveContinueColor: string;
  };
}

export class ProjectLayoutApi {
  constructor(private client: AxiosInstance) {}

  fetch(id: number): Promise<ProjectLayoutApi.Entry> {
    return this.client.get(`/api/v1/project/${id}/layout`).then(selectApiData);
  }

  save(id: number, data: ProjectLayoutApi.Entry): Promise<ProjectLayoutApi.Entry> {
    return this.client.post(`/api/v1/project/${id}/layout`, data).then(selectApiData);
  }
}
