import { CircleApi } from 'api/CirclesApi';
import { List } from 'immutable';
import { CircleMapper } from 'mapper/CircleMapper';
import { Circle } from 'model/Circle';

export class CircleRepository {
  constructor(private circleApi: CircleApi, private circleMapper: CircleMapper) {}

  fetch(): Promise<List<Circle>> {
    return this.circleApi
      .fetch()
      .then((entries) => List(entries.map((entry) => this.circleMapper.deserialize(entry))));
  }
}
