import { PermissionsApi } from 'api/PermissionsApi';
import { PermissionFlagMapper } from 'mapper/PermissionFlagMapper';
import { PermissionMapper } from 'mapper/PermissionMapper';

import { PermissionGroup } from '../model/PermissionGroup';

export class PermissionGroupMapper {
  constructor(
    private permissionMapper: PermissionMapper,
    private permissionFlagMapper: PermissionFlagMapper
  ) {}

  deserialize(entry: PermissionsApi.PermissionGroupEntry): PermissionGroup {
    return new PermissionGroup({
      id: entry.id,
      name: entry.name,
      objectIdentifier: entry.objectIdentifier,
      flags: this.permissionFlagMapper.deserializeList(entry.flags),
      permissions: entry.permissions
        ? entry.permissions.map((permission) => this.permissionMapper.deserialize(permission))
        : [],
    });
  }
}
