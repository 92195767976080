import qs from 'qs';

export const createEmployeeUploadQuery = (type: number) => {
  return (
    '?' +
    qs.stringify(
      {
        ['remove-orphans']: Boolean(Number(type)),
      },
      { encode: false, skipNulls: true }
    )
  );
};
