import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { List } from 'immutable';
import { Page } from 'model/Page';
import { PageItem } from 'model/PageItem';
import { Resource } from 'model/Resource';
import {
  createAndListPageItems,
  createPage,
  deletePageItem,
  fetchPageList,
  getTranslatedPageItem,
  listPageItems,
  moveDownPage,
  moveUpPage,
  updateAndListPageItem,
} from 'reduxStore/page/asyncActions';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';
import { addAsyncCases } from 'shared/utils/redux';

import { PageState, STORE_NAME } from './initialState';

export const pageSlice = createSlice({
  name: STORE_NAME,
  initialState: PageState.INITIAL_DOMAIN,
  reducers: {
    setCurrentItemByLanguage: (state, action: PayloadAction<PageItem>) => {
      state.currentItemByLanguage[action.payload.getLocale()] = action.payload;
    },
    clearCurrentItemByLanguage: (state) => {
      state.currentItemByLanguage = PageState.INITIAL_DOMAIN.currentItemByLanguage;
    },
    updateLanguageTab: (state, action: PayloadAction<LangCodeEnum>) => {
      state.languageTab = action.payload;
    },
    updateQuestionDetailsTab: (state, action: PayloadAction<number>) => {
      state.questionDetailsTab = action.payload;
    },
    clearPageList: (state) => {
      state.listResource = PageState.INITIAL_DOMAIN.listResource;
    },
    setCurrentItem: (state, action: PayloadAction<PageItem>) => {
      state.currentItem = action.payload;
    },
    reorderPageItemLocally: (
      state,
      action: PayloadAction<{ newSourcePage: Page; newDestinationPage: Page }>
    ) => {
      const { newSourcePage, newDestinationPage } = action.payload;
      const pageList = state.listResource.getContent();

      const updatedPageList = pageList
        .map((page) => {
          if (newDestinationPage.getId() === page.getId()) {
            return newDestinationPage;
          } else if (newSourcePage.getId() === page.getId()) {
            return newSourcePage;
          } else {
            return page;
          }
        })
        .toList();

      state.listResource = Resource.resolve(updatedPageList);
    },
  },
  extraReducers: (builder) => {
    addAsyncCases(builder, fetchPageList, (state, action) => {
      state.listResource = action.payload;
    });
    addAsyncCases(builder, createPage, (state, action) => {
      state.listResource = action.payload;
    });
    addAsyncCases(builder, moveUpPage, (state, action) => {
      state.listResource = action.payload;
    });
    addAsyncCases(builder, moveDownPage, (state, action) => {
      state.listResource = action.payload;
    });

    const listPageItemsReducer = (
      state: Draft<PageState.Domain>,
      action: PayloadAction<List<PageItem<PageItem.Shape>>, string, { arg: { pageId: number } }>
    ) => {
      const sortedPageItems = <List<PageItem>>(
        action.payload.sort((a: PageItem, b: PageItem) => a.getPosition() - b.getPosition())
      );
      state.listResource = getUpdatedListResourceByPageItems({
        listResource: state.listResource,
        pageItems: sortedPageItems,
        pageId: action.meta.arg.pageId,
      });
    };
    builder.addCase(listPageItems.fulfilled, listPageItemsReducer);
    builder.addCase(createAndListPageItems.fulfilled, listPageItemsReducer);
    builder.addCase(updateAndListPageItem.fulfilled, listPageItemsReducer);
    builder.addCase(deletePageItem.fulfilled, listPageItemsReducer);

    builder.addCase(getTranslatedPageItem.fulfilled, (state, action) => {
      state.currentItemByLanguage[action.meta.arg.language] = action.payload;
    });
    builder.addCase(getTranslatedPageItem.rejected, (state, action) => {
      state.currentItemByLanguage[action.meta.arg.language] = null;
    });
  },
});

const getUpdatedListResourceByPageItems = ({
  listResource,
  pageItems,
  pageId,
}: {
  listResource: Draft<Resource<List<Page>>>;
  pageItems: List<PageItem>;
  pageId: number;
}): Resource<List<Page>> => {
  const pageList = listResource.getContent();
  const updatedPageList = pageList.update(
    pageList.findIndex((page) => page.getId() === pageId),
    (page) => page.set('items', pageItems)
  );
  return Resource.resolve(updatedPageList);
};

export const {
  setCurrentItemByLanguage,
  clearCurrentItemByLanguage,
  clearPageList,
  setCurrentItem,
  reorderPageItemLocally,
  updateLanguageTab,
  updateQuestionDetailsTab,
} = pageSlice.actions;
