import { EditorState } from 'draft-js';
import memoize from 'lodash/memoize';
import { Validator } from 'redux-form';

export const required = memoize(
  (message: string, key?: string): Validator => (value) => {
    if (key) {
      return value === undefined || value[key] === null || value[key] === '' ? message : undefined;
    } else if (value instanceof EditorState) {
      const trimValue = value.getCurrentContent().getPlainText().trim();

      return trimValue === undefined || trimValue === null || trimValue === ''
        ? message
        : undefined;
    } else if (typeof value === 'string') {
      const trimValue = value.trim();
      return trimValue === undefined || trimValue === null || trimValue === ''
        ? message
        : undefined;
    }

    return value === undefined || value === null || value === '' ? message : undefined;
  }
);
