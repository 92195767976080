import { List } from 'immutable';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { demographicRepository } from 'container/demographicRepository';
import { Demographic } from 'model/Demographic';
import { serializeError } from 'shared/utils/redux';

import { STORE_NAME } from './initialState';

export const fetchDemographicList = createAsyncThunk(
  `${STORE_NAME}/fetchDemographicList`,
  ({ projectId }: { projectId: number }) => {
    return demographicRepository.list(projectId);
  },
  { serializeError }
);

export const fetchReportDemographicList = createAsyncThunk(
  `${STORE_NAME}/fetchReportDemographicList`,
  ({ projectId }: { projectId: number }) => {
    return demographicRepository.listReport(projectId);
  },
  { serializeError }
);

export const createDemographic = createAsyncThunk(
  `${STORE_NAME}/createDemographic`,
  ({ projectId, data }: { projectId: number; data: Demographic }) => {
    return demographicRepository.create(projectId, data);
  },
  { serializeError }
);

export const updateDemographic = createAsyncThunk(
  `${STORE_NAME}/updateDemographic`,
  ({ projectId, data }: { projectId: number; data: Demographic }) => {
    return demographicRepository.update(projectId, data);
  },
  { serializeError }
);

export const createDefaultDemographic = createAsyncThunk(
  `${STORE_NAME}/createDefaultDemographic`,
  ({ projectId, data }: { projectId: number; data: Demographic }) => {
    return demographicRepository.createDefault(projectId, data);
  },
  { serializeError }
);

export const deleteDemographic = createAsyncThunk(
  `${STORE_NAME}/deleteDemographic`,
  ({ projectId, demographicId }: { projectId: number; demographicId: number }) => {
    return demographicRepository.delete(projectId, demographicId).then(() => demographicId);
  },
  { serializeError }
);

export const moveDemographic = createAsyncThunk<
  void,
  {
    projectId: number;
    oldDemographicList: List<Demographic>;
    newDemographicList: List<Demographic>;
  }
>(
  `${STORE_NAME}/moveDemographic`,
  async ({ projectId, newDemographicList }) => {
    await demographicRepository.reorder(
      projectId,
      newDemographicList.map((item) => item.getId()).toList()
    );
  },
  { serializeError }
);
