import { createSlice } from '@reduxjs/toolkit';
import { Resource } from 'model/Resource';
import { ConfigsState, STORE_NAME } from 'reduxStore/configs/initialState';

import { fetchConstantsAndTimezones } from './asyncActions';

export const configsSlice = createSlice({
  name: STORE_NAME,
  initialState: ConfigsState.INITIAL_DOMAIN,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConstantsAndTimezones.pending, (state) => {
        state.constants = Resource.pending();
        state.timezones = Resource.pending();
      })
      .addCase(fetchConstantsAndTimezones.fulfilled, (state, action) => {
        state.constants = Resource.resolve(action.payload.constants);
        state.timezones = Resource.resolve(action.payload.timezones);
      })
      .addCase(fetchConstantsAndTimezones.rejected, (state, action) => {
        state.constants = Resource.reject(action.error);
        state.timezones = Resource.reject(action.error);
      });
  },
});
