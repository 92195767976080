import qs from 'qs';

export const createCompanyQuery = () => {
  return (
    '?' +
    qs.stringify(
      {
        limit: 1000,
      },
      { encode: false, skipNulls: true }
    )
  );
};
