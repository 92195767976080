import { LogicApi } from 'api/LogicApi';
import { Logic } from 'model/Logic';

export interface LogicTypeMapper<
  M extends Logic = Logic,
  E extends LogicApi.Entry = LogicApi.Entry
  // tslint:disable-next-line
> {
  serialize(model: M): E;
  deserialize(entry: E): M;
  supportsSerialize(model: M): boolean;
  supportsDeserialize(entry: E): boolean;
}

export class LogicMapper implements LogicTypeMapper<Logic, LogicApi.EntryAny> {
  constructor(private mappers: LogicTypeMapper[]) {}

  serialize(model: Logic): LogicApi.Entry {
    return this.findSerializer(model).serialize(model);
  }

  deserialize(entry: LogicApi.Entry): Logic {
    return this.findDeserializer(entry).deserialize(entry);
  }

  supportsSerialize(model: Logic): boolean {
    return this.mappers.some((mapper) => mapper.supportsSerialize(model));
  }

  supportsDeserialize(entry: LogicApi.Entry): boolean {
    return this.mappers.some((mapper) => mapper.supportsDeserialize(entry));
  }

  private findSerializer(model: Logic): LogicTypeMapper {
    const serializer = this.mappers.find((mapper) => mapper.supportsSerialize(model));

    if (!serializer) {
      throw new Error('Cannot find serializer for logic model: ' + JSON.stringify(model));
    }

    return serializer;
  }

  private findDeserializer(entry: LogicApi.Entry): LogicTypeMapper {
    const deserializer = this.mappers.find((mapper) => mapper.supportsDeserialize(entry));

    if (!deserializer) {
      throw new Error('Cannot find deserializer for logic entry: ' + JSON.stringify(entry));
    }

    return deserializer;
  }
}
