import { createAsyncThunk } from '@reduxjs/toolkit';
import { paperDistributionRepository } from 'container/paperDistributionRepository';
import { STORE_NAME } from 'reduxStore/paperDistribution/initialState';
import { selectCurrentProjectId } from 'reduxStore/project/projectSelector';
import { serializeError } from 'shared/utils/redux';

export const fetchPaperDistribution = createAsyncThunk(
  `${STORE_NAME}/fetchPaperDistribution`,
  (_, { getState }) => {
    const projectId = selectCurrentProjectId(getState());
    return paperDistributionRepository.get(projectId);
  },
  { serializeError }
);
