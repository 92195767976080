import { selectApiData } from 'util/selector/apiSelector';

import { WithData } from 'api/interfaces';
import { PulseProjectApi } from 'api/PulseProjectApi';
import { AxiosInstance } from 'axios';
import { Project } from 'model/Project';

export namespace DemandPollApi {
  export type Entry = PulseProjectApi.Entry;
}

export class DemandPollApi {
  constructor(private apiClient: AxiosInstance) {}

  create(id: number, project: Project): Promise<DemandPollApi.Entry> {
    return this.apiClient
      .post<WithData<DemandPollApi.Entry>>(`/api/v1/pulse-template/${id}/on-demand`, project)
      .then(selectApiData);
  }
}
