import { ContactList } from 'model/ContactList';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'contactList';

export namespace ContactListState {
  export type ContactListResource = Resource<ContactList>;
  export type Domain = {
    contactList?: ContactListResource;
  };

  export const INITIAL_DOMAIN: Domain = {
    contactList: Resource.void<ContactList>(),
  };
}
