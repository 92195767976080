import { List, Map } from 'immutable';
import { PageItem } from 'model/PageItem';
import { PageItemKind } from 'model/PageItemKind';
import { SimpleModel } from 'model/SimpleModel';
import { StaticResource } from 'model/StaticResource';

export namespace ImageWidget {
  export interface Shape extends PageItem.Shape {
    image: StaticResource;
    imageNew?: Map<'file', File>; // TODO: missing part - FormType class :(
    width?: number;
    height?: number;
    align?: string;
  }
}

export class ImageWidget extends PageItem<ImageWidget.Shape> {
  static search(images: List<ImageWidget>, search: string): List<ImageWidget> {
    return images.filter((image) => image.getName().includes(search)).toList();
  }

  constructor(initialData: SimpleModel.Data<ImageWidget.Shape>) {
    super(initialData, PageItemKind.ImageWidget);
  }

  getImage(): StaticResource {
    return this.get('image');
  }

  getImageNew(): Map<'file', File> {
    return this.get('imageNew');
  }

  getName(): string {
    return this.getImage() && this.getImage().getFileOriginalName();
  }

  getWidth(): number {
    return this.get('width');
  }

  getHeight(): number {
    return this.get('height');
  }

  getAlign(): string {
    return this.get('align');
  }
}
