import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectAdminApiUrl } from 'util/selector/apiSelector';

import MdEventNote from '@mui/icons-material/EventNote';
import FileDownload from '@mui/icons-material/FileUpload';
import { Box, Divider, Grid } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { RaisedButton, SecondaryButton } from 'component/Button/Button';
import { BaseTextField } from 'component/Form/BaseTextField';
import { Pagination } from 'component/Pagination/Pagination';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import qs from 'qs';
import { useAppDispatch } from 'reduxStore/appStore';
import { selectIsAdmin, selectIsWBAdmin } from 'reduxStore/auth/selectors';
import { selectCircles } from 'reduxStore/circles/selectors';
import { fetchClients } from 'reduxStore/client/asyncActions';
import { selectClientListResource, selectClientPagination } from 'reduxStore/client/selectors';
import { clearClientState } from 'reduxStore/client/slice';
import { openModal } from 'reduxStore/modal/slice';
import { AdminModal } from 'register/AdminModal';
import { openInNewTab } from 'router/routerAction';
import { usePaginationPage } from 'shared/hooks/usePaginationPage';
import { useSearchFilters } from 'shared/hooks/useSearchFilters';
import { ClientsTable } from 'view/ClientsPage/ClientsTable/ClientsTable';
import { CreateClientModal } from 'view/ClientsPage/CreateClient/CreateClientModal';
import { ClientListFilter, clientListFilterEmptyValue } from 'view/ClientsPage/model';
import { projectListFilterEmptyValue } from 'view/ProjectsListPage/model';
import { MenuTitle } from 'view/ProjectsListPage/ProjectsListPage.s';

export const ClientsPage: React.VFC = () => {
  const dispatch = useAppDispatch();

  const circles = useSelector(selectCircles);
  const hasSingleCircle = circles.size === 1;
  const singleCircleId = circles.first()?.getId();

  const isAdmin = useSelector(selectIsAdmin);
  const isWBAdmin = useSelector(selectIsWBAdmin);
  const clientsResource = useSelector(selectClientListResource);
  const [exportSurveyDatesUrl, setExportSurveyDatesUrl] = useState<string>();

  const clientPagination = useSelector(selectClientPagination);
  const totalPages = clientPagination?.getPages();
  const totalNumber = clientPagination?.getTotal();

  const { page, limit, setPaginationPage, resetPaginationPage } = usePaginationPage({
    initialValue: { page: 1, limit: 10 },
    paginationResult: {
      pages: totalPages,
      total: totalNumber,
    },
  });
  const { filters, debouncedFilters, mergeFilters } = useSearchFilters<ClientListFilter>(
    { searchText: '', circleId: clientListFilterEmptyValue },
    { resetPaginationPage }
  );

  useEffect(() => {
    const circleIdFilterValue =
      filters.circleId === projectListFilterEmptyValue ? undefined : filters.circleId;
    const circleId = hasSingleCircle ? singleCircleId : circleIdFilterValue;

    setExportSurveyDatesUrl(
      `${selectAdminApiUrl()}/api/v1/company/survey-dates-report${qs.stringify(
        { filter: { circleId } },
        { encode: false, skipNulls: true, addQueryPrefix: true }
      )}`
    );

    dispatch(fetchClients({ searchText: debouncedFilters.searchText, page, limit, circleId }));
  }, [
    debouncedFilters.searchText,
    dispatch,
    filters.circleId,
    hasSingleCircle,
    limit,
    page,
    singleCircleId,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearClientState());
    };
  }, [dispatch]);

  const onCreateClient = () => dispatch(openModal({ name: AdminModal.CreateClient, params: {} }));

  const onViewClick = (url: string) => {
    return dispatch(openInNewTab(url));
  };

  return (
    <>
      <Grid container columnSpacing={2} mb={3} alignItems="end" justifyContent="space-between">
        <Grid item xs={12} sm={3}>
          <RaisedButton
            id="createClientButton"
            label="Create Client"
            endIcon={<MdEventNote />}
            onClick={onCreateClient}
            fullWidth
            data-testid="createClientButton"
            color="primary"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {isAdmin && (
            <SecondaryButton
              label="Export Survey Dates"
              endIcon={<FileDownload />}
              data-testid="exportSurveyDates"
              href={exportSurveyDatesUrl}
              sx={{ mt: 2 }}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={3} mt={1}>
          <BaseTextField
            value={filters.searchText}
            onChange={(event) => mergeFilters({ searchText: event.target.value })}
            label="Search Clients..."
            data-testid="clientsSearchText"
            removeBottomMargin
            fullWidth
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container columnSpacing={2} mt={2} alignItems="end" justifyContent="space-between">
        <Grid item xs={12} sm={3}>
          <MenuTitle>Clients</MenuTitle>
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
        <Grid item xs={12} sm={3}>
          {isWBAdmin && circles.size > 1 && (
            <BaseTextField
              fullWidth
              data-testid="circleFilterSelect"
              label="Filter by circle"
              value={filters.circleId}
              onChange={(event) => {
                mergeFilters({ circleId: event.target.value });
              }}
              select
            >
              <MenuItem value={projectListFilterEmptyValue}>Show all</MenuItem>
              {circles.map((circle, index) => (
                <MenuItem key={index} value={circle.getId()}>
                  {circle.getName()}
                </MenuItem>
              ))}
            </BaseTextField>
          )}
        </Grid>
      </Grid>

      <ResourceLoader
        resource={clientsResource}
        content={(clients) => {
          return (
            <Grid
              container
              columnSpacing={2}
              mb={3}
              alignItems="end"
              justifyContent="space-between"
            >
              <Grid item xs={12} sm={9}>
                <ClientsTable clients={clients} onViewClick={onViewClick} />
                {totalPages > 1 && (
                  <Box mt={4} data-testid="clientsPagination">
                    <Pagination
                      page={page - 1}
                      pageCount={totalPages}
                      onPageChange={(newPage) => setPaginationPage(newPage + 1)}
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          );
        }}
      />
      <CreateClientModal />
    </>
  );
};
