import { Department } from 'model/Department';

export const findSiblingsInDepartmentsTree = (
  departments: Department[],
  departmentId: number
): Department[] => {
  if (departments.some((department) => department.getId() === departmentId)) {
    return departments;
  } else {
    return departments.reduce(
      (siblings, department) =>
        siblings || findSiblingsInDepartmentsTree(department.getChildren().toArray(), departmentId),
      undefined
    ) as Department[];
  }
};
