import { push } from 'connected-react-router';

import { AdminThunk } from 'reduxStore/appStore';
import { compilePath, CompilePathParams, CompilePathQuery } from 'router/compilePath';

/**
 * @deprecated
 */
export const pushRoute = (
  pattern: string,
  params?: CompilePathParams,
  query?: CompilePathQuery
): AdminThunk<unknown> => (dispatch) => dispatch(push(compilePath(pattern, params, query)));

export const openInNewTab = (url: string) => () => {
  window.open(url, '__blank');
};
