import React from 'react';
import { connect } from 'react-redux';

import { Button, SecondaryButton } from 'component/Button/Button';
import { ConnectedDialog } from 'component/ConnectedDialog/ConnectedDialog';
import { ReopenProjectModalForm } from 'component/SelectStopDate/ReopenProjectModalForm';
import { Project } from 'model/Project';
import { submit } from 'redux-form';
import { AdminDispatch, AdminState } from 'reduxStore/appStore';
import { selectModalParam } from 'reduxStore/modal/selectors';
import { closeModal } from 'reduxStore/modal/slice';
import { AdminFormNames } from 'register/AdminFormNames';
import { AdminModal } from 'register/AdminModal';

namespace ReopenProjectModal {
  export type StateProps = {
    project?: Project;
  };
  export type DispatchProps = {
    onSaveClick: () => void;
    onCancelClick: () => void;
  };
  export type OwnProps = {
    loadProjectList: () => void;
  };
  export type Props = OwnProps & StateProps & DispatchProps;
}

export const ReopenProjectModalPure: React.VFC<ReopenProjectModal.Props> = (props) => (
  <ConnectedDialog
    name={AdminModal.ReopenProject}
    title="Reopen project"
    actions={[
      <SecondaryButton keyboardFocused onClick={() => props.onCancelClick()} key="cancel">
        Cancel
      </SecondaryButton>,
      <Button color="primary" keyboardFocused onClick={() => props.onSaveClick()} key="reopen">
        Reopen
      </Button>,
    ]}
    content={(open) =>
      open && (
        <div>
          Select new survey stop date
          <ReopenProjectModalForm
            project={props.project!}
            loadProjectList={props.loadProjectList}
          />
        </div>
      )
    }
  />
);

export const ReopenProjectModal = connect(
  (state: AdminState): ReopenProjectModal.StateProps => ({
    project: selectModalParam(AdminModal.ReopenProject, 'project')(state),
  }),
  (dispatch: AdminDispatch): ReopenProjectModal.DispatchProps => ({
    onSaveClick: () => dispatch(submit(AdminFormNames.LiveStopDate)),
    onCancelClick: () => dispatch(closeModal()),
  })
)(ReopenProjectModalPure);
