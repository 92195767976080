import { selectHasMatch } from 'util/selector/routerSelector';

import { ClientRoute } from 'app/route/client';
import { AdminState } from 'reduxStore/appStore';
import { createSelector } from '@reduxjs/toolkit';

import { STORE_NAME } from './initialState';

export const selectClientDomain = (state: AdminState) => state[STORE_NAME];

export const selectClientListResource = createSelector(
  selectClientDomain,
  (domain) => domain.listResource
);

export const selectClientPagination = createSelector(
  selectClientDomain,
  (domain) => domain.listPagination
);

export const selectHasClientMatch = selectHasMatch(ClientRoute.PROJECT_PATTERN);
