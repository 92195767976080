import { createSlice } from '@reduxjs/toolkit';
import { addAsyncCases } from 'shared/utils/redux';

import { fetchProjectCategories } from './asyncActions';
import { STORE_NAME, CategoriesState } from './initialState';

export const categoriesSlice = createSlice({
  name: STORE_NAME,
  initialState: CategoriesState.INITIAL_DOMAIN,
  reducers: {
    clearProjectCategories: () => {
      return CategoriesState.INITIAL_DOMAIN;
    },
  },
  extraReducers: (builder) => {
    addAsyncCases(builder, fetchProjectCategories, (state, action) => {
      state.categoriesResource = action.payload;
    });
  },
});

export const { clearProjectCategories } = categoriesSlice.actions;
