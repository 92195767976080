import { createAsyncThunk } from '@reduxjs/toolkit';
import { demandPollRepository } from 'container/demandPollRepository';
import { Project } from 'model/Project';
import { STORE_NAME } from 'reduxStore/project/initialState';
import { selectCurrentProjectId } from 'reduxStore/project/projectSelector';
import { serializeError } from 'shared/utils/redux';

export const createDemandPoll = createAsyncThunk(
  `${STORE_NAME}/createDemandPoll`,
  ({ model }: { model: Project | any }, { getState }) => {
    const projectId = selectCurrentProjectId(getState());
    return demandPollRepository.create(projectId, model);
  },
  { serializeError }
);
