import React from 'react';

import { DropzoneEmptyMessage } from 'component/Dropzone/DropzoneEmptyMessage';

export namespace DropzoneClientLogoEmptyMessage {
  export type Props = {};
}

export const DropzoneClientLogoEmptyMessage: React.VFC<DropzoneClientLogoEmptyMessage.Props> = () => (
  <DropzoneEmptyMessage>
    <b>Upload company logo</b>
    <br />
    (100 x 300 px and less than 1mb)
  </DropzoneEmptyMessage>
);
