import { createProjectSelectQuery } from 'util/createProjectSelectQuery';

import { serializeError } from 'shared/utils/redux';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { companyRepository } from 'container/companyRepository';
import { List } from 'immutable';
import { Company } from 'model/Company';
import { Project } from 'model/Project';
import { STORE_NAME } from 'reduxStore/company/initialState';
import { selectCurrentCompanyId, selectCurrentKind } from 'reduxStore/project/projectSelector';
import { selectCurrentCircle } from 'reduxStore/circles/selectors';

export const fetchCompanyList = createAsyncThunk<List<Company>>(
  `${STORE_NAME}/fetchCompanyList`,
  async () => companyRepository.list(),
  { serializeError }
);

export const fetchCompanyProjectList = createAsyncThunk<List<Project>>(
  `${STORE_NAME}/fetchCompanyProjectList`,
  async (_, { getState }) => {
    const companyId = selectCurrentCompanyId(getState());
    const kind = selectCurrentKind(getState());
    const currentCircle = selectCurrentCircle(getState());
    const query = createProjectSelectQuery(companyId, kind, currentCircle?.getId());

    return companyRepository.projectList(query);
  },
  { serializeError }
);
