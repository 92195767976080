import { List } from 'immutable';
import { CustomQuestionOption } from 'model/CustomQuestionOption';
import { DiscreteQuestion } from 'model/DiscreteQuestion';
import { PageItemKind } from 'model/PageItemKind';
import { ProjectKind } from 'model/ProjectKind';
import { Question } from 'model/Question';
import { SimpleModel } from 'model/SimpleModel';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';

export namespace QuestionCheckbox {
  export interface Shape extends Question.Shape {
    options: List<CustomQuestionOption>;
    limitAnswerCount?: number;
    isRequired?: boolean;
    hasOther?: boolean;
    numberingSkipped?: boolean;
    locale?: LangCodeEnum;
  }
}

export class QuestionCheckbox extends Question<QuestionCheckbox.Shape> implements DiscreteQuestion {
  static showSkipNumberingCheckbox(kind: ProjectKind): boolean {
    switch (kind) {
      case ProjectKind.PULSE_ON_DEMAND_PROJECT:
      case ProjectKind.PULSE_PROJECT:
      case ProjectKind.PULSE_TEMPLATE:
        return false;
      default:
        return true;
    }
  }

  constructor(initialData: SimpleModel.Data<QuestionCheckbox.Shape>) {
    super(initialData, PageItemKind.QuestionCheckbox);
  }

  getOptions(): List<CustomQuestionOption> {
    return this.get('options');
  }

  isRequired(): boolean {
    return this.get('isRequired');
  }

  hasOther(): boolean {
    return this.get('hasOther');
  }

  getLimitAnswerCount(): number {
    return this.get('limitAnswerCount');
  }

  getAvailableOptions(): List<CustomQuestionOption> {
    return this.getOptions();
  }

  isNumberingSkipped(): boolean {
    return this.get('numberingSkipped');
  }

  getLocale() {
    return this.get('locale');
  }
}
