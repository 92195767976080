import { AccessCodeApi } from 'api/AccessCodeApi';
import { AccessCode } from 'model/AccessCode';

export class AccessCodeMapper {
  serialize(model: AccessCode): AccessCodeApi.Entry {
    return {
      projectDistribution: model.getDistribution(),
      surveyLink: model.getSurveyLink(),
      code: model.getCode(),
      subscribed: model.isSubscribed(),
    };
  }

  deserialize(entry: AccessCodeApi.Entry): AccessCode {
    return new AccessCode({
      employeeId: entry.employeeId,
      employeeConnectedId: entry.employeeConnectedId,
      employeeEmail: entry.employeeEmail,
      distribution: entry.projectDistribution,
      surveyLink: entry.surveyLink,
      code: entry.code,
      subscribed: entry.subscribed,
    });
  }
}
