import { CategoryApi } from 'api/CategoryApi';
import { PageItemApi } from 'api/PageItemApi';
import { List } from 'immutable';
import { CategoryMapper } from 'mapper/CategoryMapper';
import { PageItemKindMapper } from 'mapper/PageItemMapper';
import { QuestionAbstractMapper } from 'mapper/QuestionAbstractMapper';
import { PageItem } from 'model/PageItem';
import { PageItemKind } from 'model/PageItemKind';
import { QuestionOpinion } from 'model/QuestionOpinion';

export class QuestionOpinionMapper
  implements PageItemKindMapper<QuestionOpinion, PageItemApi.QuestionOpinionEntry> {
  constructor(private categoryMapper: CategoryMapper) {}

  serialize(model: QuestionOpinion): PageItemApi.QuestionOpinionEntry {
    return {
      ...QuestionAbstractMapper.serialize(model),
      category: model.getCategory() ? model.getCategory().getId() : undefined,
      option1: model.getOptions().get(0),
      option2: model.getOptions().get(1),
      option3: model.getOptions().get(2),
      option4: model.getOptions().get(3),
      option5: model.getOptions().get(4),
      reportedAs: model.getReportedAs(),
      notApplicable: model.getNotApplicable(),
      required: model.isRequired() ? 1 : 0,
      isRecommendedQuestion: model.getIsRecommendedQuestion(),
      locale: model.getLocale(),
      hasNotApplicableOption: model.getHasNotApplicableOption(),
      benchmarkItem: model.getBenchmarkItemId() || null,
      trendItem: model.getTrendItemId() || null,
    };
  }

  deserialize(entry: PageItemApi.QuestionOpinionEntry): QuestionOpinion {
    return new QuestionOpinion({
      ...QuestionAbstractMapper.deserializeShape(entry),
      category: entry.category
        ? this.categoryMapper.deserialize(entry.category as CategoryApi.Entry)
        : undefined,
      options: List.of(entry.option1, entry.option2, entry.option3, entry.option4, entry.option5),
      reportedAs: entry.reportedAs,
      notApplicable: entry.notApplicable,
      isRequired: Boolean(entry.required),
      isRecommendedQuestion: entry.isRecommendedQuestion,
      locale: entry.locale,
      hasNotApplicableOption: entry.hasNotApplicableOption,
    });
  }

  supportsSerialize(model: PageItem): boolean {
    return model instanceof QuestionOpinion;
  }

  supportsDeserialize(entry: PageItemApi.ImageWidgetEntry): boolean {
    return entry.kind === PageItemKind.QuestionOpinion;
  }
}
