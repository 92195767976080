import { routerMiddleware } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import { configureStore } from '@reduxjs/toolkit';
import appHistory from 'app/history';
import { AdminContainer } from 'container/index.d';
import { History } from 'history';
import { AnyAction, Middleware } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { adminReducer, AdminState as AdminStateFromReducer } from './appReducer';
import { loggingMiddleware } from './appMiddlewares';

export type AdminState = AdminStateFromReducer;

export const prepareStore = ({
  preloadedState,
  history = appHistory,
  middleware = [],
}: {
  preloadedState?: AdminState;
  history?: History;
  middleware?: Middleware[];
}) => {
  return configureStore({
    reducer: adminReducer({ history }),
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      })
        .concat(routerMiddleware(history))
        .concat(middleware);
    },
    preloadedState,
  });
};

export const store = prepareStore({ middleware: [loggingMiddleware] });

export type StoreType = ReturnType<typeof prepareStore>;

export type AdminThunk<R> = ThunkAction<R, AdminState, AdminContainer, AnyAction>;

export type AdminDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AdminDispatch>();
