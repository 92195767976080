import { AccessCodeApi } from 'api/AccessCodeApi';
import { List } from 'immutable';
import { AccessCodeMapper } from 'mapper/AccessCodeMapper';
import { AccessCode } from 'model/AccessCode';

export class AccessCodeRepository {
  constructor(private accessCodeApi: AccessCodeApi, private accessCodeMapper: AccessCodeMapper) {}

  unsubscribed(distributionId: string): Promise<List<AccessCode>> {
    return this.accessCodeApi
      .unsubscribed(distributionId)
      .then((entries) => List(entries.map((entry) => this.accessCodeMapper.deserialize(entry))));
  }

  search(distributionId: string, query: string): Promise<List<AccessCode>> {
    return this.accessCodeApi
      .search(distributionId, query)
      .then((entries) => List(entries.map((entry) => this.accessCodeMapper.deserialize(entry))));
  }

  resendLastEmail(distributionId: string, accessCode: string): Promise<any> {
    return this.accessCodeApi.resendLastEmail(distributionId, accessCode);
  }

  resendFailedEmail(emailTemplateId: number, code: string): Promise<any> {
    return this.accessCodeApi.resendFailedEmail(emailTemplateId, code);
  }

  resendEmail(emailTemplateId: number, email: string): Promise<any> {
    return this.accessCodeApi.resendEmail(emailTemplateId, email);
  }
}
