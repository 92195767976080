import { DemandPollApi } from 'api/DemandPollApi';
import { PulseTemplateMapper } from 'mapper/PulseTemplateMapper';
import { Project } from 'model/Project';
import { PulseTemplate } from 'model/PulseTemplate';

export class DemandPollRepository {
  constructor(
    private demandPollApi: DemandPollApi,
    private pulseTemplateMapper: PulseTemplateMapper
  ) {}

  create(id: number, project: Project): Promise<PulseTemplate> {
    return this.demandPollApi
      .create(id, project)
      .then((entry) => this.pulseTemplateMapper.deserialize(entry));
  }
}
