import { selectApiData, selectApiNoContent } from 'util/selector/apiSelector';
import { selectResponseData } from 'util/selector/axiosSelector';

import { ApiMessage } from 'api/const';
import { WithData } from 'api/interfaces';
import { AxiosInstance } from 'axios';
import { SortDirection } from 'shared/constants';

export namespace CategoryApi {
  export type Entry = {
    name: string;
    id?: number;
    isGlobal?: boolean;
    isActive?: boolean;
  };
}

export class CategoryApi {
  constructor(private client: AxiosInstance) {}

  list(id: number): Promise<CategoryApi.Entry[]> {
    return this.client
      .get(`/api/v1/project/${id}/category?sort[name]=${SortDirection.ASC}`)
      .then((response) => selectResponseData(response, 'data', []));
  }

  create(id: number, entry: CategoryApi.Entry): Promise<CategoryApi.Entry> {
    return this.client
      .post<WithData<CategoryApi.Entry>>(`api/v1/project/${id}/category`, entry)
      .then(selectApiData);
  }

  update(entry: CategoryApi.Entry): Promise<CategoryApi.Entry> {
    const { id: categoryId, ...data } = entry;

    return this.client
      .post<WithData<CategoryApi.Entry>>(`api/v1/category/${categoryId}`, data)
      .then(selectApiData);
  }

  delete(categoryId: number): Promise<void> {
    return this.client
      .delete<ApiMessage.Deleted>(`api/v1/category/${categoryId}`)
      .then(selectApiNoContent);
  }
}
