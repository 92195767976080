import { AuthApi } from 'api/AuthApi';
import { JWTUser } from 'model/JWTUser';

export class JWTUserMapper {
  deserialize(entry: AuthApi.UserEntry): Readonly<JWTUser> {
    return new JWTUser({
      id: entry.id,
      username: entry.username,
      roles: entry.roles,
      firstName: entry.firstName,
      lastName: entry.lastName,
    });
  }
}
