import { ProjectSubmitApi } from 'api/ProjectSubmitApi';
import { List } from 'immutable';
import { ProjectSubmitMapper } from 'mapper/ProjectSubmitMapper';
import { ProjectSubmit } from 'model/ProjectSubmit';

export class ProjectSubmitRepository {
  constructor(
    private projectSubmitApi: ProjectSubmitApi,
    private projectSubmitMapper: ProjectSubmitMapper
  ) {}

  list(projectId: number): Promise<List<ProjectSubmit>> {
    return this.projectSubmitApi
      .list(projectId)
      .then((entries) => List(entries.map((entry) => this.projectSubmitMapper.deserialize(entry))));
  }

  delete(projectSubmitId: number): Promise<void> {
    return this.projectSubmitApi.delete(projectSubmitId);
  }
}
