import { DraggableLocation } from 'react-beautiful-dnd';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { PageItemTranslationDto } from 'api/dto/PageItemTranslationsDto';
import { pageItemRepository } from 'container/pageItemRepository';
import { pageRepository } from 'container/pageRepository';
import { Exception } from 'exception/Exception';
import { Page } from 'model/Page';
import { PageItem } from 'model/PageItem';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';
import { serializeError } from 'shared/utils/redux';

import { STORE_NAME } from './initialState';
import { selectPageList } from './selectors';
import { reorderPageItemLocally } from './slice';

export const fetchPageList = createAsyncThunk(
  `${STORE_NAME}/fetchPageList`,
  ({ projectId }: { projectId: number }) => {
    return pageRepository.list(projectId);
  },
  { serializeError }
);

export const createPage = createAsyncThunk(
  `${STORE_NAME}/createPage`,
  ({ projectId, page }: { projectId: number; page: Page }) => {
    return pageRepository.create(projectId, page);
  },
  { serializeError }
);

export const deletePage = createAsyncThunk(
  `${STORE_NAME}/deletePage`,
  async ({ pageId }: { pageId: number }) => {
    await pageRepository.delete(pageId);
  },
  { serializeError }
);

export const moveUpPage = createAsyncThunk(
  `${STORE_NAME}/moveUpPage`,
  ({ projectId, page }: { projectId: number; page: Page }) => {
    return pageRepository.moveUp(projectId, page);
  },
  { serializeError }
);

export const moveDownPage = createAsyncThunk(
  `${STORE_NAME}/moveDownPage`,
  ({ projectId, page }: { projectId: number; page: Page }) => {
    return pageRepository.moveDown(projectId, page);
  },
  { serializeError }
);

export const createPageItem = createAsyncThunk(
  `${STORE_NAME}/createPageItem`,
  ({
    pageId,
    pageItem,
    shouldSendFormData,
  }: {
    pageId: number;
    pageItem: PageItem;
    shouldSendFormData?: boolean;
  }) => {
    return pageItemRepository.create(pageId, pageItem, shouldSendFormData);
  },
  { serializeError }
);

export const listPageItems = createAsyncThunk(
  `${STORE_NAME}/listPageItems`,
  ({ pageId }: { pageId: number }) => {
    return pageItemRepository.list(pageId);
  },
  { serializeError }
);

export const createAndListPageItems = createAsyncThunk(
  `${STORE_NAME}/createAndListPageItems`,
  ({ pageId, pageItem }: { pageId: number; pageItem: PageItem }) => {
    return pageItemRepository.createAndList(pageId, pageItem);
  },
  { serializeError }
);

export const savePageItemTranslations = createAsyncThunk(
  `${STORE_NAME}/createPageItemTranslations`,
  ({ itemId, translations }: { itemId: number; translations: PageItemTranslationDto[] }) => {
    return pageItemRepository.savePageItemTranslations({ itemId, translations });
  },
  { serializeError }
);

export const getTranslatedPageItem = createAsyncThunk(
  `${STORE_NAME}/getTranslatedPageItem`,
  ({ itemId, language }: { itemId: number; language: LangCodeEnum }) => {
    return pageItemRepository.getTranslatedPageItem({ itemId, language });
  },
  { serializeError }
);

export const updateAndListPageItem = createAsyncThunk(
  `${STORE_NAME}/updateAndListPageItem`,
  ({
    pageId,
    pageItem,
    shouldSendFormData,
  }: {
    pageId: number;
    pageItem: PageItem;
    shouldSendFormData?: boolean;
  }) => {
    return pageItemRepository.updateAndList(pageItem, pageId, shouldSendFormData);
  },
  { serializeError }
);

export const updatePageItem = createAsyncThunk(
  `${STORE_NAME}/updatePageItem`,
  ({ pageItem, shouldSendFormData }: { pageItem: PageItem; shouldSendFormData?: boolean }) => {
    return pageItemRepository.update(pageItem, shouldSendFormData);
  },
  { serializeError }
);

export const deletePageItem = createAsyncThunk(
  `${STORE_NAME}/deletePageItem`,
  ({ pageId, itemId }: { pageId: number; itemId: number }) => {
    return pageItemRepository.delete(itemId, pageId);
  },
  { serializeError }
);

export const reorderPageItem = createAsyncThunk(
  `${STORE_NAME}/reorderPageItem`,
  (
    {
      destination,
      source,
      draggedItemId,
      isAtTheSamePage,
    }: {
      destination: DraggableLocation;
      source: DraggableLocation;
      draggedItemId: number;
      isAtTheSamePage: boolean;
    },
    { dispatch, getState }
  ) => {
    const destinationPageId = Number(destination.droppableId);
    const destinationPosition = Number(destination.index);
    let firstPositionBackendIndex = 1;
    const sourcePageId = Number(source.droppableId);
    const sourcePosition = Number(source.index);

    const pageList = selectPageList(getState());
    const destinationPage = pageList.find((page) => page.getId() === destinationPageId);
    const sourcePage = pageList.find((page) => page.getId() === sourcePageId);

    const draggedItem = sourcePage.findItem(draggedItemId);

    const newSourcePage = isAtTheSamePage ? sourcePage : sourcePage.removeItem(draggedItemId);

    const isFirstPage = destinationPage.getPosition() === 1;
    if (isFirstPage) {
      const items = destinationPage.getItems();
      const itemPositions = items.map((item) => item.getPosition()).toArray();

      // On first page BE indexation starts from 2 instead of 1.
      // That is because BE not sending first element on first page which should always be hidden (Header)
      firstPositionBackendIndex = itemPositions ? itemPositions[0] : 2;
    }

    const newDestinationPage = isAtTheSamePage
      ? destinationPage.moveItem(sourcePosition, destinationPosition)
      : destinationPage.insertItem(destinationPosition, draggedItem);

    dispatch(
      reorderPageItemLocally({
        newSourcePage,
        newDestinationPage,
      })
    );

    // destinationPosition represents frontend list position. Each item is listed (indexed) from 0
    // firstPositionBackendIndex is a modifier to be used for BE call to match BE indexation (starting from 1 or from 2)
    const bePosition = destinationPosition + firstPositionBackendIndex;

    return pageItemRepository
      .reorder(destinationPageId, bePosition, draggedItem)
      .catch((error: Exception) => {
        dispatch(
          reorderPageItemLocally({
            newSourcePage: sourcePage,
            newDestinationPage: destinationPage,
          })
        );
        throw error;
      });
  },
  { serializeError }
);
