import React from 'react';

import { Grid, Typography } from '@mui/material/';

export namespace ProjectStatistic {
  export type Props = {
    completed?: number;
    partial?: number;
    started?: number;
    className?: string;
  };
}

export const ProjectStatistic: React.VFC<ProjectStatistic.Props> = (props) => (
  <Grid container spacing={4}>
    <Grid item xs={4}>
      <Typography textAlign="center" variant="h5">
        Completed
      </Typography>
      <Typography textAlign="center">{props.completed || '-'}</Typography>
    </Grid>
    <Grid item xs={4}>
      <Typography textAlign="center" variant="h5">
        Partial
      </Typography>
      <Typography textAlign="center">{props.partial || '-'}</Typography>
    </Grid>
    <Grid item xs={4}>
      <Typography textAlign="center" variant="h5">
        Just Started
      </Typography>
      <Typography textAlign="center">{props.started || '-'}</Typography>
    </Grid>
  </Grid>
);
