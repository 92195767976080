import { createAsyncThunk } from '@reduxjs/toolkit';
import { pulseProjectRepository } from 'container/pulseProjectRepository';
import { LifecycleType } from 'model/Lifecycle';
import { redirectToClientDashboard } from 'reduxStore/auth/utils';
import {
  selectCurrentProjectId,
  selectSubProjectsPageQuery,
} from 'reduxStore/project/projectSelector';
import { FetchSubProjectsQuery, PulseFrequencyData } from 'repository/PulseProjectRepository';
import { serializeError } from 'shared/utils/redux';

import { STORE_NAME } from '../initialState';

export const fetchCurrentPulseProject = createAsyncThunk(
  `${STORE_NAME}fetchCurrentPulseProject`,
  async () => {
    const result = await pulseProjectRepository.fetch();
    redirectToClientDashboard({ id: result.getProject().getId() });
    return result;
  },
  { serializeError }
);

export const getPulseProject = createAsyncThunk(
  `${STORE_NAME}getPulseProject`,
  (_, { getState }) => {
    const pulseTemplateProjectId = selectCurrentProjectId(getState());

    return pulseProjectRepository.get(pulseTemplateProjectId);
  },
  { serializeError }
);

export const fetchSubProjectsList = createAsyncThunk(
  `${STORE_NAME}/fetchSubProjectsList`,
  ({ config }: { config?: Partial<FetchSubProjectsQuery> }, { getState }) => {
    const pageQuery: FetchSubProjectsQuery = selectSubProjectsPageQuery(getState());
    const pulseTemplateProjectId = selectCurrentProjectId(getState());

    return pulseProjectRepository.fetchSubProjects(pulseTemplateProjectId, {
      ...pageQuery,
      ...config,
    });
  },
  { serializeError }
);

export const createPulseProject = createAsyncThunk(
  `${STORE_NAME}/createPulseProject`,
  (
    {
      pulseFrequencyData,
    }: {
      pulseFrequencyData: PulseFrequencyData;
    },
    { getState }
  ) => {
    const pulseTemplateProjectId = selectCurrentProjectId(getState());

    return pulseProjectRepository.create(pulseTemplateProjectId, pulseFrequencyData);
  },
  { serializeError }
);

export const changePulseFrequency = createAsyncThunk(
  `${STORE_NAME}/changePulseFrequency`,
  (
    {
      pulseFrequencyData,
    }: {
      pulseFrequencyData: PulseFrequencyData;
    },
    { getState }
  ) => {
    const pulseTemplateProjectId = selectCurrentProjectId(getState());

    return pulseProjectRepository.updatePulseFrequency(pulseTemplateProjectId, pulseFrequencyData);
  },
  { serializeError }
);

export const distributeLifecycle = createAsyncThunk(
  `${STORE_NAME}/distributeLifecycle`,
  (
    {
      lifecycleType,
      employeesIds,
    }: {
      lifecycleType: LifecycleType;
      employeesIds: number[];
    },
    { getState }
  ) => {
    const pulseTemplateProjectId = selectCurrentProjectId(getState());
    return pulseProjectRepository.distributeLifecycle(
      pulseTemplateProjectId,
      lifecycleType,
      employeesIds
    );
  },
  { serializeError }
);

export const uploadLifecycleAudience = createAsyncThunk(
  `${STORE_NAME}/uploadLifecycleAudience`,
  ({ lifecycleType, file }: { lifecycleType: LifecycleType; file: File }, { getState }) => {
    const pulseTemplateProjectId = selectCurrentProjectId(getState());
    return pulseProjectRepository.uploadRespondents(pulseTemplateProjectId, lifecycleType, file);
  },
  { serializeError }
);
