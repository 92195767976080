import { VALIDATION_ERROR } from 'exception/EmployeeCodeExpections';
import { Exception } from 'exception/Exception';

export class ValidationErrorException extends Exception {
  static readonly API_CODE = VALIDATION_ERROR;

  protected path: string;

  constructor(message: string, args?: any) {
    super({
      type: 'API_ERROR',
      code: ValidationErrorException.API_CODE,
      message,
    });
    this.path = args.path;

    Object.setPrototypeOf(this, ValidationErrorException.prototype);
  }

  getMessage(): string {
    if (this.isSpreadsheetError()) {
      return this.getExcelMessage();
    }

    return super.getMessage();
  }

  getPath(): string {
    return this.path.replace('data.', '');
  }

  /**
   * In case path is cell address e.g.: A12, AB45 etc. It is spreadsheet error`
   * @private
   */
  private isSpreadsheetError(): boolean {
    // If path starts with data. it won't be a spreadsheet error;
    if (this.path.includes('data.')) {
      return false;
    }

    // Exactly mach string that start with capital letters and ends with any number of digits
    return !!this.getPath().match(`^[A-Z]+[0-9]+$`);
  }

  private getExcelMessage() {
    return this.message + '. Check out the cell ' + this.getPath();
  }
}
