import { deepOrange, grey } from '@mui/material/colors';
import { css } from 'glamor';

export const container = css({
  display: 'flex',
  justifyContent: 'center',
  listStyle: 'none',
  margin: '0',
});

export const paginateItem = css({
  margin: '0 0.5em',

  color: grey[400],
  '&:hover': {
    color: grey[500],
  },
  '&:focus': {
    outline: 'none',
  },
});

export const paginateLinkItem = css({
  color: grey[400],
  padding: '5px 10px',
  cursor: 'pointer',
  transition: 'background-color .1s ease-in',
  '&:hover': {
    color: grey[500],
  },
  '&:hover, &:focus': {
    outline: 'none',
    textDecoration: 'none',
  },
});

export const arrowLinkItem = css({
  color: grey[400],
  '&:hover': {
    cursor: 'pointer',
    color: grey[500],
  },
  '&:focus': {
    outline: 'none',
    textDecoration: 'none',
  },
});

export const active = css({
  '& a, & a:hover': {
    backgroundColor: deepOrange[700],
    borderRadius: '2px',
    color: 'white',
  },
});
