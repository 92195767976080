import { PermissionsApi } from 'api/PermissionsApi';
import { PermissionMapper } from 'mapper/PermissionMapper';
import { PermissionTypes } from 'model/PermissionTypes';

export class PermissionTypesMapper {
  constructor(private permissionMapper: PermissionMapper) {}

  deserialize(entry: PermissionsApi.Entry): PermissionTypes {
    return new PermissionTypes({
      primary_feature: entry.primary_feature
        ? entry.primary_feature.map((permission) => this.permissionMapper.deserialize(permission))
        : [],
      feature: entry.feature
        ? entry.feature.map((permission) => this.permissionMapper.deserialize(permission))
        : [],
      comment_demographic: entry.comment_demographic
        ? entry.comment_demographic.map((permission) =>
            this.permissionMapper.deserialize(permission)
          )
        : [],
      comment_department: entry.comment_department
        ? entry.comment_department.map((permission) =>
            this.permissionMapper.deserialize(permission)
          )
        : [],
      summary_demographic: entry.summary_demographic
        ? entry.summary_demographic.map((permission) =>
            this.permissionMapper.deserialize(permission)
          )
        : [],
      summary_department: entry.summary_department
        ? entry.summary_department.map((permission) =>
            this.permissionMapper.deserialize(permission)
          )
        : [],
      access: entry.access?.map(this.permissionMapper.deserialize) ?? [],
    });
  }
}
