import { DemographicKind } from 'model/DemographicKind';
import { SimpleModel } from 'model/SimpleModel';
import { Moment } from 'moment';

export namespace Classification {
  export interface Shape {
    id?: number;
    title?: string;
    value?: Moment;
    position?: number;
    demographyId?: number;
    demographyKind?: DemographicKind;
  }
}

export class Classification extends SimpleModel<Classification.Shape> {
  getId(): number {
    return this.get('id');
  }

  getTitle(): string {
    return this.get('title');
  }

  getPosition(): number {
    return this.get('position');
  }

  getDemographyId(): number {
    return this.get('demographyId');
  }

  getDemographyKind(): DemographicKind {
    return this.get('demographyKind');
  }

  getValue(): Moment {
    return this.get('value');
  }
}
