import React from 'react';

import MaterialDatePicker, {
  DatePickerProps as MaterialDatePickerProps,
} from '@mui/lab/DatePicker';
import { mapError } from 'component/Form/mapError';
import { WrappedFieldProps } from 'redux-form';
import { DEFAULT_DATE_FORMAT } from 'shared/constants';
import { DistributiveOmit } from 'shared/utils/type';

import { BaseTextField } from './BaseTextField';

export type Props = WrappedFieldProps &
  DistributiveOmit<MaterialDatePickerProps, 'renderInput'> & {
    fullWidth?: boolean;
    style?: React.CSSProperties;
    'data-testid'?: string;
  };

export const DatePicker = ({
  input: { onChange, value, ...inputProps },
  meta,
  onChange: onChangeDatePicker,
  fullWidth = true,
  style,
  ...props
}: Props) => {
  const { errorText } = mapError(meta, 'errorText');
  const datePickerValue = value ?? props.value;

  return (
    <MaterialDatePicker
      inputFormat={DEFAULT_DATE_FORMAT}
      allowSameDateSelection
      {...inputProps}
      {...props}
      value={datePickerValue}
      onChange={(newValue) => {
        onChange(newValue);
        if (onChangeDatePicker) {
          onChangeDatePicker(newValue);
        }
      }}
      renderInput={(params) => {
        return (
          <BaseTextField
            {...params}
            data-testid={props['data-testid']}
            fullWidth={fullWidth}
            helperText={errorText}
            error={!!errorText}
            style={style}
          />
        );
      }}
    />
  );
};
