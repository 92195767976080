export const checkIsProperDate = (date: string): boolean => {
  const splitBy = date.indexOf('/') !== -1 ? '/' : '-';

  if (date) {
    const splitDate = date.split(splitBy);
    const year = splitDate[2];

    return year.length === 4;
  } else {
    return false;
  }
};
