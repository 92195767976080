import { createResourceContentSelector } from 'util/selector/resourceSelector';

import { AdminState } from 'reduxStore/appStore';
import { CompanyState, STORE_NAME } from 'reduxStore/company/initialState';
import { createSelector } from '@reduxjs/toolkit';

export const selectDomain = (state: AdminState): CompanyState.Domain => state[STORE_NAME];

export const selectCompanyListResource = createSelector(
  selectDomain,
  (domain) => domain.listResource
);

export const selectCompanyProjectListResource = createSelector(
  selectDomain,
  (domain) => domain.projectListResource
);

export const selectCompanyProjectList = createResourceContentSelector(
  selectCompanyProjectListResource
);
