import { createAsyncThunk } from '@reduxjs/toolkit';
import { jobApi } from 'container/jobApi';
import { JobName } from 'model/JobName';
import { selectEmailDistributionId } from 'reduxStore/emailDistribution/selectors';
import { commitEmployees, fetchEmployeeUploadStats } from 'reduxStore/employee/asyncActions';
import { selectHasEmployeeList } from 'reduxStore/employee/selectors';
import {
  selectCommitEmployeeDatabaseJob,
  selectCommitEmployeeDatabaseJobId,
  selectProcessEmployeeDatabaseJob,
  selectProcessUploadEmployeeJobId,
  selectUploadUserJobId,
} from 'reduxStore/job/selectors';
import { openModal } from 'reduxStore/modal/slice';
import { selectCurrentProjectOnlyId } from 'reduxStore/project/projectSelector';
import { AdminModal } from 'register/AdminModal';
import { serializeError } from 'shared/utils/redux';

import { STORE_NAME } from './initialState';

export const fetchProjectJobs = createAsyncThunk(
  `${STORE_NAME}/fetchProjectJobs`,
  async (_, { getState }) => {
    const projectId = selectCurrentProjectOnlyId(getState());
    return jobApi.listForProject(projectId);
  },
  { serializeError }
);

export const fetchEmailDistributionJobs = createAsyncThunk(
  `${STORE_NAME}/fetchEmailDistributionJobs`,
  (_, { getState }) => {
    const emailDistributionId = selectEmailDistributionId(getState());
    return jobApi.listForDistribution(emailDistributionId);
  },
  { serializeError }
);

export const fetchKioskDistributionJobs = createAsyncThunk(
  `${STORE_NAME}/fetchKioskDistributionJobs`,
  ({ kioskDistributionId }: { kioskDistributionId: string }) => {
    return jobApi.listForDistribution(kioskDistributionId);
  },
  { serializeError }
);

export const changeVisited = createAsyncThunk(
  `${STORE_NAME}/changeVisited`,
  (type: JobName, { getState }) => {
    let jobId;
    const projectId = selectCurrentProjectOnlyId(getState());

    if (type === JobName.UploadUsers) {
      jobId = selectUploadUserJobId(getState());
    } else if (type === JobName.ProcessEmployeeDatabase) {
      jobId = selectProcessUploadEmployeeJobId(getState());
    } else if (type === JobName.CommitEmployeeDatabase) {
      jobId = selectCommitEmployeeDatabaseJobId(getState());
    }
    return jobApi.changeVisited(projectId, jobId);
  },
  { serializeError }
);

export const processUploadEmployees = createAsyncThunk(
  `${STORE_NAME}/processUploadEmployees`,
  (_, { dispatch, getState }) => {
    const hasEmployees = selectHasEmployeeList(getState());

    if (hasEmployees) {
      const employeeProcessJob = selectProcessEmployeeDatabaseJob(getState());
      const isVisited = employeeProcessJob && employeeProcessJob.isVisited();

      if (!isVisited) {
        dispatch(fetchEmployeeUploadStats());
        dispatch(openModal({ name: AdminModal.EmployeesDryUpload, params: {} }));
      }
    } else {
      dispatch(fetchEmployeeUploadStats());
      dispatch(commitEmployees());
      dispatch(changeVisited(JobName.ProcessEmployeeDatabase));
    }
  },
  { serializeError }
);

export const showUploadEmployeesModal = createAsyncThunk(
  `${STORE_NAME}/showUploadEmployeesModal`,
  (_, { dispatch, getState }) => {
    const commitJob = selectCommitEmployeeDatabaseJob(getState());

    if (commitJob.getExtendedJob().hasWarnings() && !commitJob.isVisited()) {
      const warnings = commitJob.getExtendedJob().getWarnings();
      dispatch(openModal({ name: AdminModal.SoftWarningEmployeeUpload, params: { warnings } }));
    } else if (!commitJob.isVisited()) {
      dispatch(openModal({ name: AdminModal.SuccessfulEmployeesUpload, params: {} }));
    }
  },
  { serializeError }
);
