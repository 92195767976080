import { selectApiData } from 'util/selector/apiSelector';

import { DistributionApi } from 'api/DistributionApi';
import { AxiosInstance } from 'axios';

export namespace KioskDistributionApi {
  export type Entry = DistributionApi.Entry & {
    surveyLink: string;
  };
}

export class KioskDistributionApi {
  constructor(private client: AxiosInstance) {}

  fetch(projectId: number): Promise<KioskDistributionApi.Entry> {
    return this.client.get(`/api/v1/project/${projectId}/kiosk-distribution`).then(selectApiData);
  }
}
