import { createAsyncThunk } from '@reduxjs/toolkit';
import { contactListRepository } from 'container/contactListRepository';
import { ErrorCode, Exception } from 'exception/Exception';
import { ContactListSend } from 'model/ContactList';
import { ContactListDistributionType } from 'model/ContactListDistributionType';
import { selectContactListDistributionType } from 'reduxStore/contactList/selectors';
import { clearContactList } from 'reduxStore/contactList/slice';
import { selectEmailDistributionId } from 'reduxStore/emailDistribution/selectors';
import { selectKioskDistributionId } from 'reduxStore/kioskDistribution/selectors';
import { serializeError } from 'shared/utils/redux';

import { STORE_NAME } from './initialState';

export const fetchContactList = createAsyncThunk(
  `${STORE_NAME}/fetchContactList`,
  async ({ distributionId }: { distributionId: string }) => {
    try {
      return await contactListRepository.fetch(distributionId);
    } catch (error) {
      if ((error as Exception).getCode() === ErrorCode.ENTITY_NOT_FOUND) {
        // handle not-existing contact list as another case, not exception
        return null;
      }

      throw error;
    }
  },
  { serializeError }
);

export const createContactList = createAsyncThunk(
  `${STORE_NAME}/createContactList`,
  async ({ contactList }: { contactList: ContactListSend }, { dispatch, getState }) => {
    const id =
      selectContactListDistributionType(getState()) === ContactListDistributionType.Email
        ? selectEmailDistributionId(getState())
        : selectKioskDistributionId(getState());

    const result = await contactListRepository.create(id, contactList);
    dispatch(clearContactList());
    return result;
  },
  { serializeError }
);
