import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { CookiesProvider } from 'react-cookie';

import AdapterMoment from '@mui/lab/AdapterMoment';
import { CssBaseline, ThemeProvider } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { store } from 'reduxStore/appStore';
import { AppRoutes } from 'router/AppRoutes';
import { useInitializeGTM } from 'shared/hooks/useInitializeGTM';
import { useRedirectToLogin } from 'shared/hooks/useRedirectToLogin';
import { LanguageProvider } from 'translations/LanguageProvider';
import { WBGrowthBookProvider } from 'component/WBGrowthBookProvider/WBGrowthBookProvider';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import history from './history';
import { workbuzzTheme } from './theme/workbuzzTheme';

export const App = () => {
  useInitializeGTM();
  useRedirectToLogin();

  return (
    <WBGrowthBookProvider>
      <PostHogProvider client={posthog}>
        <LanguageProvider>
          <StoreProvider store={store}>
            <CookiesProvider defaultSetOptions={{ path: '/' }}>
              <ConnectedRouter history={history}>
                <ThemeProvider theme={workbuzzTheme}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <CssBaseline />
                    <AppRoutes />
                  </LocalizationProvider>
                </ThemeProvider>
              </ConnectedRouter>
            </CookiesProvider>
          </StoreProvider>
        </LanguageProvider>
      </PostHogProvider>
    </WBGrowthBookProvider>
  );
};
