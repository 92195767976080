import React from 'react';

import CardContent from '@mui/material/CardContent';
import {
  projectDataStyle,
  ChipWrapper,
  ProjectStatusContent,
  ProjectStatusWrapper,
} from 'component/ProjectStatus/ProjectStatus.s';
import { ProjectStatusLabel } from 'component/ProjectStatusLabel/ProjectStatusLabel';
import { Project } from 'model/Project';
import { Moment } from 'moment';

export namespace ProjectStatus {
  export type Props = {
    project: Project;
    liveStart: Moment;
    liveStop: Moment;
    className?: string;
  };
}

export const ProjectStatus: React.VFC<ProjectStatus.Props> = (props) => (
  <ProjectStatusWrapper className={props.className}>
    <ProjectStatusContent>
      <h6>Status</h6>
      <ChipWrapper
        data-testid="projectStatus"
        label={
          <ProjectStatusLabel status={props.project.getStatus()} kind={props.project.getKind()} />
        }
      />
      {props.liveStart && props.liveStop && (
        <CardContent style={projectDataStyle} data-testid="projectLiveStartAndStop">
          {`(${props.liveStart.format('D MMM YY')} - ${props.liveStop.format('D MMM YY') || ''})`}
        </CardContent>
      )}
    </ProjectStatusContent>
  </ProjectStatusWrapper>
);
