import { createSlice } from '@reduxjs/toolkit';
import { Resource } from 'model/Resource';
import { fetchWebLinkDistribution } from 'reduxStore/webLink/asyncActions';
import { STORE_NAME, WebLinkDistributionState } from 'reduxStore/webLink/initialState';
import { addAsyncCases } from 'shared/utils/redux';

export const webLinkDistributionSlice = createSlice({
  name: STORE_NAME,
  initialState: WebLinkDistributionState.INITIAL_DOMAIN,
  reducers: {
    clearWebLinkDistribution: (state) => {
      state.distributionResource = Resource.void();
    },
  },
  extraReducers: (builder) => {
    addAsyncCases(builder, fetchWebLinkDistribution, (state, action) => {
      state.distributionResource = action.payload;
    });
  },
});

export const { clearWebLinkDistribution } = webLinkDistributionSlice.actions;
