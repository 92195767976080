import { List } from 'immutable';
import { Classification } from 'model/Classification';
import { ClassificationDate } from 'model/ClassificationDate';
import { Demographic } from 'model/Demographic';
import { LifecycleType, PulseLifecycleProjectReceived } from 'model/Lifecycle';
import { SimpleModel } from 'model/SimpleModel';
import { Moment } from 'moment';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';

export namespace Employee {
  export interface Shape {
    id?: number;
    firstName: string;
    lastName: string;
    email: string;
    preferredLanguage?: LangCodeEnum | null;
    lineManagerEmail?: string;
    surveyMethod: string;
    active: boolean;
    departmentNestedName?: string;
    department?: number;
    classifications?: List<Classification> | List<number>;
    classificationsDate?: List<ClassificationDate>;
    createdAt?: Moment;
    pulseLifecycleProjects?: List<PulseLifecycleProjectReceived>;
  }
}

export class Employee extends SimpleModel<Employee.Shape> {
  getId(): number {
    return this.get('id');
  }

  getFirstName(): string {
    return this.get('firstName');
  }

  getLastName(): string {
    return this.get('lastName');
  }

  getName(): string {
    return `${this.getFirstName()} ${this.getLastName()}`;
  }

  getEmail(): string {
    return this.get('email');
  }

  getPreferredLanguage(): LangCodeEnum {
    return this.get('preferredLanguage');
  }

  getLineManagerEmail(): string {
    return this.get('lineManagerEmail');
  }

  getDepartment(): number {
    return this.get('department');
  }

  getDepartmentNestedName(): string {
    return this.get('departmentNestedName');
  }

  getSurveyMethod(): string {
    return this.get('surveyMethod');
  }

  getClassificationDate(): List<ClassificationDate> {
    return this.get('classificationsDate');
  }

  getClassifications(): List<Classification> | List<number> {
    return this.get('classifications');
  }

  getClassificationByDemographyId(demographyId: Demographic.Shape['id']): Classification {
    const classifications = this.getClassifications() as List<Classification>;
    return classifications?.find((item) => item.getDemographyId() === demographyId);
  }

  getCreatedAt(): Moment | undefined {
    return this.get('createdAt');
  }

  getPulseLifecycleProjects(): List<PulseLifecycleProjectReceived> {
    return this.get('pulseLifecycleProjects');
  }

  getPulseLifecycleProjectByType(lifecycleType: LifecycleType) {
    return this.getPulseLifecycleProjects()?.find(({ type }) => type === lifecycleType);
  }

  isActive(): boolean {
    return this.get('active');
  }
}
