import { EmployeeApi } from 'api/EmployeeApi';
import qs from 'qs';

export const createEmployeePaginationAndSortQuery = (
  page: number,
  limit?: number,
  search?: string,
  department?: number,
  active?: EmployeeApi.FilterActiveParam,
  sort?: EmployeeApi.SortParam,
  createdAtSince?: string
) => {
  return (
    '?' +
    qs.stringify(
      {
        page: page,
        filter: {
          search,
          department,
          active,
          created_at_since: createdAtSince,
        },
        limit,
        sort: sort,
      },
      { encode: false, skipNulls: true }
    )
  );
};
