import React from 'react';

import { CardContent, CardHeader } from '@mui/material';
import {
  ProjectCompanyDataWrapper,
  ProjectIcon,
  SubtitleWrapper,
} from 'component/ProjectCompanyData/ProjectCompanyData.s';
import { SurveyTypeLabel } from 'component/SurveyTypeLabel/SurveyTypeLabel';
import { ProjectKind } from 'model/ProjectKind';
import { Moment } from 'moment';

export namespace ProjectCompanyData {
  export type Props = {
    projectName: string;
    updatedAt: Moment;
    className?: string;
  };

  export type WithDetailsProps = Props & {
    kind: ProjectKind;
    companyName?: string;
  };
}

const isWithDetailsProps = (
  props: ProjectCompanyData.Props | ProjectCompanyData.WithDetailsProps
): props is ProjectCompanyData.WithDetailsProps => {
  return !!(props as ProjectCompanyData.WithDetailsProps).kind;
};

export const ProjectCompanyData: React.VFC<
  ProjectCompanyData.Props | ProjectCompanyData.WithDetailsProps
> = (props) => (
  <ProjectCompanyDataWrapper className={props.className}>
    <ProjectIcon style={{ width: 70, height: 70 }} />
    <div>
      <CardHeader
        data-testid="projectNameHeader"
        title={props.projectName}
        {...(isWithDetailsProps(props)
          ? {
              subheader: (
                <SubtitleWrapper>
                  {props.companyName}
                  <SurveyTypeLabel kind={props.kind} />
                </SubtitleWrapper>
              ),
            }
          : {})}
      />
      <CardContent style={{ paddingLeft: 0 }}>
        Last Modified {`${props.updatedAt.format('D/M/YYYY HH:mm:ss')}`}
      </CardContent>
    </div>
  </ProjectCompanyDataWrapper>
);
