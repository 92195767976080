import { List } from 'immutable';
import { CustomQuestionOption } from 'model/CustomQuestionOption';
import { NumberingSkippable } from 'model/NumberingSkippable';
import { PageItemKind } from 'model/PageItemKind';
import { Question } from 'model/Question';
import { SimpleModel } from 'model/SimpleModel';

export namespace QuestionForm {
  export interface Shape extends Question.Shape {
    options: List<CustomQuestionOption>;
    optionHeight: number;
    numberingSkipped: boolean;
  }
}

export class QuestionForm extends Question<QuestionForm.Shape> implements NumberingSkippable {
  constructor(initialData: SimpleModel.Data<QuestionForm.Shape>) {
    super(initialData, PageItemKind.QuestionForm);
  }

  getOptions(): List<CustomQuestionOption> {
    return this.get('options');
  }

  getOptionHeight(): number {
    return this.get('optionHeight');
  }

  isNumberingSkipped(): boolean {
    return this.get('numberingSkipped');
  }
}
