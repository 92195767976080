import { selectApiData } from 'util/selector/apiSelector';
import { selectAppException } from 'util/selectAppException';

import { AxiosInstance } from 'axios';
import { BatchException } from 'exception/BatchException';
import { JobExtendedMapper } from 'mapper/JobExtendedMapper';
import { JobMapper } from 'mapper/JobMapper';
import { Job } from 'model/Job';
import { JobExtended } from 'model/JobExtended';

export namespace JobApi {
  type IsoDate = string;

  export type Entry = JobEntry;

  export type JobEntry = {
    id: number;
    name: string;
    status: number;
    reason?: string[];
    progress?: number;
    warnings?: string[];
    executionTime?: IsoDate;
    visited: boolean;
    result?: unknown;
  };
}

export class JobApi {
  constructor(
    private api: AxiosInstance,
    private jobMapper: JobMapper,
    private jobExtendedMapper: JobExtendedMapper
  ) {}

  listForProject(projectId: number): Promise<Job[]> {
    return this.api
      .get(`/api/v1/project/${projectId}/job`)
      .then(selectApiData)
      .then((entries: JobApi.Entry[]) =>
        entries.map((entry) => this.jobMapper.deserialize(entry, projectId))
      );
  }

  listForDistribution(distributionId: string): Promise<JobExtended[]> {
    return this.api
      .get(`/api/v1/distribution/${distributionId}/job`)
      .then(selectApiData)
      .then((entries) =>
        entries.map((entry: JobApi.JobEntry) => this.jobExtendedMapper.deserialize(entry))
      );
  }

  changeVisited(projectId: number, jobId: number): Promise<any> {
    return this.api.post(`/api/v1/project/${projectId}/job/${jobId}/visited`);
  }

  get(jobId: number): Promise<Job> {
    return this.api
      .get(`/api/v1/job/${jobId}`)
      .then(selectApiData)
      .then((entry: JobApi.Entry) => {
        if (entry.reason) {
          throw new BatchException(entry.reason.map(selectAppException));
        }

        return this.jobMapper.deserialize(entry);
      });
  }
}
