import { css } from 'glamor';
import { styled } from '@mui/material';

export const ListLabel = styled('div')({
  paddingLeft: 20,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: 550,
});

export const DepartmentsContent = styled('div')({
  margin: '20px 0',
});

export const DepartmentErrorsWrapper = styled('div')({
  padding: '20px 0',
});

export const styledListItem = css({
  ' button': {
    left: '10px !important',
  },
});

export const styledIcon = css({
  width: 18,
  height: 18,
  margin: '0 5px',
  cursor: 'pointer',
});
