import { createAsyncThunk } from '@reduxjs/toolkit';
import { departmentRepository } from 'container/departmentRepository';
import { trendItemsRepository } from 'container/trendItemsRepository';
import { serializeError } from 'shared/utils/redux';

import { STORE_NAME } from './initialState';

export const fetchTrendItems = createAsyncThunk(
  `${STORE_NAME}/fetchTrendItems`,
  ({ projectId }: { projectId: number }) => {
    return trendItemsRepository.list(projectId);
  },
  { serializeError }
);

export const fetchConnectedDepartments = createAsyncThunk(
  `${STORE_NAME}/fetchConnectedDepartments`,
  ({ projectId }: { projectId: number }) => {
    return departmentRepository.list(projectId);
  },
  { serializeError }
);

export const setConnectedDepartments = createAsyncThunk(
  `${STORE_NAME}/setConnectedDepartments`,
  ({ sourceId, destinationId }: { sourceId: number; destinationId: number | null }) => {
    return trendItemsRepository.setConnectedDepartments(sourceId, destinationId);
  },
  { serializeError }
);
