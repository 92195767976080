import { List } from 'immutable';
import { Distribution } from 'model/Distribution';
import { DistributionChannel } from 'model/DistributionChannel';
import { EmailTemplate } from 'model/EmailTemplate';

export namespace EmailDistribution {
  export interface Shape extends Distribution.Shape {
    id: string;
    invitation?: EmailTemplate;
    reminders?: List<EmailTemplate>;
  }
}

export class EmailDistribution extends Distribution<EmailDistribution.Shape> {
  getId(): string {
    return this.get('id');
  }

  getChannel(): DistributionChannel {
    return DistributionChannel.Email;
  }

  getInvitation(): EmailTemplate {
    return this.get('invitation');
  }

  setInvitation(invitation: EmailTemplate): this {
    return this.set('invitation', invitation);
  }

  getReminders(): List<EmailTemplate> {
    return this.get('reminders', List<EmailTemplate>());
  }

  setReminders(reminders: List<EmailTemplate>): this {
    return this.set('reminders', reminders);
  }

  getEmailTemplates(): List<EmailTemplate> {
    return List<EmailTemplate>([this.getInvitation()])
      .concat(this.getReminders())
      .toList();
  }

  setEmailTemplates(emailTemplates: List<EmailTemplate>): this {
    const invitation = emailTemplates.find((emailTemplate) => emailTemplate.isInvitation());
    const reminders = emailTemplates.filter((emailTemplate) => emailTemplate.isReminder()).toList();

    return this.set('invitation', invitation || this.get('invitation')).set('reminders', reminders);
  }
}
