import { employeeApi } from 'container/employeeApi';
import { employeeMapper } from 'container/employeeMapper';
import { employeeStatsMapper } from 'container/employeeStatsMapper';
import { paginationMapper } from 'container/paginationMapper';
import { EmployeeRepository } from 'repository/EmployeeRepository';

export const employeeRepository = new EmployeeRepository(
  employeeApi,
  employeeMapper,
  paginationMapper,
  employeeStatsMapper
);
