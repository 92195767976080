import { createSlice } from '@reduxjs/toolkit';
import { Resource } from 'model/Resource';
import { addAsyncCases } from 'shared/utils/redux';

import {
  createDefaultDemographic,
  createDemographic,
  deleteDemographic,
  fetchDemographicList,
  fetchReportDemographicList,
  moveDemographic,
  updateDemographic,
} from './asyncActions';
import { DemographicState, STORE_NAME } from './initialState';

export const demographicSlice = createSlice({
  name: STORE_NAME,
  initialState: DemographicState.INITIAL_DOMAIN,
  reducers: {
    clearDemographicList: (state) => {
      state.listResource = DemographicState.INITIAL_DOMAIN['listResource'];
    },
  },
  extraReducers: (builder) => {
    addAsyncCases(builder, fetchDemographicList, (state, action) => {
      state.listResource = action.payload;
    });
    addAsyncCases(builder, fetchReportDemographicList, (state, action) => {
      state.reportListResource = action.payload;
    });

    builder
      .addCase(createDemographic.fulfilled, (state, action) => {
        const demographicList = state.listResource.getContent();
        const newDemographicList = demographicList.set(demographicList.size, action.payload);
        state.listResource = Resource.resolve(newDemographicList);
      })
      .addCase(updateDemographic.fulfilled, (state, action) => {
        const demographicList = state.listResource.getContent();
        const index = demographicList.findIndex((item) => item.getId() === action.payload.getId());
        const newDemographicList = demographicList.update(index, () => action.payload);
        state.listResource = Resource.resolve(newDemographicList);
      })
      .addCase(createDefaultDemographic.fulfilled, (state, action) => {
        const demographicList = state.listResource.getContent();
        const newDemographicList = demographicList.set(demographicList.size, action.payload);
        state.listResource = Resource.resolve(newDemographicList);
      })
      .addCase(deleteDemographic.fulfilled, (state, action) => {
        const demographicList = state.listResource.getContent();
        const index = demographicList.findIndex(
          (item) => item.getId() === action.meta.arg.demographicId
        );
        const newDemographicList = demographicList.delete(index);
        state.listResource = Resource.resolve(newDemographicList);
      })
      .addCase(moveDemographic.pending, (state, action) => {
        state.listResource = Resource.resolve(action.meta.arg.newDemographicList);
      })
      .addCase(moveDemographic.rejected, (state, action) => {
        state.listResource = Resource.resolve(action.meta.arg.oldDemographicList);
      });
  },
});

export const { clearDemographicList } = demographicSlice.actions;
