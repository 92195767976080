import { ProjectLayoutApi } from 'api/ProjectLayoutApi';
import { ProjectLayoutMapper } from 'mapper/ProjectLayoutMapper';
import { ProjectLayout } from 'model/ProjectLayout';

export class ProjectLayoutRepository {
  constructor(
    private projectLayoutApi: ProjectLayoutApi,
    private projectLayoutMapper: ProjectLayoutMapper
  ) {}

  fetch(projectId: number): Promise<ProjectLayout> {
    return this.projectLayoutApi
      .fetch(projectId)
      .then((entry: ProjectLayoutApi.Entry) => this.projectLayoutMapper.deserialize(entry));
  }

  save(projectId: number, data: ProjectLayout): Promise<ProjectLayout> {
    return this.projectLayoutApi
      .save(projectId, this.projectLayoutMapper.serialize(data))
      .then((response) => {
        return this.projectLayoutMapper.deserialize(response);
      });
  }
}
