import memoize from 'lodash/memoize';
import moment, { Moment } from 'moment';
import { Validator } from 'redux-form';

const defaultMessage = 'Please enter a valid date';

export const dateFormat = memoize(
  (message: string = defaultMessage): Validator => (value: Moment | string | number | Date) =>
    moment(value).isValid() ? undefined : message
);
