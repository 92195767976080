import { List } from 'immutable';
import { Page } from 'model/Page';
import { PageItem } from 'model/PageItem';
import { Resource } from 'model/Resource';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';
import { languageConfig } from 'translations/languageConfig';

export const STORE_NAME = 'page';

export namespace PageState {
  export type Domain = {
    listResource: Resource<List<Page>>;
    currentItem: PageItem;
    currentItemByLanguage: Record<LangCodeEnum, PageItem | null>;
    questionDetailsTab: number;
    languageTab: LangCodeEnum;
  };

  export const INITIAL_DOMAIN: Domain = {
    listResource: Resource.void(),
    currentItem: null,
    currentItemByLanguage: {} as Record<LangCodeEnum, PageItem | null>,
    questionDetailsTab: 1,
    languageTab: languageConfig.baseLanguage,
  };
}
