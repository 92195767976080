import { range } from 'lodash';
import { DeepPartial } from 'redux';
import { AdminState } from 'reduxStore/appReducer';

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';

export const VARIABLE_CONFIDENTIALITY_THRESHOLD_DISABLED_OPTION = -1;
export const VARIABLE_CONFIDENTIALITY_THRESHOLD_OPTIONS = range(3, 21);
export const VARIABLE_CONFIDENTIALITY_THRESHOLD_TOOLTIP = `We will take this value and 
  calculate the total sum of  groups within a demographic filter that do not meet the confidentiality threshold, above. 
  If the sum in those groups is under this variable threshold then we may apply a confidentiality protection message to 
  all filters within that group to protect the confidentiality of those smaller groups.`;

export const WB_CIRCLE_NAME = 'WorkBuzz';

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export const wrappedReduxStateSnapshot: DeepPartial<{ state: AdminState }> = {};
export const latestReduxActions: { type: string; payload: any }[] = []; // Max 30 actions
