import { Distribution } from 'model/Distribution';
import { DistributionChannel } from 'model/DistributionChannel';

export namespace PaperDistribution {
  export interface Shape extends Distribution.Shape {
    link: string;
  }
}

export class PaperDistribution extends Distribution<PaperDistribution.Shape> {
  getChannel(): DistributionChannel {
    return DistributionChannel.Paper;
  }

  getLink(): string {
    return this.get('link');
  }
}
