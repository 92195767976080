import { createSlice } from '@reduxjs/toolkit';
import { fetchCompanyList, fetchCompanyProjectList } from 'reduxStore/company/asyncActions';
import { CompanyState, STORE_NAME } from 'reduxStore/company/initialState';
import { addAsyncCases } from 'shared/utils/redux';

export const companySlice = createSlice({
  name: STORE_NAME,
  initialState: CompanyState.INITIAL_DOMAIN,
  reducers: {},
  extraReducers: (builder) => {
    addAsyncCases(builder, fetchCompanyList, (state, action) => {
      state.listResource = action.payload;
    });
    addAsyncCases(builder, fetchCompanyProjectList, (state, action) => {
      state.projectListResource = action.payload;
    });
  },
});
