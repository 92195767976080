import React from 'react';

import { mapError } from 'component/Form/mapError';
import { WrappedFieldProps } from 'redux-form';
import { Autocomplete } from '@mui/material';

import { BaseTextField, BaseTextFieldProps } from './BaseTextField';

export type TypeheadSelectFieldProps = WrappedFieldProps & {
  autocompleteOptions: { name: string; id: number | string }[];
} & BaseTextFieldProps;

export const TypeheadSelectField: React.VFC<TypeheadSelectFieldProps> = (props) => {
  const { input, autocompleteOptions, meta, label, disabled } = props;
  const { onChange } = input;

  const { errorText } = mapError(meta, 'errorText');

  const optionIds = autocompleteOptions ? autocompleteOptions.map((option) => option.id) : [];

  const getSelectedOptionId = () => {
    if (!input.value) return null;

    const foundOption = autocompleteOptions.find((option) => option.id === input.value);

    if (!foundOption) {
      return null;
    }

    return foundOption.id;
  };

  return (
    <Autocomplete
      data-testid="autocomplete"
      disableClearable
      disabled={disabled}
      value={getSelectedOptionId()}
      options={optionIds}
      getOptionLabel={(selectedOptionId) =>
        autocompleteOptions.find((option) => option.id === selectedOptionId)?.name
      }
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      fullWidth
      renderInput={(params) => (
        <BaseTextField
          {...props}
          {...params}
          value={input.value}
          error={!!errorText}
          helperText={errorText}
          fullWidth
          label={label}
        />
      )}
    />
  );
};
