export namespace ClientRoute {
  export const PROJECT_PATTERN = '/pulse-project/:id';
  export const SURVEY_PATTERN = `${PROJECT_PATTERN}/survey` as const;
  export const PEOPLE_PATTERN = `${PROJECT_PATTERN}/people` as const;
  export const TRENDING_PATTERN = `${PROJECT_PATTERN}/trending` as const;
  export const DATA_PATTERN = `${PROJECT_PATTERN}/data` as const;
  export const DEMAND_POLL_PATTERN = `${PROJECT_PATTERN}/demand-poll` as const;

  export namespace Project {
    export const COMPANY_SETTINGS = `${PROJECT_PATTERN}/company-settings` as const;
    export const COMPANY_LANGUAGES = `${PROJECT_PATTERN}/company-languages` as const;
    export const ON_DEMAND_SURVEY = `${PROJECT_PATTERN}/on-demand-survey` as const;
    export const ACCOUNT_SETTINGS = `${PROJECT_PATTERN}/account-settings` as const;

    export namespace People {
      export const EMPLOYEES = `${PEOPLE_PATTERN}/employees` as const;
      export const ATTRIBUTES_PATTERN = `${PEOPLE_PATTERN}/attributes` as const;
      export const ATTRIBUTES_DEMOGRAPHICS = `${ATTRIBUTES_PATTERN}/demographics` as const;
      export const ATTRIBUTES_DEPARTMENTS = `${ATTRIBUTES_PATTERN}/departments` as const;
      export const DASHBOARD_PERMISSIONS_PATTERN = `${PEOPLE_PATTERN}/permissions` as const;
      export const KIOSK_PATTERN = `${DASHBOARD_PERMISSIONS_PATTERN}/kiosk` as const;
      export const USERS_PATTERN = `${DASHBOARD_PERMISSIONS_PATTERN}/users` as const;
      export const ACCESS_CONTROL_PATTERN = `${DASHBOARD_PERMISSIONS_PATTERN}/access-control` as const;
      export const NOTIFICATIONS_PATTERN = `${DASHBOARD_PERMISSIONS_PATTERN}/notifications` as const;
    }

    export namespace Languages {
      export const SURVEY = `${COMPANY_LANGUAGES}/survey` as const;
      export const DASHBOARD = `${COMPANY_LANGUAGES}/dashboard` as const;
    }

    export namespace Survey {
      export const SURVEY_SETTINGS = `${SURVEY_PATTERN}/survey-settings` as const;
      export const QUESTION_LIBRARY = `${SURVEY_PATTERN}/question-library` as const;
      export const DEVELOP_PATTERN = `${SURVEY_PATTERN}/:survey_id` as const;
      export const LIVE_AND_SCHEDULED = `${SURVEY_PATTERN}/live-scheduled` as const;
      export const ARCHIVED = `${SURVEY_PATTERN}/archived` as const;

      export namespace Develop {
        export const QUESTION_PATTERN = `${DEVELOP_PATTERN}/questions` as const;
        export const AUDIENCE = `${DEVELOP_PATTERN}/audience` as const;
        export const EMAIL = `${DEVELOP_PATTERN}/email` as const;
        export const ADMINISTRATION = `${DEVELOP_PATTERN}/administration` as const;
        export const ADVANCED = `${DEVELOP_PATTERN}/advanced` as const;
      }
    }

    export namespace DemandPoll {
      export const CREATE = `${DEMAND_POLL_PATTERN}/create` as const;
      export const LIVE_AND_SCHEDULED = `${DEMAND_POLL_PATTERN}/live-scheduled` as const;
      export const ARCHIVED = `${DEMAND_POLL_PATTERN}/archived` as const;
    }

    export namespace Trending {
      export const BENCHMARK_DATA_PATTERN = `${TRENDING_PATTERN}/benchmark-data` as const;
      export const TREND_DATA_PATTERN = `${TRENDING_PATTERN}/trend-data` as const;
    }

    export namespace Data {
      export const EXPORT_PATTERN = `${DATA_PATTERN}/export` as const;
      export const DOWNLOADS_PATTERN = `${DATA_PATTERN}/downloads` as const;
      export const IMPORT_SURVEY_PATTERN = `${DATA_PATTERN}/import` as const;
      export const MANAGE_SURVEY_PATTERN = `${DATA_PATTERN}/manage` as const;
    }
  }
}
