import { useMount } from 'react-use';

import { useAppDispatch } from 'reduxStore/appStore';
import { fetchConstantsAndTimezones } from 'reduxStore/configs/asyncActions';

export const useFetchConstantsAndTimezones = () => {
  const dispatch = useAppDispatch();

  useMount(() => {
    dispatch(fetchConstantsAndTimezones());
  });
};
