import { LogicApi } from 'api/LogicApi';
import { LogicTypeMapper } from 'mapper/LogicMapper';
import { Logic } from 'model/Logic';
import { LogicDistribution } from 'model/LogicDistribution';
import { LogicType } from 'model/LogicType';

export class LogicDistributionMapper
  implements LogicTypeMapper<LogicDistribution, LogicApi.DistributionEntry> {
  serialize(model: LogicDistribution): LogicApi.DistributionEntry {
    return {
      type: model.getType(),
      channel: model.getChannel(),
    };
  }

  deserialize(entry: LogicApi.DistributionEntry): LogicDistribution {
    return new LogicDistribution({
      id: entry.id,
      type: entry.type,
      channel: entry.channel,
    });
  }

  supportsSerialize(model: Logic): boolean {
    return model instanceof LogicDistribution;
  }

  supportsDeserialize(entry: LogicApi.Entry): boolean {
    return entry.type === LogicType.Distribution;
  }
}
