import { AccessCode } from 'model/AccessCode';
import { SimpleModel } from 'model/SimpleModel';

export namespace FailedEmails {
  export interface Shape {
    status: number;
    accessCode: AccessCode;
  }
}

export class FailedEmails extends SimpleModel<FailedEmails.Shape> {
  getStatus(): number {
    return this.get('status');
  }

  getAccessCode(): AccessCode {
    return this.get('accessCode');
  }
}
