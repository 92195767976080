import React from 'react';

import { ProjectSortBy } from 'model/ProjectSortBy';

export namespace ProjectSortByLabel {
  export type Props = {
    sortBy: ProjectSortBy;
    className?: string;
  };
}

const projectSortByLabel = {
  [ProjectSortBy.LastModified]: 'Last Modified',
  [ProjectSortBy.LiveDates]: 'Live Dates',
};

export function getProjectSortByLabel(sortBy: ProjectSortBy): string {
  return projectSortByLabel.hasOwnProperty(sortBy) ? projectSortByLabel[sortBy] : 'Unknown field';
}

export const ProjectSortByLabel: React.VFC<ProjectSortByLabel.Props> = (props) => (
  <span className={props.className}>{getProjectSortByLabel(props.sortBy)}</span>
);
