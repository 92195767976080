import qs from 'qs';
import { SortDirection } from 'shared/constants';

export type PaginationQuery = {
  page: number;
  limit?: number;
  filters?: { [key: string]: (number | string)[] | string | number };
  sort?: { [key: string]: SortDirection };
  search?: string;
};

export const createPaginationQuery = ({
  page,
  limit = 10,
  filters,
  sort,
  search,
}: PaginationQuery) => {
  const query = qs.stringify(
    {
      page: page,
      search: search,
      filter: filters,
      sort,
      limit,
    },
    { encode: false, skipNulls: true }
  );

  return `?${query}`;
};
