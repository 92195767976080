import { createAsyncThunk } from '@reduxjs/toolkit';
import { accessCodeRepository } from 'container/accessCodeRepository';
import { STORE_NAME } from 'reduxStore/accessCode/initialState';
import { serializeError } from 'shared/utils/redux';

export const fetchUnsubscribedAccessCodes = createAsyncThunk(
  `${STORE_NAME}/fetchUnsubscribedAccessCodes`,
  async ({ distributionId }: { distributionId: string }) => {
    return accessCodeRepository.unsubscribed(distributionId);
  },
  { serializeError }
);

export const searchAccessCodes = createAsyncThunk(
  `${STORE_NAME}/searchAccessCodes`,
  async ({ distributionId, query }: { distributionId: string; query: string }) => {
    return accessCodeRepository.search(distributionId, query);
  },
  { serializeError }
);
