import { grey } from '@mui/material/colors';
import Card from '@mui/material/Card';
import TableCell from '@mui/material/TableCell';
import { styled } from '@mui/material';

export const ClientsTableWrapper = (styled(Card)({
  fontWeight: 400,
}) as any) as typeof Card;

export const ClientHeaderColumnCentered = styled(TableCell)({
  fontWeight: ('500 !important' as any) as number,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ClientHeaderColumn = styled(TableCell)({
  fontWeight: ('500 !important' as any) as number,
});

export const NoClientsFoundInfo = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: 50,
  paddingLeft: 10,
  color: grey[400],
  fontSize: 14,
});
