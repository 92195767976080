import { LangCodeEnum } from 'shared/enums/LangCodeEnum';

export const LanguageCodeToLocaleDirectoryMapper: Record<LangCodeEnum, string> = {
  [LangCodeEnum.AFRIKAANS]: 'af_ZA',
  [LangCodeEnum.ARABIC_UAE]: 'ar_AE',
  [LangCodeEnum.BELGIAN]: 'nl_BE',
  [LangCodeEnum.BENGALI]: 'bn_IN',
  [LangCodeEnum.BOSNIAN]: 'bs_BA',
  [LangCodeEnum.BULGARIAN]: 'bg_BG',
  [LangCodeEnum.BURMESE]: 'my_MY',
  [LangCodeEnum.CHINESE_DEFAULT]: 'zh_CN',
  [LangCodeEnum.CHINESE_SIMPLIFIED]: 'zh_CN',
  [LangCodeEnum.CHINESE_TRADITIONAL]: 'zh_TW',
  [LangCodeEnum.CROATIAN]: 'hr_HR',
  [LangCodeEnum.CZECH]: 'cs_CZ',
  [LangCodeEnum.DANISH]: 'da_DK',
  [LangCodeEnum.DUTCH]: 'nl_NL',
  [LangCodeEnum.ENGLISH_AMERICAN]: 'en_US',
  [LangCodeEnum.ENGLISH_BRITISH]: 'en_GB',
  [LangCodeEnum.ENGLISH_DEFAULT]: 'en_GB',
  [LangCodeEnum.FILIPINO]: 'fil_PH',
  [LangCodeEnum.FINNISH]: 'fi_FI',
  [LangCodeEnum.FRENCH]: 'fr_FR',
  [LangCodeEnum.GERMAN]: 'de_DE',
  [LangCodeEnum.GUJARATI]: 'gu_IN',
  [LangCodeEnum.HINDI]: 'hi_IN',
  [LangCodeEnum.HUNGARIAN]: 'hu_HU',
  [LangCodeEnum.INDONESIAN]: 'id_ID',
  [LangCodeEnum.IRISH]: 'ga_IE',
  [LangCodeEnum.ITALIAN]: 'it_IT',
  [LangCodeEnum.JAPANESE]: 'ja_JP',
  [LangCodeEnum.KOREAN]: 'ko_KR',
  [LangCodeEnum.LATVIAN]: 'lv_LV',
  [LangCodeEnum.LITHUANIAN]: 'lt_LT',
  [LangCodeEnum.MALAGASY]: 'mg_MG',
  [LangCodeEnum.NORWEGIAN]: 'no_NO',
  [LangCodeEnum.PERSIAN]: 'fa_FA',
  [LangCodeEnum.POLISH]: 'pl_PL',
  [LangCodeEnum.PORTUGUESE]: 'pt_PT',
  [LangCodeEnum.PORTUGUESE_BRAZILIAN]: 'pt_BR',
  [LangCodeEnum.PORTUGUESE_DEFAULT]: 'pt_PT',
  [LangCodeEnum.PUNJABI_INDIAN]: 'pa_IN',
  [LangCodeEnum.ROMANIAN]: 'ro_RO',
  [LangCodeEnum.RUSSIAN]: 'ru_RU',
  [LangCodeEnum.SERBIAN]: 'sr_RS',
  [LangCodeEnum.SLOVAKIAN]: 'sk_SK',
  [LangCodeEnum.SLOVENIAN]: 'sl_SI',
  [LangCodeEnum.SPANISH]: 'es_ES',
  [LangCodeEnum.SPANISH_DEFAULT]: 'es_MX',
  [LangCodeEnum.SPANISH_MEXICAN]: 'es_MX',
  [LangCodeEnum.SWAHILI]: 'sw_KE',
  [LangCodeEnum.SWEDISH]: 'sv_SE',
  [LangCodeEnum.TAMIL]: 'ta_IN',
  [LangCodeEnum.TETUN]: 'tet_TET',
  [LangCodeEnum.TSONGA]: 'ts_TS',
  [LangCodeEnum.TURKISH]: 'tr_TR',
  [LangCodeEnum.UKRAINIAN]: 'uk_UA',
  [LangCodeEnum.URDU_PAKISTAN]: 'ur_PK',
  [LangCodeEnum.VIETNAMESE]: 'vi_VN',
  [LangCodeEnum.WELSH]: 'cy_GB',
  [LangCodeEnum.XHOSA]: 'xh_XH',
};
