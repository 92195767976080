import { createAsyncThunk } from '@reduxjs/toolkit';
import { emailDistributionRepository } from 'container/emailDistributionRepository';
import { scheduleRepository } from 'container/scheduleRepository';
import { Schedule } from 'model/Schedule';
import { STORE_NAME } from 'reduxStore/emailDistribution/initialState';
import { serializeError } from 'shared/utils/redux';

export const fetchEmailDistribution = createAsyncThunk(
  `${STORE_NAME}/fetchEmailDistribution`,
  async ({ projectId }: { projectId: number }) => {
    return emailDistributionRepository.get(projectId);
  },
  { serializeError }
);

export const scheduleEmailTemplate = createAsyncThunk(
  `${STORE_NAME}/scheduleEmailTemplate`,
  async ({ emailTemplateId, schedule }: { emailTemplateId: number; schedule: Schedule }) => {
    return scheduleRepository.schedule(emailTemplateId, schedule);
  },
  { serializeError }
);

export const unscheduleEmailTemplate = createAsyncThunk(
  `${STORE_NAME}/unscheduleEmailTemplate`,
  async ({ emailTemplateId, scheduleId }: { emailTemplateId: number; scheduleId: number }) => {
    return scheduleRepository.unschedule(emailTemplateId, scheduleId);
  },
  { serializeError }
);
