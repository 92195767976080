import { LifecycleLabel, LifecycleType } from 'model/Lifecycle';

export const mapLifecycleTypeToLifecycleLabel = (lifecycleType: LifecycleType): LifecycleLabel => {
  switch (lifecycleType) {
    case LifecycleType.ONBOARD:
      return LifecycleLabel.ONBOARD;
    case LifecycleType.EXIT:
      return LifecycleLabel.EXIT;
  }
};

export const mapLifecycleLabelToLifecycleType = (lifecycleLabel: LifecycleLabel): LifecycleType => {
  switch (lifecycleLabel) {
    case LifecycleLabel.ONBOARD:
      return LifecycleType.ONBOARD;
    case LifecycleLabel.EXIT:
      return LifecycleType.EXIT;
  }
};
