import React from 'react';

import { ImagePreview } from 'component/Dropzone/Dropzone.s';

export namespace DropzoneImagePreview {
  export type Props = {
    url?: string;
    file?: File;
  };
}

export const DropzoneImagePreview: React.VFC<DropzoneImagePreview.Props> = (props) => {
  if (props.url) {
    return <ImagePreview src={props.url} />;
  } else if (props.file && props.file.name) {
    return <span>{props.file.name}</span>;
  } else {
    return null;
  }
};
