import { match } from 'react-router-dom';

import { createResourceContentSelector } from 'util/selector/resourceSelector';
import { selectHasMatch, selectMatch } from 'util/selector/routerSelector';

import { AdminRoute } from 'app/route/admin';
import { AdminState } from 'reduxStore/appStore';
import { ProjectTemplateState } from 'reduxStore/projectTemplate/initialState';
import { createSelector } from '@reduxjs/toolkit';

export const selectProjectTemplateDomain = (state: AdminState): ProjectTemplateState.Domain => {
  return state.projectTemplate;
};

export const selectProjectTemplateListResource = createSelector(
  selectProjectTemplateDomain,
  (domain) => domain.listResource
);

export const selectProjectTemplateList = createResourceContentSelector(
  selectProjectTemplateListResource
);

export const selectCurrentProjectTemplateResource = createSelector(
  selectProjectTemplateDomain,
  (domain) => domain.currentResource
);

export const selectCurrentProjectTemplate = createResourceContentSelector(
  selectCurrentProjectTemplateResource
);

export const selectCurrentProjectTemplateId = createSelector(
  selectMatch(AdminRoute.PROJECT_TEMPLATE_PATTERN),
  selectMatch(AdminRoute.Library.SURVEY_PATTERN),
  (match: match<{ id: string }>) => match && parseInt(match.params.id, 10)
);

export const selectIsProjectTemplate = selectHasMatch(AdminRoute.PROJECT_TEMPLATE_PATTERN);
