import { createAsyncThunk } from '@reduxjs/toolkit';
import { clientRepository } from 'container/clientRepository';
import { serializeError } from 'shared/utils/redux';

import { STORE_NAME } from './initialState';

export const fetchClients = createAsyncThunk(
  `${STORE_NAME}/fetchClients`,
  async ({
    searchText,
    page,
    limit,
    circleId,
  }: {
    searchText: string;
    page: number;
    limit: number;
    circleId?: string;
  }) => {
    return clientRepository.get({ searchText, page, limit, circleId });
  },
  { serializeError }
);
