import { AxiosError, AxiosResponse } from 'axios';
import get from 'lodash/get';
import { Selector } from 'reselect';

/**
 * Select response code, for example 201
 */
export const selectResponseCode: Selector<AxiosResponse | undefined, number | undefined> = (
  response
) => response?.status;

export function selectResponseData<T extends AxiosResponse>(response?: T): T['data'];
export function selectResponseData<T extends AxiosResponse, K extends keyof T['data']>(
  response?: T,
  field?: K
): T['data'][K];
export function selectResponseData<T extends AxiosResponse, K extends keyof T['data'], F>(
  response?: T,
  field?: K,
  notSetValue?: F
): T['data'][K] | F;

export function selectResponseData(response: AxiosResponse, field?: string, notSetValue?: any) {
  const data = response && response.data;

  return field ? get(data || {}, field, notSetValue) : data;
}

export const selectIsAxiosError = (error: AxiosError | any): error is AxiosError =>
  error && error.response && error.response.status && error.isAxiosError;

export const selectErrorResponse = (error: AxiosError): AxiosResponse | undefined => error.response;

/**
 * Select error code, for example 403
 */
export const selectErrorCode = (error: AxiosError) =>
  selectResponseCode(selectErrorResponse(error));

export const selectErrorData = (error: AxiosError, field?: string, notSetValue?: any) =>
  selectResponseData(selectErrorResponse(error), field, notSetValue);
