import { buildProperFormatDate } from 'util/buildProperFormatDate';
import { checkIsProperDate } from 'util/checkIsProperDate';

import { DemographicApi } from 'api/DemographicApi';
import { Classification } from 'model/Classification';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from 'shared/constants';

export class ClassificationMapper {
  serialize(model: Classification): DemographicApi.ClassificationEntry {
    return {
      id: model.getId(),
      title: model.getTitle(),
      position: model.getPosition(),
    };
  }

  deserialize(
    entry: DemographicApi.ClassificationEntry,
    classificationsDate?: DemographicApi.ClassificationDateEntry[]
  ): Classification {
    const classificationDate =
      classificationsDate &&
      classificationsDate.find((item) => item.demography === entry.demographyId);

    return new Classification({
      id: entry.id,
      title: entry.title,
      position: entry.position,
      demographyId: entry.demographyId,
      demographyKind: entry.demographyKind,
      value: classificationDate
        ? checkIsProperDate(classificationDate.value)
          ? moment.utc(classificationDate.value, DEFAULT_DATE_FORMAT)
          : moment.utc(buildProperFormatDate(classificationDate.value), DEFAULT_DATE_FORMAT)
        : null,
    });
  }
}
