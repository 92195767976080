import { NumberingSkippable } from 'model/NumberingSkippable';
import { PageItemKind } from 'model/PageItemKind';
import { Question } from 'model/Question';
import { isSentimentSet, Sentiment, SentimentMap } from 'model/Sentiment';
import { SimpleModel } from 'model/SimpleModel';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';

export namespace QuestionCommentBox {
  export interface Shape extends Question.Shape {
    textBoxHeight: number;
    reportedAs: string;
    isRequired?: boolean;
    numberingSkipped?: boolean;
    sentiment: SentimentMap[Sentiment];
    locale?: LangCodeEnum;
  }
}

export class QuestionCommentBox
  extends Question<QuestionCommentBox.Shape>
  implements NumberingSkippable {
  constructor(initialData: SimpleModel.Data<QuestionCommentBox.Shape>) {
    super(initialData, PageItemKind.QuestionCommentsBox);
  }

  isSentimentEnabled(): boolean {
    return isSentimentSet(this.get('sentiment'));
  }

  getSentiment(): SentimentMap[Sentiment] {
    return this.get('sentiment');
  }

  isRequired(): boolean {
    return this.get('isRequired');
  }

  getTextBoxHeight(): number {
    return this.get('textBoxHeight');
  }

  getReportedAs(): string {
    return this.get('reportedAs');
  }

  isReportedInSync(): boolean {
    return this.getReportedAs() === this.getContent();
  }

  isNumberingSkipped(): boolean {
    return this.get('numberingSkipped');
  }

  getLocale() {
    return this.get('locale');
  }
}
