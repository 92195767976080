import { ProjectTemplateApi } from 'api/ProjectTemplateApi';
import { List } from 'immutable';
import { CategoryMapper } from 'mapper/CategoryMapper';
import { PageMapper } from 'mapper/PageMapper';
import { ProjectTemplate } from 'model/ProjectTemplate';
import moment from 'moment';

export class ProjectTemplateMapper {
  constructor(private categoryMapper: CategoryMapper, private pageMapper: PageMapper) {}

  serialize(model: ProjectTemplate): ProjectTemplateApi.Entry {
    return {
      id: model.getId(),
      name: model.getName(),
      createdAt: model.getCreatedAt() && model.getCreatedAt().format('YYYY-MM-DD HH:mm:ss'),
      updatedAt: model.getUpdatedAt() && model.getUpdatedAt().format('YYYY-MM-DD HH:mm:ss'),
      createdBy: model.getCreatedBy(),
      updatedBy: model.getUpdatedBy(),
      kind: model.getKind(),
      categories: model
        .getCategories()
        .map((category) => this.categoryMapper.serialize(category))
        .toArray(),
    };
  }

  deserialize(entry: ProjectTemplateApi.Entry): ProjectTemplate {
    return new ProjectTemplate({
      id: entry.id,
      name: entry.name,
      createdAt: moment.utc(entry.createdAt, 'YYYY-MM-DD HH:mm:ss'),
      updatedAt: moment.utc(entry.updatedAt, 'YYYY-MM-DD HH:mm:ss'),
      createdBy: entry.createdBy,
      updatedBy: entry.updatedBy,
      kind: entry.kind,
      categories: List(
        (entry.categories || []).map((categoryEntry) =>
          this.categoryMapper.deserialize(categoryEntry)
        )
      ),
      pages: List((entry.pages || []).map((pageEntry) => this.pageMapper.deserialize(pageEntry))),
    });
  }
}
