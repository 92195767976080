import { selectAdminApiUrl } from 'util/selector/apiSelector';

import { createSelector } from '@reduxjs/toolkit';
import { List } from 'immutable';
import { Employee } from 'model/Employee';
import { AdminState } from 'reduxStore/appStore';
import { selectCommitEmployeeDatabaseJob } from 'reduxStore/job/selectors';
import { selectCurrentProjectId } from 'reduxStore/project/projectSelector';

import { EmployeeState, STORE_NAME } from './initialState';

// TODO Detector remove optional & INITIAL_DOMAIN when detector will be totally removed
export const selectEmployeeDomain = (state: AdminState) =>
  state?.[STORE_NAME] ?? EmployeeState.INITIAL_DOMAIN;

export const selectEmployeeList = createSelector(selectEmployeeDomain, (domain) => domain.list);

// TODO Move outside, it is not selector related to employee
export const selectEmployeeTemplateUrl = createSelector(
  selectCurrentProjectId,
  selectAdminApiUrl,
  (projectId, adminApiUrl) => `${adminApiUrl}/api/v1/project/${projectId}/employees-template`
);

export const selectHasEmployees = createSelector(selectCommitEmployeeDatabaseJob, (job) =>
  job ? job.getExtendedJob().isSuccessful() : false
);

export const selectHasEmployeeList = createSelector(selectEmployeeList, (list) => !list?.isEmpty());

export const selectEmployeeListPagination = createSelector(
  selectEmployeeDomain,
  (domain) => domain?.listPagination
);

export const selectTotalEmployeesCount = createSelector(
  selectEmployeeListPagination,
  (pagination) => pagination?.getTotal()
);

export const selectEmployeeUploadStats = createSelector(
  selectEmployeeDomain,
  (domain) => domain?.employeeUploadStats
);

export const selectChosenEmployees = createSelector(
  selectEmployeeDomain,
  (domain) => domain?.chosenEmployees || List.of<Employee>()
);
