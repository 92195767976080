import { createResourceContentSelector } from 'util/selector/resourceSelector';

import { AutocompleteData } from 'component/Form/Autocomplete';
import { List } from 'immutable';
import { Enum } from 'model/Enum';
import { Timezone } from 'model/Timezone';
import { AdminState } from 'reduxStore/appStore';
import { createSelector } from '@reduxjs/toolkit';

import { STORE_NAME } from './initialState';

export const selectConstantsResource = (state: AdminState) => state[STORE_NAME].constants;

export const selectTimezonesResource = (state: AdminState) => state[STORE_NAME].timezones;

export const selectConstants = createResourceContentSelector(selectConstantsResource);

export const selectBenchmarkSectors = createSelector(selectConstants, (domain) =>
  domain ? domain.getBenchmarkSectors() : List<Enum>()
);

export const selectPulseFrequencies = createSelector(selectConstants, (domain) =>
  domain ? domain.getPulseFrequencies() : List<Enum>()
);

export const selectEmployeeRangeGroup = createSelector(selectConstants, (domain) =>
  domain ? domain.getEmployeeRangeGroup() : List<Enum>()
);

export const selectDemographicConstants = createSelector(
  selectConstants,
  (domain) => domain && domain.getDemographic()
);

export const selectDemographicEmploySinceConstant = createSelector(
  selectDemographicConstants,
  (demographics) =>
    demographics && demographics.find((item) => item.getKey() === 'DEMOGRAPHY_DATE_EMPLOYED_SINCE')
);

export const selectDemographicDateOfBirthConstant = createSelector(
  selectDemographicConstants,
  (demographics) =>
    demographics && demographics.find((item) => item.getKey() === 'DEMOGRAPHY_DATE_OF_BIRTH')
);

export const selectTimezones = createResourceContentSelector(selectTimezonesResource);

export const selectTimezoneAutocompleteOptions = createSelector(
  selectTimezones,
  (timezones): AutocompleteData<string>[] => {
    return timezones
      ? timezones
          .map((timezone: Timezone) => ({
            text: `(${timezone.getOffset()}) ${timezone.getName()}`,
            value: timezone.getId(),
          }))
          .toArray()
      : [];
  }
);
