import React from 'react';

import Button, { ButtonProps } from '@mui/material/Button';

export namespace DangerFlatButton {
  export type Props = ButtonProps & { label: string };
}

export const DangerFlatButton: React.VFC<DangerFlatButton.Props> = ({ label, ...props }) => (
  <Button color="error" {...props}>
    {label}
  </Button>
);
