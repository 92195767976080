export enum ProjectKind {
  PROJECT_CLIENT_ADVOCACY_SCORE = 'project_client_advocacy_score',
  PROJECT_ENGAGEMENT_SCORE = 'project_engagement_score',
  PROJECT_OVERALL_SCORE = 'project_overall_score',

  PULSE_TEMPLATE = 'pulse_template',
  PULSE_PROJECT = 'pulse_project',
  PULSE_ON_DEMAND_PROJECT = 'pulse_on_demand_project',
  PULSE_LIFECYCLE_PROJECT = 'pulse_lifecycle_project',
}
