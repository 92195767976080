import { selectApiData } from 'util/selector/apiSelector';

import { WithData } from 'api/interfaces';
import { ProjectApi } from 'api/ProjectApi';
import { SurveySettingsApi } from 'api/SurveySettingsApi';
import { AxiosInstance } from 'axios';

export namespace ProjectSettingsApi {
  export type Entry = {
    automaticQuestionNumbering: boolean;
    saveAndContinue: boolean;
    saveAndContinueContent: string;
    almostFinishedContent: string;
    thankYouPageContent: string;
    closedPageContent: string;
    aggregationMonths: number;
    surveyCodeQuestionContent: string;
    minimalAmountOfClosedAnswers: number;
    minimalAmountOfCommentAnswers: number;
    threePointScale: boolean;
    overallScore: boolean;
    respondentProtectionMode: number;
    inviteSentAt: string;
    dashboardNotifications?: boolean;
    sentimentAnalysis: boolean;
    lifecycleSurveys: boolean;
    lifecycleReminderInterval: string | null;
    showConsentQuestion: boolean;
    shareFeedbackWithLineManager: boolean;
    requireMultiFactorAuthentication: boolean;
    variableConfidentialityThreshold?: number | null;
  };
}

export class ProjectSettingsApi {
  constructor(private client: AxiosInstance) {}

  fetch(id: number): Promise<ProjectSettingsApi.Entry> {
    return this.client
      .get<WithData<ProjectSettingsApi.Entry>>(`/api/v1/project/${id}/settings`)
      .then(selectApiData);
  }

  update(id: number, data: SurveySettingsApi.Entry): Promise<ProjectApi.Entry> {
    return this.client.post(`/api/v1/project/${id}`, data).then(selectApiData);
  }

  updateDates(projectId: number, data: SurveySettingsApi.Dates): Promise<null> {
    return this.client
      .post<null>(`/api/v1/project/${projectId}/change-dates`, data)
      .then(() => null);
  }
}
