import { List } from 'immutable';
import { Answer } from 'model/Answer';
import { ProjectSubmitStatus } from 'model/ProjectSubmitStatus';
import { SimpleModel } from 'model/SimpleModel';
import { Moment } from 'moment';

export namespace ProjectSubmit {
  export interface Shape {
    id: number;
    status: ProjectSubmitStatus;
    submitTime?: Moment;
    email?: string;
    firstName?: string;
    lastName?: string;
    answers?: List<Answer>;
  }
}

export class ProjectSubmit extends SimpleModel<ProjectSubmit.Shape> {
  static filterByEmployee(
    filter: string,
    projectSubmits: List<ProjectSubmit>
  ): List<ProjectSubmit> {
    const normalize = (input: string = '') => input?.trim().toLowerCase() || '';
    const normalizedSearch = normalize(filter);

    return projectSubmits
      .filter(
        (projectSubmit) =>
          filter === '' ||
          normalize(projectSubmit.getEmail()).indexOf(normalizedSearch) !== -1 ||
          normalize(projectSubmit.getName()).indexOf(normalizedSearch) !== -1
      )
      .toList();
  }

  getId(): number {
    return this.get('id');
  }

  getStatus(): ProjectSubmitStatus {
    return this.get('status');
  }

  getSubmitTime(): Moment {
    return this.get('submitTime');
  }

  getEmail(): string {
    return this.get('email', '');
  }

  getFirstName(): string {
    return this.get('firstName', '');
  }

  getLastName(): string {
    return this.get('lastName', '');
  }

  getName(): string {
    return this.getFirstName() ? `${this.getFirstName()} ${this.getLastName()}` : '';
  }

  getEmployee(): string {
    return this.getEmail() || this.getName();
  }

  isStarted(): boolean {
    return this.getStatus() === ProjectSubmitStatus.Started;
  }

  isPartial(): boolean {
    return this.getStatus() === ProjectSubmitStatus.Partial;
  }

  isCompleted(): boolean {
    return this.getStatus() === ProjectSubmitStatus.Completed;
  }

  getAnswers(): List<Answer> {
    return this.get('answers');
  }
}
