import { createSlice } from '@reduxjs/toolkit';
import { addAsyncCases } from 'shared/utils/redux';

import { CirclesState, STORE_NAME } from './initialState';
import { fetchCircles } from './asyncActions';

export const circlesSlice = createSlice({
  name: STORE_NAME,
  initialState: CirclesState.INITIAL_DOMAIN,
  reducers: {},
  extraReducers: (builder) => {
    addAsyncCases(builder, fetchCircles, (state, action) => {
      state.list = action.payload;
    });
  },
});
