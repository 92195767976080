import qs from 'qs';

export const createClientQuery = ({
  searchText,
  page,
  limit,
  circleId,
}: {
  searchText: string;
  page: number;
  limit: number;
  circleId?: string;
}) => {
  return (
    '?' +
    qs.stringify(
      {
        search: searchText,
        page,
        limit,
        filter: { circleId },
      },
      { encode: false, skipNulls: true }
    )
  );
};
