import {
  ActionReducerMapBuilder,
  AsyncThunk,
  Draft,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import { Resource } from 'model/Resource';

export function addAsyncCases<State, Payload>(
  builder: ActionReducerMapBuilder<State>,
  asyncActionCreator: AsyncThunk<Payload, unknown, {}>,
  reducer: (state: Draft<State>, action: PayloadAction<Resource<Payload>>) => void
) {
  builder
    .addCase(asyncActionCreator.pending, (state, action) => {
      reducer(state, { ...action, payload: Resource.pending() });
    })
    .addCase(asyncActionCreator.fulfilled, (state, action) => {
      reducer(state, { ...action, payload: Resource.resolve(action.payload) });
    })
    .addCase(asyncActionCreator.rejected, (state, action) => {
      if (!action.meta.aborted) {
        // Error is serialized by @redux-toolkit into a plain object
        reducer(state, {
          ...action,
          payload: Resource.reject(action.payload ?? (action.error as SerializedError)),
        });
      }
    });
}
