import { convertObjectToArray } from 'util/convertObjectToArray';

import { TrendItemsApi } from 'api/TrendItemsApi';
import { List } from 'immutable';
import { DepartmentMapper } from 'mapper/DepartmentMapper';
import { Department } from 'model/Department';

export type TrendQuestion = {
  questionId: number;
  questionContent: string;
  categoryName: string;
  projectName: string;
};

export class TrendItemsRepository {
  constructor(private trendItemsApi: TrendItemsApi, private departmentMapper: DepartmentMapper) {}

  list(id: number): Promise<List<TrendQuestion>> {
    return this.trendItemsApi.list(id).then((result) => List(convertObjectToArray(result)));
  }

  setConnectedDepartments(sourceId: number, destinationId: number | null): Promise<Department> {
    return this.trendItemsApi
      .setConnectedDepartments(sourceId, destinationId)
      .then((result) => this.departmentMapper.deserialize(result));
  }
}
