import { selectAuthUiUrl } from 'util/selector/apiSelector';

import { AdminRoute } from 'app/route/admin';
import { ClientRoute } from 'app/route/client';
import posthog from 'posthog-js';
import { compilePath } from 'router/compilePath';

export const redirectToLogin = () => {
  if (posthog) {
    posthog.reset();
  }
  const referrer = `?referrer=${window.location.href}`;
  window.location.replace(`${selectAuthUiUrl()}${referrer}`);
};

export const redirectToProjectsList = () => {
  if (window.location.pathname === '/') {
    window.location.replace(AdminRoute.PROJECTS_PATTERN);
  }
};

export const redirectToClientsList = () => {
  if (window.location.pathname === '/') {
    window.location.replace(AdminRoute.CLIENTS_PATTERN);
  }
};

export const redirectToClientDashboard = ({ id }: { id: number }) => {
  if (window.location.pathname === '/') {
    window.location.replace(compilePath(ClientRoute.Project.COMPANY_SETTINGS, { id }));
  }
};
