import { createDepartmentTreeList } from 'util/createDepartmentTreeList';
import { selectAdminApiUrl } from 'util/selector/apiSelector';
import { createResourceContentSelector } from 'util/selector/resourceSelector';

import { List } from 'immutable';
import { Department } from 'model/Department';
import { Resource } from 'model/Resource';
import { DepartmentState } from 'reduxStore/department/initialState';
import { selectCurrentProjectOnlyId } from 'reduxStore/project/projectSelector';
import { createSelector } from '@reduxjs/toolkit';
import { AdminState } from 'reduxStore/appStore';

export function selectDepartmentDomain(state: AdminState): DepartmentState.Domain {
  return state && state.department;
}

export const selectDepartmentListResource = createSelector(
  selectDepartmentDomain,
  (domain) => (domain && domain.listResource) || Resource.void<List<Department>>()
);

export const selectDepartmentFlatListResource = createSelector(
  selectDepartmentListResource,
  (listResource) =>
    listResource.updateContent(
      (departments) =>
        departments.reduce(
          (nested, department) =>
            nested.push(department).concat(department.getNestedChildren()).toList(),
          List<Department>()
        ),
      List<Department>()
    )
);

export const selectDepartmentList = createResourceContentSelector(
  selectDepartmentListResource,
  List<Department>()
);
export const selectDepartmentFlatList = createResourceContentSelector(
  selectDepartmentFlatListResource
);

export const selectDepartmentTreeList = createSelector(selectDepartmentList, (departments) =>
  departments ? createDepartmentTreeList(departments) : []
);

export const selectDepartmentDryListResource = createSelector(
  selectDepartmentDomain,
  (domain) => (domain && domain.dryListResource) || Resource.void<List<Department>>()
);

export const selectIsDryUpload = createSelector(
  selectDepartmentDryListResource,
  (dryListResource) => dryListResource.isFulfilled()
);

export const selectDepartmentUploadedListResource = createSelector(
  selectDepartmentListResource,
  selectDepartmentDryListResource,
  (listResource, dryListResource) =>
    dryListResource.isFulfilled() ? dryListResource : listResource
);

export const selectExportToExcelUrl = createSelector(
  selectAdminApiUrl,
  selectCurrentProjectOnlyId,
  (adminApiUrl, projectId) => `${adminApiUrl}/api/v1/project/${projectId}/department/export`
);

export const selectHasDepartment = createSelector(
  selectDepartmentList,
  (departmentList) => departmentList && !departmentList.isEmpty()
);

export const selectHasDepartmentDryList = createSelector(
  selectDepartmentDryListResource,
  (dryListResource) => dryListResource.isFulfilled()
);

export const selectIsDepartmentListUploaded = createSelector(
  selectDepartmentListResource,
  (departmentResource) =>
    departmentResource &&
    departmentResource.hasContent() &&
    !departmentResource.getContent().isEmpty()
);
