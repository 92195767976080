import { SimpleModel } from 'model/SimpleModel';

export namespace PermissionFlag {
  export interface Shape {
    permissionId?: string;
    label: string;
  }
}

export class PermissionFlag extends SimpleModel<PermissionFlag.Shape> {
  static getLabelByPermissionId(permissionFlags: PermissionFlag[], permissionId: string): string {
    const permissionFlag =
      permissionFlags &&
      permissionFlags.find(
        (permissionFlagItem) => permissionFlagItem.getPermissionId() === permissionId
      );

    return permissionFlag ? permissionFlag.getLabel() : null;
  }

  getLabel(): string {
    return this.get('label');
  }

  getPermissionId(): string {
    return this.get('permissionId');
  }
}
