import { selectApiData } from 'util/selector/apiSelector';

import { DistributionApi } from 'api/DistributionApi';
import { AxiosInstance } from 'axios';

export namespace WebLinkDistributionApi {
  export type Entry = DistributionApi.Entry & {
    surveyLink: string;
  };
}

export class WebLinkDistributionApi {
  constructor(private client: AxiosInstance) {}

  get(projectId: number): Promise<WebLinkDistributionApi.Entry> {
    return this.client.get(`/api/v1/project/${projectId}/link-distribution`).then(selectApiData);
  }
}
