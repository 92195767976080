import { paginationMapper } from 'container/paginationMapper';
import { pulseProjectApi } from 'container/pulseProjectApi';
import { pulseProjectMapper } from 'container/pulseProjectMapper';
import { pulseTemplateMapper } from 'container/pulseTemplateMapper';
import { PulseProjectRepository } from 'repository/PulseProjectRepository';

export const pulseProjectRepository = new PulseProjectRepository(
  pulseProjectApi,
  pulseTemplateMapper,
  pulseProjectMapper,
  paginationMapper
);
