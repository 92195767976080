import { Job } from 'model/Job';
import { JobExtended } from 'model/JobExtended';
import { Resource } from 'model/Resource';
import { TakeLatest } from 'shared/utils/type';

export const STORE_NAME = 'job';

export namespace JobState {
  export type Domain = {
    projectJobs: TakeLatest<Resource<Job[]>>;
    emailDistributionJobs: TakeLatest<Resource<JobExtended[]>>;
    kioskDistributionJobs: TakeLatest<Resource<JobExtended[]>>;
  };

  export const INITIAL_DOMAIN: Domain = {
    projectJobs: {
      data: Resource.pending(),
      requestId: '',
    },
    emailDistributionJobs: {
      data: Resource.pending(),
      requestId: '',
    },
    kioskDistributionJobs: {
      data: Resource.pending(),
      requestId: '',
    },
  };
}
