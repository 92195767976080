import { ProjectLayoutApi } from 'api/ProjectLayoutApi';
import { ProjectLayout } from 'model/ProjectLayout';
import tinycolor from 'tinycolor2';

export class ProjectLayoutMapper {
  serialize(model: ProjectLayout): ProjectLayoutApi.Entry {
    return {
      questionTextColor: tinycolor(model.getQuestionTextColor()).toHexString(),
      backgroundColor: tinycolor(model.getBackgroundColor()).toHexString(),
      navigationAndProgressColor: tinycolor(model.getNavigationAndProgressColor()).toHexString(),
      saveContinueColor: tinycolor(model.getSaveContinueColor()).toHexString(),
    };
  }

  deserialize(entry: ProjectLayoutApi.Entry): ProjectLayout {
    return new ProjectLayout({
      questionTextColor: tinycolor(entry.questionTextColor).toRgb(),
      backgroundColor: tinycolor(entry.backgroundColor).toRgb(),
      navigationAndProgressColor: tinycolor(entry.navigationAndProgressColor).toRgb(),
      saveContinueColor: tinycolor(entry.saveContinueColor).toRgb(),
    });
  }
}
