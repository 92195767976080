import { projectMapper } from 'container/projectMapper';
import { projectSettingsApi } from 'container/projectSettingsApi';
import { projectSettingsMapper } from 'container/projectSettingsMapper';
import { surveySettingsMapper } from 'container/surveySettingsMapper';
import { ProjectSettingsRepository } from 'repository/ProjectSettingsRepository';

export const projectSettingsRepository: ProjectSettingsRepository = new ProjectSettingsRepository(
  projectSettingsApi,
  projectSettingsMapper,
  projectMapper,
  surveySettingsMapper
);
