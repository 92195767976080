import React from 'react';

import { Project } from 'model/Project';
import { ProjectKind } from 'model/ProjectKind';
import { ProjectStatus } from 'model/ProjectStatus';

export namespace ProjectStatusLabel {
  export type Props = StatusConfig & {
    className?: string;
  };

  export type StatusConfig = {
    status: ProjectStatus;
    kind?: ProjectKind;
  };
}

function getProjectStatusLabel({ status, kind }: ProjectStatusLabel.StatusConfig): string {
  switch (status) {
    case ProjectStatus.InDevelopment:
      return Project.isPulseTypeProject(kind) ? 'Scheduled' : 'In development';
    case ProjectStatus.Live:
      return 'Live';
    case ProjectStatus.Closed:
      return 'Closed';
    case ProjectStatus.Deleted:
      return 'Deleted';
    default:
      return 'Unknown status';
  }
}

export const ProjectStatusLabel: React.VFC<ProjectStatusLabel.Props> = (props) => {
  const status = getProjectStatusLabel({ status: props.status, kind: props.kind });
  return (
    <span className={props.className} data-project-status={status}>
      {status}
    </span>
  );
};
