import { LogicApi } from 'api/LogicApi';
import { Set } from 'immutable';
import { LogicTypeMapper } from 'mapper/LogicMapper';
import { Logic } from 'model/Logic';
import { LogicClassification } from 'model/LogicClassification';
import { LogicType } from 'model/LogicType';

export class LogicClassificationMapper
  implements LogicTypeMapper<LogicClassification, LogicApi.ClassificationEntry> {
  serialize(model: LogicClassification): LogicApi.ClassificationEntry {
    return {
      type: model.getType(),
      departments: model.getDepartmentsIds().toArray(),
      classifications: model.getClassificationsIds().toArray(),
    };
  }

  deserialize(entry: LogicApi.ClassificationEntry): LogicClassification {
    return new LogicClassification({
      type: entry.type,
      departmentsIds: Set<number>(entry.departments),
      classificationsIds: Set<number>(entry.classifications),
    });
  }

  supportsSerialize(model: Logic): boolean {
    return model instanceof LogicClassification;
  }

  supportsDeserialize(entry: LogicApi.Entry): boolean {
    return entry.type === LogicType.Classification;
  }
}
