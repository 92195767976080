import { permissionsApi } from 'container/permissionsApi';
import { permissionFlagMapper } from 'container/permissionFlagMapper';
import { permissionGroupMapper } from 'container/permissionGroupMapper';
import { permissionTypesMapper } from 'container/permissionTypesMapper';
import { PermissionsRepository } from 'repository/PermissionsRepository';

export const permissionsRepository = new PermissionsRepository(
  permissionsApi,
  permissionTypesMapper,
  permissionGroupMapper,
  permissionFlagMapper
);
