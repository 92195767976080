import { createClientQuery } from 'util/createClientQuery';

import { ClientApi } from 'api/ClientApi';
import { ClientMapper } from 'mapper/ClientMapper';
import { PaginationMapper } from 'mapper/PaginationMapper';
import { Client } from 'model/Client';
import { Pagination } from 'model/Pagination';

export class ClientRepository {
  constructor(
    private clientApi: ClientApi,
    private clientMapper: ClientMapper,
    private paginationMapper: PaginationMapper
  ) {}

  get({
    searchText,
    page,
    limit,
    circleId,
  }: {
    searchText: string;
    page: number;
    limit: number;
    circleId?: string;
  }): Promise<{ list: Client[]; pagination: Pagination }> {
    const query = createClientQuery({ searchText, page, limit, circleId });

    return this.clientApi.get(query).then((entry) => ({
      list: entry._embedded.items.map((client) => this.clientMapper.deserialize(client)),
      pagination: this.paginationMapper.deserialize(entry),
    }));
  }

  create(client: Client, logo?: File): Promise<Client> {
    return this.clientApi
      .create(this.clientMapper.serialize(client), logo)
      .then((entry) => this.clientMapper.deserialize(entry));
  }

  delete(id: number): Promise<void> {
    return this.clientApi.delete(id);
  }

  changePlan(id: number, plan: number): Promise<Client> {
    return this.clientApi
      .changePlan(id, plan)
      .then((entry) => this.clientMapper.deserialize(entry));
  }
}
