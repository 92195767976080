import { PermissionsApi } from 'api/PermissionsApi';

import { Permission } from '../model/Permission';

export class PermissionMapper {
  serialize(model: Permission): PermissionsApi.PermissionEntry {
    return {
      id: model.getId(),
      name: model.getName(),
      children: model.getChildren()
        ? model.getChildren().map((permission) => this.serialize(permission))
        : [],
    };
  }

  deserialize(entry: PermissionsApi.PermissionEntry): Permission {
    return new Permission({
      id: entry.id,
      name: entry.name,
      hint: entry.hint,
      isClientAdminBlocked: entry.clientAdminBlocked,
      isAdminBlocked: entry.wbAdminBlocked,
      children: entry.children
        ? entry.children.map((permission) => this.deserialize(permission))
        : [],
    });
  }
}
