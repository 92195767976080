import { createPaginationQuery } from 'util/createPaginationQuery';

import { PulseProjectApi } from 'api/PulseProjectApi';
import { List } from 'immutable';
import { PaginationMapper } from 'mapper/PaginationMapper';
import { PulseProjectMapper } from 'mapper/PulseProjectMapper';
import { PulseTemplateMapper } from 'mapper/PulseTemplateMapper';
import { LifecycleType } from 'model/Lifecycle';
import { Pagination } from 'model/Pagination';
import { Project } from 'model/Project';
import { ProjectKind } from 'model/ProjectKind';
import { ProjectStatus } from 'model/ProjectStatus';
import { PulseTemplate } from 'model/PulseTemplate';
import { Moment } from 'moment';
import { PaginationQuery } from 'repository/ProjectRepository';
import { SortDirection } from 'shared/constants';

export type FetchSubProjectsQuery = PaginationQuery & {
  kind?: ProjectKind;
  status?: ProjectStatus[];
  order?: SortDirection;
};

export type PulseFrequencyData = {
  startDate: Moment;
  pulseFrequencyId: string;
};

export class PulseProjectRepository {
  constructor(
    private pulseProjectApi: PulseProjectApi,
    private pulseTemplateMapper: PulseTemplateMapper,
    private pulseProjectMapper: PulseProjectMapper,
    private paginationMapper: PaginationMapper
  ) {}

  fetch(): Promise<PulseTemplate> {
    return this.pulseProjectApi
      .fetch()
      .then((entry) => this.pulseTemplateMapper.deserialize(entry));
  }

  get(pulseTemplateId: Project.Shape['id']): Promise<PulseTemplate> {
    return this.pulseProjectApi
      .get(pulseTemplateId)
      .then((entry) => this.pulseTemplateMapper.deserialize(entry));
  }

  create(pulseTemplateId: Project.Shape['id'], data: PulseFrequencyData): Promise<PulseTemplate> {
    return this.pulseProjectApi
      .create(pulseTemplateId, data)
      .then((entry) => this.pulseTemplateMapper.deserialize(entry));
  }

  updatePulseFrequency(
    pulseTemplateId: Project.Shape['id'],
    data: PulseFrequencyData
  ): Promise<PulseTemplate> {
    return this.pulseProjectApi
      .updateFrequency(pulseTemplateId, data)
      .then((entry) => this.pulseTemplateMapper.deserialize(entry));
  }

  distributeLifecycle(
    pulseTemplateId: Project.Shape['id'],
    lifecycleType: LifecycleType,
    data: number[]
  ): Promise<void> {
    return this.pulseProjectApi.distributeLifecycle(pulseTemplateId, lifecycleType, data);
  }

  fetchSubProjects(
    templateId: Project.Shape['id'],
    config: FetchSubProjectsQuery
  ): Promise<{
    list: List<Project>;
    pagination: Pagination;
  }> {
    const query = createPaginationQuery({
      page: config.page,
      limit: 9,
      sort: {
        liveStop: config.order,
      },
      filters: {
        kind: config.kind,
        status: config.status,
      },
    });

    return this.pulseProjectApi.getSubProjects(templateId, query).then(({ list, pagination }) => {
      return {
        list: List(list.map((entry) => this.pulseProjectMapper.deserialize(entry))),
        pagination: this.paginationMapper.deserialize(pagination),
      };
    });
  }

  uploadRespondents(
    pulseTemplateId: Project.Shape['id'],
    lifecycleType: LifecycleType,
    file: File
  ): Promise<void> {
    return this.pulseProjectApi.uploadRespondents(pulseTemplateId, lifecycleType, file);
  }
}
