import { List } from 'immutable';
import { DeliveryStatistic } from 'model/DeliveryStatistic';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'deliveryStatistic';

export namespace DeliveryStatisticState {
  export type Domain = {
    listResource: Resource<List<DeliveryStatistic>>;
  };

  export const INITIAL_DOMAIN: Domain = {
    listResource: Resource.void<List<DeliveryStatistic>>(),
  };
}
