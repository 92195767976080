import { selectApiData } from 'util/selector/apiSelector';

import { DistributionApi } from 'api/DistributionApi';
import { EmailTemplateApi } from 'api/EmailTemplateApi';
import { AxiosInstance } from 'axios';

export namespace EmailDistributionApi {
  export type Entry = DistributionApi.Entry & {
    invitation?: EmailTemplateApi.Entry;
    reminders?: EmailTemplateApi.Entry[];
  };
}

export class EmailDistributionApi {
  constructor(private client: AxiosInstance) {}

  get(projectId: number): Promise<EmailDistributionApi.Entry> {
    return this.client.get(`/api/v1/project/${projectId}/email-distribution`).then(selectApiData);
  }

  test(distributionId: string, to: string[], content: string, subject?: string): Promise<any> {
    return this.client.post(`/api/v1/distribution/${distributionId}/test`, {
      to,
      content,
      subject,
    });
  }
}
