import { List } from 'immutable';
import { Demographic } from 'model/Demographic';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'demographic';

export namespace DemographicState {
  type Demographics = List<Demographic>;
  type ListResource = Resource<Demographics>;

  export type Domain = {
    listResource: ListResource;
    reportListResource: ListResource;
  };

  export const INITIAL_DOMAIN: Domain = {
    listResource: Resource.void(),
    reportListResource: Resource.void(),
  };
}

export type DemographicState = {
  demographic?: DemographicState.Domain;
};
