import React from 'react';

import { DropzoneMessage } from 'component/Dropzone/Dropzone.s';

export const DropzoneMultiFilesMessage: React.VFC<{ files: File[] }> = ({ files }) => (
  <DropzoneMessage>
    Selected {files.length} files:
    <br />
    {files.map((file) => file.name).join(', ')}
  </DropzoneMessage>
);
