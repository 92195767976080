import { UserApi } from 'api/UserApi';
import { User } from 'model/User';

import { PermissionGroupMapper } from './PermissionGroupMapper';

export class UserMapper {
  constructor(private permissionGroupMapper: PermissionGroupMapper) {}

  serialize(model: User): UserApi.Post {
    return {
      id: model.getId(),
      firstName: model.getFirstName(),
      lastName: model.getLastName(),
      email: model.getEmail(),
      password: model.getPassword(),
      isClientAdmin: model.isClientAdmin(),
      department: model.getDepartment(),
      groups: model.getPermissionGroups().map((permissionGroup) => permissionGroup.getId()),
    };
  }

  deserialize(entry: UserApi.Entry): User {
    return new User({
      id: entry.id,
      firstName: entry.firstName,
      lastName: entry.lastName,
      email: entry.email,
      permissions: entry.permissions,
      role: entry.role,
      isClientAdmin: entry.isClientAdmin,
      groups: entry.groups
        ? entry.groups.map((group) => this.permissionGroupMapper.deserialize(group))
        : [],
    });
  }
}
