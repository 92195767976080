import { selectApiData } from 'util/selector/apiSelector';

import { AxiosInstance } from 'axios';

export namespace StaticResourceApi {
  export type Entry = {
    id?: number;
    file: string;
    fileOriginalName?: string;
    fileSize?: number;
    fileMimeType?: string;
  };
}

export class StaticResourceApi {
  constructor(private client: AxiosInstance) {}

  list(): Promise<StaticResourceApi.Entry[]> {
    return this.client.get(`/api/v1/static/resource`).then(selectApiData);
  }
}
