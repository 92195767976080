import { DeliveryStatisticApi } from 'api/DeliveryStatisticApi';
import { AccessCodeMapper } from 'mapper/AccessCodeMapper';
import { FailedEmails } from 'model/FailedEmails';

export class FailedEmailsMapper {
  constructor(private accessCodeMapper: AccessCodeMapper) {}

  serialize(model: FailedEmails): DeliveryStatisticApi.FailedEmailsEntry {
    return {
      status: model.getStatus(),
      accessCode: this.accessCodeMapper.serialize(model.getAccessCode()),
    };
  }

  deserialize(entry: DeliveryStatisticApi.FailedEmailsEntry): FailedEmails {
    return new FailedEmails({
      status: entry.status,
      accessCode: this.accessCodeMapper.deserialize(entry.accessCode),
    });
  }
}
