import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { relativePath } from 'router/relativePath';

export function createRelativeRoute(baseRoute: string): React.VFC<RouteProps> {
  return ({ path, ...props }: RouteProps) => {
    if (typeof path === 'string') {
      return <Route path={relativePath(baseRoute, path)} {...props} />;
    }

    console.log('PATH IS AN ARRAY');
    return null;
  };
}
