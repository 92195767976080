import { match, matchPath } from 'react-router-dom';

import { Location } from 'history';
import memoize from 'lodash/memoize';
import { parse as parseQuery } from 'query-string';
import { AdminState } from 'reduxStore/appStore';
import { createSelector, Selector } from 'reselect';

export function selectLocation(state: AdminState): Location {
  return (state && state.router && state.router.location) || undefined;
}

export const selectLocationPath = createSelector(
  selectLocation,
  (location) => (location && location.pathname) || undefined
);

export const selectQueryString = createSelector(selectLocation, (location) => {
  let queryString = (location && location.search) || '';
  if (queryString.startsWith('?')) {
    queryString = queryString.substring(1);
  }

  return queryString;
});

export const selectQueryParams = createSelector(selectQueryString, (queryString) =>
  parseQuery(queryString)
);

export const selectQueryParam: <T = any>(param: string) => Selector<any, T> = memoize(
  (param: string) =>
    createSelector(selectQueryParams, (params) => params[param]) as Selector<any, any>
);

export const selectQueryParamForMatch: <T = any>(
  param: string,
  ...routes: string[]
) => Selector<any, T> = memoize(
  (param: string, ...routes: string[]) =>
    createSelector(selectQueryParams, selectMatch(...routes), (params: any, match: match<any>) =>
      match ? params[param] : undefined
    ) as Selector<any, any>,
  (param: string, ...routes: string[]) => `[${param}]${routes.join(' ')}`
);

export const selectQueryParamForExactMatch: <T = any>(
  param: string,
  ...routes: string[]
) => Selector<any, T> = memoize(
  (param: string, ...routes: string[]) =>
    createSelector(selectQueryParams, selectExactMatch(...routes), (params, match) =>
      match ? params[param] : undefined
    ) as Selector<any, any>,
  (param: string, ...routes: string[]) => `[${param}]${routes.join(' ')}`
);

export const selectMatch: <P = any>(...routes: string[]) => Selector<any, match<P>> = memoize(
  (...routes: string[]) =>
    createSelector(
      selectLocationPath,
      (currentPath) =>
        currentPath &&
        routes.reduce(
          (match, route) => match || matchPath(currentPath, { path: route, exact: false }),
          null
        )
    ) as Selector<any, match<any>>,
  (...routes: string[]) => routes.join(' ')
);

export const selectExactMatch = <P = any>(...routes: string[]): Selector<any, match<P>> =>
  createSelector(
    selectLocationPath,
    (currentPath) =>
      currentPath &&
      routes.reduce(
        (match, route) => match || matchPath(currentPath, { path: route, exact: true }),
        null
      )
  ) as Selector<any, match<any>>;

export const selectHasMatch: (...routes: string[]) => Selector<any, boolean> = memoize(
  (...routes: string[]) =>
    createSelector(selectMatch(...routes), (match) => !!match) as Selector<any, boolean>,
  (...routes: string[]) => routes.join(' ')
);

export const selectHasExactMatch: (...routes: string[]) => Selector<any, boolean> = memoize(
  (...routes: string[]) =>
    createSelector(selectExactMatch(...routes), (match) => !!match) as Selector<any, boolean>,
  (...routes: string[]) => routes.join(' ')
);

export const selectHasPathExactMatch: (...paths: string[]) => Selector<any, boolean> = memoize(
  (...paths: string[]) =>
    createSelector(selectLocationPath, (currentPath) =>
      paths.some((path) => path === currentPath)
    ) as Selector<any, boolean>,
  (...paths: string[]) => paths.join(' ')
);
