import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMount } from 'react-use';

import { ProjectStatus } from 'model/ProjectStatus';
import { projectRepository } from 'container/projectRepository';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { ConfirmationModal } from 'component/ConfirmationModal/ConfirmationModal';
import { CreateProjectDialog } from 'component/CreateProject/CreateProjectDialog';
import { DeleteConfirmationDialog } from 'component/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { BaseTextField } from 'component/Form/BaseTextField';
import { ProjectSortByLabel } from 'component/ProjectSortByLabel/ProjectSortByLabel';
import { ProjectStatusLabel } from 'component/ProjectStatusLabel/ProjectStatusLabel';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { ReopenProjectModal } from 'component/SelectStopDate/ReopenProjectModal';
import { SingleProject } from 'component/SingleProject/SingleProject';
import { Exception } from 'exception/Exception';
import { List } from 'immutable';
import { Project } from 'model/Project';
import { ProjectSortBy } from 'model/ProjectSortBy';
import { useAppDispatch } from 'reduxStore/appStore';
import { selectHasRegularProjects, selectCircles } from 'reduxStore/circles/selectors';
import { openModal } from 'reduxStore/modal/slice';
import { fetchProjectList } from 'reduxStore/project/project/asyncActions';
import {
  selectProjectListPagination,
  selectProjectListResource,
} from 'reduxStore/project/projectSelector';
import { fetchProjectTemplateList } from 'reduxStore/projectTemplate/asyncActions';
import { openSnackbar } from 'reduxStore/snackbars/asyncActions';
import { AdminModal } from 'register/AdminModal';
import { usePaginationPage } from 'shared/hooks/usePaginationPage';
import { useSearchFilters } from 'shared/hooks/useSearchFilters';
import { ProjectListPaginationView } from 'view/ProjectListPaginationView/ProjectListPaginationView';
import { ProjectListFilter, projectListFilterEmptyValue } from 'view/ProjectsListPage/model';
import { selectIsCircleAdmin } from 'reduxStore/auth/selectors';

import { MenuTitle } from './ProjectsListPage.s';

const ProjectsList: React.VFC = () => {
  const dispatch = useAppDispatch();

  const listResource = useSelector(selectProjectListResource);

  const circles = useSelector(selectCircles);
  const hasSingleCircle = circles.size === 1;
  const singleCircleId = circles.first()?.getId();

  const projectListPagination = useSelector(selectProjectListPagination);
  const { page, limit, resetPaginationPage, setPaginationPage } = usePaginationPage({
    initialValue: { page: 1, limit: 10 },
    paginationResult: {
      pages: projectListPagination?.getPages(),
      total: projectListPagination?.getTotal(),
    },
  });
  const { filters, debouncedFilters, mergeFilters } = useSearchFilters<ProjectListFilter>(
    {
      searchText: '',
      circleId: projectListFilterEmptyValue,
      status: projectListFilterEmptyValue,
      sortBy: ProjectSortBy.LastModified,
    },
    { resetPaginationPage }
  );

  const loadProjectList = useCallback(() => {
    const circleIdFilterValue =
      filters.circleId === projectListFilterEmptyValue ? undefined : filters.circleId;
    const circleId = hasSingleCircle ? singleCircleId : circleIdFilterValue;
    const status = filters.status === projectListFilterEmptyValue ? undefined : filters.status;

    dispatch(
      fetchProjectList({
        page,
        limit,
        searchText: debouncedFilters.searchText,
        sortBy: filters.sortBy,
        circleId,
        status,
      })
    );
  }, [
    debouncedFilters.searchText,
    dispatch,
    filters.circleId,
    filters.sortBy,
    filters.status,
    hasSingleCircle,
    limit,
    page,
    singleCircleId,
  ]);

  useMount(() => {
    dispatch(fetchProjectTemplateList());
  });

  useEffect(() => {
    loadProjectList();
  }, [loadProjectList]);

  const onCreateRequest = () => dispatch(openModal({ name: AdminModal.CreateProject, params: {} }));

  const onDelete = (projectId: number) =>
    projectRepository
      .delete(projectId)
      .then(() => loadProjectList())
      .then(() => dispatch(openSnackbar({ message: 'Project deleted successfully.' })))
      .catch((error: Exception) =>
        dispatch(openSnackbar({ message: `Cannot delete project - ${error.getMessage()}` }))
      );

  const onClose = (projectId: number) =>
    projectRepository
      .close(projectId)
      .then(() => loadProjectList())
      .then(() => dispatch(openSnackbar({ message: 'Project closed successfully.' })))
      .catch((error: Exception) => {
        dispatch(openSnackbar({ message: error.getMessage() }));
      });

  const renderProjects = (): JSX.Element => {
    return (
      <ResourceLoader
        resource={listResource}
        content={(list: List<Project>) =>
          list.isEmpty() ? (
            <Box textAlign="center">No projects found</Box>
          ) : (
            <>
              {list.map((project) => (
                <SingleProject key={project.getId()} project={project} />
              ))}
              <ProjectListPaginationView
                listResource={listResource}
                onPageChange={setPaginationPage}
                currentPage={page}
                totalPages={projectListPagination?.getPages()}
              />
            </>
          )
        }
      />
    );
  };

  const renderModals = (): JSX.Element => {
    return (
      <div>
        <CreateProjectDialog />
        <ReopenProjectModal loadProjectList={loadProjectList} />

        <DeleteConfirmationDialog
          name={AdminModal.DeleteProject}
          onConfirm={(params: any) => onDelete(params.projectId)}
        >
          Are you sure you want to delete this project?
        </DeleteConfirmationDialog>

        <ConfirmationModal
          name={AdminModal.CloseProject}
          data-testid="closeProjectConfirmationButton"
          onConfirm={(params) => onClose(params.projectId)}
        >
          Are you sure you want to close this project?
        </ConfirmationModal>
      </div>
    );
  };

  return (
    <>
      <Grid container columnSpacing={2} mb={3} alignItems="end" justifyContent="space-between">
        <Grid item xs={12} sm={3}>
          <Button
            id="createProjectButton"
            endIcon={<EventNoteIcon />}
            onClick={() => onCreateRequest()}
            fullWidth
            color="primary"
            variant="contained"
          >
            Create Project
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
        <Grid item xs={12} sm={3} mt={1}>
          <BaseTextField
            value={filters.searchText}
            onChange={(event) => {
              mergeFilters({ searchText: event.target.value });
            }}
            data-testid="searchProjectsText"
            label="Search Projects..."
            fullWidth
            removeBottomMargin
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container columnSpacing={2} mt={2} alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={3}>
          <MenuTitle>Projects</MenuTitle>
        </Grid>
        <Grid item xs={12} sm={3}>
          {circles.size > 1 && (
            <BaseTextField
              fullWidth
              data-testid="circleFilterSelect"
              label="Filter by circle"
              value={filters.circleId}
              onChange={(event) => {
                mergeFilters({ circleId: event.target.value });
              }}
              select
            >
              <MenuItem value={projectListFilterEmptyValue}>Show all</MenuItem>
              {circles.map((circle, index) => (
                <MenuItem key={index} value={circle.getId()}>
                  {circle.getName()}
                </MenuItem>
              ))}
            </BaseTextField>
          )}
        </Grid>
        <Grid item xs={12} sm={3}>
          <BaseTextField
            fullWidth
            data-testid="statusFilterSelect"
            label="Filter by status"
            value={filters.status}
            onChange={(event) => {
              mergeFilters({ status: (event.target.value as unknown) as ProjectStatus | null });
            }}
            select
          >
            <MenuItem value={projectListFilterEmptyValue}>Show all</MenuItem>
            {Project.STATUSES.map((status, index) => (
              <MenuItem key={index} value={status}>
                <ProjectStatusLabel status={status} />
              </MenuItem>
            ))}
          </BaseTextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <BaseTextField
            fullWidth
            label="Sort by"
            value={filters.sortBy}
            onChange={(event) => {
              mergeFilters({
                sortBy: (event.target.value as unknown) as ProjectListFilter['sortBy'],
              });
            }}
            data-testid="sortBySelect"
            select
          >
            {Project.SORT_BYS.map((sortBy, index) => (
              <MenuItem key={index} value={sortBy}>
                <ProjectSortByLabel sortBy={sortBy} />
              </MenuItem>
            ))}
          </BaseTextField>
        </Grid>
      </Grid>
      {renderModals()}
      {renderProjects()}
    </>
  );
};

export const ProjectsListPage: React.VFC = () => {
  const hasRegularProjects = useSelector(selectHasRegularProjects);
  const isCircleAdmin = useSelector(selectIsCircleAdmin);

  return isCircleAdmin && !hasRegularProjects ? (
    <Box justifyContent="center" display="flex">
      <Typography>You don't have permission to view this page</Typography>
    </Box>
  ) : (
    <ProjectsList />
  );
};
