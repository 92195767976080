import { RGBColor } from 'react-color';

import { isArrayEmpty } from 'util/isArrayEmpty';

import { List } from 'immutable';
import { SimpleModel } from 'model/SimpleModel';

export namespace Client {
  export interface Shape {
    id?: number;
    circleId: string;
    plan: number;
    name: string;
    logo?: string;
    brandColor?: RGBColor;
    timezone: string;
    approxEmployeeNumber?: number;
    sectors?: List<number>;
    pulseTemplates?: List<number>;
    url: string;
  }
}

export class Client extends SimpleModel<Client.Shape> {
  getId(): number {
    return this.get('id');
  }

  getCircleId(): string {
    return this.get('circleId');
  }

  getName(): string {
    return this.get('name');
  }

  getUrl(): Client.Shape['url'] {
    return this.get('url');
  }

  getLogo(): string {
    return this.get('logo');
  }

  getBrandColor(): RGBColor {
    return this.get('brandColor');
  }

  getTimezone(): string {
    return this.get('timezone');
  }

  getSectors(): List<number> {
    return this.get('sectors');
  }

  getApproxEmployeeNumber(): number {
    return this.get('approxEmployeeNumber');
  }

  getPlan(): number {
    return this.get('plan');
  }

  getPulseTemplates(): List<number> {
    return this.get('pulseTemplates');
  }

  getFirstPulseTemplateId(): number {
    return !isArrayEmpty(this.getPulseTemplates()) ? this.getPulseTemplates().get(0) : null;
  }
}
