import { selectErrorData, selectResponseData } from 'util/selector/axiosSelector';

import { getAppConfig } from 'app/getConfig';
import { AxiosError, AxiosResponse } from 'axios';
import { SingleApiError } from 'exception/Exception.types';

export const selectAdminApiUrl = () => getAppConfig().ADMIN_API_CLIENT_URL;
export const selectAuthApiUrl = () => getAppConfig().AUTH_API_CLIENT_URL;
export const selectReportApiUrl = () => getAppConfig().REPORT_API_CLIENT_URL;
export const selectAuthUiUrl = () => getAppConfig().AUTH_UI_URL;
export const selectReportUiUrl = () => getAppConfig().REPORT_UI_URL;
export const selectSurveyUiUrl = () => getAppConfig().SURVEY_UI_URL;

export const selectApiData = <T extends AxiosResponse>(response: T) =>
  selectResponseData<T, 'data'>(response, 'data');
export const selectApiNoContent: () => void = () => null;

export const selectApiErrors = <T = SingleApiError[]>(error: AxiosError): T =>
  selectErrorData(error, 'errors');
export const selectApiError = (error: AxiosError): SingleApiError =>
  selectErrorData(error, 'errors[0]');
