import { createAsyncThunk } from '@reduxjs/toolkit';
import { staticResourceRepository } from 'container/staticResourceRepository';
import { STORE_NAME } from 'reduxStore/staticResource/initialState';
import { serializeError } from 'shared/utils/redux';

export const fetchStaticResourceList = createAsyncThunk(
  `${STORE_NAME}/fetchStaticResourceList`,
  async () => {
    return staticResourceRepository.list();
  },
  { serializeError }
);
