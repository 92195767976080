import { WebLinkDistributionApi } from 'api/WebLinkDistributionApi';
import { DistributionMapperByChannel } from 'mapper/DistributionMapper';
import { DistributionChannel } from 'model/DistributionChannel';
import { WebLinkDistribution } from 'model/WebLinkDistribution';

export class WebLinkDistributionMapper
  implements DistributionMapperByChannel<WebLinkDistribution, WebLinkDistributionApi.Entry> {
  serialize(model: WebLinkDistribution): WebLinkDistributionApi.Entry {
    return {
      id: model.getId(),
      slug: model.getSlug(),
      surveyLink: model.getLink(),
    };
  }

  deserialize(entry: WebLinkDistributionApi.Entry): WebLinkDistribution {
    return new WebLinkDistribution({
      id: entry.id,
      slug: entry.slug,
      link: entry.surveyLink,
    });
  }

  supportsSerialize(model: WebLinkDistribution): boolean {
    return model.getChannel() === DistributionChannel.WebLink;
  }

  supportsDeserialize(entry: WebLinkDistributionApi.Entry): boolean {
    return entry.channel === DistributionChannel.WebLink;
  }
}
