import { selectAdminApiUrl } from 'util/selector/apiSelector';
import { selectErrorCode, selectIsAxiosError } from 'util/selector/axiosSelector';

import { redirectToLogin } from 'reduxStore/auth/utils';
import axios, { AxiosInstance } from 'axios';
import { createException, createUnknownException } from 'exception/createException';
import { UNAUTHORIZED } from 'http-status-codes';
import { logInterceptedError } from 'shared/hooks/useInitializeDataDogLogs';

const backendApiClient: AxiosInstance = axios.create({
  baseURL: selectAdminApiUrl(),
  withCredentials: true,
  // `headers` are custom headers to be sent
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'en-GB',
    Accept: 'application/json',
  },
});

// redirect to login page on 401 UNAUTHORIZED code
backendApiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorCode = selectErrorCode(error);

    logInterceptedError(error);

    if (!selectIsAxiosError(error)) {
      throw createUnknownException();
    }

    if (errorCode === UNAUTHORIZED) {
      redirectToLogin();
    }

    throw createException(error);
  }
);

export { backendApiClient };
