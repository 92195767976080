import { DeliveryStatisticApi } from 'api/DeliveryStatisticApi';
import { List } from 'immutable';
import { FailedEmailsMapper } from 'mapper/FailedEmailsMapper';
import { DeliveryStatistic } from 'model/DeliveryStatistic';

export class DeliveryStatisticMapper {
  constructor(private failedMapper: FailedEmailsMapper) {}

  serialize(model: DeliveryStatistic): DeliveryStatisticApi.Entry {
    return {
      delivered: model.getDelivered().toArray(),
      failed: model
        .getFailed()
        .map((item) => this.failedMapper.serialize(item))
        .toArray(),
      enqueued: model.getEnqueued().toArray(),
    };
  }

  deserialize(entry: DeliveryStatisticApi.Entry): DeliveryStatistic {
    return new DeliveryStatistic({
      delivered: List(entry.delivered),
      failed: List(entry.failed.map((item) => this.failedMapper.deserialize(item))),
      enqueued: List(entry.enqueued),
      emailTemplateId: entry.emailTemplate.id,
      emailTemplateJobId: entry.emailTemplate.job ? entry.emailTemplate.job.id : null,
    });
  }
}
