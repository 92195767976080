import { createCompanyQuery } from 'util/createCompanyQuery';

import { CompanyApi } from 'api/CompanyApi';
import { List } from 'immutable';
import { CompanyMapper } from 'mapper/CompanyMapper';
import { ProjectMapper } from 'mapper/ProjectMapper';
import { Company } from 'model/Company';
import { Project } from 'model/Project';

export class CompanyRepository {
  constructor(
    private companyApi: CompanyApi,
    private companyMapper: CompanyMapper,
    private projectMapper: ProjectMapper
  ) {}

  list(): Promise<List<Company>> {
    const query = createCompanyQuery();

    return this.companyApi.getList(query).then((list = []) => {
      return List(list.map((entry) => this.companyMapper.deserialize(entry)));
    });
  }

  projectList(query: string): Promise<List<Project>> {
    return this.companyApi
      .getProjectList(query)
      .then((result) =>
        List(result._embedded.items.map((entry) => this.projectMapper.deserialize(entry)))
      );
  }

  updateEnabledLanguages(id: number, languages: string[]): Promise<CompanyApi.EnabledLocales> {
    return this.companyApi.updateEnabledLanguages(id, languages);
  }

  updateEnabledDashboardLanguages(
    id: number,
    languages: string[],
    enableDashboardLanguages: boolean,
    translateComments: boolean
  ): Promise<CompanyApi.EnabledLocales> {
    return this.companyApi.updateEnabledDashboardLanguages(
      id,
      languages,
      enableDashboardLanguages,
      translateComments
    );
  }
}
