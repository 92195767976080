import { List } from 'immutable';
import { Project } from 'model/Project';
import { SimpleModel } from 'model/SimpleModel';

export namespace PulseTemplate {
  export interface Shape {
    hasChildProjectsAnyPublishableSubmits: boolean;
    project: Project;
    pulseProjects: List<Project>;
    pulseOnDemandPolls: List<Project>;
    pulseLifecycleProjects: List<Project>;
  }
}

export class PulseTemplate extends SimpleModel<PulseTemplate.Shape> {
  static getDefaultPulseFrequencyId(): string {
    return 'quarterly';
  }

  hasChildProjectsAnyPublishableSubmits(): boolean {
    return this.get('hasChildProjectsAnyPublishableSubmits');
  }

  getProject(): Project {
    return this.get('project');
  }

  setProject(project: Project): this {
    return this.set('project', project);
  }

  hasOnDemandPolls(): boolean {
    return this.getDemandPolls() && this.getDemandPolls().size > 0;
  }

  getPulseProjects(): List<Project> {
    return this.get('pulseProjects');
  }

  setPulseLifecycleProject(pulseLifecycleProject: Project) {
    const pulseLifecycleProjects = this.getPulseLifecycleProjects();
    const index = pulseLifecycleProjects.findIndex(
      (item) => item.getId() === pulseLifecycleProject.getId()
    );

    const newPulseLifecycleProjects = pulseLifecycleProjects.set(index, pulseLifecycleProject);
    return this.set('pulseLifecycleProjects', newPulseLifecycleProjects);
  }

  getPulseLifecycleProjects(): List<Project> {
    return this.get('pulseLifecycleProjects');
  }

  getDemandPolls(): List<Project> {
    return this.get('pulseOnDemandPolls');
  }

  getChildProjects(): List<Project> {
    return List<Project>([
      ...this.getPulseProjects().toArray(),
      ...this.getDemandPolls().toArray(),
    ]);
  }

  getLiveAndScheduledSurveys(): List<Project> {
    return this.getPulseProjects()
      .filter((project) => {
        return project.isLive() || project.isInDevelopment();
      })
      .toList();
  }

  getArchivedSurveys(): List<Project> {
    return this.getPulseProjects()
      .filter((project) => {
        return project.isClosed();
      })
      .sort(Project.sortByLiveStop)
      .toList();
  }

  getLiveAndScheduledDemandPolls(): List<Project> {
    return this.getDemandPolls()
      .filter((project) => {
        return project.isLive() || project.isInDevelopment();
      })
      .toList();
  }

  getArchivedDemandPolls(): List<Project> {
    return this.getDemandPolls()
      .filter((project) => {
        return project.isClosed();
      })
      .sort(Project.sortByLiveStop)
      .toList();
  }
}
