export type Sentiment = 'Positive' | 'Neutral' | 'Negative';

export type SentimentMap = {
  Positive: '1';
  Neutral: '0';
  Negative: '-1';
  NotSet: null;
};

export const Sentiment: SentimentMap = {
  Positive: '1',
  Negative: '-1',
  Neutral: '0',
  NotSet: null,
};

export const isSentimentSet = (value: any): boolean => {
  return [Sentiment.Positive, Sentiment.Negative, Sentiment.Neutral].includes(value);
};
