import { createAsyncThunk } from '@reduxjs/toolkit';
import { EmployeeApi } from 'api/EmployeeApi';
import { employeeRepository } from 'container/employeeRepository';
import { createUnknownException } from 'exception/createException';
import { Exception } from 'exception/Exception';
import { Employee } from 'model/Employee';
import { formValueSelector } from 'redux-form';
import { fetchDeliveryStatistic } from 'reduxStore/deliveryStatistic/asyncActions';
import { selectEmailDistributionId } from 'reduxStore/emailDistribution/selectors';
import { fetchProjectJobs } from 'reduxStore/job/asyncActions';
import { selectProcessEmployeeDatabaseJob } from 'reduxStore/job/selectors';
import {
  selectCurrentProjectId,
  selectCurrentProjectOnlyId,
} from 'reduxStore/project/projectSelector';
import { AdminFormNames } from 'register/AdminFormNames';
import { serializeError } from 'shared/utils/redux';

import { STORE_NAME } from './initialState';

export const fetchEmployees = createAsyncThunk(
  `${STORE_NAME}/fetchEmployees`,
  ({
    projectId,
    sort,
    searchText,
    department,
    isActive,
    page,
    limit,
  }: {
    projectId: number;
    sort: EmployeeApi.SortParam;
    searchText: string;
    department?: number;
    isActive: EmployeeApi.FilterActiveParam;
    page: number;
    limit: number;
  }) => {
    return employeeRepository.list({
      projectId,
      page,
      limit,
      searchText,
      department,
      active: isActive,
      sort,
    });
  },
  { serializeError }
);

export const processEmployees = createAsyncThunk(
  `${STORE_NAME}/processEmployees`,
  (file: File, { getState }) => {
    const projectId = selectCurrentProjectId(getState());

    return employeeRepository.process(projectId, file).catch((error: Exception) => {
      if (error instanceof Exception) {
        throw error;
      } else {
        throw createUnknownException();
      }
    });
  },
  { serializeError }
);

export const commitEmployees = createAsyncThunk(
  `${STORE_NAME}/commitEmployees`,
  (_, { getState, dispatch }) => {
    const projectId = selectCurrentProjectId(getState());
    const job = selectProcessEmployeeDatabaseJob(getState());
    const uploadType = formValueSelector(AdminFormNames.EmployeeUploadType)(getState(), 'type');

    return employeeRepository
      .commit(projectId, job.getExtendedJob().getId(), uploadType)
      .then(() => dispatch(fetchProjectJobs()));
  },
  { serializeError }
);

export const addEmployee = createAsyncThunk(
  `${STORE_NAME}/addEmployee`,
  ({ employee }: { employee: Employee }, { getState }) => {
    return employeeRepository.add(selectCurrentProjectId(getState()), employee);
  },
  { serializeError }
);

export const editEmployee = createAsyncThunk(
  `${STORE_NAME}/editEmployee`,
  (
    {
      id,
      employee,
    }: {
      id: number;
      employee: Employee;
    },
    { getState }
  ) => {
    return employeeRepository.edit(selectCurrentProjectId(getState()), id, employee);
  },
  { serializeError }
);

export const deleteEmployee = createAsyncThunk(
  `${STORE_NAME}/deleteEmployee`,
  ({ employeeId }: { employeeId: number; pageLimit: number }) => {
    return employeeRepository.delete(employeeId).then(() => employeeId);
  },
  { serializeError }
);

export const fetchEmployeeUploadStats = createAsyncThunk(
  `${STORE_NAME}/fetchEmployeeUploadStats`,
  (_, { getState }) => {
    const projectId = selectCurrentProjectOnlyId(getState());
    return employeeRepository.stats(projectId);
  },
  { serializeError }
);

export const updateEmployeeEmail = createAsyncThunk(
  `${STORE_NAME}/updateEmployeeEmail`,
  (
    {
      employeeId,
      email,
    }: {
      employeeId: number;
      email: string;
    },
    thunkAPI
  ) => {
    const emailDistributionId = selectEmailDistributionId(thunkAPI.getState());
    return employeeRepository
      .updateEmail(employeeId, email)
      .then(() => thunkAPI.dispatch(fetchDeliveryStatistic({ emailDistributionId })));
  },
  { serializeError }
);
