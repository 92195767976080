import { selectApiData } from 'util/selector/apiSelector';

import { WithData } from 'api/interfaces';
import { AxiosInstance } from 'axios';
import { List } from 'immutable';
import { TimezoneMapper } from 'mapper/TimezoneMapper';
import { Timezone } from 'model/Timezone';

export namespace TimezoneApi {
  export type Entry = {
    id: string;
    name: string;
    offset: string;
  };
}

export class TimezoneApi {
  constructor(private api: AxiosInstance, private timezoneMapper: TimezoneMapper) {}

  list(): Promise<List<Timezone>> {
    return this.api.get<WithData<TimezoneApi.Entry[]>>('/api/v1/timezones').then((response) => {
      return List<Timezone>(
        selectApiData(response).map((entry: TimezoneApi.Entry) =>
          this.timezoneMapper.deserialize(entry)
        )
      );
    });
  }
}
