import { Map } from 'immutable';
import { Logic } from 'model/Logic';
import { NumberingSkippable } from 'model/NumberingSkippable';
import { PageItemKind } from 'model/PageItemKind';
import { SimpleModel } from 'model/SimpleModel';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';

export namespace PageItem {
  export interface Shape {
    id?: number;
    position?: number;
    kind?: PageItemKind;
    logic?: Logic;
    page?: number;
    benchmarkItemId?: number;
    trendItemId?: number;
    locale?: LangCodeEnum;
    [key: string]: any;
  }
}

export abstract class PageItem<S extends PageItem.Shape = PageItem.Shape>
  extends SimpleModel<S>
  implements NumberingSkippable {
  static readonly REGULAR_PROJECT_QUESTION_KINDS: PageItemKind[] = [
    PageItemKind.QuestionOpinion,
    PageItemKind.QuestionRadioButton,
    PageItemKind.QuestionCheckbox,
    PageItemKind.QuestionCAS,
    PageItemKind.QuestionCommentsBox,
    PageItemKind.QuestionForm,
    PageItemKind.QuestionDemographic,
  ];

  static readonly PULSE_PROJECT_QUESTION_KINDS: PageItemKind[] = [
    PageItemKind.QuestionOpinion,
    PageItemKind.QuestionRadioButton,
    PageItemKind.QuestionCheckbox,
    PageItemKind.QuestionCommentsBox,
    PageItemKind.QuestionDemographic,
  ];

  constructor(initialData: SimpleModel.Data<PageItem.Shape>, kind?: PageItemKind) {
    const map = (Map.isMap(initialData) ? initialData : Map<any, any>(initialData)) as Map<
      keyof PageItem.Shape,
      any
    >;

    super(kind ? map.set('kind', kind) : map);
  }

  getId(): number {
    return this.get('id');
  }

  hasLogic(): boolean {
    return !!this.get('logic');
  }

  setId(id: number): this {
    return this.set('id', id);
  }

  getPosition(): number {
    return this.get('position');
  }

  setPosition(position: number): this {
    return this.set('position', position);
  }

  getKind(): PageItemKind {
    return this.get('kind');
  }

  getGeneralKind(): PageItemKind.General {
    return PageItemKind.getGeneralKind(this.getKind());
  }

  getLogic(): Logic {
    return this.get('logic');
  }

  removeLogic(): this {
    return this.set('logic', null);
  }

  hasClassificationLogic(): boolean {
    return this.get('logic').isClassification();
  }

  hasDistributionLogic(): boolean {
    return this.get('logic').isDistribution();
  }

  hasDependencyLogic(): boolean {
    return this.get('logic').isDependency();
  }

  isNumberingSkipped(): boolean {
    return false;
  }

  getPage(): number {
    return this.get('page');
  }

  getBenchmarkItemId(): number {
    return this.get('benchmarkItemId');
  }

  getTrendItemId(): number {
    return this.get('trendItemId');
  }

  setConnectionItemId(name: 'benchmarkItemId' | 'trendItemId', value: number): this {
    return this.set(name, value);
  }

  getLocale() {
    return this.get('locale');
  }
}
