import React from 'react';
import { connect } from 'react-redux';

import { Box, Card, Divider, Grid } from '@mui/material/';
import { AdminRoute } from 'app/route/admin';
import { ClientRoute } from 'app/route/client';
import { SecondaryButton } from 'component/Button/Button';
import { DangerFlatButton } from 'component/DangerFlatButton/DangerFlatButton';
import { ProjectCompanyData } from 'component/ProjectCompanyData/ProjectCompanyData';
import { ProjectRate } from 'component/ProjectRate/ProjectRate';
import { ProjectStatistic } from 'component/ProjectStats/ProjectStatistic';
import { ProjectStatus } from 'component/ProjectStatus/ProjectStatus';
import { CardActonWrapper } from 'component/SingleProject/SingleProject.s';
import { Project } from 'model/Project';
import { AdminDispatch } from 'reduxStore/appStore';
import { openModal } from 'reduxStore/modal/slice';
import { AdminModal } from 'register/AdminModal';
import { compilePath } from 'router/compilePath';

namespace SingleProject {
  export type State = {};

  export type StateProps = {};
  export type DispatchProps = {
    onDeleteRequest: (projectId: number) => void;
    onCloseRequest: (projectId: number) => void;
    onReopenRequest: (project: Project) => void;
  };
  export type OwnProps = {
    project: Project;
  };

  export type PulseProps = {
    templateId: number;
  } & SingleProject.OwnProps;

  export type Props = OwnProps & StateProps & DispatchProps;
}

export const SingleProjectPure: React.VFC<SingleProject.Props> = (props) => {
  return (
    <Box mb={2}>
      <Card data-testid="projectItem">
        <Box p={2}>
          <Grid container spacing={4} alignItems="center" justifyContent="space-between">
            <Grid item xs={12} md={6} lg={4}>
              <ProjectCompanyData
                companyName={props.project.getCompany() ? props.project.getCompany().getName() : ''}
                projectName={props.project.getName()}
                updatedAt={props.project.getUpdatedAt()}
                kind={props.project.getKind()}
              />
            </Grid>
            <Grid item xs={6} lg={2}>
              <ProjectStatus
                project={props.project}
                liveStart={props.project.getLiveStart()}
                liveStop={props.project.getLiveStop()}
              />
            </Grid>
            <Grid item xs={6} lg={2}>
              <ProjectRate rate={props.project.getResponseRate()} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ProjectStatistic
                partial={props.project.getResponsePartial() || null}
                started={props.project.getResponseStarted() || null}
                completed={props.project.getResponseCompleted() || null}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        {(props.project.isEditable() ||
          props.project.isCloseable() ||
          props.project.isDeletable()) && (
          <CardActonWrapper>
            {(props.project.isEditable() || props.project.isFixableOnly()) && (
              <SecondaryButton
                data-testid="editButton"
                href={compilePath(AdminRoute.Project.SURVEY_PATTERN, { id: props.project.getId() })}
                label="Edit"
              />
            )}
            {props.project.isCloseable() && (
              <SecondaryButton
                label="Close"
                onClick={() => props.onCloseRequest(props.project.getId())}
              />
            )}
            {props.project.isClosed() && (
              <SecondaryButton
                label="Reopen"
                onClick={() => props.onReopenRequest(props.project)}
              />
            )}
            {props.project.isDeletable() && (
              <DangerFlatButton
                label="Delete"
                onClick={() => props.onDeleteRequest(props.project.getId())}
              />
            )}
          </CardActonWrapper>
        )}
      </Card>
    </Box>
  );
};

export const SingleSubProjectPure = (props: SingleProject.PulseProps): JSX.Element => {
  return (
    <Box mb={2}>
      <Card data-testid="pulseSurveyItem">
        <Box p={2}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} sm={6}>
              <ProjectCompanyData
                projectName={props.project.getName()}
                updatedAt={props.project.getUpdatedAt()}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <ProjectStatus
                project={props.project}
                liveStart={props.project.getLiveStart()}
                liveStop={props.project.getLiveStop()}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <ProjectRate rate={props.project.getResponseRate()} />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <CardActonWrapper>
          <SecondaryButton
            data-testid="editButton"
            href={compilePath(ClientRoute.Project.Survey.Develop.QUESTION_PATTERN, {
              id: props.templateId && props.templateId.toString(),
              survey_id: props.project.getId(),
            })}
          >
            Edit
          </SecondaryButton>
        </CardActonWrapper>
      </Card>
    </Box>
  );
};

export const SingleProject: React.ComponentClass<SingleProject.OwnProps> = connect(
  null,
  (dispatch: AdminDispatch): SingleProject.DispatchProps => ({
    onDeleteRequest: (projectId: number) =>
      dispatch(openModal({ name: AdminModal.DeleteProject, params: { projectId } })),
    onCloseRequest: (projectId: number) =>
      dispatch(openModal({ name: AdminModal.CloseProject, params: { projectId } })),
    onReopenRequest: (project: Project) =>
      dispatch(openModal({ name: AdminModal.ReopenProject, params: { project } })),
  })
)(SingleProjectPure) as React.ComponentClass<SingleProject.OwnProps>;
