import React from 'react';
import ReactPaginate from 'react-paginate';

import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  active,
  arrowLinkItem,
  container,
  paginateItem,
  paginateLinkItem,
} from 'component/Pagination/Pagination.s';

export namespace Pagination {
  export type Props = {
    pageCount: number;
    page: number;
    onPageChange: (page: number) => void;
  };
}

export const Pagination: React.VFC<Pagination.Props> = (props) => (
  <ReactPaginate
    previousLabel={<ArrowLeftIcon />}
    nextLabel={<ArrowRightIcon />}
    pageCount={props.pageCount}
    forcePage={props.page}
    marginPagesDisplayed={2}
    pageRangeDisplayed={5}
    onPageChange={({ selected }) => props.onPageChange(selected)}
    containerClassName={`${container}`}
    pageClassName={`${paginateItem}`}
    pageLinkClassName={`${paginateLinkItem}`}
    previousLinkClassName={`${arrowLinkItem}`}
    nextLinkClassName={`${arrowLinkItem}`}
    activeClassName={`${active}`}
  />
);
