import { SimpleModel } from 'model/SimpleModel';

export namespace Circle {
  export interface Shape {
    id: string;
    name: string;
    emailLogoUrl: string;
    hasRegularProjects: boolean;
  }
}

export class Circle extends SimpleModel<Circle.Shape> {
  getId(): string {
    return this.get('id');
  }

  getName(): string {
    return this.get('name');
  }

  getEmailLogoUrl(): string {
    return this.get('emailLogoUrl');
  }

  getHasRegularProjects(): boolean {
    return this.get('hasRegularProjects');
  }
}
