import { selectAuthApiUrl } from 'util/selector/apiSelector';

import axios, { AxiosInstance } from 'axios';
import { logInterceptedError } from 'shared/hooks/useInitializeDataDogLogs';

const authApiClient: AxiosInstance = axios.create({
  baseURL: selectAuthApiUrl(),
  withCredentials: true,
  // `headers` are custom headers to be sent
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'en-GB',
    Accept: 'application/json',
  },
});

authApiClient.interceptors.response.use(null, (error) => {
  logInterceptedError(error);
});

export { authApiClient };
