import { CircleApi } from 'api/CirclesApi';
import { Circle } from 'model/Circle';

export class CircleMapper {
  serialize(model: Circle): CircleApi.Entry {
    return {
      id: model.getId(),
      name: model.getName(),
      emailLogoUrl: model.getEmailLogoUrl(),
      hasRegularProjects: model.getHasRegularProjects(),
    };
  }

  deserialize(entry: CircleApi.Entry): Circle {
    return new Circle({
      id: entry.id,
      name: entry.name,
      emailLogoUrl: entry.emailLogoUrl,
      hasRegularProjects: entry.hasRegularProjects,
    });
  }
}
