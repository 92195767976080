import { StaticResourceApi } from 'api/StaticResourceApi';
import { List } from 'immutable';
import { StaticResourceMapper } from 'mapper/StaticResourceMapper';
import { StaticResource } from 'model/StaticResource';

export class StaticResourceRepository {
  constructor(
    private staticResourceApi: StaticResourceApi,
    private staticResourceMapper: StaticResourceMapper
  ) {}

  list(): Promise<List<StaticResource>> {
    return this.staticResourceApi
      .list()
      .then((entries) =>
        List(entries.map((entry) => this.staticResourceMapper.deserialize(entry)))
      );
  }
}
