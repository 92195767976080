import { treeToFlatList } from 'util/flattenTree';

import { createSlice } from '@reduxjs/toolkit';

import {
  fetchConnectedDepartments,
  fetchTrendItems,
  setConnectedDepartments,
} from './asyncActions';
import { STORE_NAME, TrendItemsState } from './initialState';

export const trendItemsSlice = createSlice({
  name: STORE_NAME,
  initialState: TrendItemsState.INITIAL_DOMAIN,
  reducers: {
    clearTrendItems: () => {
      return TrendItemsState.INITIAL_DOMAIN;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrendItems.fulfilled, (state, action) => {
        state.list = action.payload;
      })

      .addCase(fetchConnectedDepartments.fulfilled, (state, action) => {
        state.departments = treeToFlatList(action.payload);
      })

      .addCase(setConnectedDepartments.fulfilled, (state, action) => {
        const updatedItemIndex = state.departments.findIndex(
          (item) => item.getId() === action.payload.getId()
        );
        state.departments = state.departments.update(updatedItemIndex, () => action.payload);
      });
  },
});

export const { clearTrendItems } = trendItemsSlice.actions;
