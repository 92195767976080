import { TrendDataApi } from 'api/TrendDataApi';
import { List } from 'immutable';
import { TrendDataMapper } from 'mapper/TrendDataMapper';
import { Trend } from 'model/Trend';

export class TrendDataRepository {
  constructor(private trendDataApi: TrendDataApi, private trendDataMapper: TrendDataMapper) {}

  list(id: number): Promise<List<Trend>> {
    return this.trendDataApi
      .list(id)
      .then((entries) => List(entries.map((entry) => this.trendDataMapper.deserialize(entry))));
  }

  delete(id: number): Promise<void> {
    return this.trendDataApi.delete(id);
  }

  upload(file: File, id: number): Promise<Trend> {
    return this.trendDataApi
      .upload(file, id)
      .then((entry) => this.trendDataMapper.deserialize(entry));
  }
}
