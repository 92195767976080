import { colors } from 'app/theme/colors';
import { styled } from '@mui/material';

export type DropdownListProps = {
  visible: boolean;
};

export const DropdownWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

export const DropdownMenu = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    float: 'left',
    backgroundColor: colors.white,
    height: '38px',
    cursor: 'pointer',
    border: `1px solid ${colors.shipGray}`,
    boxSizing: 'border-box',
    borderRadius: '25px',
    padding: '0 15px 0 15px',
    letterSpacing: '0.2px',
  },
  (props: { variant: 'primary' | 'clear' }) => ({
    borderColor: props.variant === 'clear' ? 'transparent' : colors.shipGray,
  })
);

export const DropdownMenuLabel = styled('span')(
  {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minWidth: '80px',
    position: 'relative',
    boxSizing: 'border-box',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
  },
  (props: { variant: 'primary' | 'clear' }) => ({
    fontSize: props.variant === 'clear' ? '16px' : '14px',
    fontWeight: props.variant === 'clear' ? 500 : 400,
  })
);

export const DropdownListItem = styled('div')({
  position: 'absolute',
  top: '100%',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '100%',
  minWidth: '170px',
  zIndex: 100,
  cursor: 'pointer',
  backgroundColor: colors.white,
  boxSizing: 'border-box',
  borderRadius: '5px',
  border: `1px solid ${colors.alto}`,
  boxShadow: `0px 0px 11px 0px ${colors.silverChalice77}`,
});

export const DropdownItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  float: 'left',
  width: '100%',
  height: '50px',
  padding: '0 5px',
  fontSize: '14px',
  boxSizing: 'border-box',
  '&:not(:first-child)': {
    borderTop: `1px solid ${colors.alto}`,
  },
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: colors.seashell,
  },
});
