import { createAsyncThunk } from '@reduxjs/toolkit';
import { ProjectTemplateApi } from 'api/ProjectTemplateApi';
import { projectTemplateRepository } from 'container/projectTemplateRepository';
import { STORE_NAME } from 'reduxStore/pulseLifecycle/initialState';
import { serializeError } from 'shared/utils/redux';

export const fetchProjectTemplateList = createAsyncThunk(
  `${STORE_NAME}/fetchProjectTemplateList`,
  () => projectTemplateRepository.list(),
  { serializeError }
);

export const fetchCurrentProjectTemplate = createAsyncThunk(
  `${STORE_NAME}/fetchCurrentProjectTemplate`,
  ({ currentProjectTemplateId }: { currentProjectTemplateId: number }) =>
    projectTemplateRepository.get(currentProjectTemplateId),
  { serializeError }
);

export const createProjectTemplate = createAsyncThunk(
  `${STORE_NAME}/createProjectTemplate`,
  (data: ProjectTemplateApi.PostEntry) => projectTemplateRepository.create(data),
  { serializeError }
);

export const adaptProjectTemplate = createAsyncThunk(
  `${STORE_NAME}/adaptProjectTemplate`,
  ({
    sourceProjectId,
    destinationProjectId,
  }: {
    sourceProjectId: number;
    destinationProjectId: number;
  }) => projectTemplateRepository.adapt(sourceProjectId, destinationProjectId),
  { serializeError }
);

export const adaptPreviousSurvey = createAsyncThunk(
  `${STORE_NAME}/adaptPreviousSurvey`,
  ({
    sourceProjectId,
    destinationProjectId,
  }: {
    sourceProjectId: number;
    destinationProjectId: number;
  }) => projectTemplateRepository.adaptPrevious(sourceProjectId, destinationProjectId),
  { serializeError }
);
