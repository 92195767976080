import { List } from 'immutable';
import { Circle } from 'model/Circle';
import { AdminState } from 'reduxStore/appStore';
import { createSelector } from '@reduxjs/toolkit';
import { selectCompanyCircleId } from 'reduxStore/project/projectSelector';
import { WB_CIRCLE_NAME } from 'shared/constants';

import { STORE_NAME } from './initialState';

export const selectCirclesDomain = (state: AdminState) => {
  return state[STORE_NAME];
};

export const selectCirclesResource = createSelector(selectCirclesDomain, (domain) => domain.list);

export const selectCircles = createSelector(
  selectCirclesDomain,
  (domain) => domain.list.getContent() ?? List.of<Circle>()
);

export const selectCurrentCircle = createSelector(
  selectCompanyCircleId,
  selectCircles,
  (currCircleId, circles) => circles.find((circle) => currCircleId === circle.getId())
);

export const selectIsInWorkBuzzCircle = createSelector(selectCircles, (circles) => {
  return circles.some((circle) => circle.getName() === WB_CIRCLE_NAME);
});

export const selectHasRegularProjects = createSelector(
  selectCircles,
  selectCurrentCircle,
  (circles, currentCircle) =>
    currentCircle?.getHasRegularProjects() ||
    circles.some((circle) => circle.getHasRegularProjects())
);
