import { createSlice } from '@reduxjs/toolkit';
import { addAsyncCases } from 'shared/utils/redux';

import { fetchKioskDistribution } from './asyncActions';
import { KioskDistributionState, STORE_NAME } from './initialState';

export const kioskDistributionSlice = createSlice({
  name: STORE_NAME,
  initialState: KioskDistributionState.INITIAL_DOMAIN,
  reducers: {
    clearKioskModeDistribution: (state) => {
      state.distributionResource = KioskDistributionState.INITIAL_DOMAIN.distributionResource;
    },
  },
  extraReducers: (builder) => {
    addAsyncCases(builder, fetchKioskDistribution, (state, action) => {
      state.distributionResource = action.payload;
    });
  },
});

export const { clearKioskModeDistribution } = kioskDistributionSlice.actions;
