import { useEffect } from 'react';

import { useAppDispatch } from 'reduxStore/appStore';
import { fetchCompanyList } from 'reduxStore/company/asyncActions';

export const useFetchCompanyList = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCompanyList());
  }, [dispatch]);
};
