import { selectApiData } from 'util/selector/apiSelector';
import { selectErrorCode, selectIsAxiosError } from 'util/selector/axiosSelector';

import { WithData } from 'api/interfaces';
import { AxiosInstance } from 'axios';
import { Exception } from 'exception/Exception';
import { HttpException } from 'exception/HttpException';
import { UserUnauthorizedException } from 'exception/UserUnauthorizedException';
import { UNAUTHORIZED } from 'http-status-codes';
import { Circle } from 'model/Circle';

export namespace AuthApi {
  export type UserEntry = {
    id: string;
    username: string;
    roles: string[];
    firstName: string;
    lastName: string;
  };

  export type ResetPasswordEntry = {
    name: string;
    logo?: string;
  };

  export type ResetPasswordRequestParams = {
    email: string;
    currentCircle: Circle;
  };
}

export class AuthApi {
  constructor(private api: AxiosInstance) {}

  logged(): Promise<AuthApi.UserEntry> {
    return this.api
      .get<WithData<AuthApi.UserEntry>>('/api/auth/user')
      .then(selectApiData)
      .catch((error: Exception) => {
        if (selectIsAxiosError(error)) {
          switch (selectErrorCode(error)) {
            case UNAUTHORIZED:
              throw UserUnauthorizedException.createFromAxiosError(error);
            default:
              throw HttpException.createFromAxiosError(error);
          }
        }

        throw error;
      });
  }

  logout(): Promise<void> {
    return this.api.post('/api/auth/logout').then(() => undefined);
  }

  resetPasswordRequest({
    email,
    currentCircle,
  }: AuthApi.ResetPasswordRequestParams): Promise<unknown> {
    if (!currentCircle) {
      return this.api.post(`/api/user/reset-password-request/${email}`).then(selectApiData);
    }

    const data = {
      name: currentCircle.getName(),
      logo: currentCircle.getEmailLogoUrl(),
    };

    return this.api
      .post<WithData<AuthApi.ResetPasswordEntry>>(`/api/user/reset-password-request/${email}`, data)
      .then(selectApiData);
  }

  resetMfaRequest({ email }: { email: string }): Promise<unknown> {
    return this.api.post(`/api/user/reset-mfa-request/${email}`).then(selectApiData);
  }
}
