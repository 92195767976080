import { Resource } from 'model/Resource';
import { WebLinkDistribution } from 'model/WebLinkDistribution';

export const STORE_NAME = 'webLink';

export namespace WebLinkDistributionState {
  export type WebLinkDistributionResource = Resource<WebLinkDistribution>;
  export type Domain = {
    distributionResource?: WebLinkDistributionResource;
  };

  export const INITIAL_WEBLINK_DISTRIBUTION_RESOURCE: WebLinkDistributionResource = Resource.void<WebLinkDistribution>();

  export const INITIAL_DOMAIN: Domain = {
    distributionResource: INITIAL_WEBLINK_DISTRIBUTION_RESOURCE,
  };
}
