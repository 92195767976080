import { selectReportApiUrl } from 'util/selector/apiSelector';

import axios, { AxiosError, AxiosInstance } from 'axios';
import { logInterceptedError } from 'shared/hooks/useInitializeDataDogLogs';

const reportApiClient: AxiosInstance = axios.create({
  baseURL: selectReportApiUrl(),
  withCredentials: true,
  // `headers` are custom headers to be sent
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'en-GB',
    Accept: 'application/json',
  },
});

reportApiClient.interceptors.response.use(undefined, (error: AxiosError) => {
  logInterceptedError(error);

  throw error;
});

export { reportApiClient };
