import { Permission } from 'model/Permission';
import { PermissionFlag } from 'model/PermissionFlag';
import { SimpleModel } from 'model/SimpleModel';

// Permission Groups are Roles

export namespace PermissionGroup {
  export interface Shape {
    id?: string;
    name: string;
    objectIdentifier?: string;
    flags?: PermissionFlag[];
    permissions?: Permission[];
  }
}

export class PermissionGroup extends SimpleModel<PermissionGroup.Shape> {
  static CLIENT_ADMIN = 'Client Admin';

  static isClientAdmin = (roleName: string) => {
    return PermissionGroup.CLIENT_ADMIN === roleName;
  };

  static getById(permissionGroups: PermissionGroup[], id: string) {
    return (
      permissionGroups && permissionGroups.find((permissionGroup) => permissionGroup.getId() === id)
    );
  }

  static hasPermissionByIdMatrixAccessFeature(permissionGroups: PermissionGroup[], id: string) {
    const permissionGroup = !!permissionGroups && PermissionGroup.getById(permissionGroups, id);

    return permissionGroup && permissionGroup.hasMatrixAccessFeature();
  }

  getId(): string {
    return this.get('id');
  }

  getName(): string {
    return this.get('name');
  }

  getNameWithFlags(): string {
    return this.hasFlags() ? `${this.getName()} (${this.getFlagsToString()})` : this.getName();
  }

  getObjectIdentifier(): string {
    return this.get('objectIdentifier');
  }

  getFlags(): PermissionFlag[] {
    return this.get('flags');
  }

  hasFlags(): boolean {
    const flags = this.get('flags');

    return !!(flags && flags.length);
  }

  getFlagsToString(): string {
    return this.hasFlags()
      ? this.getFlags()
          .map((permissionFlag) => permissionFlag.getLabel())
          .toString()
      : '';
  }

  getPermissions(): Permission[] {
    return this.get('permissions');
  }

  hasMatrixAccessFeature(): boolean {
    return this.getPermissions().some((permission) => permission.isMatrixAccessFeature());
  }
}
