import { selectAdminApiUrl } from 'util/selector/apiSelector';
import { createResourceContentSelector } from 'util/selector/resourceSelector';
import { selectHasMatch } from 'util/selector/routerSelector';

import { AdminRoute } from 'app/route/admin';
import { KioskDistribution } from 'model/KioskDistribution';
import { Resource } from 'model/Resource';
import { AdminState } from 'reduxStore/appStore';
import { KioskDistributionState } from 'reduxStore/kioskDistribution/initialState';
import { createSelector } from '@reduxjs/toolkit';

export function selectKioskDistributionDomain(state: AdminState): KioskDistributionState.Domain {
  return state && state.kioskDistribution;
}

export const selectKioskDistributionResource = createSelector(
  selectKioskDistributionDomain,
  (domain) => (domain && domain.distributionResource) || Resource.void<KioskDistribution>()
);

export const selectKioskDistribution = createResourceContentSelector(
  selectKioskDistributionResource
);

export const selectKioskDistributionId = createSelector(
  selectKioskDistribution,
  (kioskDistribution) => kioskDistribution && kioskDistribution.getId()
);

export const selectKioskUniqueCodesUrl = createSelector(
  selectAdminApiUrl,
  selectKioskDistributionId,
  (adminApiUrl, kioskDistributionId) =>
    `${adminApiUrl}/api/v1/distribution/${kioskDistributionId}/access-codes/excel`
);

export const selectHasMatchRouteKioskDistribution = selectHasMatch(
  AdminRoute.Project.Distribution.KIOSK_PATTERN
);
