import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { LoaderWrapper } from 'component/Loader/Loader.s';

export namespace Loader {
  export type Props = {
    className?: string;
    style?: any;
  };
}

export const Loader: React.VFC<Loader.Props> = (props) => (
  <LoaderWrapper className={props.className} style={props.style}>
    <CircularProgress />
  </LoaderWrapper>
);
