import { createAsyncThunk } from '@reduxjs/toolkit';
import { constantApi } from 'container/constantApi';
import { timezoneApi } from 'container/timezoneApi';
import { serializeError } from 'shared/utils/redux';

import { STORE_NAME } from './initialState';

export const fetchConstantsAndTimezones = createAsyncThunk(
  `${STORE_NAME}/fetchConstantsAndTimezones`,
  async () => {
    const [constants, timezones] = await Promise.all([constantApi.list(), timezoneApi.list()]);
    return { constants, timezones };
  },
  { serializeError }
);
