import { SubmissionError } from 'redux-form';

export enum ErrorCode {
  ENTITY_NOT_FOUND = 'ENTITY_NOT_FOUND',
}

interface Error {
  type: string;
  message: string;
  code: string;
}

interface FormError extends Error {
  type: 'FORM_ERROR';
  code: 'VALIDATION';
}

interface ApiError extends Error {
  type: 'API_ERROR';
}

interface UnknownError extends Error {
  type: 'UNKNOWN_ERROR';
  message: 'Unknown error';
  code: 'UNKNOWN_ERROR';
}

interface ValidationError extends Error {
  type: 'VALIDATION_ERROR';
  code: 'VALIDATION_ERROR';
}

/**
 * @deprecated
 * App moving away for HttpError, use ApiError and do not extend it
 */
interface HttpError extends Error {
  type: 'HTTP_ERROR';
}

/**
 * @deprecated
 */
interface BatchError extends Error {
  type: 'BATCH_ERROR';
}

type ErrorObject = FormError | ApiError | UnknownError | BatchError | HttpError | ValidationError;

export class Exception extends Error {
  readonly message: string;
  private readonly type: string;
  private readonly code: string;
  private exceptionList: Exception[];

  constructor({ type, code, message }: ErrorObject) {
    super(message);

    if (type === 'HTTP_ERROR' || type === 'BATCH_ERROR') {
      // tslint:disable-next-line:no-console
      console.warn('Using deprecated Error type');
    }

    this.code = code;
    this.type = type;
    this.message = message;
    this.exceptionList = [];
    Object.setPrototypeOf(this, Exception.prototype);
  }

  mapToSubmissionError(): SubmissionError {
    return new SubmissionError({
      _error: this.getMessage(),
    });
  }

  getType(): string {
    return this.type;
  }

  getCode(): string {
    return this.code;
  }

  getMessage(): string {
    return this.message;
  }

  getExceptionList(): Exception[] {
    return this.exceptionList;
  }

  setExceptionList(exceptionList: Exception[]) {
    return (this.exceptionList = exceptionList);
  }

  /**
   * Used by Redux to serialize object to action payload
   */
  toJSON() {
    return {
      type: this.getType(),
      code: this.getCode(),
      message: this.getMessage(),
      isException: true,
    };
  }
}
