import { FORM_VALIDATION_ERROR } from 'exception/EmployeeCodeExpections';
import { Exception } from 'exception/Exception';
import { Violation } from 'exception/Exception.types';
import { SubmissionError } from 'redux-form';

export class FormValidationException extends Exception {
  static readonly API_CODE = FORM_VALIDATION_ERROR;

  protected path: string;

  constructor(message: string, private readonly violations: Violation[]) {
    super({
      type: 'FORM_ERROR',
      code: FormValidationException.API_CODE,
      message,
    });

    Object.setPrototypeOf(this, FormValidationException.prototype);
  }

  mapToSubmissionError(): SubmissionError {
    return new SubmissionError({
      _error: this.getMessage(),
      ...this.getViolations().reduce(
        (errors, violation) => ({
          ...errors,
          [violation.path]: violation.message,
        }),
        {}
      ),
    });
  }

  getViolations(): Violation[] {
    return this.violations;
  }
}
