import React from 'react';

import { FormHelperText, FormLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import { mapError } from 'component/Form/mapError';
import { WrappedFieldProps } from 'redux-form';

export namespace RadioButtonGroup {
  export type Props = WrappedFieldProps & RadioGroupProps & { showError?: boolean; label?: string };
}

export const RadioButtonGroupField = ({
  input: { onChange, ...inputProps },
  children,
  name,
  label = '',
  ...props
}: RadioButtonGroup.Props): JSX.Element => {
  const { errorText } = mapError(props && props.meta, 'errorText', !!props.showError);

  return (
    <FormControl error={!!errorText}>
      {!!label ? <FormLabel>{label}</FormLabel> : null}
      <RadioGroup
        name={name}
        {...inputProps}
        {...props}
        onChange={(event, newValue) => onChange(newValue)}
      >
        {children}
      </RadioGroup>
      {props.showError ? <FormHelperText>{errorText}</FormHelperText> : null}
    </FormControl>
  );
};
