import { selectApiData } from 'util/selector/apiSelector';

import { WithData } from 'api/interfaces';
import { DemographicApi } from 'api/DemographicApi';
import { DepartmentApi } from 'api/DepartmentApi';
import { AxiosInstance } from 'axios';
import { ContactListType } from 'model/ContactListType';

export namespace ContactListApi {
  export type EntryRecieve = {
    type: ContactListType;
    id?: number;
    list?: DepartmentApi.Entry[] | DemographicApi.Entry[];
  };

  export type EntrySend = {
    type: ContactListType;
    id?: number;
    list?: number[];
  };
}

export class ContactListApi {
  constructor(private client: AxiosInstance) {}

  fetch(id: string): Promise<ContactListApi.EntryRecieve> {
    return this.client
      .get<WithData<ContactListApi.EntryRecieve>>(`/api/v1/distribution/${id}/contact-list`)
      .then(selectApiData);
  }

  create(id: string, entry: ContactListApi.EntrySend): Promise<void> {
    return this.client.post(`/api/v1/distribution/${id}/contact-list`, entry).then(() => undefined);
  }
}
