import { EmailDistributionApi } from 'api/EmailDistributionApi';
import { EmailDistributionMapper } from 'mapper/EmailDistributionMapper';
import { EmailDistribution } from 'model/EmailDistribution';

export class EmailDistributionRepository {
  constructor(
    private emailDistributionApi: EmailDistributionApi,
    private emailDistributionMapper: EmailDistributionMapper
  ) {}

  get(projectId: number): Promise<EmailDistribution> {
    return this.emailDistributionApi
      .get(projectId)
      .then((entry) => this.emailDistributionMapper.deserialize(entry));
  }

  test(distributionId: string, to: string[], content: string, subject?: string): Promise<any> {
    return this.emailDistributionApi.test(distributionId, to, content, subject);
  }
}
