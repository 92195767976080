import { EmailDistribution } from 'model/EmailDistribution';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'emailDistribution';

export namespace EmailDistributionState {
  export type EmailDistributionResource = Resource<EmailDistribution>;
  export type Domain = {
    distributionResource?: EmailDistributionResource;
  };

  export const INITIAL_DOMAIN: Domain = {
    distributionResource: Resource.void<EmailDistribution>(),
  };
}
