import { createSlice } from '@reduxjs/toolkit';
import { addAsyncCases } from 'shared/utils/redux';

import { fetchPermissionFlags, fetchPermissionGroups, fetchPermissions } from './asyncActions';
import { PermissionsState, STORE_NAME } from './initialState';

export const permissionsSlice = createSlice({
  name: STORE_NAME,
  initialState: PermissionsState.INITIAL_DOMAIN,
  reducers: {
    clearPermissionGroups: (state) => {
      state.permissionGroups = PermissionsState.INITIAL_DOMAIN.permissionGroups;
    },
  },
  extraReducers: (builder) => {
    addAsyncCases(builder, fetchPermissions, (state, action) => {
      state.permissionTypes = action.payload.getContent();
    });

    addAsyncCases(builder, fetchPermissionGroups, (state, action) => {
      state.permissionGroups = action.payload;
    });

    addAsyncCases(builder, fetchPermissionFlags, (state, action) => {
      state.permissionFlags = action.payload.getContent();
    });
  },
});

export const { clearPermissionGroups } = permissionsSlice.actions;
