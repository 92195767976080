import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Employee } from 'model/Employee';

import { fetchEmployees, fetchEmployeeUploadStats } from './asyncActions';
import { EmployeeState, STORE_NAME } from './initialState';

export const employeeSlice = createSlice({
  name: STORE_NAME,
  initialState: EmployeeState.INITIAL_DOMAIN,
  reducers: {
    clearEmployeesList: (state) => {
      state.list = EmployeeState.INITIAL_DOMAIN.list;
      state.listPagination = EmployeeState.INITIAL_DOMAIN.listPagination;
    },
    addToChosenEmployees: (state, action: PayloadAction<Employee>) => {
      state.chosenEmployees = state.chosenEmployees.push(action.payload);
    },
    editChosenEmployee: (state, action: PayloadAction<Employee>) => {
      const index = state.chosenEmployees.findIndex(
        (item) => item?.getId() === action.payload.getId()
      );
      state.chosenEmployees =
        index >= 0 ? state.chosenEmployees.set(index, action.payload) : state.chosenEmployees;
    },
    removeFromChosenEmployees: (state, action: PayloadAction<number>) => {
      state.chosenEmployees = state.chosenEmployees.delete(
        state.chosenEmployees.findIndex((item) => item.getId() === action.payload)
      );
    },
    clearChosenEmployees: (state) => {
      state.chosenEmployees = EmployeeState.INITIAL_DOMAIN.chosenEmployees;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        state.list = action.payload.list;
        state.listPagination = action.payload.pagination;
      })
      .addCase(fetchEmployeeUploadStats.fulfilled, (state, action) => {
        state.employeeUploadStats = action.payload;
      });
  },
});

export const {
  addToChosenEmployees,
  clearChosenEmployees,
  clearEmployeesList,
  editChosenEmployee,
  removeFromChosenEmployees,
} = employeeSlice.actions;
