import { DemographicKind } from 'model/DemographicKind';
import { SimpleModel } from 'model/SimpleModel';

export namespace ClassificationDate {
  export interface Shape {
    id?: number;
    value?: string;
    demographyId?: number;
    demographyKind?: DemographicKind;
  }
}

export class ClassificationDate extends SimpleModel<ClassificationDate.Shape> {
  getId(): number {
    return this.get('id');
  }

  getValue(): string {
    return this.get('value');
  }

  getDemographyId(): number {
    return this.get('demographyId');
  }

  getDemographyKind(): DemographicKind {
    return this.get('demographyKind');
  }
}
