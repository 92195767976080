import { createAsyncThunk } from '@reduxjs/toolkit';
import { serializeError } from 'shared/utils/redux';
import { circleRepository } from 'container/circleRepository';

import { STORE_NAME } from './initialState';

export const fetchCircles = createAsyncThunk(
  `${STORE_NAME}/fetchCircles`,
  () => {
    return circleRepository.fetch();
  },
  { serializeError }
);
