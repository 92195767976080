import { QueueApi } from 'api/QueueApi';
import { Queue } from 'model/Queue';

export class QueueMapper {
  serialize(model: Queue): QueueApi.Entry {
    return {
      id: model.getId(),
      name: model.getName(),
      status: model.getStatus(),
      projectId: model.getProjectId(),
    };
  }

  deserialize(entry: QueueApi.Entry): Queue {
    return new Queue({
      id: entry.id,
      name: entry.name,
      status: entry.status,
      reason: entry.reason,
      projectId: entry.projectId,
      progress: entry.progress || 0,
    });
  }
}
