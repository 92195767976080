import { ProjectSubmitApi } from 'api/ProjectSubmitApi';
import { List } from 'immutable';
import { AnswerMapper } from 'mapper/AnswerMapper';
import { ProjectSubmit } from 'model/ProjectSubmit';
import moment from 'moment';

export class ProjectSubmitMapper {
  constructor(private answerMapper: AnswerMapper) {}

  deserialize(entry: ProjectSubmitApi.Entry): ProjectSubmit {
    return new ProjectSubmit({
      id: entry.id,
      status: entry.status,
      submitTime: moment(Date.parse(entry.submitTime)),
      email: entry.respondent && entry.respondent.email,
      firstName: entry.respondent && entry.respondent.firstName,
      lastName: entry.respondent && entry.respondent.lastName,
      answers: List(
        (entry.answers || []).map((answerEntry) => this.answerMapper.deserialize(answerEntry))
      ),
    });
  }
}
