import { PaginationApi } from 'api/PaginationApi';
import { Pagination } from 'model/Pagination';

export class PaginationMapper {
  deserialize(entry: PaginationApi.Entry): Pagination {
    return new Pagination({
      limit: entry.limit,
      total: entry.total,
      page: entry.page,
      pages: entry.pages,
    });
  }
}
