import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import posthog from 'posthog-js';
import { selectUserId, selectUserRoles } from 'reduxStore/auth/selectors';
import { IS_POSTHOG_IDENTIFIED } from 'shared/constants/cookies';
import moment from 'moment';
import { UserRoleType } from 'model/UserRoleType';
import { selectCurrentCompanyName } from 'reduxStore/project/projectSelector';

export const useIdentifyPostHog = () => {
  const userId = useSelector(selectUserId);
  const roles = useSelector(selectUserRoles);
  const isPosthogEnabled = useFeatureIsOn('posthog-core');
  const [cookies, setCookie] = useCookies([IS_POSTHOG_IDENTIFIED]);
  const isPostHogIdentified = cookies[IS_POSTHOG_IDENTIFIED] ? true : false;
  const companyName = useSelector(selectCurrentCompanyName);

  useEffect(() => {
    const expires = moment().add(1, 'year').toDate();

    if (isPostHogIdentified || !userId || !isPosthogEnabled) {
      return;
    }

    if (roles.includes(UserRoleType.ClientAdmin)) {
      // Postpone identification until companyName available for non Internal users
      if (companyName) {
        posthog.identify(userId, { roles: roles, clients: companyName });
        setCookie(IS_POSTHOG_IDENTIFIED, 'true', { expires, sameSite: 'none', secure: true });
      }
    } else {
      posthog.identify(userId, { roles: roles });
      setCookie(IS_POSTHOG_IDENTIFIED, 'true', { expires, sameSite: 'none', secure: true });
    }
  }, [userId, roles, isPostHogIdentified, setCookie, isPosthogEnabled, companyName]);
};
