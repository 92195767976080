import { PermissionsApi } from 'api/PermissionsApi';
import { PermissionFlag } from 'model/PermissionFlag';

export class PermissionFlagMapper {
  deserialize(entry: PermissionsApi.PermissionFlagEntry): PermissionFlag {
    return new PermissionFlag({
      label: entry.label,
      permissionId: entry.permissionId,
    });
  }

  deserializeList(entries: PermissionsApi.PermissionFlagEntry[]): PermissionFlag[] {
    return entries ? entries.map((permissionFlag) => this.deserialize(permissionFlag)) : [];
  }
}
