import { ProjectTemplateApi } from 'api/ProjectTemplateApi';
import { List } from 'immutable';
import { ProjectTemplateMapper } from 'mapper/ProjectTemplateMapper';
import { ProjectTemplate } from 'model/ProjectTemplate';

export class ProjectTemplateRepository {
  constructor(
    private projectTemplateApi: ProjectTemplateApi,
    private projectTemplateMapper: ProjectTemplateMapper
  ) {}

  list(): Promise<List<ProjectTemplate>> {
    return this.projectTemplateApi
      .list()
      .then((list = []) =>
        List(list.map((entry) => this.projectTemplateMapper.deserialize(entry)))
      );
  }

  get(id: number): Promise<ProjectTemplate> {
    return this.projectTemplateApi
      .get(id)
      .then((entry) => this.projectTemplateMapper.deserialize(entry));
  }

  create(data: ProjectTemplateApi.PostEntry): Promise<ProjectTemplate> {
    return this.projectTemplateApi
      .create(data)
      .then((entry) => this.projectTemplateMapper.deserialize(entry));
  }

  adapt(sourceId: number, destinationId: number): Promise<ProjectTemplate> {
    return this.projectTemplateApi
      .adapt(sourceId, destinationId)
      .then((entry) => this.projectTemplateMapper.deserialize(entry));
  }

  adaptPrevious(sourceId: number, destinationId: number): Promise<ProjectTemplate> {
    return this.projectTemplateApi
      .adaptPrevious(sourceId, destinationId)
      .then((entry) => this.projectTemplateMapper.deserialize(entry));
  }
}
