import { PermissionId } from 'model/PermissionId';
import { SimpleModel } from 'model/SimpleModel';

export namespace Permission {
  export interface Shape {
    id?: string;
    name?: string;
    hint?: string;
    isClientAdminBlocked?: boolean;
    isAdminBlocked?: boolean;
    children?: Permission[];
  }
}

export class Permission extends SimpleModel<Permission.Shape> {
  getId(): string {
    return this.get('id');
  }

  getName(): string {
    return this.get('name');
  }

  getHint(): string {
    return this.get('hint');
  }

  hasChildren(): boolean {
    return !!this.getChildren();
  }

  getChildren(): Permission[] {
    return this.get('children');
  }

  isOverall(): boolean {
    return this.get('id').endsWith('overall');
  }

  isInGroup(groupId: string): boolean {
    return this.get('id').startsWith(groupId);
  }

  isClientAdminBlocked(): boolean {
    return this.get('isClientAdminBlocked');
  }

  isAdminBlocked(): boolean {
    return this.get('isAdminBlocked');
  }

  isMatrixAccessFeature(): boolean {
    return this.getId() === PermissionId.FEATURE_MATRIX_ACCESS;
  }
}
