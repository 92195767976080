import React from 'react';
import { RGBColor } from 'react-color';
import { connect } from 'react-redux';

import { orange } from '@mui/material/colors';
import { ClientRoute } from 'app/route/client';
import { ColorPickerPopover } from 'component/ColorPicker/ColorPickerPopover';
import {
  BenchmarkingDescription,
  BenchmarkingTitle,
  BenchmarkingWrapper,
  CompanyBrandColorWrapper,
} from 'component/CreateProject/CreateProjectDialog.s';
import { Dropzone } from 'component/Dropzone/Dropzone';
import { DropzoneDefaultMessage } from 'component/Dropzone/DropzoneDefaultMessage';
import { DropzoneClientLogoEmptyMessage } from 'component/DropzoneClientLogoEmptyMessage/DropzoneClientLogoEmptyMessage';
import { AutocompleteData } from 'component/Form/Autocomplete';
import { SelectField } from 'component/Form/SelectField';
import { TextField } from 'component/Form/TextField';
import { MenuItem } from 'component/MenuItem/MenuItem';
import { clientRepository } from 'container/clientRepository';
import { Exception } from 'exception/Exception';
import { ValidationErrorException } from 'exception/ValidationErrorException';
import { List } from 'immutable';
import { Client } from 'model/Client';
import { getClientPlanName, ClientPlanType } from 'model/ClientPlanType';
import { Enum } from 'model/Enum';
import { Project } from 'model/Project';
import { Timezone } from 'model/Timezone';
import { compose } from 'redux';
import { reduxForm, Field, InjectedFormProps, SubmissionError } from 'redux-form';
import { AdminDispatch } from 'reduxStore/appStore';
import {
  selectBenchmarkSectors,
  selectEmployeeRangeGroup,
  selectTimezoneAutocompleteOptions,
} from 'reduxStore/configs/selectors';
import { closeModal } from 'reduxStore/modal/slice';
import { openSnackbar } from 'reduxStore/snackbars/asyncActions';
import { AdminFormNames } from 'register/AdminFormNames';
import { pushRoute } from 'router/routerAction';
import tinycolor from 'tinycolor2';
import { required } from 'validator/required';
import { selectCirclesResource, selectCircles } from 'reduxStore/circles/selectors';
import { Circle } from 'model/Circle';
import { Resource } from 'model/Resource';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';

export namespace CreateClientModalForm {
  export type StateProps = {
    benchmarkSectors: List<Enum>;
    circlesResource: Resource<List<Circle>>;
    employeeRangeGroup: List<Enum>;
    timezones: AutocompleteData<string>[];
    onSubmit: (data: FormData, dispatch: AdminDispatch) => void;
    initialValues: FormData | any;
  };
  export type OwnProps = {
    onSubmitSuccess?: () => void;
  };
  export type FormData = {
    name?: string;
    circle: string;
    companyLogo?: File;
    brandColor?: RGBColor;
    timezone: string;
    benchmarkSector?: number;
    approxNumberEmployee?: number;
    plan: number;
  };
  export type Props = OwnProps & InjectedFormProps<FormData, OwnProps> & StateProps;
}

export const CreateClientModalFormPure = (props: CreateClientModalForm.Props): JSX.Element => (
  <ResourceLoader
    resource={props.circlesResource}
    content={(circles: List<Circle>) => (
      <form onSubmit={props.handleSubmit}>
        <Field
          name="name"
          component={TextField}
          label="Company name"
          validate={required('Company name is required')}
          fullWidth
        />
        {circles.count() > 1 && (
          <Field
            name="circle"
            component={SelectField}
            label="Circle"
            validate={required('Circle is required')}
            fullWidth
          >
            {circles
              .sort((a, b) => a.getName().localeCompare(b.getName()))
              .map((circle, index) => (
                <MenuItem key={index} value={circle.getId()}>
                  {circle.getName()}
                </MenuItem>
              ))}
          </Field>
        )}

        <Field
          name="companyLogo"
          label="Logo"
          component={Dropzone}
          maxSize={1048576}
          accept={Project.getAcceptedImagesTypes()}
        >
          {(files: File[]) => {
            switch (files.length) {
              case 0:
                return <DropzoneClientLogoEmptyMessage />;
              default:
                return <DropzoneDefaultMessage files={files} />;
            }
          }}
        </Field>
        <CompanyBrandColorWrapper>
          <Field
            name="brandColor"
            component={ColorPickerPopover}
            style={{ margin: '0px 10px 0px 0px' }}
            popoverStyle={{ bottom: '200px' }}
          />
          <span>Company Brand Colour</span>
        </CompanyBrandColorWrapper>
        <Field
          name="timezone"
          component={SelectField}
          label="Timezone"
          validate={required('Timezone is required')}
        >
          {props.timezones.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.text}
            </MenuItem>
          ))}
        </Field>
        <BenchmarkingWrapper>
          <BenchmarkingTitle>Benchmarking</BenchmarkingTitle>
          <BenchmarkingDescription>
            To benchmark your results against similar companies please tell us a bit more about your
            organisation:
          </BenchmarkingDescription>
          <Field
            name="benchmarkSector"
            component={SelectField}
            label="Sector"
            validate={required('Benchmark sector is required')}
          >
            {props.benchmarkSectors.map((item, index) => (
              <MenuItem key={index} value={item.getKey()}>
                {item.getName()}
              </MenuItem>
            ))}
          </Field>
          <Field
            name="approxNumberEmployee"
            component={SelectField}
            label="Approx number of employees"
            validate={required('Approx number of employees is required')}
          >
            {props.employeeRangeGroup.map((item, index) => (
              <MenuItem key={index} value={item.getKey()}>
                {item.getName()}
              </MenuItem>
            ))}
          </Field>
          <Field
            name="plan"
            component={SelectField}
            label="Plan"
            validate={required('Plan is required')}
          >
            <MenuItem value={ClientPlanType.Legacy}>
              {getClientPlanName(ClientPlanType.Legacy)}
            </MenuItem>
            <MenuItem value={ClientPlanType.Essential}>
              {getClientPlanName(ClientPlanType.Essential)}
            </MenuItem>
            <MenuItem value={ClientPlanType.Professional}>
              {getClientPlanName(ClientPlanType.Professional)}
            </MenuItem>
            <MenuItem value={ClientPlanType.Enterprise}>
              {getClientPlanName(ClientPlanType.Enterprise)}
            </MenuItem>
          </Field>
        </BenchmarkingWrapper>
      </form>
    )}
  ></ResourceLoader>
);

export const CreateClientModalForm = compose(
  connect(
    (state): CreateClientModalForm.StateProps => {
      const timezones = selectTimezoneAutocompleteOptions(state);
      const initialTimezone = timezones.find(({ value }) => value === Timezone.DEFAULT_TIMEZONE);

      return {
        benchmarkSectors: selectBenchmarkSectors(state),
        circlesResource: selectCirclesResource(state),
        employeeRangeGroup: selectEmployeeRangeGroup(state),
        timezones: timezones,
        initialValues: {
          brandColor: tinycolor(orange[600]).toRgb(),
          timezone: initialTimezone && initialTimezone.value,
        },
        onSubmit: (data: CreateClientModalForm.FormData, dispatch: AdminDispatch) => {
          const model = new Client({
            name: data.name,
            brandColor: data.brandColor,
            circleId: data.circle || selectCircles(state).first().getId(),
            timezone: data.timezone,
            approxEmployeeNumber: data.approxNumberEmployee,
            sectors: List.of(data.benchmarkSector),
            plan: data.plan,
            url: undefined,
          });

          return clientRepository
            .create(model, data.companyLogo)
            .then((client) => {
              dispatch(openSnackbar({ message: 'Client created successfully.' }));
              dispatch(
                pushRoute(ClientRoute.Project.COMPANY_SETTINGS, {
                  id: client.getFirstPulseTemplateId(),
                })
              );
              dispatch(closeModal());
            })
            .catch((error: Exception) => {
              dispatch(openSnackbar({ message: `Cannot create client - ${error.getMessage()}` }));

              // TODO fix error fields mapping after WB2-2729 is done
              // investigate case with multiple validation errors
              if (error instanceof ValidationErrorException) {
                throw new SubmissionError({
                  [error.getPath()]: error.getMessage(),
                });
              }
            });
        },
      };
    }
  ),
  reduxForm({
    form: AdminFormNames.CreateClient,
  })
)(CreateClientModalFormPure) as React.ComponentClass<CreateClientModalForm.OwnProps>;
