import { selectResponseData } from 'util/selector/axiosSelector';

import { AxiosInstance } from 'axios';

export namespace CircleApi {
  export type Entry = {
    id: string;
    name: string;
    emailLogoUrl: string;
    hasRegularProjects: boolean;
  };
}

export class CircleApi {
  constructor(private client: AxiosInstance) {}

  fetch(): Promise<CircleApi.Entry[]> {
    return this.client.get(`/api/v1/circles`).then((response) => {
      const responseData = selectResponseData(response);
      return responseData.data;
    });
  }
}
