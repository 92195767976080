import React from 'react';

import { Dropdown } from 'component/Dropdown/Dropdown';
import { DropdownItem } from 'component/Dropdown/Dropdown.s';
import { UserLogoutWrapper } from 'view/TopBars/Header/Header.s';

export namespace UserLogout {
  export type Props = {
    userName: string;
    onLogout: () => void;
  };
}

export const UserLogout = (props: UserLogout.Props) => {
  return (
    <UserLogoutWrapper>
      <Dropdown data-testid="logout" label={props.userName}>
        <DropdownItem data-testid="logoutButton" onClick={props.onLogout}>
          Log Out
        </DropdownItem>
      </Dropdown>
    </UserLogoutWrapper>
  );
};
