import { TrendDataApi } from 'api/TrendDataApi';
import { Trend } from 'model/Trend';

export class TrendDataMapper {
  serialize(model: Trend): TrendDataApi.Entry {
    return {
      id: model.getId(),
      title: model.getTitle(),
    };
  }

  deserialize(entry: TrendDataApi.Entry): Trend {
    return new Trend({
      id: entry.id,
      title: entry.title,
    });
  }
}
