import { createSlice } from '@reduxjs/toolkit';
import {
  fetchDeliveryStatistic,
  refreshDeliveryStatistic,
} from 'reduxStore/deliveryStatistic/asyncActions';
import { DeliveryStatisticState, STORE_NAME } from 'reduxStore/deliveryStatistic/initialState';
import { addAsyncCases } from 'shared/utils/redux';

export const deliveryStatisticSlice = createSlice({
  name: STORE_NAME,
  initialState: DeliveryStatisticState.INITIAL_DOMAIN,
  reducers: {
    clearDeliveryStatistic: () => DeliveryStatisticState.INITIAL_DOMAIN,
  },
  extraReducers: (builder) => {
    addAsyncCases(builder, fetchDeliveryStatistic, (state, action) => {
      state.listResource = action.payload;
    });
    addAsyncCases(builder, refreshDeliveryStatistic, () => {});
  },
});

export const { clearDeliveryStatistic } = deliveryStatisticSlice.actions;
