import { selectApiData } from 'util/selector/apiSelector';
import { selectResponseData } from 'util/selector/axiosSelector';

import { AxiosInstance } from 'axios';

export namespace AccessCodeApi {
  export type Entry = {
    id?: number;
    employeeId?: number;
    employeeConnectedId?: number;
    employeeEmail?: string;
    projectDistribution: string;
    code: string;
    surveyLink: string;
    subscribed: boolean;
  };
}

export class AccessCodeApi {
  constructor(private client: AxiosInstance) {}

  unsubscribed(distributionId: string): Promise<AccessCodeApi.Entry[]> {
    return this.client
      .get(`api/v1/distribution/${distributionId}/access-codes?filter[subscribed]=0`)
      .then(selectApiData);
  }

  search(distributionId: string, query: string): Promise<AccessCodeApi.Entry[]> {
    return this.client
      .get(
        `api/v1/distribution/${distributionId}/access-codes?query=${encodeURIComponent(
          query
        )}&limit=100`
      )
      .then(selectApiData);
  }

  resendLastEmail(distributionId: string, accessCode: string): Promise<any> {
    return this.client
      .post(`api/v1/distribution/${distributionId}/resend-last-email/${accessCode}`)
      .then(selectResponseData);
  }

  resendFailedEmail(emailTemplateId: number, accessCode: string): Promise<any> {
    return this.client
      .post(`api/v1/email-template/${emailTemplateId}/resend/${accessCode}`)
      .then(selectResponseData);
  }

  resendEmail(emailTemplateId: number, email: string): Promise<any> {
    return this.client.post(`api/v1/email-template/${emailTemplateId}/resend/${email}`);
  }
}
