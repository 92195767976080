import { EmailTemplateApi } from 'api/EmailTemplateApi';
import { List } from 'immutable';
import { ScheduleMapper } from 'mapper/ScheduleMapper';
import { EmailTemplate } from 'model/EmailTemplate';

export class EmailTemplateMapper {
  constructor(private scheduleMapper: ScheduleMapper) {}

  serialize(model: EmailTemplate): EmailTemplateApi.Entry {
    return {
      id: model.getId(),
      title: model.getTitle(),
      senderName: model.getSenderName(),
      replyTo: model.getReplyTo(),
      replyToName: model.getReplyToName(),
      content: JSON.stringify(model.getContent()),
      htmlContent: model.getHtmlContent(),
      schedules: model
        .getSchedules()
        ?.map((schedule) => this.scheduleMapper.serialize(schedule))
        .toArray(),
    };
  }

  deserialize(entry: EmailTemplateApi.Entry): EmailTemplate {
    let content;

    try {
      content = JSON.parse(entry.content);
    } catch (e) {
      // tslint:disable-next-line
      console.error(e);
      content = null;
    }

    return new EmailTemplate({
      id: entry.id,
      type: entry.type as EmailTemplate.Type,
      title: entry.title,
      senderName: entry.senderName,
      replyTo: entry.replyTo,
      replyToName: entry.replyToName,
      content: content,
      htmlContent: entry.htmlContent,
      schedules: entry.schedules
        ? List(entry.schedules.map((schedule) => this.scheduleMapper.deserialize(schedule)))
        : null,
      jobId: entry.job ? entry.job.id : null,
    });
  }
}
