import { draftEntityTransformer } from 'util/draftEntityTransformer';

import { RawDraftContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { List } from 'immutable';
import { Schedule } from 'model/Schedule';
import { SimpleModel } from 'model/SimpleModel';

export namespace EmailTemplate {
  export type Type = 'invitation' | 'reminder';
  export interface Shape {
    id?: number;
    type: Type;
    title?: string;
    senderName?: string;
    jobId?: number;
    replyTo?: string;
    replyToName?: string;
    content?: RawDraftContentState;
    htmlContent?: string;
    schedules?: List<Schedule>;
  }
}

export class EmailTemplate extends SimpleModel<EmailTemplate.Shape> {
  getId(): number {
    return this.get('id');
  }

  getType(): EmailTemplate.Type {
    return this.get('type', 'invitation');
  }

  getTitle(): string {
    return this.get('title');
  }

  getSenderName(): string {
    return this.get('senderName');
  }

  getReplyTo(): string {
    return this.get('replyTo');
  }

  getReplyToName(): string {
    return this.get('replyToName');
  }

  getJobId(): number {
    return this.get('jobId');
  }

  isInvitation(): boolean {
    return 'invitation' === this.getType();
  }

  isReminder(): boolean {
    return 'reminder' === this.getType();
  }

  hasContent(): boolean {
    return !!this.get('content');
  }

  getContent(): RawDraftContentState {
    return this.get('content');
  }

  setContent(content: RawDraftContentState): this {
    return this.set('content', content);
  }

  getHtmlContent(): string {
    return this.hasContent()
      ? draftToHtml(this.getContent(), undefined, undefined, draftEntityTransformer)
      : '';
  }

  hasSchedules(): boolean {
    return !this.getSchedules().isEmpty();
  }

  getSchedules(): List<Schedule> {
    return this.get('schedules');
  }

  mergeSchedule(schedule: Schedule): this {
    const schedules = this.getSchedules();

    if (!this.hasSchedules()) {
      return this.set('schedules', List.of(schedule));
    }

    const index = schedules.findIndex((item) => item.getId() === schedule.getId());
    if (index >= 0) {
      return this.set('schedules', schedules.set(index, schedule));
    }

    return this.set('schedules', schedules.push(schedule));
  }

  removeScheduleById(scheduleId: number) {
    const schedules = this.getSchedules();
    return this.set('schedules', schedules.filter((item) => item.getId() !== scheduleId).toList());
  }

  copy(): this {
    return this.set('id', undefined).set('schedules', null);
  }
}
