import { SurveySettingsApi } from 'api/SurveySettingsApi';
import { ProjectSettingsMapper } from 'mapper/ProjectSettingsMapper';
import { SurveySettings } from 'model/SurveySettings';
import moment from 'moment';

export class SurveySettingsMapper {
  constructor(private settingsMapper: ProjectSettingsMapper) {}

  serializeDates(model: SurveySettings): SurveySettingsApi.Dates {
    return {
      liveStop: model.getLiveStop() && moment(model.getLiveStop()).toDate().toDateString(),
      liveStart: model.getLiveStart() && moment(model.getLiveStart()).toDate().toDateString(),
    };
  }

  serialize(model: SurveySettings): SurveySettingsApi.Entry {
    return {
      ...this.serializeDates(model),
      name: model.getName(),
      settings: model.getSettings() && this.settingsMapper.serialize(model.getSettings()),
      reportRecipients: model.getReportRecipients() && model.getReportRecipients().toArray(),
    };
  }
}
