import React, { useState } from 'react';

import { SelectField } from 'component/Form/SelectField';
import { TypeheadSelectField } from 'component/Form/TypeheadSelectField';
import { MenuItem } from 'component/MenuItem/MenuItem';
import { SurveyTypeLabel } from 'component/SurveyTypeLabel/SurveyTypeLabel';
import { List } from 'immutable';
import { Company } from 'model/Company';
import { Project } from 'model/Project';
import { Field } from 'redux-form';
import { required } from 'validator/required';
import { Circle } from 'model/Circle';

export type CreateProjectExistingCompanyProps = {
  companies: List<Company>;
  circles: List<Circle>;
};

export const CreateProjectExistingCompany: React.VFC<CreateProjectExistingCompanyProps> = ({
  circles,
  companies,
}) => {
  const [autocompleteOptions, setAutocompleteOptions] = useState(() => {
    if (circles.count() === 1) {
      return getFilteredCompaniesByCircleId(circles.first().getId());
    }

    if (circles.count() < 1) {
      return companies
        .toArray()
        .map((company) => ({ id: company, name: company.getName() }))
        .sort((a, b) => a.name.localeCompare(b.name));
    }

    return null;
  });

  function getFilteredCompaniesByCircleId(circleId: string) {
    return companies
      .filter((company) => {
        return company.getCircleId() === circleId;
      })
      .toArray()
      .map((company) => ({ id: company, name: company.getName() }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  return (
    <div>
      <Field
        data-testid="projectKindField"
        name="kind"
        component={SelectField}
        label="Survey type"
        validate={required('Survey type is required')}
      >
        {Project.SURVEY_TYPE.map((type, index) => (
          <MenuItem key={index} value={type}>
            <SurveyTypeLabel kind={type} />
          </MenuItem>
        ))}
      </Field>
      {circles.count() > 1 && (
        <Field
          data-testid="circleField"
          name="circle"
          component={SelectField}
          label="Circle"
          validate={required('Circle is required')}
          fullWidth
          onChange={(circleId: any) => {
            setAutocompleteOptions(getFilteredCompaniesByCircleId(circleId));
          }}
        >
          {circles
            .sort((a, b) => a.getName().localeCompare(b.getName()))
            .map((circle, index) => (
              <MenuItem key={index} value={circle.getId()}>
                {circle.getName()}
              </MenuItem>
            ))}
        </Field>
      )}
      {autocompleteOptions && (
        <Field
          data-testid="companyField"
          name="company"
          component={TypeheadSelectField}
          label="Company list"
          validate={required('Company is required')}
          autocompleteOptions={autocompleteOptions}
        />
      )}
    </div>
  );
};
