import { RGBColor } from 'react-color';

import { styled } from '@mui/material';

export const columnStyle: any = {
  margin: '15px 0',
  display: 'flex',
  alignItems: 'center',
};

export const swatch: any = {
  padding: '5px',
  margin: '0 10px',
  background: '#fff',
  borderRadius: '1px',
  boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
  display: 'inline-block',
  cursor: 'pointer',
};

type ColorPreviewProps = {
  color: RGBColor;
};
export const ColorPreview = styled('div')(
  {
    width: '36px',
    height: '14px',
    borderRadius: '2px',
  },
  (props: ColorPreviewProps) => ({
    background: `rgba(${props.color.r}, ${props.color.g}, ${props.color.b}, ${props.color.a})`,
  })
);

export const popover: any = {
  position: 'absolute',
  zIndex: '2',
};

export const cover: any = {
  position: 'fixed',
  top: '0px',
  right: '0px',
  bottom: '0px',
  left: '0px',
};
