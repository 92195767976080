import { categoryMapper } from 'container/categoryMapper';
import { companyMapper } from 'container/companyMapper';
import { distributionMapper } from 'container/distributionMapper';
import { jobMapper } from 'container/jobMapper';
import { pageMapper } from 'container/pageMapper';
import { projectLayoutMapper } from 'container/projectLayoutMapper';
import { projectSettingsMapper } from 'container/projectSettingsMapper';
import { ProjectMapper } from 'mapper/ProjectMapper';

export const projectMapper: ProjectMapper = new ProjectMapper(
  pageMapper,
  projectLayoutMapper,
  projectSettingsMapper,
  distributionMapper,
  categoryMapper,
  jobMapper,
  companyMapper
);
