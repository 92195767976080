import { ProjectKind } from 'model/ProjectKind';
import { SimpleModel } from 'model/SimpleModel';
import { UserRoles, UserRoleType } from 'model/UserRoleType';
import { Moment } from 'moment';

export namespace ProjectSettings {
  export interface Shape {
    automaticNumbering: boolean;
    saveAndContinue: boolean;
    saveAndContinueContent: string;
    almostFinishedContent: string;
    thankYouPageContent: string;
    closedPageContent: string;
    surveyCodeQuestionContent: string;
    minimalAmountOfClosedAnswers: number;
    minimalAmountOfCommentAnswers: number;
    threePointScale: boolean;
    overallScore: boolean;
    respondentProtectionMode: number;
    aggregationMonths: number;
    inviteSentAt: Moment;
    dashboardNotifications?: boolean;
    sentimentAnalysis: boolean;
    lifecycleSurveys: boolean;
    lifecycleReminderInterval: string | null;
    showConsentQuestion: boolean;
    shareFeedbackWithLineManager: boolean;
    requireMultiFactorAuthentication: boolean;
    variableConfidentialityThreshold?: number;
  }
}

export class ProjectSettings extends SimpleModel<ProjectSettings.Shape> {
  static canEditSentimentAnalysis(config: { roles: UserRoles[]; kind: ProjectKind }): boolean {
    const { roles, kind } = config;
    if (!roles.includes(UserRoleType.Admin)) {
      return false;
    }

    const sentimentCompatibleKinds = [
      ProjectKind.PROJECT_ENGAGEMENT_SCORE,
      ProjectKind.PROJECT_OVERALL_SCORE,
      ProjectKind.PULSE_TEMPLATE,
    ];

    return sentimentCompatibleKinds.includes(kind);
  }

  hasAutomaticNumbering(): boolean {
    return this.get('automaticNumbering');
  }

  hasSaveAndContinue(): boolean {
    return this.get('saveAndContinue');
  }

  getAlmostFinishedContent(): string {
    return this.get('almostFinishedContent');
  }

  getThankYouPageContent(): string {
    return this.get('thankYouPageContent');
  }

  getClosedPageContent(): string {
    return this.get('closedPageContent');
  }

  getSaveAndContinueContent(): string {
    return this.get('saveAndContinueContent');
  }

  getSurveyCodeQuestionContent(): string {
    return this.get('surveyCodeQuestionContent');
  }

  getMinimalAmountOfCloseAnswers(): number {
    return this.get('minimalAmountOfClosedAnswers');
  }

  getMinimalAmountOfCommentAnswers(): number {
    return this.get('minimalAmountOfCommentAnswers');
  }

  getSentimentAnalysis(): boolean {
    return this.get('sentimentAnalysis');
  }

  getLifecycleSurveys(): boolean {
    return this.get('lifecycleSurveys');
  }

  getLifecycleReminderInterval(): string | null {
    return this.get('lifecycleReminderInterval');
  }

  getShowConsentQuestion(): boolean {
    return this.get('showConsentQuestion');
  }

  getShareFeedbackWithLineManager(): boolean {
    return this.get('shareFeedbackWithLineManager');
  }

  getRequireMultiFactorAuthentication(): boolean {
    return this.get('requireMultiFactorAuthentication');
  }

  hasMinimalAmountOfCloseAnswer(): boolean {
    return this.get('minimalAmountOfClosedAnswers') >= 0;
  }

  hasMinimalAmountOfCommentQuestion(): boolean {
    return this.get('minimalAmountOfCommentAnswers') >= 0;
  }

  isThreePointScale(): boolean {
    return this.get('threePointScale');
  }

  isOverallScore(): boolean {
    return this.get('overallScore');
  }

  getRespondentProtectionMode(): number {
    return this.get('respondentProtectionMode');
  }

  getInviteSentAt(): Moment {
    return this.get('inviteSentAt');
  }

  getDashboardNotifications(): boolean {
    return this.get('dashboardNotifications');
  }

  getAggregationMonths(): ProjectSettings.Shape['aggregationMonths'] {
    return this.get('aggregationMonths');
  }

  getVariableConfidentialityThreshold(): ProjectSettings.Shape['variableConfidentialityThreshold'] {
    return this.get('variableConfidentialityThreshold');
  }
}
