import { PlaceholderApi } from 'api/PlaceholderApi';
import { Placeholder } from 'model/Placeholder';

export class PlaceholderMapper {
  deserialize(entry: PlaceholderApi.Entry): Placeholder {
    return new Placeholder({
      type: entry.type ? entry.type : null,
      label: entry.label,
      value: Array.isArray(entry.value)
        ? entry.value.map((item) => this.deserialize(item))
        : entry.value,
    });
  }
}
