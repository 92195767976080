import { jsonToFormData } from 'util/jsonToFormData';
import { selectApiData, selectApiNoContent } from 'util/selector/apiSelector';
import { selectResponseData } from 'util/selector/axiosSelector';

import { ApiMessage } from 'api/const';
import { WithData } from 'api/interfaces';
import { PaginationApi } from 'api/PaginationApi';
import { AxiosInstance } from 'axios';

export namespace ClientApi {
  export type Entry = {
    id: number;
    circleId?: string;
    name: string;
    logo: string;
    brandColor: string;
    timezone: string;
    approxEmployeeNumber: number;
    sectors: number[];
    plan: number;
    pulse_templates: {
      id: number;
      reportDashboardUrl: string;
    }[];
  };

  export type PaginatedEntry = PaginationApi.Entry & {
    _embedded: {
      items: ClientApi.Entry[];
    };
  };
}

export class ClientApi {
  constructor(private client: AxiosInstance) {}

  get(query: string): Promise<ClientApi.PaginatedEntry> {
    return this.client
      .get(`/api/v1/company/clients${query}`)
      .then((response) => selectResponseData(response) || []);
  }

  create(client: ClientApi.Entry, logo?: File): Promise<ClientApi.Entry> {
    const newClient = { ...client, logoFile: logo };
    return this.client
      .post<WithData<ClientApi.Entry>>(`/api/v1/company`, jsonToFormData(newClient))
      .then(selectApiData);
  }

  delete(clientId: number): Promise<void> {
    return this.client
      .delete<ApiMessage.Deleted>(`/api/v1/company/${clientId}`)
      .then(selectApiNoContent);
  }

  changePlan(clientId: number, plan: number): Promise<ClientApi.Entry> {
    return this.client
      .post<WithData<ClientApi.Entry>>(`/api/v1/company/${clientId}/plan`, { plan: plan })
      .then(selectApiData);
  }
}
