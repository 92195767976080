import React from 'react';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import { DropzoneMessage } from 'component/Dropzone/Dropzone.s';

export namespace DropzoneEmptyMessage {
  export type Props = {
    icon?: React.ReactNode;
    children?: React.ReactNode;
  };
}

export const DropzoneEmptyMessage: React.VFC<DropzoneEmptyMessage.Props> = (props) => (
  <DropzoneMessage>
    {props.icon}
    <br />
    {props.children}
  </DropzoneMessage>
);

DropzoneEmptyMessage.defaultProps = {
  icon: <FileUploadIcon style={{ width: 36, height: 36 }} color="inherit" />,
  children: 'Drop some files here, or click to select files to upload.',
};
