import { PageItemApi } from 'api/PageItemApi';
import { PageItemKindMapper } from 'mapper/PageItemMapper';
import { QuestionAbstractMapper } from 'mapper/QuestionAbstractMapper';
import { PageItem } from 'model/PageItem';
import { PageItemKind } from 'model/PageItemKind';
import { QuestionCAS } from 'model/QuestionCAS';

export class QuestionCASMapper
  implements PageItemKindMapper<QuestionCAS, PageItemApi.QuestionCASEntry> {
  serialize(model: QuestionCAS): PageItemApi.QuestionCASEntry {
    return {
      ...QuestionAbstractMapper.serialize(model),
      required: model.isRequired() ? 1 : 0,
    };
  }

  deserialize(entry: PageItemApi.QuestionCASEntry): QuestionCAS {
    return new QuestionCAS({
      ...QuestionAbstractMapper.deserializeShape(entry),
      isRequired: Boolean(entry.required),
    });
  }

  supportsSerialize(model: PageItem): boolean {
    return model instanceof QuestionCAS;
  }

  supportsDeserialize(entry: PageItemApi.ItemEntry): boolean {
    return entry.kind === PageItemKind.QuestionCAS;
  }
}
