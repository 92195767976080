import { selectProjectDomain } from 'reduxStore/project/projectSelector';
import { createSelector } from '@reduxjs/toolkit';

export const selectSubProjectsDomain = createSelector(
  selectProjectDomain,
  (projectDomain) => projectDomain && projectDomain.subProjects
);
export const selectSubProjectsList = createSelector(
  selectSubProjectsDomain,
  (domain) => domain.list
);
export const selectSubProjectsPagination = createSelector(
  selectSubProjectsDomain,
  (domain) => domain.pagination
);
