import React from 'react';
import { connect, ConnectedComponent } from 'react-redux';

import { projectRepository } from 'container/projectRepository';
import { Alert } from 'component/Alert/Alert';
import { DatePicker } from 'component/Form/DatePicker';
import { Exception } from 'exception/Exception';
import { Project } from 'model/Project';
import moment, { Moment } from 'moment';
import { compose } from 'redux';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { AdminDispatch } from 'reduxStore/appStore';
import { closeModal } from 'reduxStore/modal/slice';
import { openSnackbar } from 'reduxStore/snackbars/asyncActions';
import { AdminFormNames } from 'register/AdminFormNames';
import { dateFormat } from 'validator/dateFormat';
import { required } from 'validator/required';

export namespace ReopenProjectModalForm {
  export type StateProps = {
    initialValues: Partial<FormData>;
  };
  export type DispatchProps = {};
  export type OwnProps = {
    project: Project;
    loadProjectList: () => void;
  };
  export type FormData = {
    liveStop: Moment;
  };
  export type Props = OwnProps & InjectedFormProps<FormData, OwnProps>;
}

const today = new Date();
const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);

export const ReopenProjectModalFormPure: React.VFC<ReopenProjectModalForm.Props> = (props) => (
  <div>
    {props.error && <Alert severity="error">{props.error}</Alert>}
    <Field
      name="liveStop"
      component={DatePicker}
      label="When the survey should stop"
      minDate={moment(tomorrow)}
      validate={[required('Stop date is required'), dateFormat()]}
      fullWidth
    />
  </div>
);

export const ReopenProjectModalForm: ConnectedComponent<
  React.VFC<ReopenProjectModalForm.OwnProps>,
  ReopenProjectModalForm.OwnProps
> = compose(
  connect(
    (): ReopenProjectModalForm.StateProps => ({
      initialValues: {
        liveStop: null,
      },
    }),
    (): ReopenProjectModalForm.DispatchProps => ({})
  ),
  reduxForm({
    form: AdminFormNames.LiveStopDate,
    onSubmit: (
      data: ReopenProjectModalForm.FormData,
      dispatch: AdminDispatch,
      ownProps: ReopenProjectModalForm.OwnProps
    ) => {
      return projectRepository
        .reopen(moment(data.liveStop), ownProps.project.getId())
        .then(() => {
          dispatch(openSnackbar({ message: 'Project reopened successfully' }));
          dispatch(closeModal());
          ownProps.loadProjectList();
        })
        .catch((error: Exception) => {
          // TODO map error to specific field using path
          // blocked by WB2-2729
          throw error.mapToSubmissionError();
        });
    },
  })
)(ReopenProjectModalFormPure);
