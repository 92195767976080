import { List } from 'immutable';
import { SimpleModel } from 'model/SimpleModel';

export namespace Department {
  export interface Shape {
    id?: number;
    name: string;
    children: List<Department>;
    connected: Department | null;
    position: number;
    parentId?: number;
  }
}

export class Department extends SimpleModel<Department.Shape> {
  static OVERALL = 'all';

  static getMenuLabel(index: number, department: Department): string {
    return index + 1 + '. ' + department.getName();
  }

  static isOverall(departmentTag: string): boolean {
    return departmentTag === Department.OVERALL;
  }

  getId(): number {
    return this.get('id');
  }

  getName(): string {
    return this.get('name');
  }

  getChildren(): List<Department> {
    return this.get('children', List<Department>());
  }

  getPosition(): number {
    return this.get('position');
  }

  getConnected(): Department {
    return this.get('connected');
  }

  hasConnection(): boolean {
    return !!this.get('connected');
  }

  getParentId(): number {
    return this.get('parentId');
  }

  getNestedChildren(): List<Department> {
    return this.getChildren().reduce(
      (nested, child) => nested.push(child).concat(child.getNestedChildren()).toList(),
      List<Department>()
    );
  }

  hasChildren(): boolean {
    return !this.getChildren().isEmpty();
  }
}
