import React from 'react';
import { connect } from 'react-redux';

import { ConnectedDialog } from 'component/ConnectedDialog/ConnectedDialog';
import { FlatButton, SecondaryButton } from 'component/Button/Button';
import { isSubmitting, submit } from 'redux-form';
import { AdminDispatch, AdminState } from 'reduxStore/appStore';
import { closeModal } from 'reduxStore/modal/slice';
import { AdminFormNames } from 'register/AdminFormNames';
import { AdminModal } from 'register/AdminModal';
import { CreateClientModalForm } from 'view/ClientsPage/CreateClient/CreateClientModalForm';

namespace CreateClientModal {
  export type StateProps = {
    isSaving: boolean;
  };
  export type DispatchProps = {
    onSave: () => void;
    onCancel: () => void;
  };
  export type OwnProps = {};
  export type Props = OwnProps & StateProps & DispatchProps;
}

export const CreateClientModalPure: React.VFC<CreateClientModal.Props> = (props) => (
  <ConnectedDialog
    name={AdminModal.CreateClient}
    title="Create New Client"
    actions={[
      <SecondaryButton onClick={() => props.onCancel()} key={0}>
        Cancel
      </SecondaryButton>,
      <FlatButton
        id="createClient"
        label="Create"
        color="primary"
        keyboardFocused
        disabled={props.isSaving}
        onClick={() => props.onSave()}
        key={1}
      />,
    ]}
  >
    <CreateClientModalForm />
  </ConnectedDialog>
);

export const CreateClientModal: React.ComponentClass<CreateClientModal.OwnProps> = connect(
  (state: AdminState): CreateClientModal.StateProps => ({
    isSaving: isSubmitting(AdminFormNames.CreateClient)(state),
  }),
  (dispatch: AdminDispatch): CreateClientModal.DispatchProps => ({
    onSave: () => {
      dispatch(submit(AdminFormNames.CreateClient));
    },
    onCancel: () => dispatch(closeModal()),
  })
)(CreateClientModalPure);
