import { Queue } from 'model/Queue';
import { Resource } from 'model/Resource';
import { TakeLatest } from 'shared/utils/type';

export const STORE_NAME = 'queue';

export namespace QueueState {
  export type Domain = {
    projectQueues: TakeLatest<Resource<Queue[]>>;
  };

  export const INITIAL_DOMAIN: QueueState.Domain = {
    projectQueues: {
      data: Resource.pending(),
      requestId: '',
    },
  };
}
