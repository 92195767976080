import { grey } from '@mui/material/colors';
import { styled } from '@mui/material';

type HeaderProps = {
  boldFontWeight?: boolean;
};

export const Header = styled('h2')(
  {
    fontSize: '1.8em',
    color: grey[700],
  },
  (props: HeaderProps) => ({
    fontWeight: (props.boldFontWeight ? 'bold' : 'normal') as 'bold' | 'normal',
  })
);

export const MenuTitle = styled('h4')({
  fontFamily: 'Roboto',
  fontWeight: 400,
  padding: '10px 0',
});
