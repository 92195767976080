import { isArrayEmpty } from 'util/isArrayEmpty';

import { JobName } from 'model/JobName';
import { JobStatus } from 'model/JobStatus';
import { SimpleModel } from 'model/SimpleModel';

export namespace JobResult {
  export type ProcessLifecycleRespondents = {
    existingRespondents: number;
    newRespondents: number;
    orphanRespondents: number;
    orphanToRemove: number;
  };

  export type ResolveType<Name> = Name extends typeof JobName.ProcessLifecycleRespondents
    ? JobResult.ProcessLifecycleRespondents
    : unknown;
}

export namespace JobExtended {
  export interface Shape<Name = string> {
    id: number;
    name: Name;
    status: JobStatus;
    reason: any[];
    progress: number;
    warnings: any[];
    executionTime: number;
    visited: boolean;
    result: JobResult.ResolveType<Name>;
  }
}

export class JobExtended<Name = string> extends SimpleModel<JobExtended.Shape<Name>> {
  getId(): number {
    return this.get('id');
  }

  getStatus(): JobStatus {
    return this.get('status');
  }

  getName(): Name {
    return this.get('name');
  }

  getReason(): any {
    return this.get('reason');
  }

  getProgress(): number {
    return this.get('progress', 0);
  }

  isSuccessful(): boolean {
    return this.getStatus() === JobStatus.Success;
  }

  isFailed(): boolean {
    return this.getStatus() === JobStatus.Failure;
  }

  isPending(): boolean {
    return this.getStatus() === JobStatus.Execution;
  }

  getWarnings(): any[] {
    return this.get('warnings');
  }

  getExecutionTime(): number {
    return this.get('executionTime');
  }

  hasWarnings(): boolean {
    return !isArrayEmpty(this.get('warnings'));
  }

  getVisited(): boolean {
    return this.get('visited');
  }

  getResult(): JobResult.ResolveType<Name> {
    return this.get('result');
  }
}
