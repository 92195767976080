import { categoryMapper } from 'container/categoryMapper';
import { customQuestionOptionMapper } from 'container/customQuestionOptionMapper';
import { staticResourceMapper } from 'container/staticResourceMapper';
import { ImageWidgetMapper } from 'mapper/ImageWidgetMapper';
import { PageItemMapper } from 'mapper/PageItemMapper';
import { QuestionCheckboxMapper } from 'mapper/QuestionCheckboxMapper';
import { QuestionCommentBoxMapper } from 'mapper/QuestionCommentBoxMapper';
import { QuestionConsentMapper } from 'mapper/QuestionConsentMapper';
import { QuestionCASMapper } from 'mapper/QuestionCASMapper';
import { QuestionDemographicMapper } from 'mapper/QuestionDemographicMapper';
import { QuestionFormMapper } from 'mapper/QuestionFormMapper';
import { QuestionOpinionMapper } from 'mapper/QuestionOpinionMapper';
import { QuestionRadioMapper } from 'mapper/QuestionRadioMapper';
import { TextWidgetMapper } from 'mapper/TextWidgetMapper';

export const pageItemMapper = new PageItemMapper([
  new QuestionDemographicMapper(customQuestionOptionMapper),
  new QuestionOpinionMapper(categoryMapper),
  new QuestionCommentBoxMapper(),
  new QuestionFormMapper(customQuestionOptionMapper),
  new QuestionCheckboxMapper(customQuestionOptionMapper),
  new QuestionConsentMapper(),
  new QuestionRadioMapper(customQuestionOptionMapper),
  new QuestionCASMapper(),
  new QuestionDemographicMapper(customQuestionOptionMapper),
  new TextWidgetMapper(),
  new ImageWidgetMapper(staticResourceMapper),
]);
