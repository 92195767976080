import { colors } from 'app/theme/colors';
import { styled } from '@mui/material';

export const Header = styled('header')({
  width: '100%',
  background: 'white',
});

export const Logo = styled('img')({
  objectFit: 'contain',
  height: '130px',
  width: '200px',
  overflow: 'hidden',

  '&:hover': {
    cursor: 'pointer',
  },
});

export const ActionsButtonsWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const BottomLine = styled('span')({
  display: 'block',
  width: '100%',
  background: `linear-gradient(to right, ${colors.treePoppy}, ${colors.flamingo})`,
  boxShadow: '0px 0px 0px 1px #d9d9d9',
  height: '6px',
});

export const HeaderContentWrapper = styled('div')({
  display: 'flex',
  height: '150px',
  alignItems: 'center',
  padding: '10px 20px',
});

export const LogosWrapper = styled('div')({
  flex: '1 0 auto',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});

export const UserActionsWrapper = styled('div')({
  flex: '1 0 200px',
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
});

export const UserLogoutWrapper = styled('div')({
  marginLeft: '30px',
});

export const HeaderItem = styled('div')({
  margin: '0 15px',
});

export const HeaderLink = styled('a')({
  textDecoration: 'none',
  fontSize: '16px',
  fontWeight: 500,
  color: colors.mineShaft,
  '&:hover, &:visited, &:active': {
    color: colors.mineShaft,
  },
});
