import { getNewDepartmentsOrder } from 'util/getNewDepartmentsOrder';

import { serializeError } from 'shared/utils/redux';
import { Department } from 'model/Department';
import { DepartmentPositionType } from 'model/DepartmentPositionType';
import { selectDepartmentList } from 'reduxStore/department/selectors';
import { clearEmployeesList } from 'reduxStore/employee/slice';
import { selectCurrentProjectOnlyId } from 'reduxStore/project/projectSelector';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { STORE_NAME } from 'reduxStore/department/initialState';
import { departmentRepository } from 'container/departmentRepository';
import { clearDepartmentsDryList } from 'reduxStore/department/slice';

export const fetchDepartmentList = createAsyncThunk(
  `${STORE_NAME}/fetchDepartmentList`,
  ({ projectId }: { projectId: number }) => {
    return departmentRepository.list(projectId);
  },
  { serializeError }
);

export const uploadDepartmentList = createAsyncThunk(
  `${STORE_NAME}/uploadDepartmentList`,
  ({ file, projectId }: { file: File; projectId: number }, { dispatch }) => {
    return departmentRepository.upload(projectId, file, false).then((result) => {
      dispatch(clearDepartmentsDryList());
      dispatch(clearEmployeesList());
      return result;
    });
  },
  { serializeError }
);

export const uploadDepartmentDry = createAsyncThunk(
  `${STORE_NAME}/uploadDepartmentDry`,
  ({ file, projectId }: { file: File; projectId: number }) => {
    return departmentRepository.upload(projectId, file, true);
  },
  { serializeError }
);

export const addDepartment = createAsyncThunk(
  `${STORE_NAME}/addDepartment`,
  ({ name }: { name: string }, { getState }) => {
    const projectId = selectCurrentProjectOnlyId(getState());
    return departmentRepository.add(projectId, name);
  },
  { serializeError }
);

export const editDepartment = createAsyncThunk(
  `${STORE_NAME}/editDepartment`,
  ({ departmentId, name }: { departmentId: number; name: string }) => {
    return departmentRepository.edit(departmentId, name);
  },
  { serializeError }
);

export const deleteDepartment = createAsyncThunk(
  `${STORE_NAME}/deleteDepartment`,
  ({ department }: { department: Department }) => {
    return departmentRepository.delete(department.getId());
  },
  { serializeError }
);

export const changeDepartmentPosition = createAsyncThunk(
  `${STORE_NAME}/changeDepartmentPosition`,
  (
    {
      selectedDepartment,
      positionDepartmentId,
      positionType,
    }: {
      selectedDepartment: Department;
      positionDepartmentId: number;
      positionType: DepartmentPositionType;
    },
    { getState }
  ) => {
    const departmentList = selectDepartmentList(getState());

    const order = getNewDepartmentsOrder(
      selectedDepartment,
      positionDepartmentId,
      positionType,
      departmentList
    );

    return departmentRepository.reorder(order);
  },
  { serializeError }
);

export const changeDepartmentParent = createAsyncThunk(
  `${STORE_NAME}/changeDepartmentParent`,
  ({ childId, parentId }: { childId: number; parentId: number }) => {
    const newParentId = parentId === 0 ? null : parentId;
    return departmentRepository.changeParent(childId, newParentId);
  },
  { serializeError }
);
