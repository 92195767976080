import { Exception } from 'exception/Exception';

export class BatchException extends Exception {
  static normalize(exception: any): Exception[] {
    if (exception instanceof BatchException) {
      return exception.getExceptions();
    }

    return [exception];
  }

  constructor(private exceptions: Exception[]) {
    super({
      type: 'BATCH_ERROR',
      message: exceptions.map((exception) => exception.getMessage()).join(','),
      code: 'BATCH_ERROR',
    });
    Object.setPrototypeOf(this, BatchException.prototype);
  }

  getExceptions(): Exception[] {
    return this.exceptions;
  }
}
