import { createSlice } from '@reduxjs/toolkit';
import { fetchStaticResourceList } from 'reduxStore/staticResource/asyncActions';
import { addAsyncCases } from 'shared/utils/redux';

import { StaticResourceState, STORE_NAME } from './initialState';

export const staticResourceSlice = createSlice({
  name: STORE_NAME,
  initialState: StaticResourceState.INITIAL_DOMAIN,
  reducers: {
    clearStaticResourceList: (state) => {
      state.listResource = StaticResourceState.INITIAL_DOMAIN.listResource;
    },
  },
  extraReducers: (builder) => {
    addAsyncCases(builder, fetchStaticResourceList, (state, action) => {
      state.listResource = action.payload;
    });
  },
});

export const { clearStaticResourceList } = staticResourceSlice.actions;
