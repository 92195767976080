import Chip from '@mui/material/Chip';
import { styled } from '@mui/material';

export const projectDataStyle = {
  padding: '10px 0 0 0',
  minWidth: '152px',
  textAlign: 'center' as const,
};

export const ProjectStatusWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const ProjectStatusContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  textAlign: 'center',
});

export const ChipWrapper = (styled(Chip)({
  display: 'flex',
  justifyContent: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
}) as any) as typeof Chip;
