import { PageItemApi } from 'api/PageItemApi';
import { PageItem } from 'model/PageItem';

export interface PageItemKindMapper<
  M extends PageItem = PageItem,
  E extends PageItemApi.ItemEntry = PageItemApi.ItemEntry
  // tslint:disable-next-line
> {
  serialize(model: M): E;
  deserialize(entry: E): M;
  supportsSerialize(model: M): boolean;
  supportsDeserialize(entry: E): boolean;
}

export class PageItemMapper implements PageItemKindMapper<PageItem, PageItemApi.ItemEntryAny> {
  constructor(private mappers: PageItemKindMapper[]) {}

  serialize<T extends PageItemApi.ItemEntryAny>(model: PageItem): T {
    return this.findSerializer(model).serialize(model) as T;
  }

  deserialize<T extends PageItem>(entry: PageItemApi.ItemEntryAny): T {
    return this.findDeserializer(entry).deserialize(entry) as T;
  }

  supportsSerialize(model: PageItem): boolean {
    return this.mappers.some((mapper) => mapper.supportsSerialize(model));
  }

  supportsDeserialize(entry: PageItemApi.ItemEntry): boolean {
    return this.mappers.some((mapper) => mapper.supportsDeserialize(entry));
  }

  private findSerializer(model: PageItem): PageItemKindMapper {
    const serializer = this.mappers.find((mapper) => mapper.supportsSerialize(model));

    if (!serializer) {
      throw new Error('Cannot find serializer for page item model: ' + JSON.stringify(model));
    }

    return serializer;
  }

  private findDeserializer(entry: PageItemApi.ItemEntry): PageItemKindMapper {
    const deserializer = this.mappers.find((mapper) => mapper.supportsDeserialize(entry));

    if (!deserializer) {
      throw new Error('Cannot find deserializer for page item entry: ' + JSON.stringify(entry));
    }

    return deserializer;
  }
}
