import { selectApiData } from 'util/selector/apiSelector';
import { selectResponseData } from 'util/selector/axiosSelector';

import { WithData } from 'api/interfaces';
import { CategoryApi } from 'api/CategoryApi';
import { PageApi } from 'api/PageApi';
import { AxiosInstance } from 'axios';
import { ProjectKind } from 'model/ProjectKind';

export namespace ProjectTemplateApi {
  export type Entry = {
    categories?: CategoryApi.Entry[];
    createdAt: string;
    createdBy: string;
    id: number;
    kind: ProjectKind;
    name: string;
    pages?: PageApi.Entry[];
    updatedAt: string;
    updatedBy: string;
  };
  export type PostEntry = {
    name: string;
    kind: ProjectKind;
  };
}

export class ProjectTemplateApi {
  constructor(private client: AxiosInstance) {}

  list(): Promise<ProjectTemplateApi.Entry[]> {
    return this.client
      .get('/api/v1/project-template')
      .then((response) => selectResponseData(response, '_embedded.items', []));
  }

  get(id: number): Promise<ProjectTemplateApi.Entry> {
    return this.client
      .get<WithData<ProjectTemplateApi.Entry>>(`/api/v1/project-template/${id}`)
      .then(selectApiData);
  }

  create(data: ProjectTemplateApi.PostEntry): Promise<ProjectTemplateApi.Entry> {
    return this.client
      .post<WithData<ProjectTemplateApi.Entry>>(`/api/v1/project-template`, data)
      .then(selectApiData);
  }

  adapt(sourceId: number, destinationId: number): Promise<ProjectTemplateApi.Entry> {
    return this.client
      .post<WithData<ProjectTemplateApi.Entry>>(
        `/api/v1/project-template/${sourceId}/copy-to-project/${destinationId}`
      )
      .then(selectApiData);
  }

  adaptPrevious(sourceId: number, destinationId: number): Promise<ProjectTemplateApi.Entry> {
    return this.client
      .post(`/api/v1/project/${sourceId}/copy-to-project/${destinationId}`)
      .then(selectApiData);
  }
}
