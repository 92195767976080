import { DistributionChannel } from 'model/DistributionChannel';
import { Logic } from 'model/Logic';
import { LogicType } from 'model/LogicType';
import { SimpleModel } from 'model/SimpleModel';

export namespace LogicDistribution {
  export interface Shape extends Logic.Shape {
    channel: DistributionChannel;
  }
}

export class LogicDistribution extends Logic<LogicDistribution.Shape> {
  constructor(initialData: SimpleModel.Data<LogicDistribution.Shape>) {
    super(initialData, LogicType.Distribution);
  }

  getChannel(): DistributionChannel {
    return this.get('channel');
  }

  hasChannel(): boolean {
    return this.get('channel') !== undefined;
  }
}
