import React from 'react';

import { ProjectKind } from 'model/ProjectKind';

type SurveyTypeLabelProps = {
  kind: ProjectKind;
  className?: string;
};

export function getSurveyTypeLabel(kind: ProjectKind): string {
  switch (kind) {
    case ProjectKind.PROJECT_CLIENT_ADVOCACY_SCORE:
      return 'Client Advocacy Score';
    case ProjectKind.PULSE_PROJECT:
    case ProjectKind.PROJECT_ENGAGEMENT_SCORE:
      return 'Engagement Score';
    case ProjectKind.PROJECT_OVERALL_SCORE:
      return 'Overall Score';
    default:
      return 'Unknown kind';
  }
}

export const SurveyTypeLabel: React.VFC<SurveyTypeLabelProps> = (props) => (
  <span className={props.className}>{getSurveyTypeLabel(props.kind)}</span>
);
