import { useState } from 'react';
import { useDebounce } from 'react-use';

export const useDebounceValue = <T>(value: T, delay = 300) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useDebounce(
    () => {
      setDebouncedValue(value);
    },
    delay,
    [value, delay]
  );

  return debouncedValue;
};
