import { SimpleModel } from 'model/SimpleModel';
import { Moment } from 'moment';

export namespace Schedule {
  export interface Shape {
    id?: number;
    date?: Moment;
    lastSent?: Moment;
    relativeDate?: string;
  }
}

export class Schedule extends SimpleModel<Schedule.Shape> {
  getId(): number {
    return this.get('id');
  }

  getDate(): Moment {
    return this.get('date');
  }

  getNativeDate(): Date {
    return this.get('date') ? this.get('date').toDate() : undefined;
  }

  getLastSent(): Moment {
    return this.get('lastSent');
  }

  wasSent(): boolean {
    return !!this.get('lastSent');
  }

  getRelativeDate(): string {
    return this.get('relativeDate');
  }

  isRelative(): boolean {
    return !!this.get('relativeDate');
  }

  isAbsolute(): boolean {
    return !!this.get('date');
  }
}
