import { createAsyncThunk } from '@reduxjs/toolkit';
import { queueApi } from 'container/queueApi';
import { serializeError } from 'shared/utils/redux';

import { STORE_NAME } from './initialState';

export const fetchProjectQueues = createAsyncThunk(
  `${STORE_NAME}/fetchProjectQueues`,
  ({ projectId }: { projectId: number }) => {
    return queueApi.list(projectId);
  },
  { serializeError }
);
