import { SimpleModel } from 'model/SimpleModel';

export namespace AccessCodes {
  export interface Shape {
    employeeId: number;
    employeeConnectedId: number;
    employeeEmail: string;
    distribution: string;
    code: string;
    surveyLink: string;
    subscribed: boolean;
  }
}

export class AccessCode extends SimpleModel<AccessCodes.Shape> {
  getEmployeeId(): number {
    return this.get('employeeId');
  }

  getEmployeeConnectedId(): number {
    return this.get('employeeConnectedId');
  }

  getEmployeeEmail(): string {
    return this.get('employeeEmail');
  }

  getDistribution(): string {
    return this.get('distribution');
  }

  getCode(): string {
    return this.get('code');
  }

  getSurveyLink(): string {
    return this.get('surveyLink');
  }

  isSubscribed(): boolean {
    return this.get('subscribed');
  }
}
