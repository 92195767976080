export enum PageItemKind {
  QuestionOpinion = 101,
  QuestionRadioButton = 102,
  QuestionCheckbox = 103,
  QuestionCAS = 105, // Client Advocacy Store
  QuestionCommentsBox = 106,
  QuestionForm = 107,
  QuestionDemographic = 108,
  QuestionConsent = 109,
  TextWidget = 201,
  ImageWidget = 301,
}

export namespace PageItemKind {
  export enum General {
    Question = 100,
    TextWidget = 200,
    ImageWidget = 300,
  }

  export function getGeneralKind(kind: PageItemKind): PageItemKind.General {
    return Math.floor(kind / 100) * 100;
  }
}
