import { createResourceContentSelector } from 'util/selector/resourceSelector';

import { createSelector } from '@reduxjs/toolkit';
import { List } from 'immutable';
import groupBy from 'lodash/groupBy';
import { ImageWidget } from 'model/ImageWidget';
import { LogicDependency } from 'model/LogicDependency';
import { Page } from 'model/Page';
import { PageItemKind } from 'model/PageItemKind';
import { Question } from 'model/Question';
import { QuestionOpinion } from 'model/QuestionOpinion';
import { TextWidget } from 'model/TextWidget';
import { AdminState } from 'reduxStore/appStore';
import { selectModalParam } from 'reduxStore/modal/selectors';
import { PageState } from 'reduxStore/page/initialState';
import { AdminModal } from 'register/AdminModal';

export const selectPageDomain = (state: AdminState): PageState.Domain => state.page;

export const selectPageListResource = createSelector(
  selectPageDomain,
  (domain) => domain.listResource
);

export const selectPageList = createResourceContentSelector(selectPageListResource);

export const selectCurrentItem = createSelector(selectPageDomain, (domain) => domain.currentItem);

export const selectCurrentItemByLanguage = createSelector(
  selectPageDomain,
  (domain) => domain.currentItemByLanguage
);

export const selectLanguageTab = createSelector(selectPageDomain, (domain) => domain.languageTab);

export const selectQuestionDetailsTab = createSelector(
  selectPageDomain,
  (domain) => domain.questionDetailsTab
);

export const selectSelectedPage = createSelector(
  selectModalParam(AdminModal.TextOrMedia, 'page'),
  selectModalParam(AdminModal.TextOrMediaMultiLanguage, 'page'),
  selectModalParam(AdminModal.Question, 'page'),
  selectModalParam(AdminModal.QuestionMultiLanguage, 'page'),
  (...pages: Page[]) => pages.find((page) => !!page)
);

export const selectQuestionPosition = createSelector(
  selectPageListResource,
  selectSelectedPage,
  (listResource, selectedPage) =>
    listResource.isFulfilled()
      ? listResource
          .getContent()
          .filter((page) => page.getId() <= (selectedPage && selectedPage.getId()))
          .map((page) => page.getItems().size)
          .reduce((sum, count) => sum + count + 1, 0)
      : null
);

export const selectItemKind = createSelector(selectCurrentItem, (currentItem) =>
  currentItem ? currentItem.getKind() : null
);

export const selectPreviousPages = createSelector(
  selectPageList,
  selectSelectedPage,
  (pages, selectedPage) =>
    pages
      ? pages
          .filter(
            (page) => page && page.getPosition() <= (selectedPage && selectedPage.getPosition())
          )
          .toList()
      : null
);

export const selectPreviousDiscreteQuestionsByPage = createSelector(
  selectPreviousPages,
  selectSelectedPage,
  selectCurrentItem,
  selectQuestionPosition,
  (pages, selectedPage, currentItem, currentPosition) =>
    pages
      .map((page) => {
        if (page.getPosition() < selectedPage.getPosition()) {
          return page.getDiscreteQuestions().toList();
        } else if (page.getPosition() === selectedPage.getPosition()) {
          return page
            .getDiscreteQuestions()
            .map((question) =>
              currentItem
                ? question.getPosition() < currentItem.getPosition()
                  ? question
                  : null
                : question.getPosition() < currentPosition
                ? question
                : null
            )
            .filter((question) => question !== null)
            .toList();
        } else {
          return null;
        }
      })
      .toList()
);

export const selectQuestions = createSelector(selectPageList, (pages) =>
  pages
    ? pages.reduce(
        (questions, page) => questions.concat(page.getQuestions()).toList(),
        List<Question>()
      )
    : List<Question>()
);

export const selectOnlyTemplateQuestions = createSelector(selectPageList, (pages) =>
  pages
    ? pages.reduce((questions, page) => {
        /**
         * 1 is hardcoded position for template questions
         */
        if (page.getPosition() !== 1) {
          return questions;
        }

        return questions.concat(page.getQuestions()).toList();
      }, List<Question>())
    : List<Question>()
);

export const selectMediaQuestions = createSelector(selectPageList, (pages) => {
  return pages
    ? pages.reduce(
        (questions, page) => questions.concat(page.getMediaQuestions()).toList(),
        List<ImageWidget | TextWidget>()
      )
    : List<ImageWidget | TextWidget>();
});

export const selectAllDependencyLogicIds = createSelector(
  selectQuestions,
  (questions) =>
    questions &&
    questions
      .filter((question: Question) => question.hasLogic() && question.hasDependencyLogic())
      .map((question) => question.getLogic())
      .map((logic: LogicDependency) => logic.getOptions().toArray())
      .reduce((acc, e) => acc.concat(e), [])
);

export const selectHasClassificationOrDependencyLogic = createSelector(
  selectQuestions,
  selectMediaQuestions,
  (questions, media) =>
    (questions || media) &&
    questions
      .concat(media)
      .some(
        (question) =>
          question.hasLogic() &&
          (question.hasClassificationLogic() || question.hasDistributionLogic())
      )
);

export const selectHasClassificationLogic = createSelector(
  selectQuestions,
  selectMediaQuestions,
  (questions, media) =>
    (questions || media) &&
    questions
      .concat(media)
      .some((question) => question.hasLogic() && question.hasClassificationLogic())
);

export const selectHasDistributionLogic = createSelector(
  selectQuestions,
  selectMediaQuestions,
  (questions, media) =>
    (questions || media) &&
    questions
      .concat(media)
      .some((question) => question.hasLogic() && question.hasDistributionLogic())
);

export const selectHasLoadedPages = createSelector(selectPageList, (pages) => !!pages);

export const selectGroupedQuestionList = createSelector(
  selectOnlyTemplateQuestions,
  (questions: List<Question | QuestionOpinion>) => {
    const groupByCallback = (question: Question | QuestionOpinion) => {
      if (QuestionOpinion.isQuestionOpinion(question)) {
        // TODO page state QUESTIONS for main and sub project must be splitted because of issue with clearing state
        return question.getCategory()?.getName();
      }

      return 'Open Questions';
    };

    return (
      questions &&
      groupBy(
        questions
          .filter((question) =>
            [PageItemKind.QuestionOpinion, PageItemKind.QuestionCommentsBox].includes(
              question.getKind()
            )
          )
          .toArray(),
        groupByCallback
      )
    );
  }
);
