import { List } from 'immutable';
import { Classification } from 'model/Classification';
import { ContactListType } from 'model/ContactListType';
import { Demographic } from 'model/Demographic';
import { Department } from 'model/Department';
import { SimpleModel } from 'model/SimpleModel';

export namespace ContactList {
  export interface Shape {
    type: number;
    id?: number;
    list?: List<Demographic> | List<Department>;
  }
}

export class ContactList extends SimpleModel<ContactList.Shape> {
  getId(): number {
    return this.get('id');
  }

  getType(): ContactListType {
    return this.get('type');
  }

  getList(): List<Demographic> | List<Department> {
    return this.get('list');
  }
}

export type ContactListSend = {
  type: number;
  list?: List<Classification> | List<Department>;
};
