import React from 'react';

import { mapError } from 'component/Form/mapError';
import { WrappedFieldProps } from 'redux-form/lib/Field';

import { BaseTextField, BaseTextFieldProps } from './BaseTextField';

type BackwardCompatible = {
  hintText?: string;
};

export type Props = WrappedFieldProps & BaseTextFieldProps & BackwardCompatible;

// TODO hintTExt
export const TextField = ({ input, meta, hintText, ...props }: Props) => {
  const { errorText } = mapError(meta, 'errorText');
  const hasError = !!errorText;

  return (
    <BaseTextField
      placeholder={props.placeholder ? props.placeholder : hintText}
      {...input}
      error={hasError}
      helperText={errorText}
      {...props}
    />
  );
};
