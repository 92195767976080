import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Client } from 'model/Client';
import { ClientsTableWrapper, NoClientsFoundInfo } from 'view/ClientsPage/ClientsPage.s';
import { ClientsTableBody } from 'view/ClientsPage/ClientsTable/ClientsTableBody';

namespace ClientsTable {
  export type Props = {
    clients: Client[];
    onViewClick: (id: string) => void;
  };
}

export const ClientsTable: React.VFC<ClientsTable.Props> = (props) => (
  <ClientsTableWrapper>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Client Name</TableCell>
          <TableCell>Plan</TableCell>
          <TableCell align="center">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody data-testid="clientTable">
        {props.clients &&
          (props.clients.length === 0 ? (
            <NoClientsFoundInfo>No clients found</NoClientsFoundInfo>
          ) : (
            props.clients.map((client, index) => (
              <ClientsTableBody key={index} client={client} onViewClick={props.onViewClick} />
            ))
          ))}
      </TableBody>
    </Table>
  </ClientsTableWrapper>
);
