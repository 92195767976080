import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { PulseTemplate } from 'model/PulseTemplate';
import { Resource } from 'model/Resource';
import { ProjectState } from 'reduxStore/project/initialState';

import {
  fetchProjectSettings,
  updateProjectSettings,
  updatePulseLifecycleProjectSettings,
} from './actions';

export const projectSettingsReducer = (builder: ActionReducerMapBuilder<ProjectState.Domain>) => {
  builder
    .addCase(fetchProjectSettings.fulfilled, (state, action) => {
      state.settingsResource = Resource.resolve(action.payload);
    })
    .addCase(updateProjectSettings.fulfilled, (state, action) => {
      const project = action.payload;

      state.currentResource = Resource.resolve(project);
      if (project instanceof PulseTemplate) {
        state.settingsResource = Resource.resolve(project.getProject().getSettings());
      } else {
        state.settingsResource = Resource.resolve(project.getSettings());
      }
    })
    .addCase(updatePulseLifecycleProjectSettings.fulfilled, (state, action) => {
      const currentProject = state.currentResource.getContent();
      if (currentProject instanceof PulseTemplate) {
        const { pulseLifecycleProject } = action.payload;
        state.currentResource = Resource.resolve(
          currentProject.setPulseLifecycleProject(pulseLifecycleProject)
        );
      }
    });
};
