import { PageItemApi } from 'api/PageItemApi';
import { RawDraftContentState } from 'draft-js';
import { PageItemAbstractMapper } from 'mapper/PageItemAbstractMapper';
import { PageItemKindMapper } from 'mapper/PageItemMapper';
import { PageItem } from 'model/PageItem';
import { PageItemKind } from 'model/PageItemKind';
import { TextWidget } from 'model/TextWidget';

export class TextWidgetMapper
  implements PageItemKindMapper<TextWidget, PageItemApi.TextWidgetEntry> {
  serialize(model: TextWidget): PageItemApi.TextWidgetEntry {
    return {
      ...PageItemAbstractMapper.serialize(model),
      content: JSON.stringify(model.getContent()),
      htmlContent: model.getHtmlContent(),
    };
  }

  deserialize(entry: PageItemApi.TextWidgetEntry): TextWidget {
    let content: RawDraftContentState;

    try {
      content = JSON.parse(entry.content);
    } catch (e) {
      // tslint:disable-next-line
      console.error(e);
    }

    return new TextWidget({
      ...PageItemAbstractMapper.deserializeShape(entry),
      content: content,
      htmlContent: entry.htmlContent,
    });
  }

  supportsSerialize(model: PageItem): boolean {
    return model instanceof TextWidget;
  }

  supportsDeserialize(entry: PageItemApi.ItemEntry): boolean {
    return entry.kind === PageItemKind.TextWidget;
  }
}
