export enum ClientPlanType {
  Essential = 1,
  Professional = 2,
  Enterprise = 3,
  Legacy = 4,
}

export const getClientPlanName = (value: ClientPlanType): string => {
  switch (value) {
    case ClientPlanType.Enterprise:
      return 'Enterprise';
    case ClientPlanType.Essential:
      return 'Essential';
    case ClientPlanType.Legacy:
      return 'Legacy - Core';
    case ClientPlanType.Professional:
      return 'Professional';
  }
};
