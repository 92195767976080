import { selectApiData } from 'util/selector/apiSelector';

import { WithData } from 'api/interfaces';
import { DepartmentApi } from 'api/DepartmentApi';
import { AxiosInstance } from 'axios';
import { QuestionOpinion } from 'model/QuestionOpinion';

export namespace TrendItemsApi {
  export type Entry = QuestionOpinion[];
}

export class TrendItemsApi {
  constructor(private client: AxiosInstance) {}

  list(id: number): Promise<TrendItemsApi.Entry> {
    return this.client
      .get<WithData<TrendItemsApi.Entry>>(`/api/v1/project/${id}/trend-items`)
      .then(selectApiData);
  }

  setConnectedDepartments(sourceId: number, destinationId: number): Promise<DepartmentApi.Entry> {
    return this.client
      .post(`/api/v1/department/update-connection/${sourceId}/${destinationId}`)
      .then((response) => response.data.data);
  }
}
