import { isKeyValueListEmpty } from 'util/isKeyValueListEmpty';
import { selectOr } from 'util/selector/logicOperationSelector';
import { createResourceContentSelector } from 'util/selector/resourceSelector';
import { selectHasMatch } from 'util/selector/routerSelector';

import { AdminRoute } from 'app/route/admin';
import { ClientRoute } from 'app/route/client';
import { formKeyToId } from 'form/keys';
import { ContactList } from 'model/ContactList';
import { ContactListDistributionType } from 'model/ContactListDistributionType';
import { ContactListType } from 'model/ContactListType';
import { Resource } from 'model/Resource';
import { formValueSelector } from 'redux-form';
import { AdminState } from 'reduxStore/appStore';
import { ContactListState } from 'reduxStore/contactList/initialState';
import { selectHasInvitationAtLeastOneScheduleSent } from 'reduxStore/emailDistribution/selectors';
import { selectHasEmployees, selectHasEmployeeList } from 'reduxStore/employee/selectors';
import { AdminFormNames } from 'register/AdminFormNames';
import { createSelector, Selector } from '@reduxjs/toolkit';

export function selectContactListDomain(state: AdminState): ContactListState.Domain {
  return state && state.contactList;
}

export const selectContactListResource = createSelector(
  selectContactListDomain,
  (domain) => domain?.contactList || Resource.void<ContactList>()
);

export const selectContactListStatus = createSelector(selectContactListResource, (contactList) =>
  contactList.getStatus()
);

export const selectContactList = createResourceContentSelector(selectContactListResource);

export const selectHasContactList = createSelector(
  selectContactList,
  (contactList) => !!contactList
);

export const selectContactListType = createSelector(
  selectContactList,
  selectHasContactList,
  (contactList, hasContactList) => hasContactList && contactList.getType()
);

export const selectIsEmployeesContactList = createSelector(
  selectContactListType,
  (type) => type === ContactListType.All
);

export const selectIsDepartmentContactList = createSelector(
  selectContactListType,
  (type) => type === ContactListType.Departments
);

export const selectIsDemographicContactList = createSelector(
  selectContactListType,
  (type) => type === ContactListType.Demographics
);

export const createContactListOptionFormSelector = formValueSelector(
  AdminFormNames.ContactListType
);

export const createContactListFormSelector = formValueSelector(AdminFormNames.ContactList);

export const selectContactType: Selector<AdminState, string> = (state) =>
  createContactListOptionFormSelector(state, 'type');

export const selectSelectedDepartments: Selector<AdminState, { [id: string]: boolean }> = (state) =>
  createContactListFormSelector(state, 'department');

export const selectSelectedDemographics: Selector<AdminState, { [id: string]: boolean }> = (
  state
) => createContactListFormSelector(state, 'classifications');

export const selectCanSaveContactList = createSelector(
  selectContactType,
  selectSelectedDepartments,
  selectSelectedDemographics,
  selectHasEmployees,
  selectHasEmployeeList,
  selectHasInvitationAtLeastOneScheduleSent,
  (
    type,
    departmentList,
    demographicList,
    hasEmployees,
    hasEmployeesList,
    hasAtLeastOneScheduleSent
  ) => {
    switch (Number(type)) {
      case ContactListType.Departments:
        return !hasAtLeastOneScheduleSent && !isKeyValueListEmpty(departmentList);

      case ContactListType.Demographics:
        return !hasAtLeastOneScheduleSent && !isKeyValueListEmpty(demographicList);

      case ContactListType.All:
        return !hasAtLeastOneScheduleSent;

      default:
        return false;
    }
  }
);

export const selectIsMatchingEmailDistributionRoute = selectOr(
  selectHasMatch(ClientRoute.Project.Survey.Develop.AUDIENCE),
  selectHasMatch(AdminRoute.Project.Distribution.EMAIL_PATTERN)
);

export const selectContactListDistributionType = createSelector(
  selectIsMatchingEmailDistributionRoute,
  (emailDistribution) =>
    emailDistribution ? ContactListDistributionType.Email : ContactListDistributionType.Kiosk
);

export const selectSelectedDepartmentId = createSelector(
  selectSelectedDepartments,
  (departments) =>
    departments &&
    Object.keys(departments)
      .map((key) => (departments[key] ? formKeyToId(key) : undefined))
      .filter((item) => !!item)[0]
);
