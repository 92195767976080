import { PermissionsApi } from 'api/PermissionsApi';
import { PermissionFlagMapper } from 'mapper/PermissionFlagMapper';
import { PermissionGroupMapper } from 'mapper/PermissionGroupMapper';
import { PermissionTypesMapper } from 'mapper/PermissionTypesMapper';
import { PermissionFlag } from 'model/PermissionFlag';
import { PermissionGroup } from 'model/PermissionGroup';
import { PermissionTypes } from 'model/PermissionTypes';

export class PermissionsRepository {
  constructor(
    private permissionsApi: PermissionsApi,
    private permissionTypesMapper: PermissionTypesMapper,
    private permissionGroupMapper: PermissionGroupMapper,
    private permissionFlagMapper: PermissionFlagMapper
  ) {}

  get(projectId: number): Promise<PermissionTypes> {
    return this.permissionsApi
      .get(projectId)
      .then((entry) => this.permissionTypesMapper.deserialize(entry));
  }

  getPermissionGroups(projectId: number): Promise<PermissionGroup[]> {
    return this.permissionsApi
      .getPermissionGroups(projectId)
      .then((entries) => entries.map((entry) => this.permissionGroupMapper.deserialize(entry)));
  }

  addPermissionGroup(
    projectId: number,
    data: PermissionsApi.PermissionGroupPostEntry
  ): Promise<PermissionGroup> {
    return this.permissionsApi
      .addPermissionGroup(projectId, data)
      .then((entry) => this.permissionGroupMapper.deserialize(entry));
  }

  deletePermissionGroup(projectId: number, permissionGroupId: string): Promise<void> {
    return this.permissionsApi.deletePermissionGroup(projectId, permissionGroupId);
  }

  editPermissionGroup(
    projectId: number,
    data: PermissionsApi.PermissionGroupPostEntry
  ): Promise<PermissionGroup> {
    return this.permissionsApi
      .editPermissionGroup(projectId, data)
      .then((entry) => this.permissionGroupMapper.deserialize(entry));
  }

  getFlags(): Promise<PermissionFlag[]> {
    return this.permissionsApi
      .getFlags()
      .then((entries) => this.permissionFlagMapper.deserializeList(entries));
  }
}
