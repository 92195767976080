import { Distribution } from 'model/Distribution';
import { DistributionChannel } from 'model/DistributionChannel';

export namespace KioskDistribution {
  export interface Shape extends Distribution.Shape {
    link: string;
  }
}

export class KioskDistribution extends Distribution<KioskDistribution.Shape> {
  getChannel(): DistributionChannel {
    return DistributionChannel.Kiosk;
  }

  getLink(): string {
    return this.get('link');
  }
}
