import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import {
  DropdownListItem,
  DropdownMenu,
  DropdownMenuLabel,
  DropdownWrapper,
} from 'component/Dropdown/Dropdown.s';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export namespace Dropdown {
  export type State = {
    visible: boolean;
  };
  export type Props = {
    label: string;
    noArrow?: boolean;
    variant?: 'primary' | 'clear';
    'data-testid'?: string;
  };
}

export class Dropdown extends Component<Dropdown.Props, Dropdown.State> {
  private wrapperRef: React.ReactInstance | null;

  constructor(props: Dropdown.Props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  render() {
    const { variant = 'primary', 'data-testid': dataTestId } = this.props;

    return (
      <DropdownWrapper ref={(ref: React.ReactInstance) => (this.wrapperRef = ref)}>
        <DropdownMenu
          data-testid={dataTestId}
          onClick={() => this.toggleDropdown()}
          variant={variant}
        >
          <DropdownMenuLabel variant={variant}> {this.props.label} </DropdownMenuLabel>
          {!this.props.noArrow && <ExpandMoreIcon style={{ fontSize: 25 }} />}
        </DropdownMenu>
        {this.state.visible && (
          <DropdownListItem data-testid={dataTestId ? `${dataTestId}DropdownList` : undefined}>
            {React.Children.map(this.props.children, (child: React.ReactChild | any) =>
              React.cloneElement(child, {
                onClick: child.props.onClick,
              })
            )}
          </DropdownListItem>
        )}
      </DropdownWrapper>
    );
  }

  toggleDropdown() {
    this.setState(({ visible }) => ({
      visible: !visible,
    }));
  }

  handleClickOutside: EventListener = (event) => {
    const node = ReactDOM.findDOMNode(this.wrapperRef);

    if (node && !node.contains(event.target as Element)) {
      this.setState({
        visible: false,
      });
    }
  };
}
