import { createSlice } from '@reduxjs/toolkit';
import { addAsyncCases } from 'shared/utils/redux';

import { fetchLoggedUser } from './asyncActions';
import { STORE_NAME, AuthState } from './initialState';

export const authSlice = createSlice({
  name: STORE_NAME,
  initialState: AuthState.INITIAL_DOMAIN,
  reducers: {},
  extraReducers: (builder) => {
    addAsyncCases(builder, fetchLoggedUser, (state, action) => {
      state.loggedUserResource = action.payload;
    });
  },
});
