import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Project } from 'model/Project';
import { PulseTemplate } from 'model/PulseTemplate';
import {
  adaptPreviousSurvey,
  adaptProjectTemplate,
  fetchCurrentProjectTemplate,
  fetchProjectTemplateList,
} from 'reduxStore/projectTemplate/asyncActions';
import { ProjectTemplateState, STORE_NAME } from 'reduxStore/projectTemplate/initialState';
import { addAsyncCases } from 'shared/utils/redux';

export const projectTemplateSlice = createSlice({
  name: STORE_NAME,
  initialState: ProjectTemplateState.INITIAL_DOMAIN,
  reducers: {
    clearProjectTemplateList: (state) => {
      state.listResource = ProjectTemplateState.INITIAL_DOMAIN.listResource;
    },
    clearCurrentProjectTemplate: (state) => {
      state.currentResource = ProjectTemplateState.INITIAL_DOMAIN.currentResource;
    },
    updateCurrentProject: (state, action: PayloadAction<{ project: Project }>) => {
      const currentProject = state.currentResource.getContent();
      if (currentProject instanceof PulseTemplate) {
        state.currentResource.setContent(currentProject.setProject(action.payload.project));
      }
    },
  },
  extraReducers: (builder) => {
    addAsyncCases(builder, fetchProjectTemplateList, (state, action) => {
      state.listResource = action.payload;
    });
    addAsyncCases(builder, fetchCurrentProjectTemplate, (state, action) => {
      state.currentResource = action.payload;
    });
    addAsyncCases(builder, adaptProjectTemplate, (state, action) => {
      state.currentResource = action.payload;
    });
    addAsyncCases(builder, adaptPreviousSurvey, (state, action) => {
      state.currentResource = action.payload;
    });
  },
});

export const {
  clearProjectTemplateList,
  clearCurrentProjectTemplate,
  updateCurrentProject,
} = projectTemplateSlice.actions;
