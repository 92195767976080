import { push } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';

import { FlatButton } from 'component/Button/Button';
import { HeaderItem } from 'view/TopBars/Header/Header.s';
import { labelStyle } from 'view/TopBars/NavigationBar/NavItem/NavItem.s';

namespace NavItem {
  export type DispatchProps = {
    onClick: () => any;
  };
  export type OwnProps = {
    href: string;
    label: string;
  };
  export type Props = DispatchProps & OwnProps;
}

export const NavItemPure: React.VFC<NavItem.Props> = (props) => (
  <HeaderItem>
    <FlatButton onClick={props.onClick} label={props.label} style={labelStyle} />
  </HeaderItem>
);

export const NavItem = connect(undefined, (dispatch, ownProps: NavItem.OwnProps) => ({
  onClick: () => dispatch(push(ownProps.href)),
}))(NavItemPure);
