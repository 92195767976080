import { EmailDistributionApi } from 'api/EmailDistributionApi';
import { List } from 'immutable';
import { DistributionMapperByChannel } from 'mapper/DistributionMapper';
import { EmailTemplateMapper } from 'mapper/EmailTemplateMapper';
import { DistributionChannel } from 'model/DistributionChannel';
import { EmailDistribution } from 'model/EmailDistribution';

export class EmailDistributionMapper
  implements DistributionMapperByChannel<EmailDistribution, EmailDistributionApi.Entry> {
  constructor(private emailTemplateMapper: EmailTemplateMapper) {}

  serialize(model: EmailDistribution): EmailDistributionApi.Entry {
    return {
      id: model.getId(),
      slug: model.getSlug(),
      invitation: model.getInvitation()
        ? this.emailTemplateMapper.serialize(model.getInvitation())
        : null,
      reminders: model
        .getReminders()
        .map((reminder) => this.emailTemplateMapper.serialize(reminder))
        .toArray(),
    };
  }

  deserialize(entry: EmailDistributionApi.Entry): EmailDistribution {
    return new EmailDistribution({
      id: entry.id,
      slug: entry.slug,
      invitation: entry.invitation ? this.emailTemplateMapper.deserialize(entry.invitation) : null,
      reminders: List(
        (entry.reminders || []).map((reminderEntry) =>
          this.emailTemplateMapper.deserialize(reminderEntry)
        )
      ),
    });
  }

  supportsSerialize(model: EmailDistribution): boolean {
    return model.getChannel() === DistributionChannel.Email;
  }

  supportsDeserialize(entry: EmailDistributionApi.Entry): boolean {
    return entry.channel === DistributionChannel.Email;
  }
}
