import React from 'react';
import { SketchPicker } from 'react-color';

import { WrappedFieldProps } from 'redux-form';

export namespace ColorPicker {
  export type Props = WrappedFieldProps;
}

export const ColorPicker: React.VFC<ColorPicker.Props> = (props) => (
  <SketchPicker color={props.input.value} onChange={(color) => props.input.onChange(color.rgb)} />
);
