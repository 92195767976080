import React from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'reduxStore/appStore';
import { logout } from 'reduxStore/auth/asyncActions';
import {
  selectIsCircleAdmin,
  selectIsClientAdmin,
  selectIsWBAdmin,
  selectUserName,
} from 'reduxStore/auth/selectors';
import { selectHasRegularProjects, selectIsInWorkBuzzCircle } from 'reduxStore/circles/selectors';
import { selectCurrentCompany } from 'reduxStore/project/projectSelector';
import {
  BottomLine,
  Header,
  HeaderContentWrapper,
  UserActionsWrapper,
} from 'view/TopBars/Header/Header.s';
import { Logos } from 'view/TopBars/Header/Logos';
import { UserActions } from 'view/TopBars/Header/UserActions';
import { UserLogout } from 'view/TopBars/Header/UserLogout';

export type AppHeaderPureProps = {
  isWBAdmin: boolean;
  isClientAdmin: boolean;
  isCircleAdmin: boolean;
  isInWorkBuzzCircle: boolean;
  hasRegularProjects: boolean;
  companyLogo?: string;
  companyName?: string;
  userName: string;
  onLogout: () => Promise<any>;
};

export const AppHeaderPure: React.VFC<AppHeaderPureProps> = ({
  isCircleAdmin,
  isClientAdmin,
  isWBAdmin,
  isInWorkBuzzCircle,
  hasRegularProjects,
  companyLogo,
  companyName,
  userName,
  onLogout,
}) => {
  return (
    <Header data-testid="header">
      <HeaderContentWrapper>
        <Logos isClientAdmin={isClientAdmin} companyLogo={companyLogo} companyName={companyName} />
        <UserActionsWrapper>
          <UserActions
            isWBAdmin={isWBAdmin}
            isCircleAdmin={isCircleAdmin}
            isClientAdmin={isClientAdmin}
            isInWorkBuzzCircle={isInWorkBuzzCircle}
            hasRegularProjects={hasRegularProjects}
          />
          <UserLogout userName={userName} onLogout={onLogout} />
        </UserActionsWrapper>
      </HeaderContentWrapper>
      <BottomLine />
    </Header>
  );
};

export const AppHeader: React.VFC = () => {
  const dispatch = useAppDispatch();

  const isWBAdmin = useSelector(selectIsWBAdmin);
  const isClientAdmin = useSelector(selectIsClientAdmin);
  const isCircleAdmin = useSelector(selectIsCircleAdmin);
  const currentCompany = useSelector(selectCurrentCompany);
  const userName = useSelector(selectUserName);
  const hasRegularProjects = useSelector(selectHasRegularProjects);
  const isInWorkBuzzCircle = useSelector(selectIsInWorkBuzzCircle);

  const handleLogout = () => {
    return dispatch(logout());
  };

  return (
    <AppHeaderPure
      isWBAdmin={isWBAdmin}
      isClientAdmin={isClientAdmin}
      isCircleAdmin={isCircleAdmin}
      isInWorkBuzzCircle={isInWorkBuzzCircle}
      hasRegularProjects={hasRegularProjects}
      companyLogo={currentCompany?.getLogo()}
      companyName={currentCompany?.getName()}
      userName={userName}
      onLogout={handleLogout}
    />
  );
};
