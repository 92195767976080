import { selectApiData } from 'util/selector/apiSelector';
import { selectResponseData } from 'util/selector/axiosSelector';

import { LangCodeEnum } from 'shared/enums/LangCodeEnum';
import { DemographicApi } from 'api/DemographicApi';
import { DepartmentApi } from 'api/DepartmentApi';
import { PaginationApi } from 'api/PaginationApi';
import { AxiosInstance } from 'axios';
import { PulseLifecycleProjectReceivedEntry } from 'model/Lifecycle';
import { SortDirection } from 'shared/constants';

export namespace EmployeeApi {
  export type Entry = {
    id?: number;
    firstName: string;
    lastName: string;
    email: string;
    preferredLanguage?: LangCodeEnum | null;
    lineManagerEmail?: string;
    department: DepartmentApi.Entry | number;
    departmentNestedName?: string;
    demographyClassifications?: DemographicApi.ClassificationEntry[] | number[];
    classificationValues?: DemographicApi.ClassificationDateEntry[];
    active: number;
    createdAt?: string;
    lifecycleSurveys?: PulseLifecycleProjectReceivedEntry[];
  };
  export type StatsEntry = {
    existingRespondents: number;
    newRespondents: number;
    orphanRespondents: number;
  };
  export type PaginationEntry = PaginationApi.Entry & {
    _embedded: {
      items: EmployeeApi.Entry[];
    };
  };
  export type FilterActiveParam = 0 | 1;

  export type SortParam = {
    name?: SortDirection;
    surveyMethod?: SortDirection;
    lineManager?: SortDirection;
    createdAt?: SortDirection;
    demography?: {
      [demographyId: number]: SortDirection;
    };
  };
}

export class EmployeeApi {
  constructor(private client: AxiosInstance) {}

  list(projectId: number, query: string): Promise<EmployeeApi.PaginationEntry> {
    return this.client
      .get(`/api/v1/project/${projectId}/respondent${query}`)
      .then((response) => selectResponseData(response));
  }

  process(projectId: number, file: File): Promise<void> {
    const data = new FormData();
    data.append('document', file);

    return this.client
      .post(`/api/v1/project/${projectId}/employees-excel/process`, data)
      .then(() => undefined);
  }

  commit(projectId: number, jobId: number, query: string): Promise<void> {
    return this.client
      .post(`/api/v1/project/${projectId}/employees-excel/${jobId}/commit${query}`)
      .then(() => undefined);
  }

  add(projectId: number, employee: EmployeeApi.Entry): Promise<void> {
    return this.client.post(`api/v1/project/${projectId}/employee`, employee).then(() => undefined);
  }

  edit(
    projectId: number,
    employeeId: number,
    employee: EmployeeApi.Entry
  ): Promise<EmployeeApi.Entry> {
    return this.client.post(`/api/v1/employee/${employeeId}`, { ...employee }).then(selectApiData);
  }

  delete(employeeId: number): Promise<void> {
    return this.client.delete(`api/v1/employee/${employeeId}`).then(() => undefined);
  }

  stats(projectId: number): Promise<EmployeeApi.StatsEntry> {
    return this.client
      .get(`/api/v1/project/${projectId}/employees-excel/stats`)
      .then((response) => response.data);
  }

  updateEmail(employeeId: number, email: string): Promise<void> {
    return this.client.post(`api/v1/employee-email/${employeeId}`, { email });
  }
}
