import { ScheduleApi } from 'api/ScheduleApi';
import { Schedule } from 'model/Schedule';
import moment from 'moment';

export class ScheduleMapper {
  serialize(model: Schedule): ScheduleApi.Entry {
    return {
      id: model.getId(),
      date: model.getDate() ? model.getDate().format('YYYY-MM-DD HH:mm:ss') : undefined,
      lastSent: model.getLastSent() ? model.getLastSent().format() : undefined,
      relativeDate: model.getRelativeDate(),
    };
  }

  deserialize(entry: ScheduleApi.Entry): Schedule {
    return new Schedule({
      id: entry.id,
      date: entry.date ? moment(String(entry.date), 'YYYY-MM-DD HH:mm:ss') : undefined,
      lastSent: entry.lastSent ? moment(Date.parse(String(entry.lastSent))) : undefined,
      relativeDate: entry.relativeDate,
    });
  }
}
