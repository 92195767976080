import { PaperDistributionApi } from 'api/PaperDistributionApi';
import { PaperDistributionMapper } from 'mapper/PaperDistributionMapper';
import { PaperDistribution } from 'model/PaperDistribution';

export class PaperDistributionRepository {
  constructor(
    private paperDistributionApi: PaperDistributionApi,
    private paperDistributionMapper: PaperDistributionMapper
  ) {}

  get(projectId: number): Promise<PaperDistribution> {
    return this.paperDistributionApi
      .get(projectId)
      .then((entry) => this.paperDistributionMapper.deserialize(entry));
  }
}
