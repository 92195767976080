import { useState } from 'react';

type PaginationQuery = {
  page: number;
  limit: number;
};

const DEFAULT_PAGINATION_QUERY: PaginationQuery = {
  page: 1,
  limit: 50,
};

type PaginationPageArgs = {
  paginationResult: {
    pages: number | null;
    total: number | null;
  };
  initialValue?: PaginationQuery;
};

export const usePaginationPage = ({
  initialValue,
  paginationResult: { pages, total },
}: PaginationPageArgs) => {
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>(
    initialValue ?? DEFAULT_PAGINATION_QUERY
  );

  const resetPaginationPage = () => {
    setPaginationQuery((prev) => ({ ...prev, page: 1 }));
  };

  const setPaginationPage = (page: number) => {
    setPaginationQuery((prev) => ({ ...prev, page }));
  };

  const isLastPage = pages === paginationQuery.page;
  const isLastPageContainsOnlyOneItem =
    pages !== null && total !== null && pages > 1 && total % paginationQuery.limit === 1;

  return {
    page: paginationQuery.page,
    limit: paginationQuery.limit,
    setPaginationQuery,
    setPaginationPage,
    resetPaginationPage,
    isLastPage,
    isLastPageContainsOnlyOneItem,
  };
};
