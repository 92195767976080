import { Client } from 'model/Client';
import { Pagination } from 'model/Pagination';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'client';

export namespace ClientState {
  export type Domain = {
    listResource: Resource<Client[]>;
    listPagination: Pagination | null;
  };

  export const INITIAL_DOMAIN: Domain = {
    listResource: Resource.pending(),
    listPagination: null,
  };
}
