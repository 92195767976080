import { createAsyncThunk } from '@reduxjs/toolkit';
import { projectSubmitRepository } from 'container/projectSubmitRepository';
import { serializeError } from 'shared/utils/redux';

import { STORE_NAME } from './initialState';

export const fetchProjectSubmits = createAsyncThunk(
  `${STORE_NAME}/fetchProjectSubmits`,
  async ({ projectId }: { projectId: number }) => {
    return projectSubmitRepository.list(projectId);
  },
  { serializeError }
);
