import { useEffect } from 'react';

import { selectAuthUiUrl } from 'util/selector/apiSelector';

// TODO: Remove it when https://buzzing.atlassian.net/browse/WB3-1901 is done, it's a temporal fix.
export const useRedirectToLogin = () => {
  const loginRoutes = ['/login', '/setup-mfa'];
  const loginRegexPatterns = [
    '^/oauth/callback/([\\w\\d-]+)$',
    '^/reset-password/([\\w\\d-]+)$',
    '^/first-password/([\\w\\d-]+)$',
    '^/verify-mfa/([\\w\\d-]+)$',
  ];

  useEffect(() => {
    if (
      loginRoutes.includes(location.pathname) ||
      loginRegexPatterns.some((regexPattern) => new RegExp(regexPattern).test(location.pathname))
    ) {
      window.location.replace(`${selectAuthUiUrl()}${location.pathname}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
};
