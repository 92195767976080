import { selectApiData } from 'util/selector/apiSelector';

import { WithData } from 'api/interfaces';
import { AccessCodeApi } from 'api/AccessCodeApi';
import { EmailTemplateApi } from 'api/EmailTemplateApi';
import { JobApi } from 'api/JobApi';
import { AxiosInstance } from 'axios';

export namespace DeliveryStatisticApi {
  export type Entry = {
    delivered: string[];
    failed: FailedEmailsEntry[];
    enqueued: string[];
    emailTemplate?: EmailTemplateApi.Entry;
  };
  export type FailedEmailsEntry = {
    status: number;
    accessCode: AccessCodeApi.Entry;
  };
}

export class DeliveryStatisticApi {
  constructor(private client: AxiosInstance) {}

  fetch(id: string, daysLimit: number = 0): Promise<DeliveryStatisticApi.Entry[]> {
    const query = daysLimit !== 0 ? `daysLimit=${daysLimit}` : '';

    return this.client
      .get(`/api/v1/distribution/${id}/delivery-stats?${query}`)
      .then(selectApiData);
  }

  refresh(distributionId: string, emailId: number): Promise<number> {
    return this.client
      .post<WithData<JobApi.JobEntry>>(
        `/api/v1/distribution/${distributionId}/email-template/${emailId}/update-statuses`
      )
      .then((response) => selectApiData(response).id);
  }
}
