import { ClientApi } from 'api/ClientApi';
import { List } from 'immutable';
import { Client } from 'model/Client';
import tinycolor from 'tinycolor2';

export class ClientMapper {
  serialize(model: Client): ClientApi.Entry {
    return {
      id: model.getId(),
      circleId: model.getCircleId(),
      name: model.getName(),
      logo: model.getLogo(),
      brandColor: tinycolor(model.getBrandColor()).toHexString(),
      timezone: model.getTimezone(),
      approxEmployeeNumber: model.getApproxEmployeeNumber(),
      sectors: model.getSectors().toArray(),
      plan: model.getPlan(),
      pulse_templates:
        model.getPulseTemplates() &&
        model
          .getPulseTemplates()
          .toArray()
          .map((id) => ({
            id,
            reportDashboardUrl: model.getUrl(),
          })),
    };
  }

  deserialize(entry: ClientApi.Entry): Client {
    return new Client({
      id: entry.id,
      circleId: entry.circleId,
      name: entry.name,
      logo: entry.logo,
      sectors: List(entry.sectors),
      approxEmployeeNumber: entry.approxEmployeeNumber,
      brandColor: tinycolor(entry.brandColor).toRgb(),
      timezone: entry.timezone,
      plan: entry.plan,
      pulseTemplates: List(entry.pulse_templates.map(({ id }) => id)),
      url: entry.pulse_templates.length ? entry.pulse_templates[0].reportDashboardUrl : undefined,
    });
  }
}
