import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { createDemandPoll } from 'reduxStore/project/demandPoll/asyncActions';
import { ProjectState } from 'reduxStore/project/initialState';
import { addAsyncCases } from 'shared/utils/redux';

export const demandPollReducer = (builder: ActionReducerMapBuilder<ProjectState.Domain>) => {
  addAsyncCases(builder, createDemandPoll, (state, action) => {
    state.currentResource = action.payload;
  });
};
