import { selectApiData, selectApiNoContent } from 'util/selector/apiSelector';

import { AnswerApi } from 'api/AnswerApi';
import { AxiosInstance } from 'axios';

export namespace ProjectSubmitApi {
  export type Entry = {
    id: number;
    status: number;
    submitTime: string;
    respondent: EmployeeEntry;
    answers?: AnswerApi.Entry[];
  };

  export type EmployeeEntry = {
    email: string;
    firstName: string;
    lastName: string;
  };
}

export class ProjectSubmitApi {
  constructor(private client: AxiosInstance) {}

  list(projectId: number): Promise<ProjectSubmitApi.Entry[]> {
    return this.client.get(`/api/v1/project/${projectId}/submit`).then(selectApiData);
  }

  delete(projectSubmitId: number): Promise<void> {
    return this.client.delete(`/api/v1/project/submit/${projectSubmitId}`).then(selectApiNoContent);
  }
}
