import { List } from 'immutable';
import { Enum } from 'model/Enum';
import { SimpleModel } from 'model/SimpleModel';

export namespace Constant {
  export interface Shape {
    benchmarkSectors: List<Enum>;
    pulseFrequencies: List<Enum>;
    employeeRangeGroup: List<Enum>;
    demographics: List<Enum>;
  }
}

export class Constant extends SimpleModel<Constant.Shape> {
  getBenchmarkSectors(): List<Enum> {
    return this.get('benchmarkSectors');
  }

  getPulseFrequencies(): List<Enum> {
    return this.get('pulseFrequencies');
  }

  getEmployeeRangeGroup(): List<Enum> {
    return this.get('employeeRangeGroup');
  }

  getDemographic(): List<Enum> {
    return this.get('demographics');
  }
}
